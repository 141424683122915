//const pathUrl = 'http://localhost:5959';
//const pathWebapp = 'http://localhost:8989';

const pathUrl = 'https://api.archivix.net';
const pathWebapp = 'https://app.archivix.net';

const appDeclarations = {
    connectedUser: 'connected_____use235',
    connectedCompany: 'connected_company__001',
    currentLang: 'current_language',
    connectionStatus: 'connect_status',
    apiRootTest: `${pathUrl}/api/`,
    apiUrlTest: pathUrl,
    apiPath: pathUrl,
    webAppPath: pathWebapp,
    documentsPath: `${pathUrl}/cloud_data/documents`,
    profilesPath: `${pathUrl}/cloud_data/profiles`,
    chatDoctsPath: `${pathUrl}/cloud_data/documentchats`,
    documentsSign: `${pathUrl}/cloud_data/documents_sign_ops`,
    documentsPartners: `${pathUrl}/cloud_data/partners_sign_ops`,

    userSignatures: `${pathUrl}/cloud_data/user_signatures`,
    partnerSignatures: `${pathUrl}/cloud_data/partner_signatures`,
    
    companyResourcesPath: `${pathUrl}/cloud_data/company_res`,

    userAccessKey: 'user_access_key',


    company_code: 'WBTARCHAPP001-UPMANGT',
    currentYearbook: 'current_year_book',


    currentDefaultSmsAcct: 'current_def_sms_acct',
    fileSizeallowed: 'file_size_allowed',
    itemsPerPage: 'items_per_page',
    currentDevise: 'current_devise',
    currentBranch: 'current_branch',
    userEmail: 'user_email',
    userPassword: 'userPassword',
    cartZoneArea: 'cart___zone_area____2363',
    mainPhoneNbr: '250 788 510 589',
    waitingEmailForPw: 'awaiting_____email_for_pw____x25'
}
export default appDeclarations;