import React from "react";

import { ReactComponent as AppleIcon } from "src/assets/images/svg/icons/apple.svg";
import { ReactComponent as GithubIcon } from "src/assets/images/svg/icons/github.svg";
import { ReactComponent as GoogleIcon } from "src/assets/images/svg/icons/google.svg";
import { ReactComponent as MicrosoftIcon } from "src/assets/images/svg/icons/microsoft.svg";
import { ReactComponent as TwitterIcon } from "src/assets/images/svg/icons/twitter.svg";

//import appLanguages from "src/utils/language";
//const languages = appLanguages();

interface ButtonLinkWithIconProps {
  buttonType: "google" | "apple" | "github" | "twitter" | "microsoft";
  onClick?: () => void;
  isFull?: boolean;
}

export default function ButtonLinkWithIcon({
  buttonType,
  onClick,
  isFull,
}: ButtonLinkWithIconProps) {
  const IconSvg =
    buttonType === "apple"
      ? AppleIcon
      : buttonType === "github"
      ? GithubIcon
      : buttonType === "google"
      ? GoogleIcon
      : buttonType === "microsoft"
      ? MicrosoftIcon
      : buttonType === "twitter"
      ? TwitterIcon
      : AppleIcon;

  const IconTitle =
    buttonType === "apple"
      ? "Apple"
      : buttonType === "github"
      ? "Github"
      : buttonType === "google"
      ? "Google"
      : buttonType === "microsoft"
      ? "Microsoft"
      : buttonType === "twitter"
      ? "Twitter"
      : "Apple";

  const bWider = isFull ?? false;

  const onButtonClick = () => {
    if (!onClick) return;
    onClick!();
  };

  return (
    <button
      className="border border-solid bg-gray-50 border-gray-200 rounded-lg flex justify-center items-center py-2 hover:bg-gray-100"
      onClick={onButtonClick}
    >
      <div className={`flex items-center gap-2 ${bWider ? "w-full" : "w-fit"}`}>
        <IconSvg width={30} height={30} />
        <strong className="tracking-widest text-lg">{IconTitle}</strong>
      </div>
    </button>
  );
}

interface ISmallWiseBtn {
  isBusy?: boolean;
  disabled?: boolean;
  label: string;
  onClick: () => void;
}
export const SmallWiseBtn = ({
  isBusy,
  label,
  onClick,
  disabled,
}: ISmallWiseBtn) => {
  const bBusy = isBusy ?? false;
  const bDisabled = disabled ?? false;

  return (
    <button
      className={`btn btn-sm text-white px-[1.2rem] rounded-md bg-green-400 hover:bg-green-700 disabled:bg-gray-400 disabled:text-gray-200 disabled:cursor-not-allowed`}
      onClick={onClick}
      disabled={bBusy || bDisabled}
    >
      <span className="flex flex-row items-center gap-2">
        {bBusy && <i className="fa fa-spin fa-spinner"></i>}
        <span>{label}</span>
      </span>
    </button>
  );
};
