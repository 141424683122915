import React, { useState } from "react";
import { SelectorBtnType } from "src/models/smarttypes";
import { SignerListTypes } from "src/utils/app_arch_data";

import { ReactComponent as AllFilesSvg } from "src/assets/images/svg/all-files.svg";
import { ReactComponent as DraftFilesSvg } from "src/assets/images/svg/draft-file.svg";
import { ReactComponent as CompletedFilesSvg } from "src/assets/images/svg/completed-file.svg";
import { ReactComponent as PendingFilesSvg } from "src/assets/images/svg/pending-file.svg";
import { ReactComponent as InboxFilesSvg } from "src/assets/images/svg/inbox-file.svg";

import getLanguages from "src/utils/language";

const languages = getLanguages();

interface IGroupButtonImgSelector {
  changeSrcType: (e: number) => void;
}
export const GroupButtonImgSelector = ({
  changeSrcType,
}: IGroupButtonImgSelector) => {
  const [sourceType, setSourceType] = useState(1);

  const onChangeSelector = (x: number) => {
    setSourceType(x);
    changeSrcType(x);
  };

  return (
    <div className="w-fit inline-flex rounded-md" role="group">
      <button
        type="button"
        onClick={() => onChangeSelector(1)}
        className={`${
          sourceType === 1
            ? "bg-gray-100 text-blue-700"
            : "bg-white text-gray-900"
        } px-4 py-2 text-sm font-medium border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700`}
      >
        <i className="bi bi-file-pdf-fill"></i>&nbsp;
        <span>{languages.pdf}</span>
      </button>
      <button
        type="button"
        onClick={() => onChangeSelector(2)}
        className={`${
          sourceType === 2
            ? "bg-gray-100 text-blue-700"
            : "bg-white text-gray-900"
        } px-4 py-2 text-sm font-medium border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700`}
      >
        <i className="bi bi-file-word-fill"></i>&nbsp;
        <span>{languages.word}</span>
      </button>
      <button
        onClick={() => onChangeSelector(0)}
        type="button"
        className={`${
          sourceType === 0
            ? "bg-gray-100 text-blue-700"
            : "bg-white text-gray-900"
        } px-4 py-2 text-sm font-medium   border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700`}
      >
        <i className="bi bi-images"></i>&nbsp;
        <span>{languages.images}</span>
      </button>
    </div>
  );
};

interface IGroupButtonSignerSelector {
  updateStatus: (index: number) => void;
}
export const GroupButtonSignerSelector = ({
  updateStatus,
}: IGroupButtonSignerSelector) => {
  const [selType, setSelType] = useState(0);

  const onChangeSelector = (x: number) => {
    setSelType(x);
    updateStatus(x);
  };

  return (
    <div className="w-fit inline-flex rounded-md" role="group">
      {SignerListTypes.map((oType, index) => (
        <GroupButtonElt
          key={index}
          oType={oType}
          selected={selType === index}
          onClick={() => onChangeSelector(index)}
        />
      ))}
    </div>
  );
};
interface IGroupButtonElt {
  oType: SelectorBtnType;
  selected: boolean;
  onClick: () => void;
}
const GroupButtonElt = ({ oType, selected, onClick }: IGroupButtonElt) => {
  const styleAround =
    oType.type === "all"
      ? "px-2 py-2 text-sm font-medium border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700"
      : oType.type === "inbox"
      ? "px-2 py-2 text-sm font-medium   border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700"
      : "px-2 py-2 text-sm font-medium border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700";
  const styleSel = selected
    ? "bg-gray-100 text-blue-700"
    : "bg-white text-gray-900";

  const SvgIcon =
    oType.type === "all"
      ? AllFilesSvg
      : oType.type === "completed"
      ? CompletedFilesSvg
      : oType.type === "draft"
      ? DraftFilesSvg
      : oType.type === "pending"
      ? PendingFilesSvg
      : oType.type === "inbox"
      ? InboxFilesSvg
      : AllFilesSvg;

  return (
    <button
      type="button"
      onClick={onClick}
      className={`${styleSel} ${styleAround} flex items-center gap-x-2`}
    >
      <SvgIcon width={18} height={18} />
      <span>{oType.label}</span>
    </button>
  );
};
