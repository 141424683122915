import React from "react";
import { WiseModalFullScreen } from "./modalportal/WiseModal";
import { PreviewerMobileComp } from "./PreviewerMiniatComp";

interface IModalDocumentPreviwer {
  isOpen: boolean;
  onClose?: () => void;
  padfArray: ArrayBufferLike;
}
export default function ModalDocumentPreviwer({
  isOpen,
  onClose,
  padfArray,
}: IModalDocumentPreviwer) {
  const closeDlg = () => {
    if (!onClose) return;
    onClose!();
  };

  if (!isOpen) return null;
  return (
    <WiseModalFullScreen onClose={closeDlg}>
      <PreviewerMobileComp
        pdfFile={padfArray!}
        onCloseDlg={closeDlg}
      />
    </WiseModalFullScreen>
  );
}
