import React, {  useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import appDecl from "src/utils/declarations";
import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";

import NetServices from "src/utils/netservices";

import { PreviewerMiniatComp } from "src/components/PreviewerMiniatComp";
import { ListSearchDocsRecords } from "src/components/ListSearchDocsRecords";
import ModalDocumentPreviwer from "src/components/ModalDocumentPreviwer";
import useMediaQuery from "src/utils/primitives/usemediaqueries";

const languages = getLanguages();
const appFxs = getFxs();


export default function SearchDoctsPage() {
  document.title = `${languages.search} - Archivix`;

  const [pdfArrayLoading, setPdfArrayLoading] = useState<boolean>(false);


  const [padfArray, setPdfArray] = useState<ArrayBufferLike | null>(null);

  const [isModelVOpen, setModelVOpen] = useState(false);
  const [isDlgReady, setDlgReady] = useState(false);
  const isPlatformMobile = useMediaQuery("(max-width: 1023px)");


  const navigate = useNavigate();

  const previewFolder = (fldrKey: string) => {
    navigate(`/app/documents/folders/${fldrKey}`);
  };

  


  

  const deleteDocument = async (folderKey: string) => {
    const bASk = await appFxs.showConfirm(
      languages.deleteFolder,
      languages.deleteFolderMsg
    );
    if (!bASk.isConfirmed) return;

    const oOper = await NetServices.requestPost(
      "documents/deletedocumentfolderrecordextended",
      { folderKey },
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }
  };

  const previewDetailFile = async (cdoctKey: string, fileKey: string) => {
    setPdfArrayLoading(true);

    const filePath = `${appDecl.documentsPath}/${cdoctKey}/${fileKey}/${fileKey}.pdf`;
    const fileBuffer = await appFxs.getFileBlobBuffer(filePath, true);

    if (!fileBuffer) {
      setPdfArray(null);
      setPdfArrayLoading(false);
      return;
    }

    setPdfArray(fileBuffer);
    setPdfArrayLoading(false);

    if(isPlatformMobile){
      setModelVOpen(true);
    }
  };
  const deleteDoctFile = async (fileKey: string) => {
    const bAsk = await appFxs.showConfirm(
      languages.deleteFile,
      languages.deleteFileMsg
    );
    if (!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPost(
      "documents/deletefiledocumentrecord",
      {
        fileKey: fileKey,
        reloadData: true,
      },
      true
    );
    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

  };
  const editDoctFile = (fileKey: string) => {
    navigate(`/app/documents/edit/${fileKey}`);
  };
  const detailDoctFile = (fileKey: string) => {
    const path = `/app/documents/previewdoct/${fileKey}`;
    navigate(path);
  };

  const operationFolder = (
    operType: "prev" | "del" | "edit",
    fldKey: string
  ) => {
    if (operType === "prev") previewFolder(fldKey);
    if (operType === "del") deleteDocument(fldKey);
    //if (operType === "edit") editFolderDlg(fldKey);
  };
  const operationDoct = (
    operType: "detail" | "prev" | "del" | "edit",
    doctKey: string,
    fileKey: string,
  ) => {
    if (operType === "prev") previewDetailFile(doctKey, fileKey);
    if (operType === "del") deleteDoctFile(fileKey);
    if (operType === "edit") editDoctFile(fileKey);
    if (operType === "detail") detailDoctFile(fileKey);
  };


  useEffect(() => {
    setDlgReady(isPlatformMobile);
    if (!isPlatformMobile) {
      setPdfArray(null);
    }
  }, [isPlatformMobile]);

  
  return (
    <>
    {padfArray && (
        <ModalDocumentPreviwer
          isOpen={isModelVOpen && isDlgReady}
          padfArray={padfArray!}
          onClose={() => {
            setModelVOpen(false);
            setPdfArray(null);
          }}
        />
      )}
      <div className="flex flex-wrap justify-between">
        <div className="basis-full md:basis-[65%]">
            <ListSearchDocsRecords 
              operationDoct={operationDoct} 
              operationFolder={operationFolder} />
        </div>
        <div className="hidden md:block md:basis-[32%]">
            <PreviewerMiniatComp
              loading={pdfArrayLoading}
              pdfFile={padfArray!}
              clearPdf={() => setPdfArray(null)}
            />
        </div>
      </div>
    </>
  )
}
