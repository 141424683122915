import { emulateTab } from 'emulate-tab';

import getLanguages from "src/utils/language";
import generalFxs from 'src/utils/general_fx';


const languages = getLanguages();
const appFxs = generalFxs();

interface IWiseModalTextInput {
  fieldName: string;
  label: string;
  value: string | number;
  disabled?: boolean;
  onChange?: (f: string, v: string | number) => void;
  errorText?: string;
  placeholder?: string;
  graphicType?:
    | "email"
    | "person"
    | "calendar"
    | "phone"
    | "person"
    | "lock"
    | "key"
    | "office"
    | "sex"
    | "folder"
    | "location"
    | "map"
    | "file"
    | undefined;
  fldType: React.HTMLInputTypeAttribute | "select" | "textarea";
  selectOptions?: {value: string | number, label: string}[],
  maxLength?: number,
  isNumeric?: boolean,
  selectallFocus?: boolean,
  moveToNext?: boolean,
  useTabEnter?: boolean,
  tabIndex?: number,
  showLabel?: boolean,
  rows?: number,
}

export default function WiseModalTextInput({
  value,
  disabled,
  fieldName,
  onChange,
  errorText,
  fldType,
  label,
  placeholder,
  maxLength,
  isNumeric,
  graphicType,
  selectallFocus,
  selectOptions,
  moveToNext,
  useTabEnter,
  tabIndex,
  showLabel,
  rows,
}: IWiseModalTextInput) {
  const placH = placeholder ?? languages.fillThisField;
  const mLength = maxLength ?? 35;
  const bNumeric = isNumeric ?? false;
  const fType = fldType ?? 'text';
  const bSelectAllFoc = selectallFocus ?? true;
  const sOptions = selectOptions ?? [{value: '', label: languages.notSpecified}];
  
  const bMoveToNext = moveToNext ?? false;
  const bUseTabEnter = useTabEnter ?? true;
  const bShowLabel = showLabel ?? false;

  
  const iconClass = 
  !graphicType ? null:
  graphicType === 'email' ? 'bi bi-envelope':
  graphicType === 'person' ? 'bi bi-file-person':
  graphicType === 'calendar' ? 'bi bi-calendar3':
  graphicType === 'phone' ? 'bi bi-telephone':
  graphicType === 'lock' ? 'bi bi-lock':
  graphicType === 'key' ? 'bi bi-key':
  graphicType === 'office' ? 'bi bi-building':
  graphicType === 'sex' ? 'bi bi-bezier2':
  graphicType === 'folder' ? 'bi bi-folder':
  graphicType === 'location' ? 'bi bi-house-down-fill':
  graphicType === 'map' ? 'bi bi-pin-map-fill':
  graphicType === 'file' ? 'bi bi-file-earmark-break-fill':
  'bi bi-envelope';

  const onChangeVal = (fldVal: string) => {      
      if(!onChange)return;
      onChange!(fieldName, fldVal);
  }
  const onFocusInput = (e: React.FocusEvent<HTMLInputElement>|React.FocusEvent<HTMLTextAreaElement>) => {
    if(!bSelectAllFoc) return;
    e.target.select();
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>|React.KeyboardEvent<HTMLTextAreaElement>) => {
    if(bNumeric) appFxs.strictlyNbr(e);
  }

  const onInputKeyup = (e: React.KeyboardEvent<HTMLInputElement>|React.KeyboardEvent<HTMLTextAreaElement>) => {
    if(e.key === 'Enter'){
      if(bUseTabEnter) emulateTab();
    }else{
      const len = e.currentTarget.value.length;
      if(len === mLength && bMoveToNext) emulateTab();        
    }
  }

  return (
    <label className="flex flex-col mb-2">
      {bShowLabel && (
        <div className="label">
          <span className="label-text">{label}</span>
        </div>
      )}
      <div
        className={`relative border border-solid ${
          errorText ? "border-red-400" : "border-gray-400"
        } rounded-md px-2 ${
          errorText
            ? "focus-within:border-red-600"
            : "focus-within:border-gray-600"
        }`}
      >
        <div data-type={fType} className={`flex flex-row items-center data-[type='textarea']:items-start gap-2`}>
          {graphicType && (
            <i data-error={errorText && errorText.length > 0}
              data-type={fType}
              className={`${iconClass} text-gray-500 data-[error=true]:text-red-500 data-[type='textarea']:mt-4`}
            ></i>
          )}
          {fType === "textarea" && (
            <textarea
              data-error={errorText && errorText.length > 0}
              data-type={fType}
              placeholder={placH.toLowerCase()}
              className={`resize-none flex-1 text-black data-[error=true]:text-error font-light focus:border-none focus-visible:border-none focus-visible:outline-none py-3 disabled:bg-transparent placeholder:text-gray-400/80" data-[error=true]:placeholder:text-red-400/60`}
              value={value}
              name={fieldName}
              id={fieldName}
              onChange={(e) => onChangeVal(e.target.value)}
              disabled={disabled ?? false}
              maxLength={mLength}
              onFocus={onFocusInput}
              onKeyPress={onKeyPress}
              onKeyUp={onInputKeyup}
              tabIndex={tabIndex}
              rows={rows ?? 3}></textarea>
          )}
          {!(fType === "select" || fType === "textarea") && (
            <input
              type={fType}
              placeholder={placH.toLowerCase()}
              className={`flex-1 ${
                errorText ? "text-error" : "text-black"
              } font-light focus:border-none focus-visible:border-none focus-visible:outline-none py-3 disabled:bg-transparent ${
                errorText
                  ? "placeholder:text-red-400/60"
                  : "placeholder:text-gray-400/80"
              }`}
              value={value}
              name={fieldName}
              id={fieldName}
              onChange={(e) => onChangeVal(e.target.value)}
              disabled={disabled ?? false}
              maxLength={mLength}
              onFocus={onFocusInput}
              onKeyPress={onKeyPress}
              onKeyUp={onInputKeyup}
              tabIndex={tabIndex}
            />
          )}
          {fType === "select" && (
            <select
              className="input input-bordered w-full ring-1"
              value={value}
              name={fieldName}
              id={fieldName}
              onChange={(e) => onChangeVal(e.target.value)}
              disabled={disabled ?? false}
            >
              {sOptions.map((o, idx) => (
                <option key={idx} value={o.value}>
                  {o.label}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>

      {errorText && (
        <div className="label">
          <span className="label-text-alt text-red-500">{errorText}</span>
        </div>
      )}
    </label>
  );
}

