import React from 'react';

import getLanguages from "src/utils/language";

const languages = getLanguages();

interface ILoadinInterfaceComp {
    height?: number,
    text?: string,
}
export default function LoadinInterfaceComp({height, text}:ILoadinInterfaceComp) {
    const iHeight = height ?? 150;
    const iText = text ?? languages.loading;
  return (
    <div style={{height: iHeight}} className='flex flex-col items-center justify-center'>
        <i className='fa fa-spin fa-spinner fa-3x'></i>
        <p className='py-4 font-semibold'>{`${iText}...`}</p>
    </div>
  )
}

interface INoRecordFoundComp {
  text?: string,
  onReload?: () => void,
}
export const NoRecordFoundComp = ({onReload, text}: INoRecordFoundComp) => {
  const onClick = () => {
    if(!onReload)return;
    onReload!();
  }
  return (
    <div className='flex flex-col justify-center items-center min-h-[200px] w-full space-y-2'>
      <h3 className='text-2xl font-semibold'>{text ?? languages.noRecFnd}</h3>
      <button className='btn' onClick={onClick}>
        {languages.reload}
      </button>
    </div>
  )
}