import { ReactComponent as CancelIconSvg } from "src/assets/images/svg/cancel.svg";
import { ReactComponent as SaveIconSvg } from "src/assets/images/svg/save-outline-icon.svg";
import { ReactComponent as TrashIconSvg } from "src/assets/images/svg/delete-outline-icon.svg";

interface IWiseSpecialBtn {
  type?: "primary" | "secondary" | "destructive",
  label: string,
  iconType?: "save" | "delete" | "cancel",
  loading?: boolean,
  disabled?: boolean,
  onClick?: () => void,
}
export const WiseSpecialBtn = ({
  type,
  label,
  iconType,
  disabled,
  loading,
  onClick,
}: IWiseSpecialBtn) => {
  const btnType = type ?? "primary";
  const bLoading = loading ?? false;
  const IconToDisplay = !iconType
    ? null
    : iconType === "cancel"
    ? CancelIconSvg
    : iconType === "delete"
    ? TrashIconSvg
    : iconType === "save"
    ? SaveIconSvg
    : CancelIconSvg;

    const handleClick = () => {
        if(!onClick) return;
        onClick();
    }

  return (
    <button
      data-btntype={btnType}
      disabled={disabled}
      className="inline-flex items-center px-3 py-2 data-[btntype='primary']:bg-gray-200 data-[btntype='primary']:hover:bg-gray-300 data-[btntype='primary']:text-gray-800  data-[btntype='destructive']:bg-red-600 data-[btntype='destructive']:hover:bg-red-700 data-[btntype='destructive']:text-white data-[btntype='secondary']:bg-indigo-500 data-[btntype='secondary']:hover:bg-indigo-600 data-[btntype='secondary']:text-white text-sm font-medium rounded-md"
      onClick={handleClick}
    >
      {(IconToDisplay && !bLoading) && (
        <IconToDisplay className="h-5 w-5 mr-2" stroke="currentcolor" />
      )}
      {bLoading && <><i className="fa fa-spinner fa-spin"></i>&nbsp;</>}
      {label}
    </button>
  );
};
