import React, { useRef } from "react";

import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";
import appDecl from "src/utils/declarations";

import { ReactComponent as PlusDoct } from "src/assets/images/svg/plus-doct.svg";
import { mimeTypes } from "src/utils/app_arch_data";
import { FILE_MB_SIZE } from "src/utils/constants";


interface IFilesReceiverDropSign {
  isLoading: boolean;
  disabled: boolean;
  afterDropFile: (file: File) => void;
}
const languages = getLanguages();
const appFxs = getFxs();

const fileSizeAllowed = Number(
  appFxs.getLocalStorageByKey(appDecl.fileSizeallowed, "2")
);

export const FilesReceiverSignDrop = ({
  disabled,
  isLoading,
  afterDropFile,
}: IFilesReceiverDropSign) => {
  const fileCollector = useRef<HTMLInputElement>(null);

  const onSelectFile = () => {
    if (disabled) return;
    if (isLoading) return;
    fileCollector.current?.click();
  };
  const onDropfiles = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    if (disabled) return;
    if (isLoading) return;

    if (!ev.dataTransfer.items) return;
    const item = [...ev.dataTransfer.items][0];
    if (item.kind !== "file") return;
    const file = item.getAsFile()!;
    if (!appFxs.isFilePDF(file)) return;

    if (file.size / FILE_MB_SIZE > fileSizeAllowed) {
      appFxs.showAlert(languages.files, languages.fileSizeErrorMsg);
      return;
    }

    afterDropFile(file);
  };
  const onInputFile = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;
    if (isLoading) return;
    if (!ev.target.files) {
      fileCollector!.current!.value = "";
      return;
    }

    const files = ev.target.files;
    if (files.length === 0) {
      fileCollector!.current!.value = "";
      return;
    }

    const file = files[0];

    if (file.size / FILE_MB_SIZE > fileSizeAllowed) {
      appFxs.showAlert(languages.files, languages.fileSizeErrorMsg);
      return;
    }

    afterDropFile(files[0]);
  };

  return (
    <div
      className="image-wrapper realative"
      onClick={onSelectFile}
      onDrop={onDropfiles}
      onDragOver={(e) => e.preventDefault()}
    >
      <div className="flex min-h-[40vh]" style={{ transform: "none" }}>
        <div className="bg-background text-foreground relative rounded-lg border-2 backdrop-blur-[2px] before:pointer-events-none before:absolute before:-inset-[2px] before:rounded-lg before:p-[2px] before:[background:linear-gradient(120deg,#006400)] dark:shadow-[0] focus-visible:ring-ring ring-offset-background flex flex-1 cursor-pointer flex-col items-center justify-center focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 aria-disabled:pointer-events-none aria-disabled:opacity-60 min-h-[40vh]">
          <div className="text-muted-foreground/40 flex flex-col items-center justify-center p-6 mt-6">
            <div className="flex">
              <div className="doct-interface interface-left aspect-[3/4] z-10 flex flex-col gap-y-1 w-24 origin-top-right -rotate-[22deg] py-4 px-2">
                <div className="bg-[#04f771] h-2 w-full rounded-[2px]"></div>
                <div className="bg-[#04f771] h-2 w-5/6 rounded-[2px]"></div>
                <div className="bg-[#04f771] h-2 w-full rounded-[2px]"></div>
              </div>
              <div className="doct-interface interface-middle w-24 z-20 flex aspect-[3/4] flex-col items-center justify-center gap-y-1 rounded-lg x-2 py-4 backdrop-blur-sm">
                <PlusDoct
                  className="w-12 h-12 block"
                  fill="#04f771"
                  stroke="#04f771"
                />
              </div>
              <div className="doct-interface interface-right z-10 flex aspect-[3/4] w-24 origin-top-left rotate-[22deg] flex-col gap-y-1 rounded-lg border px-2 py-4 backdrop-blur-sm">
                <div className="bg-[#04f771] h-2 w-full rounded-[2px]"></div>
                <div className="bg-[#04f771] h-2 w-5/6 rounded-[2px]"></div>
                <div className="bg-[#04f771] h-2 w-full rounded-[2px]"></div>
              </div>
            </div>
            <input
              accept={mimeTypes[1]}
              type="file"
              tabIndex={-1}
              style={{ display: "none" }}
              ref={fileCollector}
              multiple={false}
              onChange={onInputFile}
            />
            {!isLoading && (
              <React.Fragment>
                <p className="group-hover:text-foreground text-muted-foreground mt-8 font-medium">
                  {languages.addDocument}
                </p>
                <p className="text-muted-foreground/80 mt-1 text-sm ">
                  {languages.dragDropDoctHere}
                </p>
              </React.Fragment>
            )}
            {isLoading && (
              <div className="flex justify-center items-center h-[110px]]">
                <span className="loading loading-ring w-[100px]"></span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
