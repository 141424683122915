/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as ArrowBigLeft } from "src/assets/images/svg/icons/arrow-left.svg";
import { ReactComponent as ArrowBigRight } from "src/assets/images/svg/icons/arrow-right.svg";

import { ReactComponent as Circle } from "src/assets/images/svg/icons/Circle.svg";
import { ReactComponent as CircleDot } from "src/assets/images/svg/icons/CircleDot.svg";

import whiteImg from "src/assets/images/white-img-v2.png";

import appDecl from "src/utils/declarations";
import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";

import { ReactComponent as PlusDoct } from "src/assets/images/svg/plus-doct.svg";
import { mimeTypes } from "src/utils/app_arch_data";
import { IFileElt } from "src/models/smarttypes";
import { FILE_MB_SIZE } from "src/utils/constants";

const languages = getLanguages();
const appFxs = getFxs();

const fileSize = appFxs.getLocalStorageByKey(appDecl.fileSizeallowed, "2");

interface IFileSelectorComponent {
  mimeType: string;
  addFile: (e: IFileElt[]) => void;
  removeFiles: () => void;
  updateFiles: (e: IFileElt[]) => void;
  saving: boolean;
}

export default function FileSelectorComponent({
  mimeType,
  addFile,
  removeFiles,
  updateFiles,
  saving,
}: IFileSelectorComponent) {
  const fileCollector = useRef<HTMLInputElement>(null);

  const [imageFiles, setImageFiles] = useState<IFileElt[]>([]);
  const [defaultImgPos, setDefaultImgPos] = useState(0);

  const onSelectFile = () => {
    fileCollector.current?.click();
  };

  const finalFileSize = Number(fileSize);

  const onDropfiles = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      const item = [...ev.dataTransfer.items][0];
      if (item.kind !== "file") return;
      const file = item.getAsFile()!;
      const bAccept =
        appFxs.isFilePDF(file) ||
        appFxs.isFileWord(file) ||
        appFxs.isFileImage(file);

      if (!bAccept) {
        appFxs.showAlert(languages.files, languages.dropFileErrorMsg);
        return;
      }

      if (file.size / FILE_MB_SIZE > finalFileSize) {
        appFxs.showAlert(languages.files, languages.fileSizeErrorMsg);
        return;
      }

      if (appFxs.isFileImage(file) && !(mimeType === mimeTypes[0])) return;
      if (appFxs.isFilePDF(file) && !(mimeType === mimeTypes[1])) return;
      if (appFxs.isFileWord(file) && !(mimeType === mimeTypes[2])) return;

      const ext = file.name.split(".").pop()!;

      addFile([
        {
          fileBlob: file,
          fileKeyExt: `${appFxs.generateUUID()}.${ext}`,
        },
      ]);
    }
  };
  const onReceiveFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;

    const someGreater = Array.from(e.target.files!).some(x => (x.size / FILE_MB_SIZE) > finalFileSize)
    if(someGreater){
      appFxs.showAlert(languages.files, languages.fileSizeErrorMsg);
      return;
    }

    const file = e.target.files![0];
    if (appFxs.isFilePDF(file) && !(mimeType === mimeTypes[1])) return;
    if (appFxs.isFileWord(file) && !(mimeType === mimeTypes[2])) return;

    if (mimeType === mimeTypes[0]) {
      const xFiles: IFileElt[] = [];

      Array.from(e.target.files!).forEach((o) => {
        if (appFxs.isFileImage(o)) {
          const ext = o.name.split(".").pop()!;

          xFiles.push({
            fileBlob: o,
            fileKeyExt: `${appFxs.generateUUID()}.${ext}`,
          });
        }
      });

      const fFinal = [...imageFiles];
      fFinal.push(...xFiles);

      setImageFiles(fFinal);
      addFile(xFiles);
    }
    if (mimeType === mimeTypes[1] || mimeType === mimeTypes[2]) {
      setImageFiles([
        {
          fileBlob: file,
          fileKeyExt: appFxs.generateUUID(),
        },
      ]);
      addFile([
        {
          fileBlob: file,
          fileKeyExt: appFxs.generateUUID(),
        },
      ]);
    }
  };
  const onRemoveFiles = () => {
    setImageFiles([]);
    removeFiles();
  };
  const deleteImageFile = async (fileKey: string) => {
    const bAsk = await appFxs.showConfirm(
      languages.delete,
      languages.deleteImgQst
    );
    if (!bAsk.isConfirmed) return;

    const allFiles = imageFiles.filter((o) => o.fileKeyExt !== fileKey);
    setImageFiles(allFiles);
    setDefaultImgPos(0);
    updateFiles(allFiles);
  };
  const previewImageFile = (fileKey: string) => {
    const oFile = imageFiles.find((o) => o.fileKeyExt === fileKey);
    if (!oFile) return;

    const fileUrl = URL.createObjectURL(oFile!.fileBlob);
    window.open(fileUrl, "_blank", "width=800,height=600,resizable=1");
  };
  const rearrangePage = (oper: "prev" | "next", imagePos: number) => {
    const newArr = appFxs.rearrangeArrToPos(
      imageFiles,
      imagePos,
      oper
    ) as IFileElt[];

    setImageFiles(newArr);

    const newPos =
      oper === "prev" && imagePos > 0
        ? imagePos - 1
        : oper === "next" && imagePos < imageFiles.length - 1
        ? imagePos + 1
        : imagePos;

    setDefaultImgPos(newPos);
    updateFiles(newArr);
  };

  useEffect(() => {
    setImageFiles([]);
  }, [mimeType]);

  return (
    <div
      className="image-wrapper realative"
      onClick={onSelectFile}
      onDrop={onDropfiles}
      onDragOver={(e) => e.preventDefault()}
    >
      <div className="flex min-h-[40vh]" style={{ transform: "none" }}>
        {imageFiles.length === 0 && (
          <div className="bg-background text-foreground relative rounded-lg border-2 backdrop-blur-[2px] before:pointer-events-none before:absolute before:-inset-[2px] before:rounded-lg before:p-[2px] before:[background:linear-gradient(120deg,#006400)] dark:shadow-[0] focus-visible:ring-ring ring-offset-background flex flex-1 cursor-pointer flex-col items-center justify-center focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 aria-disabled:pointer-events-none aria-disabled:opacity-60 min-h-[40vh]">
            <div className="text-muted-foreground/40 flex flex-col items-center justify-center p-6">
              {/* animation */}
              <div className="flex">
                <div className="doct-interface interface-left aspect-[3/4] z-10 flex flex-col gap-y-1 w-24 origin-top-right -rotate-[22deg] py-4 px-2">
                  <div className="bg-[#04f771] h-2 w-full rounded-[2px]"></div>
                  <div className="bg-[#04f771] h-2 w-5/6 rounded-[2px]"></div>
                  <div className="bg-[#04f771] h-2 w-full rounded-[2px]"></div>
                </div>
                <div className="doct-interface interface-middle w-24 z-20 flex aspect-[3/4] flex-col items-center justify-center gap-y-1 rounded-lg x-2 py-4 backdrop-blur-sm">
                  <PlusDoct
                    className="w-12 h-12 block"
                    fill="#04f771"
                    stroke="#04f771"
                  />
                </div>
                <div className="doct-interface interface-right z-10 flex aspect-[3/4] w-24 origin-top-left rotate-[22deg] flex-col gap-y-1 rounded-lg border px-2 py-4 backdrop-blur-sm">
                  <div className="bg-[#04f771] h-2 w-full rounded-[2px]"></div>
                  <div className="bg-[#04f771] h-2 w-5/6 rounded-[2px]"></div>
                  <div className="bg-[#04f771] h-2 w-full rounded-[2px]"></div>
                </div>
              </div>
              {/* input file */}
              <input
                accept={mimeType}
                type="file"
                tabIndex={-1}
                style={{ display: "none" }}
                ref={fileCollector}
                multiple={mimeType === mimeTypes[0]}
                onChange={onReceiveFile}
              />
              {/* text: add doct */}
              <p className="group-hover:text-foreground text-muted-foreground mt-8 font-medium">
                {languages.addDocument}
              </p>
              {/* text: drag drop */}
              <p className="text-muted-foreground/80 mt-1 text-sm ">
                {languages.dragDropDoctHere}
              </p>
            </div>
          </div>
        )}

        {mimeType === mimeTypes[0] && imageFiles.length > 0 && (
          <div className="w-full min-h-[40vh] relative">
            {mimeType === mimeTypes[0] && (
              <ImagePickerSlider
                images={imageFiles}
                addMoreImages={onSelectFile}
                deleteImageFile={(e) => deleteImageFile(e)}
                previewImageFile={previewImageFile}
                rearrangePage={rearrangePage}
                defaultPos={defaultImgPos}
                saving={saving}
              />
            )}
          </div>
        )}

        {mimeType !== mimeTypes[0] && imageFiles.length > 0 && (
          <div className="w-full min-h-[40vh] relative">
            {mimeType !== mimeTypes[0] && (
              <FileSelectorPdfWordViewer
                fileName={imageFiles[0].fileBlob.name}
                fileType={mimeType === mimeTypes[1] ? "pdf" : "word"}
                onRemoveFile={onRemoveFiles}
                saving={saving}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

interface IImagePickerSlider {
  images: IFileElt[];
  defaultPos: number;
  saving: boolean;
  addMoreImages: () => void;
  previewImageFile: (fKey: string) => void;
  deleteImageFile: (fKey: string) => void;
  rearrangePage: (oper: "prev" | "next", imagePos: number) => void;
}
const ImagePickerSlider = ({
  images,
  addMoreImages,
  previewImageFile,
  deleteImageFile,
  rearrangePage,
  defaultPos,
  saving,
}: IImagePickerSlider) => {
  const [imageIndex, setImageIndex] = useState(defaultPos);

  const showPrevImage = () => {
    setImageIndex((index) => {
      if (index === 0) return images.length - 1;
      return index - 1;
    });
  };

  const showNextImage = () => {
    setImageIndex((index) => {
      if (index === images.length - 1) return 0;
      return index + 1;
    });
  };

  const onImgAction = (
    operType: "preview" | "add" | "delete" | "up" | "down",
    fileKey: string,
    imagePos: number
  ) => {
    if (operType === "add") addMoreImages();
    if (operType === "preview") previewImageFile(fileKey);
    if (operType === "delete") deleteImageFile(fileKey);
    if (operType === "up" || operType === "down")
      rearrangePage(operType === "up" ? "prev" : "next", imagePos);
  };

  useEffect(() => {
    setImageIndex(defaultPos);
  }, [defaultPos]);

  return (
    <section
      aria-label="Image Slider"
      style={{ width: "100%", height: "100%", position: "relative" }}
      className="max-h-[260px]"
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          overflow: "hidden",
        }}
      >
        {images.map((oImg, idx) => (
          <FileSelectorImgViewer
            file={oImg}
            imageIndex={imageIndex}
            key={idx}
            len={images.length}
            pos={idx}
            onImgAction={onImgAction}
            saving={saving}
          />
        ))}
      </div>
      <button
        onClick={showPrevImage}
        className="img-slider-btn"
        style={{ left: 0 }}
        aria-label="View previous Image"
      >
        <ArrowBigLeft width={16} height={16} />
      </button>
      <button
        onClick={showNextImage}
        className="img-slider-btn"
        style={{ right: 0 }}
        aria-label="View next Image"
      >
        <ArrowBigRight width={16} height={16} />
      </button>
      <div
        style={{
          position: "absolute",
          bottom: ".5rem",
          left: "50%",
          display: "flex",
          gap: ".25rem",
          transform: "translate(-50%)",
        }}
      >
        {images.map((_, index) => (
          <button
            aria-label={`View previous Imag ${index + 1}"`}
            className="img-slider-dot-btn"
            key={index}
            onClick={() => setImageIndex(index)}
            type="button"
          >
            {index === imageIndex ? (
              <CircleDot width={16} height={16} />
            ) : (
              <Circle width={16} height={16} />
            )}
          </button>
        ))}
      </div>
    </section>
  );
};

interface IFileSelectorImgViewer {
  imageIndex: number;
  file: IFileElt;
  len: number;
  pos: number;
  saving: boolean;
  onImgAction: (
    operType: "preview" | "add" | "delete" | "up" | "down",
    fileKey: string,
    imagePos: number
  ) => void;
}
const FileSelectorImgViewer = ({
  imageIndex,
  file,
  len,
  onImgAction,
  pos,
  saving,
}: IFileSelectorImgViewer) => {
  const [loading, setLoading] = useState(true);
  const [imageData, setImageData] = useState<any>(whiteImg);

  const prepareImage = () => {
    const reader = new FileReader();

    reader.onload = () => {
      setImageData(reader.result as string);
      setLoading(false);
    };

    reader.readAsDataURL(file.fileBlob);
  };

  useEffect(() => {
    prepareImage();
  }, [file]);

  return (
    <>
      {loading && (
        <div className="img-slider-img h-[259px]" style={{ display: "flex" }}>
          <i className="fa fa-spin fa-spinner fa-3x"></i>
        </div>
      )}
      {!loading && !saving && (
        <div
          className="img-slider-img relative"
          style={{ translate: `${-100 * imageIndex}%` }}
        >
          <div
            className="absolute z-[1]  items-center space-x-2 bg-black/60 px-2 py-1 rounded-lg top-1/2 left-1/2"
            style={{
              transform: "translate(-50%, -50%)",
            }}
          >
            {pos > 0 && (
              <button
                title={languages.movePageUp}
                onClick={() => onImgAction("up", file.fileKeyExt, pos)}
              >
                <i className="bi bi-arrow-up-circle-fill text-white"></i>
              </button>
            )}

            <button
              title={languages.preview}
              onClick={() => onImgAction("preview", file.fileKeyExt, pos)}
            >
              <i className="bi bi-eye-fill text-white"></i>
            </button>

            <button
              title={languages.addMorePictures}
              onClick={() => onImgAction("add", file.fileKeyExt, pos)}
            >
              <i className="bi bi-plus-circle-fill text-white"></i>
            </button>

            <button
              title={languages.delete}
              onClick={() => onImgAction("delete", file.fileKeyExt, pos)}
            >
              <i className="bi bi-trash3-fill text-white"></i>
            </button>

            {pos < len - 1 && (
              <button
                title={languages.movePageDown}
                onClick={() => onImgAction("down", file.fileKeyExt, pos)}
              >
                <i className="bi bi-arrow-down-circle-fill text-white"></i>
              </button>
            )}
          </div>
          <img className="w-full h-full object-cover" src={imageData} alt="" />
        </div>
      )}
    </>
  );
};

interface IFileSelectorPdfWordViewer {
  fileType: "pdf" | "word";
  fileName: string;
  onRemoveFile: () => void;
  saving: boolean;
}
const FileSelectorPdfWordViewer = ({
  fileType,
  fileName,
  onRemoveFile,
  saving,
}: IFileSelectorPdfWordViewer) => {
  const removeFile = async () => {
    const bAsk = await appFxs.showConfirm(
      languages.delete,
      languages.deleteFileMsg
    );
    if (!bAsk.isConfirmed) return;
    onRemoveFile();
  };

  const iconClass =
    fileType === "pdf" ? "fa fa-file-pdf-o fa-3x" : "fa fa-file-word-o fa-3x";
  return (
    <div className="relative flex flex-col w-full h-[259px] items-center justify-center border border-solid border-neutral-300/40 rounded-lg">
      {!saving && (
        <button
          className="flex items-center justify-center p-1 absolute top-1 right-1 cursor-pointer"
          title={languages.delete}
          onClick={() => removeFile()}
        >
          <i className="fa fa-trash text-red-500"></i>
        </button>
      )}
      <i className={iconClass}></i>
      <h3 className="text-lg font-bold">{fileName}</h3>
    </div>
  );
};
