import React, { useEffect, useRef, useState } from "react";

import { type PDFDocumentProxy, PasswordResponses } from "pdfjs-dist";
import { Document as PDFDocument, Page as PDFPage, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { PDFLoader } from "../majorsmallcomps";



import { PDF_VIEWER_PAGE_SELECTOR } from "src/utils/constants";


import getLanguages from 'src/utils/language';

const languages = getLanguages();

export type LoadedPDFDocument = PDFDocumentProxy;
export type OnPDFViewerPageClick = (_event: {
  pageNumber: number;
  numPages: number;
  originalEvent: React.MouseEvent<HTMLDivElement, MouseEvent>;
  pageHeight: number;
  pageWidth: number;
  pageX: number;
  pageY: number;
}) => void | Promise<void>;

pdfjs.GlobalWorkerOptions.workerSrc =
  "https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js";

interface IPDFViewer {
  file?: ArrayBufferLike | null;
  loading: boolean;
  onDocumentLoad?: (_doc: LoadedPDFDocument) => void;
}

export default function PDFViewer({
  file,
  loading,
  onDocumentLoad,
}: IPDFViewer) {
  const $el = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const [pdfError, setPdfError] = useState(false);

  const onDocumentLoaded = (doc: PDFDocumentProxy) => {
    setNumPages(doc.numPages);
    onDocumentLoad?.(doc);
  };

  useEffect(() => {
    if ($el.current) {
      const $current = $el.current;

      const { width } = $current.getBoundingClientRect();

      setWidth(width);

      const onResize = () => {
        const { width } = $current.getBoundingClientRect();

        setWidth(width);
      };

      window.addEventListener("resize", onResize);

      return () => {
        window.removeEventListener("resize", onResize);
      };
    }
  }, []);

  return (
    <div ref={$el} className="overflow-hidden">
      {loading ? (
        <div className="flex h-[80vh] max-h-[60rem] w-full flex-col items-center justify-center overflow-hidden rounded">
          <PDFLoader />
        </div>
      ) : file ? (
      <>
        {file ? <LazyPdfViewer file={file!} 
          onLoadSuccess={(x) => {}} />: <PDFLoadLoading />}
        </>
      ) : (
        <PDFLoadLoading />
      )}
    </div>
  );
}

interface ILazyPdfViewer {
  file: ArrayBufferLike;
  onLoadSuccess: (pages: number) => void;
  onPageClick?: OnPDFViewerPageClick;
}
const LazyPdfViewer = ({
  file,
  onLoadSuccess,
  onPageClick,
}: ILazyPdfViewer) => {
  const [numPages, setNumPages] = useState(0);
  const [pdfError, setPdfError] = useState(false);
  const [width, setWidth] = useState(0);

  const onDocumentLoaded = (numPages: number) => {
    setNumPages(numPages);
    //onDocumentLoad?.(doc);
  };

  const onDocumentPageClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    pageNumber: number
  ) => {
    const $el = event.target instanceof HTMLElement ? event.target : null;

    if (!$el) {
      return;
    }

    const $page = $el.closest(PDF_VIEWER_PAGE_SELECTOR);

    if (!$page) {
      return;
    }

    const { height, width, top, left } = $page.getBoundingClientRect();

    const pageX = event.clientX - left;
    const pageY = event.clientY - top;

    if (onPageClick) {
      void onPageClick({
        pageNumber,
        numPages,
        originalEvent: event,
        pageHeight: height,
        pageWidth: width,
        pageX,
        pageY,
      });
    }
  };

  return (
    <PDFDocument
      onLoadSuccess={(d) => onDocumentLoaded(d.numPages)}
      file={file}
      externalLinkTarget="_blank"
      loading={
        <div className="dark:bg-background flex h-[80vh] max-h-[60rem] flex-col items-center justify-center bg-white/50">
          {pdfError ? <PDFLoadError /> : <PDFLoader />}
        </div>
      }
      error={<PDFLoadError />}
      onSourceError={() => {
        setPdfError(true);
      }}
    >
      {Array(numPages)
        .fill(null)
        .map((_, i) => (
          <div key={i} className="last:-mb-2">
            <div className="border-border overflow-hidden rounded border will-change-transform mr-2">
              <PDFPage
                pageNumber={i + 1}
                width={width}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                loading={() => ""}
                onClick={(e) => onDocumentPageClick(e, i + 1)}
              />
            </div>
            <p className="text-muted-foreground/80 my-2 text-center text-[11px]">
              {languages.page} {i + 1} {languages.of.toLowerCase()} {numPages}
            </p>
          </div>
        ))}
    </PDFDocument>
  );
};

const PDFLoadError = () => {
  return (
    <div className="dark:bg-background flex h-[80vh] max-h-[60rem] flex-col items-center justify-center bg-white/50">
      <div className="text-muted-foreground text-center">
        <p>Something went wrong while loading the document.</p>
        <p className="mt-1 text-sm">Please try again or contact our support.</p>
      </div>
    </div>
  );
};
const PDFLoadLoading = () => {
  return (
    <div className="dark:bg-background flex h-[80vh] max-h-[60rem] flex-col items-center justify-center bg-white/50">
      <div className="text-muted-foreground text-center">
        <p>Something went wrong while loading the document.</p>
        <p className="mt-1 text-sm">Please try again or contact our support.</p>
      </div>
    </div>
  );
};

