/* eslint-disable react-hooks/exhaustive-deps */

import { ReactComponent as SignatureIconSvg } from "src/assets/images/svg/signature-icon.svg";
import { ReactComponent as PendingIconSvg } from "src/assets/images/svg/pending-file.svg";

import genFxs from "src/utils/general_fx";
import getLang from "src/utils/language";
import {
  IDoctSignRecip,
  IGuestForDoct,
  IHistoryDoctName,
} from "src/models/smarttypes";

import appDecl from "src/utils/declarations";
import { useEffect, useState } from "react";
import NetServices from "src/utils/netservices";

const appFxs = genFxs();
const languages = getLang();

interface IListOfDoctSigners {
  finalRecipes: IDoctSignRecip[];
}
export const ListOfDoctSigners = ({ finalRecipes }: IListOfDoctSigners) => {
  return (
    <section className="dark:bg-background border-border bg-widget flex flex-col rounded-xl border">
      <div className="flex flex-row items-center justify-between px-4 py-3">
        <h1 className="text-foreground font-medium">{languages.recipients}</h1>
      </div>
      <ul className="text-muted-foreground divide-y border-t">
        {finalRecipes.map((o, idx) => (
          <RecipientElement recipient={o} key={idx} />
        ))}
      </ul>
    </section>
  );
};

interface IRecipientElement {
  recipient: IDoctSignRecip;
}
const RecipientElement = ({ recipient }: IRecipientElement) => {
  const StatusElt = recipient.hasSigned ? SignatureIconSvg : PendingIconSvg;
  const name = recipient.fullName
    .split(" ")
    .map((o) => o.split("")[0])
    .join("");

  let bImage = false;
  let imagePath = `${appDecl.profilesPath}/${recipient.companyKey}`;
  if (recipient.profilePath) {
    bImage = true;
    imagePath += "/" + recipient.profilePath;
  } else {
    bImage = false;
  }

  return (
    <li className="flex items-center justify-between px-4 py-2.5 text-sm">
      <div className="flex w-full max-w-xs items-center gap-2">
        <span className="relative flex shrink-0 overflow-hidden rounded-full border-border h-10 w-10 border-2 border-solid border-[#f1f1f1]">
          <span
            className={`${
              bImage ? "bg-white" : "bg-[#f1f1f1]"
            } flex h-full w-full items-center justify-center rounded-full text-xs text-black`}
          >
            {!bImage && <span>{name}</span>}
            {bImage && <img src={imagePath} alt="" className="w-full h-full" />}
          </span>
        </span>
        <div className="flex flex-col text-left text-sm font-normal">
          <span className="text-black truncate">
            <p className="text-black text-sm">{recipient.fullName}</p>
          </span>
          <span className="text-black truncate text-xs">
            <p className="text-black/75 text-xs">{languages.signer}</p>
          </span>
        </div>
      </div>
      <div
        className={`inline-flex items-center gap-2 rounded-md font-medium ring-1 ring-inset w-fit ${
          recipient.hasSigned ? "bg-[#f1f1f1]" : "bg-[#ffffcc]"
        } text-black ring-[#92E554] px-2 py-1.5 text-xs`}
      >
        <StatusElt width={16} height={16} />
        {recipient.hasSigned ? languages.signed : languages.pending}
      </div>
    </li>
  );
};

interface IListDocumentHistory {
  companyKey: string;
  operationKey: string;
  currentSigner: string;
  lang: string;
  doctType?: "staff" | "partner";
}
export const ListDocumentHistory = ({
  companyKey,
  operationKey,
  currentSigner,
  lang,
  doctType,
}: IListDocumentHistory) => {
  const [histories, setHistories] = useState<IHistoryDoctName[]>([]);
  const [loading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const srcType = doctType ?? "staff";

  const populateHistories = async () => {
    const pathApi = `${
      srcType === "staff" ? "signdocs" : "partners"
    }/getlistofdocthistory/${companyKey}/${operationKey}`;

    const oOper = await NetServices.requestGet(pathApi);
    if (!oOper.bReturn) {
      setServerError(true);
      setErrorMessage(oOper.msgBody);
      setLoading(false);
      return;
    }

    setHistories(oOper.documentHistoriesV2!);
    setServerError(false);
    setErrorMessage("");
    setLoading(false);
  };

  useEffect(() => {
    populateHistories();
  }, []);

  return (
    <section className="dark:bg-background border-border bg-widget flex flex-col rounded-xl border">
      <div className="flex flex-row items-center justify-between border-b px-4 py-3">
        <h1 className="text-foreground font-medium">
          {languages.recentActivity}
        </h1>
      </div>
      <section style={{ opacity: 1 }}>
        <ul className="space-y-6 p-4">
          {loading && (
            <li className="relative flex gap-x-4">
              <i className="fa fa-spin fa-spinner fa-3x"></i>
            </li>
          )}
          {!loading && serverError && (
            <li className="relative flex gap-x-4">
              <p className="py-2 text-center px-3">{errorMessage}</p>
            </li>
          )}

          {histories.map((o, index) => (
            <HistoryElement
              history={o}
              key={index}
              currentSigner={currentSigner}
              lang={lang}
              visible={!loading}
              isLast={index === histories.length - 1}
            />
          ))}
        </ul>
      </section>
    </section>
  );
};

interface IListDocumentGuest {
  companyKey: string;
  operationKey: string;
  lang: string;
}
export const ListDocumentGuests = ({
  companyKey,
  operationKey,
  lang,
}: IListDocumentGuest) => {
  const [lstGuests, setLstGuests] = useState<IGuestForDoct[]>([]);
  const [loading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const populateGuests = async () => {
    const pathApi = `partners/getdocumentguests/${companyKey}/${operationKey}`;

    const oOper = await NetServices.requestGet(pathApi);
    if (!oOper.bReturn) {
      setServerError(true);
      setErrorMessage(oOper.msgBody);
      setLoading(false);
      return;
    }

    setLstGuests(oOper.documentPartGuests!);
    setServerError(false);
    setErrorMessage("");
    setLoading(false);
  };

  useEffect(() => {
    populateGuests();
  }, []);

  return (
    <section className="dark:bg-background border-border bg-widget flex flex-col rounded-xl border">
      <div className="flex flex-row items-center justify-between border-b px-4 py-3">
        <h1 className="text-foreground font-medium">{languages.guests}</h1>
      </div>
      <section style={{ opacity: 1 }}>
        <ul className="text-muted-foreground divide-y border-t">
          {loading && (
            <li className="relative flex gap-x-4">
              <i className="fa fa-spin fa-spinner fa-3x"></i>
            </li>
          )}
          {!loading && serverError && (
            <li className="relative flex gap-x-4">
              <p className="py-2 text-center px-3">{errorMessage}</p>
            </li>
          )}

          {lstGuests.map((o, index) => (
            <PartDoctGuest key={index} {...o} />
          ))}
        </ul>
      </section>
    </section>
  );
};

interface IHistoryElement {
  history: IHistoryDoctName;
  lang: string;
  currentSigner: string;
  visible: boolean;
  isLast: boolean;
}
const HistoryElement = ({
  history,
  lang,
  currentSigner,
  visible,
  isLast,
}: IHistoryElement) => {
  const bYours = history.historyRecord.operatorEmail === currentSigner;
  const youName = bYours ? languages.you : history.operatorName;

  const historyType = history.historyRecord.historyType;

  const testDisplay =
    historyType === "create"
      ? languages.createdDoct
      : historyType === "opened"
      ? languages.openedDoct
      : historyType === "sent"
      ? languages.sentDoct
      : historyType === "signed"
      ? languages.signedDoct
      : languages.documentCompleted;

  if (!visible) return null;
  return (
    <li className="relative flex gap-x-4">
      <div
        className={`${
          isLast ? "h-6" : "-bottom-6"
        } absolute left-0 top-0 flex w-6 justify-center`}
      >
        <div className="bg-border w-px"></div>
      </div>
      {(historyType === "create" || historyType === "sent") && (
        <div className="bg-white text-foreground/40 relative flex h-6 w-6 flex-none items-center justify-center">
          <div className="bg-white h-1.5 w-1.5 rounded-full ring-1 ring-gray-300 dark:ring-neutral-600"></div>
        </div>
      )}
      {historyType === "opened" && (
        <div className="text-foreground/40 relative flex h-6 w-6 flex-none items-center justify-center">
          <div className="bg-wwidget rounded-full border border-gray-300 p-1 dark:border-neutral-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="h-3 w-3"
              aria-hidden="true"
            >
              <path d="M21.2 8.4c.5.38.8.97.8 1.6v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V10a2 2 0 0 1 .8-1.6l8-6a2 2 0 0 1 2.4 0l8 6Z"></path>
              <path d="m22 10-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 10"></path>
            </svg>
          </div>
        </div>
      )}
      {historyType === "signed" && (
        <div className="text-foreground/40 relative flex h-6 w-6 flex-none items-center justify-center">
          <div className="bg-wwidget rounded-full border border-gray-300 p-1 dark:border-neutral-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="h-3 w-3"
              aria-hidden="true"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
          </div>
        </div>
      )}
      {historyType === "completed" && (
        <div className="text-foreground/40 relative flex h-6 w-6 flex-none items-center justify-center">
          <div className="bg-wwidget rounded-full border border-gray-300 p-1 dark:border-neutral-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="h-3 w-3"
              aria-hidden="true"
            >
              <path d="M18 6 7 17l-5-5"></path>
              <path d="m22 10-7.5 7.5L13 16"></path>
            </svg>
          </div>
        </div>
      )}
      <p className="text-muted-foreground dark:text-muted-foreground/70 flex-auto py-0.5 text-xs leading-5">
        {historyType !== "completed" && (
          <span className="text-foreground font-medium">{youName}</span>
        )}&nbsp;
        {testDisplay}
      </p>
      <span className="text-muted-foreground dark:text-muted-foreground/70 flex-none py-0.5 text-xs leading-5">
        {appFxs.timeLongAgo(new Date(history.historyRecord.historyDate))}
      </span>
    </li>
  );
};

const PartDoctGuest = ({
  guestEmail,
  guestImage,
  guestKey,
  guestName,
  guestType,
}: IGuestForDoct) => {
  const short = guestName
    .split(" ")
    .map((o) => o.split("")[0])
    .join("");

    const bImage = guestImage.length > 0;

  return (
    <li className="flex items-center justify-between px-4 py-2.5 text-sm w-full">
      <div className="flex items-center gap-2 w-full">
        <span className="relative flex shrink-0 overflow-hidden rounded-full border-border h-10 w-10 border-2 border-solid border-[#f1f1f1]">
          <span data-hasimg={bImage} className="data-[hasimg=true]:bg-white bg-[#f1f1f1] flex h-full w-full items-center justify-center rounded-full text-xs text-black">
            {!bImage && <span>{short}</span>}
            {bImage && (
              <img alt="" src={`${appDecl.apiPath}${guestImage}`} className="w-full h-full object-cover" />
            )}
          </span>
        </span>
        <div className="flex flex-col text-left text-sm font-normal w-full">
          <div className="flex items-start">
            <span className="text-black truncate flex-1">
              <p className="text-black text-sm">{guestName}</p>
            </span>

            <div data-owner={guestType === 'owner'} className="inline-flex items-center gap-2 rounded-md font-medium ring-1 ring-inset w-fit data-[owner=true]:bg-[#ffffcc] text-black ring-[#dbbe5e] px-2 py-1.5 text-xs">
              {guestType === 'guest' ? languages.guest: languages.owner}
            </div>
          </div>
          <span className="text-black truncate text-xs">
            <p className="text-black/75 text-xs">{guestEmail}</p>
          </span>
        </div>
      </div>
    </li>
  );
};
