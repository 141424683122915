import { IPreviewerMiniatComp } from "src/models/smarttypes";
import PDFViewer from "src/components/pdfviewer/PDFViewer";

import getLanguages from "src/utils/language";
const languages = getLanguages();

export const PreviewerMiniatComp = ({
  loading,
  pdfFile,
  clearPdf,
}: IPreviewerMiniatComp) => {
  return (
    <div className="flex flex-col px-3 py-2 bg-neutral-300/40 rounded-lg border border-neutral-500">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-bold py-2">{languages.previewDoct}</h3>
        {pdfFile && (
          <button onClick={clearPdf}>
            <i className="bi bi-x-square-fill"></i>
          </button>
        )}
      </div>
      <div className="max-h-[450px] overflow-y-auto">
        {!pdfFile && (
          <div className="h-[300px]">
            <h3>{languages.noDoctSelected}</h3>
          </div>
        )}
        {pdfFile && <PDFViewer loading={loading} file={pdfFile!} />}
      </div>
    </div>
  );
};


interface IPreviewerMobileComp {
  pdfFile: ArrayBufferLike,
  onCloseDlg: () => void,
}
export const PreviewerMobileComp = ({
  pdfFile,
  onCloseDlg,
}: IPreviewerMobileComp) => {
  return (
    <div className="flex flex-col px-3 py-2 bg-neutral-300/40 rounded-lg border border-neutral-500">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-bold py-2">{languages.previewDoct}</h3>
      </div>
      <div className="w-full max-h-[450px] overflow-y-auto">
        {!pdfFile && (
          <div className="h-[300px]">
            <h3>{languages.noDoctSelected}</h3>
          </div>
        )}
        {pdfFile && <PDFViewer loading={false} file={pdfFile!} />}
      </div>
    </div>
  );
};
