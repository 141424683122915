/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadinInterfaceComp from "src/components/LoadinInterfaceComp";
import {
  IArchivixSignatureFile,
  ISignOperElt,
  ISignReturnType,
} from "src/models/smarttypes";
import NetServices from "src/utils/netservices";
import appDecl from "src/utils/declarations";
import PDFViewer from "src/components/pdfviewer/PDFViewer";

import genFxs from "src/utils/general_fx";
import getLang from "src/utils/language";
import {
  CompleteSignatureZone,
  WiseSignaturePadRefPart,
  WiseSimpleButton,
} from "src/components/majorsmallcomps";

const appFxs = genFxs();
const languages = getLang();

const isEn = appFxs.getLocalLanguage() === "en";

export default function RequestSignOperPartnerPage() {
  document.title = `${languages.documents} | Archivix`;

  const { signoperationkey } = useParams();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const [serverErrorText, setServerErrorText] = useState("");
  const [hasServerError, setServerError] = useState(false);

  const [operationSign, setOperationSign] = useState<ISignOperElt | null>(null);

  const [pdfFile, setPdfFile] = useState<ArrayBufferLike | null>(null);
  const [operationRecord, setOperationRecord] = useState({ fullName: "" });
  const [signatureReturnSit, setSignatureReturnSit] =
    useState<ISignReturnType | null>(null);

  const [overalStatus, setOveralStatus] = useState(0);

  const populateDataFromServer = async () => {
    const oOper = await NetServices.requestGet(
      `partners/getdocumentforsignprocessnotconn/${signoperationkey}`
    );
    if (!oOper.bReturn) {
      setServerErrorText(oOper.msgBody);
      setServerError(true);
      return;
    }

    if (oOper.typeReturn !== 0) {
      const signStatus = oOper.signatureReturnSit!;
      setOveralStatus(signStatus.signingProcess);
      setSignatureReturnSit(signStatus);
      setLoading(false);
      return;
    }

    const oSignOps = oOper.signatureOperation!;

    setOperationRecord({
      ...operationRecord,
      fullName: oSignOps.signerUser.name,
    });

    setOperationSign(oSignOps);
    setServerError(false);
    setServerErrorText("");

    populateDataRecord(oSignOps);

    if (oSignOps.signatureFileRec) {
      loadSignatureToSignPad(oSignOps.signatureFileRec);
    }
  };

  const populateDataRecord = async (oper: ISignOperElt) => {
    const linkDown = `${appDecl.apiPath}/${oper.fileRelPath}`;

    const filePath = linkDown;
    const fileBuffer = await appFxs.getFileBlobBuffer(filePath);

    if (!fileBuffer) {
      navigate("/app/");
      return;
    }

    setPdfFile(fileBuffer);
    setLoading(false);
  };
  const loadSignatureToSignPad = async (oper: IArchivixSignatureFile) => {
    const linkDown = `${appDecl.partnerSignatures}/${oper.ompanyKey}/${oper.signatureName}`;
    const filePath = linkDown;

    appFxs.toDataUrl(filePath, (dataUrl) => {
      setTimeout(() => {}, 2000);
    });
  };

  const completeSignature = async () => {
    const bAsk = await appFxs.showConfirmSign(
      operationSign?.documentRecord.fileTitle!,
      isEn ? "en" : "fr"
    );
    if (!bAsk.isConfirmed) return;

    var oOper = await NetServices.requestPost(
      "partners/processsigndocuments/notconn",
      {
        signuserOperKey: operationSign!.signOper.signerOperKey,
        companyKey: operationSign!.signOper.companyKey,
      },
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    //TO DO
    //apply waiting screen
    setSignatureReturnSit(oOper.signatureReturnSit!);
    //setCompleteSigned(true);
    setOveralStatus(oOper.signatureReturnSit!.signingProcess);
  };

  const onViewDoct = (signSit: ISignReturnType) => {
    const path = `/partners/previewcomplete/${signSit.companyKey}/${signoperationkey}`;
    navigate(path);
  };
  const onDownloadDoct = async (filePath: string, fileTitle: string) => {
    await appFxs.downloadFromFileUrl(filePath, `${fileTitle}.pdf`);
  };
  const onShareDoct = () => {};

  useEffect(() => {
    populateDataFromServer();
  }, []);

  if (isLoading) return <LoadinInterfaceComp />;

  return (
    <>
      {overalStatus === 0 && (
        <div>
          {hasServerError && (
            <div className="h-[80px] flex flex-col justify-center space-y-3">
              <h3 className="text-lg">{serverErrorText}</h3>
              <button className="btn" onClick={() => populateDataFromServer()}>
                {languages.reload}
              </button>
            </div>
          )}
          {!hasServerError && (
            <div className="relative">
              <h1 className="mt-4 truncate text-2xl font-semibold md:text-3xl">
                {operationSign?.documentRecord.fileTitle}
              </h1>
              <div className="mt-2.5 flex items-center gap-x-6">
                {isEn && (
                  <p className="text-black">
                    {operationSign?.inviterUser.name}&nbsp; (
                    {operationSign?.inviterUser.email}) has invited you to sign
                    this document.
                  </p>
                )}
                {!isEn && (
                  <p className="text-black">
                    {operationSign?.inviterUser.name}&nbsp; (
                    {operationSign?.inviterUser.email}) vous a invité à signer
                    ce document.
                  </p>
                )}
              </div>
              <div className="mt-8 grid grid-cols-12 gap-y-8 lg:gap-x-8 lg:gap-y-0"></div>
              <div className="flex flex-wrap mt-8 h-screen overflow-y-auto hide-scroll">
                <div className="w-full md:w-1/2 lg:w-[60%]">
                  <div className="p-2 md:p-5">
                    <PDFViewer loading={isLoading} file={pdfFile} />
                  </div>
                </div>
                <div className="w-full md:w-1/2 lg:w-[40%]">
                  {isLoading ? (
                    <LoadinInterfaceComp />
                  ) : (
                    <div className="p-5 sticky top-10 ">
                      <div className="dark:bg-background border-border bg-widgetflex h-full max-h-[64rem] flex-col justify-between overflow-auto rounded-xl border px-4 py-6 lg:h-[calc(100vh-6rem)]">
                        <div className="-mx-2 flex flex-col px-2 flex-grow">
                          <div className="flex-grow flex-1">
                            <div className="flex flex-col">
                              <h3 className="text-foreground text-2xl font-semibold">
                                {languages.signDocument}
                              </h3>
                              <p className="text-gray-700 mt-2 text-sm">
                                {languages.signDocumentMsg}
                              </p>
                              <hr className="border-border mb-8 mt-4" />
                              <div className="custom-scrollbar -mx-2 flex flex-1 flex-col overflow-hidden px-2">
                                <div className="flex flex-1 flex-col">
                                  <div className="flex flex-col">
                                    <div className="flex flex-col gap-y-4">
                                      <div>
                                        <label
                                          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                          form="title"
                                          htmlFor="fullName"
                                        >
                                          {languages.fullName}
                                          <span className="text-destructive ml-1 inline-block font-medium">
                                            *
                                          </span>
                                        </label>
                                        <input
                                          className="border-input ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring flex h-10 w-full rounded-md border px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 bg-background my-2"
                                          id="fullName"
                                          name="fullName"
                                          value={operationRecord.fullName}
                                          disabled={true}
                                          onChange={(e) =>
                                            setOperationRecord({
                                              ...operationRecord,
                                              fullName: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                      <div>
                                        <label
                                          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                          form="title"
                                          htmlFor="signature"
                                        >
                                          {languages.signature}
                                          <span className="text-destructive ml-1 inline-block font-medium">
                                            *
                                          </span>
                                        </label>
                                        <WiseSignaturePadRefPart
                                          domainKey={operationSign!.companyKey}
                                          signerEmail={
                                            operationSign!.signerUser.email
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mt-4 flex-shrink-0">
                          <div className="flex gap-x-4 mt-4">
                            <WiseSimpleButton
                              label={languages.cancel}
                              onClick={() => navigate("/")}
                            />
                            <WiseSimpleButton
                              label={languages.complete}
                              btnType="primary"
                              onClick={() => completeSignature()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {overalStatus > 0 && (
        <CompleteSignatureZone
          signatureSit={signatureReturnSit!}
          onDowanloadDoct={(f, t) => onDownloadDoct(f, t)}
          onShareDoct={onShareDoct}
          onViewDoct={onViewDoct}
          typeSign="partner"
        />
      )}
    </>
  );
}
