/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useEffect } from "react";
import ReactDom from "react-dom";

import getLanguages from "src/utils/language";
import getApps from "src/utils/general_fx";
import { IDropModernOpt } from "../wsedropdown/WiseDropdownModern";

import { ReactComponent as CancelSvg } from "src/assets/images/svg/cancel.svg";
import { DocumentSignElt } from "src/models/smarttypes";
import { WiseSpecialBtn } from "../wise_button_files";

const languages = getLanguages();
const appFxs = getApps();

interface IWiseModel {
  children: ReactNode;
  isOpen: boolean;
  onClose: (retData: any) => void;
  width?: number;
  titleBox?: string;
  hasFooter?: boolean;
}

const MODAL_STYLES = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
  padding: "0px",
  zIndex: 1000,
};
const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,.7)",
  zIndex: 1000,
};

const MODAL_STYLES_DROP = {
  position: "fixed",
  backgroundColor: "#fff",
  padding: "0px",
  zIndex: 1000,
};
const OVERLAY_STYLES_DROP = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,.1)",
  zIndex: 1000,
};

const MODAL_STYLES_DOCT = {
  position: "fixed",
  backgroundColor: "#fff",
  padding: "0px",
  zIndex: 1000,
};
const OVERLAY_STYLES_DOCT = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,.9)",
  zIndex: 1000,
};

const WiseModal = ({
  children,
  isOpen,
  onClose,
  width,
  titleBox,
  hasFooter,
}: IWiseModel) => {
  if (!isOpen) return null;
  const nodeElement = document.getElementById("portals")!;
  const bFooter = hasFooter ?? true;

  return ReactDom.createPortal(
    <>
      <div style={{ ...OVERLAY_STYLES, position: "fixed" }}></div>
      <div
        style={{
          ...MODAL_STYLES,
          borderRadius: "7px",
          overflow: "hidden",
          position: "fixed",
          width: !width ? "550px" : `${width}px`,
        }}
      >
        <div
          className="border-b border-slate-300 border-solid"
          style={{
            padding: "10px 15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span className="font-bold">
            {titleBox ?? languages.dialogBoxTitle}
          </span>
          <button onClick={() => onClose(null)}>
            <i className="fa fa-times"></i>
          </button>
        </div>
        <div className="px-2 py-1">{children}</div>
        {bFooter && (
          <div className="dialog-footer flex gap-3 justify-end p-[15px] border-t border-slate-300 border-solid">
            <WiseSpecialBtn 
                label={languages.cancel}
                onClick={() => onClose(null)} />
          </div>
        )}
      </div>
    </>,
    nodeElement
  );
};

export default WiseModal;

interface IWiseModalDropdown {
  clientWidth: number;
  clientHeight: number;
  clientY: number;
  clientX: number;
  isOpen: boolean;
  options: IDropModernOpt[];
  position?: "left" | "right";
  onClose: (d?: DocumentSignElt, e?: number) => void;
  dataRecord: DocumentSignElt;
}

export const WiseModalDropdown = ({
  position,
  options,
  clientHeight,
  clientWidth,
  clientX,
  clientY,
  isOpen,
  onClose,
  dataRecord,
}: IWiseModalDropdown) => {
  const nodeElement = document.getElementById("portals")!;
  const mainId = appFxs.generateUUID();
  useEffect(() => {}, [isOpen]);

  if (!isOpen) return null;
  return ReactDom.createPortal(
    <>
      <div
        onClick={() => onClose()}
        style={{ ...OVERLAY_STYLES_DROP, position: "fixed" }}
      ></div>
      <div
        id={mainId}
        className="max-w-fit"
        style={{
          ...MODAL_STYLES_DROP,
          borderRadius: "7px",
          overflow: "hidden",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          /* bottom: bottom,
                left: left,
                transform: `translate(-${clientX}, -${clientY})` */
        }}
      >
        <div className="bg-popover text-popover-foreground animate-in data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 min-w-[8rem] overflow-hidden rounded-md border p-1 shadow-md w-52">
          {options.map((oOption, index) => (
            <OptionElement
              option={oOption}
              key={index}
              afterClick={(d, e) => onClose(d, e)}
              dataRecord={dataRecord}
            />
          ))}
        </div>
      </div>
    </>,
    nodeElement
  );
};
interface IOptionElement {
  option: IDropModernOpt;
  afterClick: (d?: DocumentSignElt, e?: number) => void;
  position?: "left" | "right";
  dataRecord: DocumentSignElt;
}
const OptionElement = ({ option, afterClick, dataRecord }: IOptionElement) => {
  const onItemOptClick = (e?: number) => {
    afterClick(dataRecord, e);
  };

  if (!option.clickable) {
    return (
      <div className="px-2 py-1.5 text-sm font-semibold">{option.label}</div>
    );
  }

  if (!option.enabled) {
    return (
      <div
        role="menuitem"
        aria-disabled="true"
        data-disabled=""
        className="ml-2 focus:bg-accent focus:text-accent-foreground relative flex cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
        aria-haspopup="dialog"
        aria-expanded="false"
        aria-controls="radix-:rqa:"
        data-state="closed"
        tabIndex={-1}
        data-orientation="vertical"
        data-radix-collection-item=""
      >
        <i className={option.iconCls}></i>&nbsp;
        {option.label}
      </div>
    );
  }
  return (
    <div
      role="menuitem"
      className="ml-2 hover:bg-black/40 hover:text-white focus:bg-accent focus:text-accent-foreground relative flex cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
      tabIndex={0}
      onClick={() => onItemOptClick(option.retType!)}
    >
      <i className={option.iconCls}></i>&nbsp;
      {option.label}
    </div>
  );
};

interface IWiseModalFullScreen {
  onClose: () => void;
  children: React.ReactNode;
}
export const WiseModalFullScreen = ({
  onClose,
  children,
}: IWiseModalFullScreen) => {
  const nodeElement = document.getElementById("portals")!;
  const mainId = appFxs.generateUUID();

  return ReactDom.createPortal(
    <>
      <button
        className="fixed z-[1001] top-4 right-4 inline-block py-2 px-2 bg-slate-300 rounded-md hover:scale-110 transition-transform"
        onClick={() => onClose()}
      >
        <CancelSvg width={22} height={22} />
      </button>
      <div
        onClick={() => onClose()}
        style={{ ...OVERLAY_STYLES_DOCT, position: "fixed" }}
      ></div>
      <div
        id={mainId}
        className="w-full max-w-[800px]"
        style={{
          ...MODAL_STYLES_DOCT,
          borderRadius: "7px",
          overflow: "hidden",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          /* bottom: bottom,
                left: left,
                transform: `translate(-${clientX}, -${clientY})` */
        }}
      >
        {children}
      </div>
    </>,
    nodeElement
  );
};
