/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadinInterfaceComp from "src/components/LoadinInterfaceComp";

import appDecl from "src/utils/declarations";
import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";
import NetServices from "src/utils/netservices";
import { IArchUserShort } from "src/models/smarttypes";
import {
  IArchivixUser,
  IDocumentFile,
  IDocumentForlder,
} from "src/models/archivix_models";
import PDFViewer from "src/components/pdfviewer/PDFViewer";
import DocumentChatterInner from "src/components/chatsystem/DocumentChatterInner";
import { ChatNotificationBtn } from "src/components/chatsystem/WiseDoctChatNotif";
import DocumentAccessGranter from "src/components/DocumentAccessGranter";

import { ReactComponent as SignatureSvg } from "src/assets/images/svg/signature.svg";
import { ReactComponent as DeleteSvg } from "src/assets/images/svg/delete-icon.svg";
import { ReactComponent as DownloadSvg } from "src/assets/images/svg/download-simple.svg";
import { ReactComponent as ShareSvg } from "src/assets/images/svg/share-icon.svg";

import { ReactComponent as PartnerSvg } from "src/assets/images/svg/partner-icon.svg";
import { ReactComponent as StaffSvg } from "src/assets/images/svg/staff-icon.svg";

const languages = getLanguages();
const appFxs = getFxs();

export default function PreviewDocumentFilePage() {
  document.title = `${languages.previewDoct} | Archivix`;
  const { folderKey, fileKey } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [lstUsers, setLstUsers] = useState<IArchUserShort[]>([]);
  const [archiveDoct, setArchiveDoct] = useState<IDocumentFile | null>(null);
  const [parentFolder, setParentFolder] = useState<
    IDocumentForlder | undefined
  >(undefined);
  const [pdfArray, setPdfArray] = useState<ArrayBufferLike | null>(null);
  const [chatWindowVis, setChatWindowVis] = useState(false);
  const [connectedUser, setConnectedUser] = useState<IArchivixUser | null>(
    null
  );

  const bLoaded = useRef(false);

  const populateDocumentRecord = async () => {
    setLoading(true);


    const oOper = await NetServices.requestGet(
      `documents/getarchivefiledocument/${fileKey}`
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setLoading(false);
      return;
    }

    const oDoct = oOper.archiveFileRecord!;
    const oFolder = oOper.parentFolderRecord;
    const oUsers = oOper.lstOfShortUsers!;
    const connUser = oOper.connectedUser!;


    const oPath = `${appDecl.documentsPath}/${oDoct.companyKey}/${oDoct.fileKey}/${oDoct.fileKey}.pdf`;



    setLstUsers(oUsers);
    setArchiveDoct(oDoct);
    setParentFolder(oFolder);
    setConnectedUser(connUser);


    try {
      const fileBuffer = await appFxs.getFileBlobBuffer(oPath);

      if (!fileBuffer) {
        if (folderKey) {
          navigate(`/app/documents/folders/${folderKey}`);
        } else {
          navigate("/app/documents");
        }

        return;
      }


      setPdfArray(fileBuffer);
      setLoading(false);
    } catch (error: any) {
      await appFxs.showAlert(languages.error, error.message);
      if (folderKey) {
        navigate(`/app/documents/folders/${folderKey}`);
      } else {
        navigate("/app/documents");
      }
    }
  };
  const moveBack = () => {
    if (archiveDoct?.folderKey) {
      navigate(`/app/documents/folders/${archiveDoct?.folderKey}`);
    } else {
      navigate("/app/documents");
    }
  };
  const onShareFile = async () => {
    const fileToShare = `${appDecl.documentsPath}/${archiveDoct?.companyKey}/${archiveDoct?.fileKey}/${archiveDoct?.fileKey}.pdf`;

    const fileName = archiveDoct?.fileName;
    const finalFileName = fileName?.lastIndexOf(".pdf")
      ? fileName!
      : `${fileName}.pdf`;

    const shareOps = await appFxs.shareFilesFromUrl(
      fileToShare,
      finalFileName,
      true
    );
    if (!shareOps.bReturn) {
      appFxs.showAlert(shareOps.msgTitle, shareOps.msgBody);
    }
  };
  const onDownloadFile = async () => {
    const fileToShare = `${appDecl.documentsPath}/${archiveDoct?.companyKey}/${archiveDoct?.fileKey}/${archiveDoct?.fileKey}.pdf`;
    const fileName = archiveDoct?.fileName!;
    const finalFileName = appFxs.isFilePDFExt(fileName)
      ? fileName
      : `${fileName}.pdf`;

    const oOper = await appFxs.downloadFromFileUrl(fileToShare, finalFileName);
    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
    }
  };
  const onDeleteFile = async () => {
    const bAsk = await appFxs.showConfirm(
      languages.deleteFile,
      languages.deleteFileMsg
    );
    if (!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPost(
      "documents/deletedocumentfilerecordunique",
      {
        fileKey: archiveDoct!.fileKey,
      },
      true
    );
    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    moveBack();
  };

  const onStaffSendSignature = async () => {
    const bAsk = await appFxs.showConfirm(
      languages.sign,
      languages.sendSignatureStaffMsg
    );
    if (!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPost(
      "documents/senddocumenttosign",
      {
        sourceFileKey: archiveDoct!.fileKey,
      },
      true
    );
    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    navigate(`/app/signdocs/editor/${oOper.generatedSignDocumentKey!}`);
  };

  const onPartnerSendSignature = async () => {
    const bAsk = await appFxs.showConfirm(
      languages.sign,
      languages.sendSignaturePartnerMsg
    );
    if (!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPost(
      "documents/sendpartnerdocumenttosign",
      {
        sourceFileKey: archiveDoct!.fileKey,
      },
      true
    );
    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    navigate(`/app/partners/editor/${oOper.generatedSignDocumentKey!}`);
  };

  const afterGrantAccOps = (
    sFile: IDocumentFile,
    lstUsers: IArchUserShort[],
    pFolder?: IDocumentForlder
  ) => {
    setLstUsers(lstUsers);
    setArchiveDoct(sFile);
    setParentFolder(pFolder);
  };

  useEffect(() => {
    if(bLoaded.current)return;
    populateDocumentRecord();
    bLoaded.current = true;
  }, []);

  if (loading) return <LoadinInterfaceComp />;
  return (
    <>
      {!loading && (
        <DocumentChatterInner
          isOpen={chatWindowVis}
          onClose={() => setChatWindowVis(false)}
          chatter={connectedUser!}
          archiveFile={archiveDoct!}
        />
      )}

      {!loading && (
        <ChatNotificationBtn
          isOpen={!chatWindowVis}
          connectedUser={connectedUser!}
          archiveFile={archiveDoct!}
          onClickIcon={() => setChatWindowVis(true)}
        />
      )}

      <div className="flex flex-col space-y-2">
        {/* */}
        <div className="flex flex-wrap justify-between mt-8 h-screen overflow-y-auto hide-scroll">
          <div className="w-full md:w-1/2 lg:w-[59%] maxh">
            <div className="flex flex-col space-y-3">
              <div className="flex items-center flex-wrap">
                <div className="flex-1 flex items-center gap-2 basis-full md:basis-1/2 truncate">
                  <button onClick={moveBack}>
                    <i className="bi bi-arrow-left"></i>
                  </button>
                  <div className="flex flex-col">
                    <h3 className="text-lg" title={archiveDoct?.fileName}>
                      {archiveDoct?.fileName}
                    </h3>
                    {parentFolder && (
                      <span className="flex items-center gap-2">
                        <i className="bi bi-folder-fill text-blue-500 text-sm"></i>
                        <span className="text-sm text-blue-500">
                          {parentFolder?.folderName}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-end gap-1 basis-full md:basis-1/2">
                  <button
                    title={languages.shareFile}
                    className="flex items-center justify-center border border-solid border-neutral-400/40 rounded-lg p-2 bg-slate-100 hover:bg-slate-300/40"
                    onClick={() => onShareFile()}
                  >
                    <ShareSvg width={22} height={22} />
                  </button>
                  <button
                    title={languages.download}
                    className="flex items-center justify-center border border-solid border-neutral-400/40 rounded-lg p-2 bg-slate-100 hover:bg-slate-300/40"
                    onClick={() => onDownloadFile()}
                  >
                    <DownloadSvg width={22} height={22} />
                  </button>
                  <button
                    title={languages.delete}
                    className="flex items-center justify-center border border-solid border-neutral-400/40 rounded-lg p-2 bg-slate-100 hover:bg-slate-300/40"
                    onClick={() => onDeleteFile()}
                  >
                    <DeleteSvg width={22} height={22} />
                  </button>
                  <div className="dropdown">
                    <button
                      title={languages.sendSignature}
                      className="flex items-center justify-center border border-solid border-neutral-400/40 rounded-lg p-2 bg-slate-100 hover:bg-slate-300/40  dropdown-toggle not-dropdown"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <SignatureSvg width={22} height={22} />
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <a
                          className="dropdown-item cursor-pointer "
                          onClick={() => onStaffSendSignature()}
                        >
                          <span className="flex items-center gap-2">
                            <StaffSvg width={16} height={16} />
                            <span>{languages.staff}</span>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item cursor-pointer "
                          onClick={() => onPartnerSendSignature()}
                        >
                          <span className="flex items-center gap-2">
                            <PartnerSvg width={16} height={16} />
                            <span>{languages.partners}</span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="p-2">
                <PDFViewer loading={loading} file={pdfArray} />
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-[40%]">
            <div className="sticky top-20 ">
              <DocumentAccessGranter
                lstUsers={lstUsers}
                activFile={archiveDoct!}
                afterSaveOps={afterGrantAccOps}
                meUser={connectedUser!}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
