/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import appDecl from "src/utils/declarations";
import getLang from "src/utils/language";
import generalFx from "src/utils/general_fx";
import NetServices from "src/utils/netservices";
import {
  IArchUserFixer,
  IArchivixSignDocument,
  ISignOperFld,
  ISignProcessSender,
  IStepsError,
} from "src/models/smarttypes";

import { IArchivixUser } from "src/models/archivix_models";
import ModalListUsers from "src/components/ModalListUsers";
import imgProfile from "src/assets/images/profile_placeholder.jpg";
import {
  SignDoctFieldArea,
  SignerDocumentListSigners,
  SignerDocumentTitle,
  SignerMessageSubjectArea,
} from "src/components/majorsmallcomps";
import LoadinInterfaceComp from "src/components/LoadinInterfaceComp";

import { ReactComponent as ArrowLeftSvg } from "src/assets/images/svg/arrow-left.svg";

import { ReactComponent as DraftFileSvg } from "src/assets/images/svg/draft-file.svg";
import { ReactComponent as CompletedFileSvg } from "src/assets/images/svg/completed-file.svg";
import { ReactComponent as PendingFileSvg } from "src/assets/images/svg/pending-file.svg";

import PDFViewer from "src/components/pdfviewer/PDFViewer";

const languages = getLang();
const appFxs = generalFx();

const defaultSignOps: ISignOperFld = {
  documentTitle: "",
  applyStamp: false,
  lstSigners: [],
  messageTemplate: {
    subject: "",
    message: "",
  },
  messageSet: false,
};

export default function EditorSignDoctPage() {
  const { fileKey } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [pdfFile, setPdfFile] = useState<ArrayBufferLike | null>(null);

  const [IconStatus, setIconStatus] = useState<any>(DraftFileSvg);

  const [doctTitle, setDoctTitle] = useState(languages.loading + "...");
  const [singleDignDoct, setSingleDignDoct] =
    useState<IArchivixSignDocument | null>(null);

  const [formStep, setFormStep] = useState<number>(1);
  const [allUsers, setAllUsers] = useState<IArchivixUser[]>([]);
  const [operationRecord, setOperationRecord] = useState<ISignOperFld>({
    ...defaultSignOps,
  });
  const [formErrors, setFormErrors] = useState<IStepsError>({});
  const [userDlgVis, setUserDlgVis] = useState(false);

  /* const [fieldUserSigner, setFieldUserSigner] = useState<IArchUserFixer | null>(
    null
  ); */

  const [doctStatus, setDoctStatus] = useState<string>(languages.draft);

  const bLoaded = useRef(false);

  document.title = `${doctTitle} | Archivix`;

  const populateFilePdf = async () => {
    if (!fileKey) {
      await appFxs.showAlert(
        languages.accessPathError,
        languages.accessPathErrorMsg
      );
      navigate("/app/signdocs");
      return;
    }

    setLoading(true);
    const oOper = await NetServices.requestGet(
      `signdocs/getonesinglesigndoct/${fileKey}`
    );

    if (!oOper.bReturn) {
      await appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      navigate("/app/signdocs");
      return;
    }

    const doctRec = oOper.singleDignDoct!

    setSingleDignDoct(doctRec);
    setDoctTitle(doctRec.fileTitle);
    setAllUsers(oOper.lstArchUsers!);
    setStatusOfDoct(doctRec);

    setOperationRecord({
      ...operationRecord,
      documentTitle: doctRec.fileTitle,
    });

    const filePath = `${appDecl.documentsSign}/${doctRec.companyKey}/${fileKey}/${fileKey}.pdf`;
    const fileBuffer = await appFxs.getFileBlobBuffer(filePath);

    if (!fileBuffer) {
      navigate("/app/signdocs");
      return;
    }

    setPdfFile(fileBuffer);
    setLoading(false);
  };

  const moveToAction = (oAction: "back" | "forard") => {
    if (oAction === "back") {
      setFormStep(formStep - 1);
      return;
    }

    if (formStep === 1) checkDoctTitle();
    if (formStep === 2) checkDoctSigners();
    if (formStep === 3) checkDocsSignFields();
    if (formStep === 4) sendMessageToSigners();
  };
  const checkDoctTitle = () => {
    const errors: IStepsError = {};
    if (
      !operationRecord.documentTitle ||
      operationRecord.documentTitle.length === 0
    ) {
      errors.fileTitle = languages.fldRequired;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setFormStep(formStep + 1);
  };
  const checkDoctSigners = () => {
    if (operationRecord.lstSigners.length === 0) {
      appFxs.showAlert(languages.signers, languages.signersAddErr);
      return;
    }

    setFormStep(formStep + 1);
    //setFieldUserSigner(operationRecord.lstSigners[0]);
  };
  const checkDocsSignFields = () => {
    setFormStep(formStep + 1);
  };
  const sendMessageToSigners = () => {
    const finalRecord: ISignOperFld = {
      ...operationRecord,
      messageSet: operationRecord.messageTemplate.message.length > 0,
    };

    const finalFormData: ISignProcessSender = {
      doctTitle: finalRecord.documentTitle,
      applyStamp: finalRecord.applyStamp,
      listSignerEmails: finalRecord.lstSigners.map((o) => o.userEmail),
      messageSubject: finalRecord.messageTemplate.subject,
      messageContent: finalRecord.messageTemplate.message,
      documentOperKey: singleDignDoct!.operationKey,
      companyKey: singleDignDoct!.companyKey,
    };

    documentToInboxForSigners(finalFormData);
  };

  const documentToInboxForSigners = async (e: ISignProcessSender) => {
    const dataToSend = new FormData();
    const jsSonData = JSON.stringify(e);

    dataToSend.append("requestSignOps", jsSonData);
    //TO DO



    const oOper = await NetServices.requestPostWithFileAxios(
      "signdocs/senddocumenttoinbox",
      dataToSend,
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    navigate("/app/signdocs");
  };

  const addSignerToList = (o: IArchivixUser) => {
    const oUser = operationRecord.lstSigners.find(
      (f) => f.userEmail === o.userEmail
    );
    if (oUser) return;

    const imgPath = !o.photoProfile
      ? imgProfile
      : `${appDecl.profilesPath}/${o.companyKey}/${o.photoProfile}`;

    const lst = [...operationRecord.lstSigners];
    lst.push({
      userEmail: o.userEmail,
      userName: `${o.firstName} ${o.lastName}`,
      userPic: imgPath,
    });

    setOperationRecord({ ...operationRecord, lstSigners: lst });
  };
  const removeSigner = async (oSigner: IArchUserFixer) => {
    const bAsk = await appFxs.showConfirm(
      languages.deleteSigner,
      languages.deleteSignerMsg
    );
    if (!bAsk.isConfirmed) return;
    const filtered = operationRecord.lstSigners.filter(
      (o) => o.userEmail !== oSigner.userEmail
    );
    setOperationRecord({ ...operationRecord, lstSigners: filtered });
  };

  const onMessageChange = (e: { subject: string; message: string }) => {
    const ff = { ...e };
    setOperationRecord({ ...operationRecord, messageTemplate: ff });
  };

  const setStatusOfDoct = (oDoct: IArchivixSignDocument) => {
    if(oDoct.operationStatus === 0){
        setDoctStatus(languages.draft);
        setIconStatus(DraftFileSvg);
    }
    if(oDoct.operationStatus === 1){
        setDoctStatus(languages.pending);
        setIconStatus(PendingFileSvg);
    }
    if(oDoct.operationStatus === 2){
        setDoctStatus(languages.complete);
        setIconStatus(CompletedFileSvg);
    }
  }

  useEffect(() => {
    if (bLoaded.current) return;
    populateFilePdf();
    bLoaded.current = true;
  }, []);


  if(loading) return <LoadinInterfaceComp />

  return (
    <>
      <ModalListUsers
        dialogTitle={languages.selectSigner}
        isOpen={userDlgVis}
        lstUsers={allUsers}
        onClose={(o) => {
          setUserDlgVis(false);
          if (!o) return;
          addSignerToList(o);
        }}
      />
      <div className="flex flex-col space-y-2">
        {/* Header */}
        <div className="flex flex-col">
          <div className="flex items-center flex-wrap">
            <div className="flex-1 flex items-center gap-2">
              <Link to={"/app/signdocs"}>
                <ArrowLeftSvg width={16} height={16} />
              </Link>
              <h3 className="text-lg flex-1">{doctTitle}</h3>
            </div>
            <div></div>
          </div>
          <div className="flex items-center gap-2">
            <IconStatus width={16} height={16} />

            <span className="text-sm">{doctStatus}</span>
          </div>
        </div>

        <div className="flex flex-wrap mt-8 h-screen overflow-y-auto hide-scroll">
          <div className="w-full md:w-1/2 lg:w-[60%]">
            <div className="p-5">
              <PDFViewer loading={loading} file={pdfFile} />
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-[40%]">
              <div className="sticky top-20 ">
                <div className="dark:bg-background border-border bg-widgetflex h-full max-h-[64rem] flex-col justify-between overflow-auto rounded-xl border px-4 py-6 lg:h-[calc(100vh-6rem)]">
                  <div className="-mx-2 flex flex-col px-2 flex-grow">
                    <div className="flex-grow flex-1">
                      {formStep === 1 && (
                        <>
                          <SignerDocumentTitle
                            defValue={operationRecord.documentTitle}
                            formError={formErrors.fileTitle}
                            onSaveOps={(e) => {
                              setOperationRecord({
                                ...operationRecord,
                                documentTitle: e.documentTitle,
                                applyStamp: e.applyStamp,
                              });
                            }}
                          />
                        </>
                      )}

                      {formStep === 2 && (
                        <>
                          <SignerDocumentListSigners
                              lstSigners={operationRecord.lstSigners}
                              removeSigner={(o) => removeSigner(o)}
                              setUserDlgVis={() => setUserDlgVis(true)}
                          />                          
                        </>
                      )}

                      {formStep === 3 &&
                        operationRecord.lstSigners.length > 0 && (
                          <SignDoctFieldArea
                            lstSigners={operationRecord.lstSigners}
                            addFieldToPage={(e) => {}}
                            onClickFieldAction={(e, s) => {}}
                          />
                        )}
                      {formStep === 4 && (
                        <SignerMessageSubjectArea onSaveOps={onMessageChange} />
                      )}
                    </div>
                  </div>
                  <div className="mt-4 flex-shrink-0">
                    <div className="mt-4">
                      <div>
                        <p className="text-black text-sm">
                          {languages.step}&nbsp;
                          <span>
                            {formStep}&nbsp;{languages.of.toLowerCase()}
                            &nbsp;4
                          </span>
                        </p>

                        <div className="bg-muted relative mt-4 h-[2px] rounded-md">
                          <div
                            className="bg-archivix absolute inset-y-0 left-0"
                            style={{
                              width: `${(formStep / 4) * 100}%`,
                              opacity: 1,
                            }}
                          ></div>
                        </div>
                        <div className="flex gap-x-4 mt-4">
                          <button
                            type="button"
                            disabled={formStep === 1}
                            onClick={() => moveToAction("back")}
                            className={`inline-flex items-center justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background text-primary-foreground h-11 px-8 rounded-md flex-1 bg-green-400 hover:bg-green-400/40`}
                          >
                            {languages.goback}
                          </button>
                          <button
                            type="button"
                            onClick={() => moveToAction("forard")}
                            className={`inline-flex items-center justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background text-primary-foreground h-11 px-8 rounded-md flex-1 bg-green-400 hover:bg-green-400/40 hover:text-white`}
                          >
                            {formStep === 4
                              ? languages.send
                              : languages.continue}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
}
