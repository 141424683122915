/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

import appDecl from "src/utils/declarations";
import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";
import { IArchivixUser } from "src/models/archivix_models";

import NetServices from "src/utils/netservices";
import { useNavigate } from "react-router-dom";
import WiseTextInput from "src/components/WiseTextInput";
import LoadinInterfaceComp from "src/components/LoadinInterfaceComp";

import userImage from "src/assets/images/profile_placeholder.jpg";
import { IFormError } from "src/models/smarttypes";
import { WiseSimpleButton } from "src/components/majorsmallcomps";

const languages = getLanguages();
const appFxs = getFxs();
const defUser: IArchivixUser = {
  userKey: "",
  companyKey: "",
  firstName: "",
  isAdmin: false,
  lastName: "",
  mainAdmin: false,
  registerDate: new Date(),
  userActive: false,
  userEmail: "",
  phoneNumber: "",
  photoProfile: "",
  sex: "0",
  userPassword: "",
};

export default function MyProfile() {
  document.title = `${languages.myProfile} - Archivix`;

  const [dataForm, setDataForm] = useState<IArchivixUser>({ ...defUser });
  const [formErrors, setFormErrors] = useState<IFormError>({});

  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setIsSaving] = useState<boolean>(false);

  const [imageFile, setImageFile] = useState<File | null>(null);
  const [doctImage, setDoctImage] = useState<string>("");
  const [imagePath, setImagePath] = useState<string>(userImage);

  const navigate = useNavigate();

  const bLoaded = useRef(false);
  const fileSelect = useRef<HTMLInputElement>(null);

  const updateFormData = (fldName: string, fldValue: string) => {
    setDataForm({ ...dataForm, [fldName]: fldValue });
  };

  const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files![0]!;
    const reader = new FileReader();
    setImageFile(file);

    reader.onload = () => {
      setDoctImage(reader.result as string);
    };

    reader.readAsDataURL(file);
  };
  const removeImageFile = () => {
    setImageFile(null);
  };

  const saveProfileOps = async () => {
    const errors: IFormError = {};
    const user = { ...dataForm };

    if (!user.firstName || user.firstName.length === 0) {
      errors.firstName = languages.fldRequired;
    }
    if (!user.lastName || user.lastName.length === 0) {
      errors.lastName = languages.fldRequired;
    }
    if (!user.phoneNumber || user.phoneNumber.length === 0) {
      errors.phoneNumber = languages.fldRequired;
    } else if (!appFxs.isTelephoneNbrValid(user.phoneNumber)) {
      errors.phoneNumber = languages.phonenbrLengthMsg;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    const routePath = !imageFile
      ? "managt/saveprofiledatanofile"
      : "managt/saveprofiledatawithfile";
    const oDataform = new FormData();

    const profileData = JSON.stringify(dataForm);

    oDataform.append("profileData", profileData);
    if (imageFile) {
      oDataform.append("archiveProfile", imageFile);
    }

    setIsSaving(true);

    const saveOper = await NetServices.requestPostWithFileAxios(
      routePath,
      oDataform,
      false
    );

    if (!saveOper.bReturn) {
      appFxs.showAlert(saveOper.msgTitle, saveOper.msgBody);
      setIsSaving(false);
      return;
    }

    appFxs.setLocalStorageByKey(
      appDecl.connectedUser,
      JSON.stringify(saveOper.connectedUser!)
    );
    setDataForm(saveOper.connectedUser!);

    document.location.reload();
  };

  const prepareUser = async (bAjax: boolean = false) => {
    setLoading(true);
    const oOper = await NetServices.requestGet(
      `managt/getmyprofiledata`,
      bAjax
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      navigate("/app/settings/users");
      setLoading(false);
      return;
    }

    const oPrev = oOper.connectedUser!;

    const imgP = `${appDecl.profilesPath}/${oPrev.companyKey}/${oPrev.photoProfile}`;
    if (oPrev.photoProfile) {
      setImagePath(imgP);
    }

    setDataForm(oPrev);
    setLoading(false);
  };

  useEffect(() => {
    if (bLoaded.current) return;
    prepareUser();
  }, []);

  if (loading) return <LoadinInterfaceComp height={350} />;

  return (
    <div className="flex flex-col w-full max-w-[600px] mx-auto">
      <div className="flex items-center mb-3 gap-3">
        <h3 className="flex-1 font-bold text-lg">{languages.myProfile}</h3>
        <button
          className="btn"
          title={languages.save}
          onClick={() => saveProfileOps()}
          disabled={saving}
        >
          {saving ? (
            <i className="fa fa-spin fa-spinner"></i>
          ) : (
            <i className="fa fa-save"></i>
          )}
        </button>
      </div>
      <p className="mt-1 text-gray-800 dark:text-gray-400">
        {languages.myProfileMsg}
      </p>
      <div className="flex flex-col space-y-2 mt-4">

        <div className="w-full grid place-content-center">
          <div className="aspect-video border border-solid rounded-lg h-[200px] w-[150px] relative overflow-hidden">
            {imageFile && (
              <span
                className="absolute right-1 top-1 border border-solid p-1 cursor-pointer w-5 h-5 flex items-center justify-center rounded bg-blue-500 text-white"
                onClick={removeImageFile}
              >
                <i className="fa fa-times"></i>
              </span>
            )}
            {imageFile && (
              <img
                src={doctImage}
                className="w-full h-full object-cover"
                alt=""
              />
            )}
            {!imageFile && (
              <img
                src={imagePath}
                className="w-full h-full object-cover"
                alt=""
                onClick={() => {
                  fileSelect?.current?.click();
                }}
              />
            )}
          </div>
          <input
            type="file"
            className="hidden"
            ref={fileSelect}
            onChange={onImageChange}
            accept="image/*"
          />
        </div>

        <WiseTextInput
          fldName="userEmail"
          fldType="email"
          label={languages.email}
          placeholder={languages.emailAddress.toLowerCase()}
          graphicType="email"
          value={dataForm.userEmail}
          onChange={updateFormData}
          tabIndex={1}
          disabled={true}
        />
        <WiseTextInput
          fldName="firstName"
          fldType="text"
          label={languages.firstName}
          placeholder={languages.typeFirstNameH.toLowerCase()}
          graphicType="person"
          value={dataForm.firstName}
          onChange={updateFormData}
          tabIndex={2}
          disabled={saving}
          errorText={formErrors.firstName}
        />
        <WiseTextInput
          fldName="lastName"
          fldType="text"
          label={languages.lastName}
          placeholder={languages.typeLastNameH.toLowerCase()}
          graphicType="person"
          value={dataForm.lastName}
          onChange={updateFormData}
          tabIndex={3}
          disabled={saving}
          errorText={formErrors.lastName}
        />
        <WiseTextInput
          fldName="phoneNumber"
          fldType="text"
          label={languages.phoneNbr}
          placeholder={languages.phoneNbr.toLowerCase()}
          graphicType="phone"
          value={dataForm.phoneNumber}
          onChange={updateFormData}
          tabIndex={4}
          disabled={saving}
          maxLength={12}
          isNumeric={true}
          errorText={formErrors.phoneNumber}
        />
        <WiseTextInput
          fldName="sex"
          fldType="select"
          label={languages.sex}
          graphicType="sex"
          value={dataForm.sex}
          onChange={updateFormData}
          tabIndex={5}
          disabled={saving}
          selectOptions={[
            { value: "0", label: languages.male },
            { value: "1", label: languages.female },
          ]}
          errorText={formErrors.sex}
        />
        <div className="pt-6">
          <WiseSimpleButton
            label={languages.save}
            disabled={saving}
            working={saving}
            btnType="default"
            onClick={() => saveProfileOps()}
          />
        </div>
      </div>
    </div>
  );
}
