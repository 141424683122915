/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import WiseTagsInput from "./WiseTagsInput";


import getLanguages from "src/utils/language";
import FileSelectorComponent from "./FileSelectorComponent";
import { IDocumentFile } from "src/models/archivix_models";
import { mimeTypes } from "src/utils/app_arch_data";
import { IFileElt, IFormError } from "src/models/smarttypes";
import WiseModalTextInput from "./WiseModalTextInput";

const languages = getLanguages();
const defData: IDocumentFile = {
  companyKey: "",
  createDate: new Date(),
  createdBy: "",
  fileKey: "",
  fileName: "",
  fileSources: [],
  sourceFile: 0,
  folderKey: "",
  primaryLocation: "",
  secondaryLocation: "",
  tags: [],
  userAccess: [],
};


interface IFileRecordFormComp {
  onReceiveData: (lst: IFileElt[], data: IDocumentFile) => void;
  saving: boolean;
  mimeType: string;
  folderKey?: string,
}

export default function FileRecordFormComp({
  onReceiveData,
  saving,
  mimeType,
  folderKey,
}: IFileRecordFormComp) {
  const [dataForm, setDataForm] = useState<IDocumentFile>({ ...defData, folderKey: folderKey });
  const [fromErrors, setFormErrors] = useState<IFormError>({});

  const [listFiles, setListFiles] = useState<IFileElt[]>([]);

  const addFile = (e: IFileElt[]) => {
    listFiles.push(...e);

    if (mimeType !== mimeTypes[0]) {
      setDataForm({ ...dataForm, fileName: e[0].fileBlob.name });
    }
  };
  const onChange = (f: string, v: string | number) => {
    setDataForm({ ...dataForm, [f]: v });
  };
  const onTagsChange = (f: string, v: string[]) => {
    setDataForm({ ...dataForm, [f]: v });
  };
  const onRemoveFiles = () => {
    setListFiles([]);
    setDataForm({ ...dataForm, fileName: "" });
  };
  const updateFiles = (lst: IFileElt[]) => {
    setListFiles(lst);
  };
  const saveRecords = () => {
    const errors: IFormError = {};

    if (!dataForm.fileName || dataForm.fileName.length === 0) {
      errors.fileName = languages.fldRequired;
    }

    if (listFiles.length === 0) {
      errors.files = languages.noFilesSelected;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    const fileKeys = listFiles.map((o) => o.fileKeyExt);
    const sourceType = mimeTypes.indexOf(mimeType);
    dataForm.fileSources = fileKeys;
    dataForm.sourceFile = sourceType;

    onReceiveData(listFiles, dataForm);
  };

  useEffect(() => {
    setDataForm({ ...dataForm, fileName: "" });
  }, [mimeType]);

  return (
    <>
      <FileSelectorComponent
        mimeType={mimeType}
        addFile={addFile}
        removeFiles={onRemoveFiles}
        updateFiles={updateFiles}
        saving={saving}
      />

      <WiseModalTextInput
        fieldName="fileName"
        value={dataForm.fileName}
        label={languages.documentName}
        fldType="text"
        placeholder={languages.documentName.toLowerCase()}
        errorText={fromErrors.fileName}
        maxLength={150}
        graphicType="file"
        onChange={onChange}
        disabled={saving}
      />

      <WiseModalTextInput
        fieldName="primaryLocation"
        value={dataForm.primaryLocation ?? ""}
        label={languages.primaryLoc}
        fldType="text"
        placeholder={languages.primaryLoc.toLowerCase()}
        errorText={fromErrors.primaryLocation}
        maxLength={150}
        graphicType="location"
        onChange={onChange}
        disabled={saving}
      />

      <WiseModalTextInput
        fieldName="secondaryLocation"
        value={dataForm.secondaryLocation ?? ""}
        label={languages.secondaryLoc}
        fldType="text"
        placeholder={languages.secondaryLoc.toLowerCase()}
        errorText={fromErrors.secondaryLocation}
        maxLength={150}
        graphicType="map"
        onChange={onChange}
        disabled={saving}
      />

      <WiseTagsInput
        fieldName="tags"
        label={languages.tags}
        errorText={fromErrors.secondaryLocation}
        value={dataForm.tags ?? []}
        disabled={saving}
        placeholder={languages.newTag}
        showIcon={true}
        onChange={onTagsChange}
      />
      {fromErrors.files && (
        <div className="label">
          <span className="label-text-alt text-red-500">
            {fromErrors.files}
          </span>
        </div>
      )}
      <button
        className="btn bg-[#027e6f] hover:bg-[#027e6f]/80 text-white disabled:text-black"
        onClick={() => saveRecords()}
        disabled={saving}
      >
        <span>
          {saving && (
            <span>
              <i className="fa fa-spin fa-spinner"></i>&nbsp;
            </span>
          )}
          {saving ? languages.saving : languages.save}
        </span>
      </button>
    </>
  );
}

interface IFileRecordNotFileFormComp {
  onReceiveData: (data: IDocumentFile) => void;
  saving: boolean;
  defaultData: IDocumentFile;
}
export const FileRecordNotFileFormComp = ({
  saving,
  onReceiveData,
  defaultData,
}: IFileRecordNotFileFormComp) => {
  const [dataForm, setDataForm] = useState<IDocumentFile>({ ...defaultData });
  const [fromErrors, setFormErrors] = useState<IFormError>({});

  const onChange = (f: string, v: string | number) => {
    setDataForm({ ...dataForm, [f]: v });
  };
  const onTagsChange = (f: string, v: string[]) => {
    setDataForm({ ...dataForm, [f]: v });
  };
  const saveRecords = () => {
    const errors: IFormError = {};

    if (!dataForm.fileName || dataForm.fileName.length === 0) {
      errors.fileName = languages.fldRequired;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    onReceiveData(dataForm);
  };

  return (
    <>
      <WiseModalTextInput
        fieldName="fileName"
        value={dataForm.fileName}
        label={languages.documentName}
        fldType="text"
        placeholder={languages.documentName.toLowerCase()}
        errorText={fromErrors.fileName}
        maxLength={150}
        graphicType="file"
        onChange={onChange}
        disabled={saving}
      />

      <WiseModalTextInput
        fieldName="primaryLocation"
        value={dataForm.primaryLocation ?? ""}
        label={languages.primaryLoc}
        fldType="text"
        placeholder={languages.primaryLoc.toLowerCase()}
        errorText={fromErrors.primaryLocation}
        maxLength={150}
        graphicType="location"
        onChange={onChange}
        disabled={saving}
      />

      <WiseModalTextInput
        fieldName="secondaryLocation"
        value={dataForm.secondaryLocation ?? ""}
        label={languages.secondaryLoc}
        fldType="text"
        placeholder={languages.secondaryLoc.toLowerCase()}
        errorText={fromErrors.secondaryLocation}
        maxLength={150}
        graphicType="map"
        onChange={onChange}
        disabled={saving}
      />

      <WiseTagsInput
        fieldName="tags"
        label={languages.tags}
        errorText={fromErrors.secondaryLocation}
        value={dataForm.tags ?? []}
        disabled={saving}
        placeholder={languages.newTag}
        showIcon={true}
        onChange={onTagsChange}
      />
      {fromErrors.files && (
        <div className="label">
          <span className="label-text-alt text-red-500">
            {fromErrors.files}
          </span>
        </div>
      )}
      <button
        className="group btn bg-green-400"
        onClick={() => saveRecords()}
        disabled={saving}
      >
        <span className="group-disabled:text-black text-black">
          {saving && (
            <span>
              <i className="fa fa-spin fa-spinner"></i>&nbsp;
            </span>
          )}
          {saving ? languages.saving : languages.save}
        </span>
      </button>
    </>
  );
};
