import { useState } from "react";

import getLanguages from "src/utils/language";
import generalFxs from "src/utils/general_fx";

import WiseModal from "./modalportal/WiseModal";
import WiseModalTextInput from "./WiseModalTextInput";
import { IDocumentForlder } from "src/models/archivix_models";
import {
  IDocumentRecordWrapperExtended,
  IFormError,
} from "src/models/smarttypes";
import WiseTagsInput from "./WiseTagsInput";
import NetServices from "src/utils/netservices";
import { WiseSpecialBtn } from "./wise_button_files";

const languages = getLanguages();
const appFxs = generalFxs();

type FolderFormModalProps = {
  isOpen: boolean;
  onClose?: (lst?: IDocumentRecordWrapperExtended[]) => void;
  dialogWidth?: number;

  currentRecord?: IDocumentForlder;
  recordStatus: number;
  dialogTitle: string;

  mainOperation?: boolean;
};
type FolderFormWrapperProps = {
  currentRecord?: IDocumentForlder;
  recordStatus: number;
  onClose?: (lst?: IDocumentRecordWrapperExtended[]) => void;

  mainOperation?: boolean;
};

export const FolderFormModal = ({
  isOpen,
  onClose,
  dialogWidth,
  currentRecord,
  recordStatus,
  dialogTitle,
  mainOperation,
}: FolderFormModalProps) => {
  const bMain = mainOperation ?? true;
  const closeDlg = () => {
    if (!onClose) return;
    onClose!();
  };

  if (!isOpen) return null;
  return (
    <WiseModal
      isOpen={isOpen}
      onClose={closeDlg}
      titleBox={dialogTitle}
      width={dialogWidth ?? 450}
      hasFooter={false}
    >
      <FolderFormWrapper
        currentRecord={currentRecord}
        recordStatus={recordStatus}
        onClose={onClose}
        mainOperation={bMain}
      />
    </WiseModal>
  );
};

export const FolderFormWrapper = ({
  currentRecord,
  recordStatus,
  onClose,
  mainOperation,
}: FolderFormWrapperProps) => {
  const [dataForm, setDataForm] = useState<IDocumentForlder>(currentRecord!);
  const [fromErrors, setFormErrors] = useState<IFormError>({});
  const [saving, setSaving] = useState(false);

  const deleteRecord = async () => {
    const bAsk = await appFxs.showConfirm(
      languages.deleteFolder,
      languages.deleteFolderMsg
    );
    if (!bAsk.isConfirmed) return;

    setSaving(true);

    const oOper = await NetServices.requestPost(
      "documents/deletedocumentfolderrecordextended",
      {
        folderKey: dataForm.folderKey,
      }
    );
    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setSaving(false);
      return;
    }

    onClose!(oOper.listOfRelatedDoctsExt!);
  };
  const saveRecord = async () => {
    const errors: IFormError = {};

    if (!dataForm.folderName || dataForm.folderName.length === 0) {
      errors.folderName = languages.fldRequired;
    }
    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setSaving(true);

    const oOper = await NetServices.requestPost(
      "documents/saveorupdatedocumentfolderextended",
      {
        folderRecord: dataForm,
      }
    );
    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setSaving(false);
      return;
    }

    onClose!(oOper.listOfRelatedDoctsExt!);
  };

  const onChange = (f: string, v: string | number) => {
    setDataForm({ ...dataForm, [f]: v });
  };
  const onTagsChange = (f: string, v: string[]) => {
    setDataForm({ ...dataForm, [f]: v });
  };

  return (
    <div className="flex flex-col">
      <div className="py-1 flex flex-col max-h-[400px] overflow-y-auto px-2">
        <WiseModalTextInput
          fieldName="folderName"
          value={dataForm.folderName}
          label={languages.folderName}
          fldType="text"
          placeholder={languages.folderName.toLowerCase()}
          errorText={fromErrors.folderName}
          maxLength={150}
          graphicType="folder"
          onChange={onChange}
          disabled={saving}
        />

        <WiseModalTextInput
          fieldName="primaryLocation"
          value={dataForm.primaryLocation ?? ""}
          label={languages.primaryLoc}
          fldType="text"
          placeholder={languages.primaryLoc.toLowerCase()}
          errorText={fromErrors.primaryLocation}
          maxLength={150}
          graphicType="location"
          onChange={onChange}
          disabled={saving}
        />

        <WiseModalTextInput
          fieldName="secondaryLocation"
          value={dataForm.secondaryLocation ?? ""}
          label={languages.secondaryLoc}
          fldType="text"
          placeholder={languages.secondaryLoc.toLowerCase()}
          errorText={fromErrors.secondaryLocation}
          maxLength={150}
          graphicType="map"
          onChange={onChange}
          disabled={saving}
        />

        <WiseTagsInput
          fieldName="tags"
          label={languages.tags}
          errorText={fromErrors.secondaryLocation}
          value={dataForm.tags ?? []}
          disabled={saving}
          placeholder={languages.newTag}
          showIcon={true}
          onChange={onTagsChange}
        />
      </div>

      <div className="modal-action">
        <div className="w-full flex justify-between">
          {recordStatus !== 0 ? (
            <div className="flex gap-2 items-center">
              <WiseSpecialBtn
                type="destructive"
                label={languages.delete}
                iconType="delete"
                disabled={saving}
                onClick={() => deleteRecord()}
                loading={saving}
              />
            </div>
          ) : (
            <div></div>
          )}

          <div className="flex gap-2 items-center">
            <WiseSpecialBtn
              label={languages.close}
              iconType="cancel"
              disabled={saving}
              onClick={() => onClose!()}
              loading={saving}
            />

            <WiseSpecialBtn
              label={languages.save}
              iconType="save"
              disabled={saving}
              onClick={saveRecord}
              loading={saving}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
