import React from "react";
import { Link, Outlet } from "react-router-dom";
import archivixLogoV2 from "src/assets/images/archivix_logo_black_white_v2.png";

import getLang from "src/utils/language";


const languages = getLang();

export default function SignLayout() {
  return (
    <div className="flex flex-col min-h-full">
      <header className="supports-backdrop-blur:bg-background/60 bg-background/95 sticky top-0 z-[60] flex h-16 w-full items-center border-b border-b-slate-300 backdrop-blur duration-200">
        <div className="mx-auto flex w-full max-w-screen-xl items-center justify-between gap-x-4 px-4 md:justify-normal md:px-8">
          <Link
            to={"/"}
            className="focus-visible:ring-ring ring-offset-background rounded-md focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 md:inline"
          >
            <img src={archivixLogoV2} alt="" className="w-[186px] h-[28px] object-cover" />
          </Link>
          <div className="ml-8 flex-1 items-center gap-x-12 md:flex md:justify-between">
            <div className="flex items-baseline gap-x-6">
              <Link
                className="text-black dark:text-black/60 focus-visible:ring-ring ring-offset-background rounded-md font-medium leading-5 hover:opacity-80 focus-visible:outline-none focus-visible:ring-2"
                to="/"
              >
                {languages.home}
              </Link>
            </div>
          </div>
        </div>
      </header>
      <main className="mb-8 mt-8 px-4 md:mb-12 md:mt-12 md:px-8">
        <div className="mx-auto w-full max-w-screen-xl">
          <Outlet />
        </div>
      </main>
    </div>
  );
}
