import { CSSProperties } from "react";
import {
  IArchivixUser,
  IDocumentChat,
  IDocumentFile,
  IDocumentForlder,
} from "./archivix_models";

export interface IPexelsImageSrc {
  landscape?: string;
  large?: string;
  large2x?: string;
  medium?: string;
  original?: string;
  portrait?: string;
  small?: string;
  tiny?: string;
}
export interface IPexelsImage {
  alt: string;
  avg_color: string;
  height: number;
  id: number;
  liked: boolean;
  photographer: string;
  photographer_id: number;
  photographer_url: string;
  url: string;
  width: number;
  src: IPexelsImageSrc;
}
export interface IPexelsImageRequest {
  next_page: string;
  page: number;
  per_page: number;
  photos: IPexelsImage[];
}

export type HeaderType = {
  "user-access-key": string;
  lang: string;
  "company-code": string;
  "client-operation-date": string;
  "year-exercise-app": string;
  "default-sms-account": string;
  "current-devise-val": string;
  "current-branch-val": string;
  "user-email-value": string;
  "user-password-value": string;
  "app-type": string;
};

export type EmojiFilter = "emotions" | "people";

export enum DateOperation {
  Day,
  Month,
  Year,
}

export interface IAcctFormRegister {
  adminEmail: string;

  registerCode1: string;
  registerCode2: string;

  companyEmail: string;
  companyName: string;
  shortName: string;

  firstName: string;
  lastName: string;
  displayName?: string;

  password: string;
  confirmPassword: string;
}

export interface IArchivixUserError {
  userEmail?: string;
  userPassword?: string;
  firstName?: string;
  lastName?: string;
  sex?: string;
  phoneNumber?: string;
}

export interface IFormError {
  adminEmail?: string;
  userEmail?: string;
  userPassword?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  sex?: string;
  registerDate?: string;
  folderName?: string;
  primaryLocation?: string;
  secondaryLocation?: string;
  tags?: string;
  files?: string;

  fileName?: string;
  password?: string;
  confPassword?: string;
  fileData?: string;

  
  companyName?: string;
  shortName?: string;
  companyEmail?: string;
  fullAddress?: string;

}

export type ISelectOptionType = {
  id: string;
  label: string;
};

export interface IArchUserShort {
  userEmail: string;
  userName: string;
  isAdmin: boolean;
  isMainAdmin: boolean;
}

export interface IDocumentRecordWrapper {
  doctType: "folder" | "file";
  doctKey: string;
  sourceType: number;
  doctName: string;
  referenceDate: Date;
  createdBy: IArchUserShort;
  userAccess: IArchUserShort[];
  tags: string[];
  folderData?: IDocumentForlder;
  documentData?: IDocumentFile;
}

export interface IDocumentRecordWrapperExtended {
  doctType: "folder" | "file",
  companyKey: string,
  doctKey: string,
  sourceType: number,
  doctName: string,
  referenceDate: Date,
  createdBy: IArchUserShort,
  userAccess: IArchUserShort[],
  tags: string[],
  folderData?: {
    folder: IDocumentForlder;
    files: number;
    folders: number;
  },
  documentData?: {
    file: IDocumentFile;
    folderKey: string;
    vigettePath: string;
    pdfPath: string;
    chats: number;
  },
}


export interface IArchUserShort {
  userEmail: string;
  userName: string;
  isAdmin: boolean;
  isMainAdmin: boolean;
  photoProfile: string;
}


export interface ChatMessageUser {
  message: IDocumentChat;
  user: IArchUserShort;
}

export interface InnerCss extends CSSProperties {
  "--testimonial-color"?: string;
  "--qualif-color"?: string;
  "--parent-color"?: string;
  "--entry-date-color"?: string;
  "--title-color"?: string;
  "--title-width"?: string;
  "--sub-title-width"?: string;
  "--blog-entry-color"?: string;
  "--strangeImageBg"?: string;
  "--special-color"?: string;
  "--bgImage"?: string;
  "--bgColor"?: string;
  "--bgHoverColor"?: string;
  "--textColor"?: string;
  "--textHoverColor"?: string;
  "--iconColor"?: string;
  "--width"?: string;
  "--borderRadius"?: string;
}

export interface IFileElt {
  fileKeyExt: string;
  fileBlob: File;
}

export interface SelectorBtnType {
  type: "all" | "draft" | "completed" | "pending" | "inbox";
  icon: string;
  qty: number;
  label: string;
}


export interface IDoctSignRecip {
  profilePath?: string,
  nameInitials: string,
  fullName: string,
  userEmail: string,
  hasSigned: boolean,
  companyKey: string,
}
export interface DocumentSignElt {
  companyKey: string,
  fileKey: string,
  created: Date,
  title: string,
  recipients: IDoctSignRecip[],
  status: "draft" | "pending" | "completed",
  inbox: boolean,
  inboxType: "ignore" | "waiting" | "signed",
  meOwner: boolean,
  action: 'none'|'edit'|'sign'|'download'|'complete',
  ownerName: string,
  hasStamp: boolean,
  doctOwner: IArchivixUser,
}

export interface IArchivixSignDocument {
  companyKey: string;
  operationKey: string;
  operationDate: Date;
  operatorUser: string;
  fileKey: string;
  operationStatus: number;
  fileTitle: string;
  fileSize: number;
}
export interface IArchivixSignDocumentSigner {
  companyKey: string,
  signerOperKey: string,
  mainOpsOperKey: string,
  signerEmail: string,
  ownerCopyFileKey: string,
  notificationDate: Date,
  signDate: Date,
  signStatus: number,
}
export interface IArchUserFixer {
  userEmail: string;
  userName: string;
  userPic?: string;
}

export interface IEmailName {
  orderKey: number,
  userEmail: string,
  userName: string,
  typeUser: number,
  //0: Signer, 1: Copy Receiver, 2: Approver, 3: Viewer
}


export type SignFieldType = "signature" | "email" | "name" | "date";

export interface IFieldPagePos {
  formId: string;
  type: SignFieldType;
  pageNumber: number;
  pageX: number;
  pageY: number;
  pageWidth: number;
  pageHeight: number;
  signerEmail: string;
}

export interface IUserPointer {
  userEmail: string;
}
export interface ISignProcessSenderPartner {
  doctTitle: string,
  applyStamp: boolean,
  listSignerEmails: IEmailName[],
  listOfStaffPartners: string[],
  messageSubject: string,
  messageContent: string,
  documentOperKey: string,
  companyKey: string,
}
export interface ISignProcessSender {
  doctTitle: string,
  applyStamp: boolean,
  listSignerEmails: string[],
  messageSubject: string,
  messageContent: string,
  documentOperKey: string,
  companyKey: string,
}
export interface IArchivixSignatureFile {
  ompanyKey: string,
  signatureKey:string,
  userEmail: string,
  signatureName: string,
  signatureType: number,
  signDate: Date,
  updateDate: Date,
}
export interface ISignOperElt {
  companyKey: string,
  documentRecord: IArchivixSignDocument,
  signOper: IArchivixSignDocumentSigner,
  signatureFileRec: IArchivixSignatureFile | null,
  fileRelPath: string;
  signerUser: {
    email: string;
    name: string;
  },
  inviterUser: {
    email: string;
    name: string;
  },
}

export interface IBlobRet {
  bReturn: boolean,
  file:  string | ArrayBuffer | null,
}

export interface ReturnInfo {
  bReturn: boolean,
  msgTitle: string,
  msgBody: string,
}

export interface IShortUserSelector {
  oSh: IArchUserShort, 
  activFile: IDocumentFile,
  updateUser: (email: string, checked: boolean)=> void,
  isSaving?: boolean,
}
export interface IShortUserSelectorKeys {
  oSh: IArchUserShort, 
  activFile: IDocumentFile,
  updateUser: (email: string, checked: boolean)=> void,
  isSaving?: boolean,
  isVisible?: boolean,
  keys: string[],
}

export interface IListOfDoscumentRecords {
  onRefresh: () => void,
  addFolder: () => void,
  addFile: (fileData?: IDocumentFile) => void,
  onFilter: (txt?: string) => void,
  documents: IDocumentRecordWrapperExtended[],
  operationFolder: (operType: "prev" | "del" | "edit", fldKey: string) => void,
  operationDoct: (
    operType: "detail" | "prev" | "del" | "edit",
    doctKey: string,
    fileKey: string,
  ) => void,
  folderDet?: {
    title: string;
    parentKey?: string;
  },
}

export interface ISpecialDocumentRecord {
  docFile: IDocumentRecordWrapperExtended;
  operationFolder: (operType: "prev" | "del" | "edit", fldKey: string) => void;
  operationDoct: (
    operType: "detail" | "prev" | "del" | "edit",
    doctKey: string,
    fileKey: string,
  ) => void;
}

export interface IDocumentFileWrapper {
  docFile: IDocumentRecordWrapperExtended;
  operationDoct: (
    operType: "detail" | "prev" | "del" | "edit",
    doctKey: string,
    fileKey: string,
  ) => void;
}

export interface IPreviewerMiniatComp {
  loading: boolean;
  pdfFile: ArrayBufferLike | null;
  clearPdf: () => void;
}

export interface IDocumentFolderWrapper {
  docFile: IDocumentRecordWrapperExtended;
  operationFolder: (
    operType: "prev" | "del" | "edit",
    folderKey: string
  ) => void;
}
export interface ILstSearchDocumentRecord {
  docFile: IDocumentRecordWrapperExtended,
  operationFolder: (operType: "prev" | "del" | "edit", fldKey: string) => void,
  operationDoct: (operType: "detail" | "prev" | "del" | "edit", doctKey: string, fileKey: string) => void,
}

export interface ISignOperFld {
  documentTitle: string,
  applyStamp: boolean,
  lstSigners: IArchUserFixer[],
  messageTemplate: {
    subject: string,
    message: string,
  },
  messageSet: boolean,
}

export interface ISignerFldPart {
  documentTitle: string,
  applyStamp: boolean,
  lstSigners: IEmailName[],
  messageTemplate: {
    subject: string,
    message: string,
  },
  messageSet: boolean,
}

export interface IStepsError {
  fileTitle?: string;
}

export interface ICompSetting {
  settType: 'stamp'|'signature'|'detail',
  navigateToUrl: (url: string) => void,
}

export interface ICompanyStampFile {
  stampKey: string,
  companyKey: string,
  stampfileName: string,   
  operationDate: Date,
  recorderEmail: string,
}
export interface ICompanySignatureFile {
  signatureKey: string,
  companyKey: string,
  signatureFileName: string,  
  recorderEmail: string,
  signaturePassword: string,
  operationDate: Date,
}

export interface ISignReturnType {
  signingProcess: number,
  userSignatureFilePath: string,
  originalDocumentName: string,
  companyKey: string,
  userKey: string,
  mainOperKey: string,
  doctSignStatus: number,
}

export interface ICompleteDoctHistory {
  companyKey: string,
  fileKey: string,
  documentName: string,
  lstSigners: IDoctSignRecip[],
  uploadedBy: string,
  createdDate: Date,
  lastModified: Date,
  activityDetails: string[]
}
export interface IArchivixSignDocumentHistory {
  companyKey: string,
  historyKey: string,
  mainOpsOperKey: string,

  historyTiming: number,
  historyType: 'create'|'sent'|'opened'|'signed'|'completed',
  operatorEmail: string,
  historyDate: Date,
}
export interface IHistoryDoctName {
  historyRecord: IArchivixSignDocumentHistory,
  operatorName: string,
}
export interface ISignDocumentHistory {
  companyKey: string,
  fileKey: string,

  doctElement: DocumentSignElt,
  doctOwner: IArchivixUser,
  doctStatus: number,
  meSigner: IDoctSignRecip,

  history: IArchivixSignDocumentHistory[],
}

export interface IHistoryDoctName {
  historyRecord: IArchivixSignDocumentHistory,
  operatorName: string,
}

export interface IPartnerSignDocument {
  companyKey: string,
  operationKey: string,
  operationDate: Date,
  operatorUser: string,
  fileKey: string,
  operationStatus: number,
  
  fileTitle: string,
  fileSize: number,
  applyStamp: boolean,
}

export interface IPartnerSignDocumentSigner {
  companyKey: string,
  signerOperKey: string,
  mainOpsOperKey: string,
  signerEmail: string,
  ownerCopyFileKey: string,
  notificationDate: Date,
  signDate: Date,
  signStatus: number,
  typeUser: number,
//0: Signer, 1: Copy Receiver, 2: Approver, 3: Viewer
}
export interface IGuestForDoct {
  guestKey: string,
  guestName: string,
  guestEmail: string,
  guestImage: string,
  guestType: 'guest'|'owner',
}

export interface IInboxDoct {
  companyKey: string,
  mainOpsKey: string,
  signatureOpKey: string,
  doctTitle: string,
  doctOwnerName: string,
  originalDoctPath: string,
  imageThumbPath: string,
  ownerProfileIm: string |null,
}

export interface ILatestOper {
  companyKey: string,
  doctKey: string,
  doctTitle: string,
  tags: string,
  comments: number,
  createdDate: Date,
  access: {
    name: string,
    email: string,
  }[],
}

export interface IPassworderChecker {
  capLetters: {
    len: number,
    status: 'poor'|'medium'|'high',
  },
  smLetters: {
    len: number,
    status: 'poor'|'medium'|'high',
  },
  specChars: {
    len: number,
    status: 'poor'|'medium'|'high',
  },
  numChars: {
    len: number,
    status: 'poor'|'medium'|'high',
  },
  lenChars: {
    len: number,
    status: 'poor'|'medium'|'high',
  },
  percentVal: number,
}















