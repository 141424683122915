import React, { useRef } from "react";

type IWiseTagsInput = {
  fieldName: string;
  label: string;
  value: string[];
  disabled?: boolean;
  errorText?: string;
  placeholder?: string;
  showLabel?: boolean;
  showIcon?: boolean;
  onChange?: (f: string, v: string[]) => void,
};
export default function WiseTagsInput({
  fieldName,
  label,
  value,
  disabled,
  errorText,
  placeholder,
  showLabel,
  showIcon,
  onChange
}: IWiseTagsInput) {
  const bShowLabel = showLabel ?? false;
  const bShowIcon = showIcon ?? false;
  const bDisabled = disabled ?? false;
  const elements = [...value];

  const txtInput= useRef<HTMLInputElement>(null);

  const onInputKeyup = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if(e.key === 'Enter'){
        if(!txtInput!.current!.value) return;
        if(elements.includes(txtInput!.current!.value)) return;
        onAddElt();
    }
  }

  const onDeleteElt = (pos: number) => {
    if(!onChange) return;

    const fFinal = elements.filter(o => o !== elements[pos]);
    onChange!(fieldName, fFinal);
  }
  const onAddElt = () => {
    const f = [...elements, txtInput!.current?.value!];
    onChange!(fieldName, f);

    setTimeout(() => {
        txtInput!.current!.value! = '';
    }, 200);
  }

  return (
    <div className="flex flex-col mb-2">
      {bShowLabel && (
        <div className="label">
          <span className="label-text">{label}</span>
        </div>
      )}

      <div
        className={`relative border border-solid ${
          errorText ? "border-red-400" : "border-gray-400"
        } rounded-md px-2 ${
          errorText
            ? "focus-within:border-red-600"
            : "focus-within:border-gray-600"
        }`}
      >
        {bDisabled && 
            <div className="absolute top-0 left-0 w-full h-full z-[1] bg-neutral-200/20"></div>
        }
        <div className="flex flex-row items-start gap-2 relative z-0">
          {bShowIcon && (
            <i
              className={`bi bi-tags-fill mt-2 ${
                errorText ? "text-red-500" : "text-gray-500"
              }`}
            ></i>
          )}
          <div className="flex-1 flex flex-row space-x-2 flex-wrap py-1">
            {value.map((o, idx) => <TagElement pos={idx} deleteElt={onDeleteElt} text={o} key={idx} />)}
              <input 
                ref={txtInput}
                type="text" 
                className={`font-light focus:border-none focus-visible:border-none focus-visible:outline-none py-3 disabled:bg-transparent ${
                    errorText
                      ? "placeholder:text-red-400/60"
                      : "placeholder:text-gray-400/80"
                  }`} 
                  placeholder={placeholder}
                    onKeyUp={onInputKeyup}
                  />
          </div>
        </div>
      </div>

      {errorText && (
        <div className="label">
          <span className="label-text-alt text-red-500">{errorText}</span>
        </div>
      )}
    </div>
  );
}
interface ITagElement {
    text: string,
    pos: number,
    deleteElt: (pos: number) => void,
}

const TagElement = ({text, deleteElt, pos}: ITagElement) => {
    return (
        <div className="flex flex-row items-center px-2 py-1 mb-2 border border-solid border-neutral-300 rounded-md hover:bg-neutral-200/40">
            <span>{text}</span>
            <button className="ml-2" onClick={() => deleteElt(pos)}>
                <i className="bi bi-x-square-fill"></i>
            </button>
        </div>
    );
}