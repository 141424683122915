/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as UploadIconSvg } from "src/assets/images/svg/download-icon.svg";
import { ReactComponent as TrashIconSvg } from "src/assets/images/svg/delete-icon.svg";
import { ReactComponent as ArrowLeftIconSvg } from "src/assets/images/svg/arrow-left.svg";

import appDecl from "src/utils/declarations";
import generalFxs from "src/utils/general_fx";
import generalLangs from "src/utils/language";

import LoadinInterfaceComp from "src/components/LoadinInterfaceComp";
import NetServices from "src/utils/netservices";
import { Link, useNavigate } from "react-router-dom";
//import { ICompanyStampFile } from "src/models/smarttypes";

const appFxs = generalFxs();
const languages = generalLangs();

export default function CompanySettingsStampPage() {
  const [loading, setLoading] = useState(true);
  const [hasSign, setHasSign] = useState(false);
  const [imagePath, setImagePath] = useState<string | null>(null);
  /* const [companyStamp, setCompanyStamp] = useState<ICompanyStampFile | null>(
    null
  ); */
  const [imageFile, setImageFile] = useState<File | null>(null);

  const navigate = useNavigate();
  const stampPicker = useRef<HTMLInputElement | null>(null);

  const onTakeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files!.length === 0) return;
    const oFile = e.target.files![0];

    if (!appFxs.isFileImagePng(oFile)) {
      appFxs.showAlert(languages.pngFormatError, languages.pngFormatErrorMsg);
      return;
    }

    const reader = new FileReader();
    setImageFile(oFile);

    reader.onload = () => {
      setImagePath(reader.result as string);
    };

    reader.readAsDataURL(oFile);
  };
  const removeSelectedImage = () => {
    setImageFile(null);
    stampPicker.current!.value = "";
  };
  const saveOper = async () => {
    const formData = new FormData();
    formData.append("companyStamp", imageFile!);
    const oOper = await NetServices.requestPostWithFileAxios(
      "settings/savestamprecord",
      formData,
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    navigate("/app/settings/company");
  };
  const clearImageOper = () => {
    setImagePath(null);
    setImageFile(null);
  }

  const saveOrClear = () => {
    if(imageFile) saveOper();
    if(!imageFile) clearImageOper();    
  }

  const populateStamp = async () => {
    const oOper = await NetServices.requestGet("settings/getstamprecord");

    if (!oOper.bReturn) {
      await appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      navigate("/app/settings/company");
      return;
    }

    setHasSign(oOper.hasCompanyStamp!);
    if (oOper.hasCompanyStamp!) {
      const oCompRec = oOper.companyStampData!;
      //setCompanyStamp(oCompRec);

      const oPath = `${appDecl.companyResourcesPath}/${oCompRec.companyKey}/stamps/${oCompRec.stampfileName}`;
      setImagePath(oPath);
    }

    setLoading(false);
  };

  useEffect(() => {
    populateStamp();
  }, []);

  if (loading) return <LoadinInterfaceComp />;
  return (
    <div className="flex flex-col">
      <div className="flex items-center mb-3 gap-3">
        <Link to={'/app/settings/company'}>
          <ArrowLeftIconSvg width={24} height={24} />
        </Link>
        <h3 className="font-bold text-lg">
          {languages.stamp}
        </h3>

      </div>



      <div className="flex flex-col items-center justify-center">
        <div className="relative  mb-3 w-[220px] h-[220px] border border-slate-300 rounded-lg overflow-hidden flex items-center justify-center">
          {imageFile && (
            <button
              title={languages.remove}
              className="absolute top-1 right-1 z-10"
              onClick={removeSelectedImage}
            >
              <TrashIconSvg width={16} height={16} />
            </button>
          )}
          {!(imageFile || imagePath) && (
            <UploadIconSvg
              className="cursor-pointer"
              width={50}
              height={50}
              onClick={() => stampPicker!.current!.click()}
            />
          )}

          {(imageFile || imagePath) && (
            <img
              alt=""
              className="w-full h-full object-cover relative z-0"
              src={imagePath!}
            />
          )}
        </div>
        {(imageFile || hasSign) && (
          <button className="btn"
            onClick={saveOrClear}>
            {imageFile && <span>{languages.save}</span>}
            {!imageFile && hasSign && <span>{languages.clear}</span>}
          </button>
        )}
        <input
          title="Stamp selector"
          aria-label="Stamp selector"
          accept="image/png"
          type="file"
          className="hidden"
          ref={stampPicker}
          onChange={onTakeFiles}
        />
      </div>
    </div>
  );
}
