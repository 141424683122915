/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { IArchivixUser, ICompanyRecord } from "src/models/archivix_models";
import { ReactComponent as DeleteIconSvg } from "src/assets/images/svg/delete-icon.svg";
import { ReactComponent as PreviewIconSvg } from "src/assets/images/svg/eye-icon.svg";

import { ReactComponent as DeactivateIconSvg } from "src/assets/images/svg/deactivate-icon.svg";
import { ReactComponent as ActivateIconSvg } from "src/assets/images/svg/activate-user-icon.svg";

import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";

import {
  ErrorTableRow,
  LoadingTableRow,
  NoRecordTableRow,
} from "src/components/majorsmallcomps";
import NetServices from "src/utils/netservices";
import { Link, useNavigate } from "react-router-dom";
import appDecl from "src/utils/declarations";

const languages = getLanguages();
const appFxs = getFxs();

const lang = appFxs.getLocalLanguage();
const isEn = lang === "en";

export default function ListUsersPage() {
  document.title = `${languages.users} | ${languages.management} - Archivix`;
  const [isLoading, setLoading] = useState(true);
  const [listUsers, setListUsers] = useState<IArchivixUser[]>([]);
  const [hastNetError, setNetError] = useState(false);

  const [messageDisplay, setMessageDisplay] = useState(languages.noRecFnd);
  const [connectedUser, setConnectedUser] = useState<IArchivixUser | null>(
    null
  );
  const [connectedComp, setConnectedComp] = useState<ICompanyRecord | null>(
    null
  );

  const navigate = useNavigate();

  const prepareAllData = async () => {
    const oRec = appFxs.getLocalStorageByKey(appDecl.connectedCompany, "");

    if (!oRec) {
      localStorage.clear();
      navigate("/account/login");
      return;
    }

    setConnectedComp(JSON.parse(oRec) as ICompanyRecord);

    populateData();
  };
  const populateData = async () => {
    setLoading(true);
    setNetError(false);
    setMessageDisplay("");

    const oper = await NetServices.requestGet("managt/getlistofusers", false);

    if (!oper.bReturn) {
      setNetError(true);
      setMessageDisplay(oper.msgBody);
      setLoading(false);
      return;
    }

    setListUsers(oper.lstArchUsers!);
    setConnectedUser(oper.connectedUser!);

    setNetError(false);
    setMessageDisplay("");
    setLoading(false);
  };

  const resendInvite = async (e: IArchivixUser) => {
    const bAsk = await appFxs.showConfirm(
      languages.invite,
      languages.inviteUserQst
    );
    if (!bAsk.isConfirmed) return;

    const oper = await NetServices.requestPost(
      "managt/resendemailinvite",
      {
        userRecordKey: e.userKey,
      },
      true
    );

    if (!oper.bReturn) {
      appFxs.showAlert(oper.msgTitle, oper.msgBody);
      return;
    }
  };
  const activateUser =  async (e: IArchivixUser, status: "active" | "inactive") => {
    const oTitle = status === 'active' ? languages.activateUser: languages.deactivateUser;
    const oMsg = status === 'active' ? languages.activateUserMsg: languages.deactivateUserMsg;

    const bAsk = await appFxs.showConfirm(oTitle, oMsg);
    if(!bAsk.isConfirmed)return;

    const oOper = await NetServices.requestPost('managt/activateuser', {
      activeUserEmail: e.userEmail,
      activityType: status === 'active' ? 1: 0,
    }, true);

    if(!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    setListUsers(oOper.lstArchUsers!);
    setConnectedUser(oOper.connectedUser!);
  }
  const deleteUser = async (e: IArchivixUser) => {
    const bAsk = await appFxs.showConfirm(
      languages.delete,
      languages.deleteRecordQst
    );
    if (!bAsk.isConfirmed) return;
    const oper = await NetServices.requestPost(
      "managt/deleteuserrecord",
      {
        userEmail: e.userEmail,
      },
      true
    );

    if (!oper.bReturn) {
      appFxs.showAlert(oper.msgTitle, oper.msgBody);
      return;
    }

    setListUsers(oper.lstArchUsers!);
  };

  useEffect(() => {
    prepareAllData();
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex items-center mb-3 gap-3">
        <h3 className="flex-1 font-bold text-lg">{languages.users}</h3>
        <button
          className="btn"
          title={languages.save}
          onClick={() => navigate("/app/settings/users/newuser")}
        >
          <i className="fa fa-plus"></i>
        </button>
      </div>
      <div className="flex flex-col space-y-2">
        {/* Info */}
        {!(connectedUser === null) && (
          <>
            {connectedUser.mainAdmin && isEn && (
              <div>
                <p className="text-sm my-3">
                  <strong>{`${connectedUser?.firstName} ${connectedUser?.lastName}`} </strong>
                  , you are the Administrator for <strong>{connectedComp?.companyName}</strong> Document
                  management application (<strong>Archivix</strong>). You can
                  invite your other colleagues and grant them some rights to
                  interact with the application features.
                </p>
              </div>
            )}

            {!connectedUser.mainAdmin && isEn && (
              <div>
                <p className="text-sm my-3">
                  <strong>{`${connectedUser?.firstName} ${connectedUser?.lastName}`}</strong>
                  , you've been invited by the Administrator of the <strong>{connectedComp?.companyName}</strong> Document
                  management application (<strong>Archivix</strong>). You have
                  some limited rights.
                </p>
              </div>
            )}

            {connectedUser.mainAdmin && lang === "fr" && (
              <div>
                <p className="text-sm my-3">
                  <strong>{`${connectedUser?.firstName} ${connectedUser?.lastName}`}</strong>
                  , vous êtes l'Administrateur principal de l'application de
                  gestion de l'école&nbsp;
                  <strong>{connectedComp?.companyName}</strong>. Vous pouvez
                  inviter d'autres collègues et leur octroyer des droits
                  liimités pour gérer certaines fonctionnalités de cette
                  application.
                </p>
              </div>
            )}

            {!connectedUser.mainAdmin && lang === "fr" && (
              <div>
                <p className="text-sm my-3">
                  <strong>{`${connectedUser?.firstName} ${connectedUser?.lastName}`}</strong>
                  , vous avez été invité par l'Administrateur principal de
                  l'application de gestion de l'application de gestion de
                  l'école <strong>{connectedComp?.companyName}</strong>. Vous
                  avez des droits limités.
                </p>
              </div>
            )}
          </>
        )}

        {/* User Table */}
        <table className="table table-zebra">
          <thead>
            <tr>
              <th>
                <span>{languages.name}</span>
              </th>
              <th className="hidden sm:table-cell">{languages.email}</th>
              <th className="hidden md:table-cell">{languages.phoneNbr}</th>
              <th className="hidden lg:table-cell">{languages.sex}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {/* Loading */}
            {isLoading && <LoadingTableRow colSpan={5} />}

            {/* error */}
            {!isLoading && hastNetError && (
              <ErrorTableRow
                colSpan={5}
                messageDisplay={messageDisplay}
                actionToTake={populateData}
              />
            )}

            {/* No Record */}
            {!isLoading && !hastNetError && listUsers.length === 0 && (
              <NoRecordTableRow
                colSpan={5}
                messageDisplay={languages.noProdFound}
                buttonIconCss="fa fa-plus"
                actionToTake={() => {}}
              />
            )}

            {/* list */}
            {!isLoading &&
              !hastNetError &&
              listUsers.length > 0 &&
              listUsers.map((oMsg, idx) => (
                <UserRow
                  meAdmin={connectedUser!.mainAdmin}
                  data={oMsg}
                  key={idx}
                  resendInviteMessage={resendInvite}
                  deleteUserRecord={deleteUser}
                  activateDeactivateUser={activateUser}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

interface IUserRow {
  data: IArchivixUser;
  meAdmin: boolean;
  resendInviteMessage: (e: IArchivixUser) => void;
  deleteUserRecord: (e: IArchivixUser) => void;
  activateDeactivateUser: (
    e: IArchivixUser,
    status: "active" | "inactive"
  ) => void;
}
const UserRow = ({
  meAdmin,
  data,
  resendInviteMessage,
  deleteUserRecord,
  activateDeactivateUser,
}: IUserRow) => {
  const navigate = useNavigate();

  return (
    <tr>
      <td>
        <Link
          to={`/app/settings/users/preview/${data.userKey}`}
          className="text-blue-500 hover:text-blue-700"
        >
          {`${data.firstName} ${data.lastName}`}
        </Link>
      </td>
      <td className="hidden sm:table-cell">
        <a
          href={`mailto:${data.userEmail}`}
          className="text-blue-500 hover:text-blue-700"
        >
          {data.userEmail}
        </a>
      </td>
      <td className="hidden md:table-cell">
        {data.phoneNumber && (
          <a
            href={`tel:${data.phoneNumber}`}
            className="text-blue-500 hover:text-blue-700"
          >
            {data.phoneNumber}
          </a>
        )}
      </td>
      <td className="hidden lg:table-cell">
        {data.sex === "0" ? languages.male : languages.female}
      </td>
      <td>
        <div className="flex items-center gap-1 justify-end">
        <button
              className="border border-solid border-sky-500 rounded-md py-1 px-2 inline-block"
              title={languages.preview}
              onClick={() => navigate(`/app/settings/users/preview/${data.userKey}`)}
            >
              <PreviewIconSvg width={16} height={16} />
            </button>


          {(meAdmin && (!data.userActive && !data.mainAdmin)) && (
            <button
              className="border border-solid border-sky-500 rounded-md py-1 px-2 inline-block"
              title={languages.activate}
              onClick={() => activateDeactivateUser(data, "active")}
            >
              <ActivateIconSvg width={16} height={16} />
            </button>
          )}

          {(meAdmin && (data.userActive  && !data.mainAdmin)) && (
            <button
              className="border border-solid border-sky-500 rounded-md py-1 px-2 inline-block"
              title={languages.deactivate}
              onClick={() => activateDeactivateUser(data, "inactive")}
            >
              <DeactivateIconSvg width={16} height={16} />
            </button>
          )}

          {(meAdmin && !data.userActive) && (
            <button
              className="border border-solid border-green-500 rounded-md py-1 px-2 inline-block"
              title={languages.sendInvite}
              onClick={() => resendInviteMessage(data)}
            >
              <i className="fa fa-envelope text-green-500"></i>
            </button>
          )}

          {(meAdmin && !data.mainAdmin) && (
            <button
              className="border border-solid border-red-500 rounded-md py-1 px-2 inline-block"
              title={languages.delete}
              onClick={() => deleteUserRecord(data)}
            >
              <DeleteIconSvg width={16} height={16} />
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};
