import React, { useState } from "react";

import { WiseSelectInput } from "src/components/majorsmallcomps";
import getLangs from "src/utils/language";
import generalFxs from "src/utils/general_fx";
import appDecl from "src/utils/declarations";
import { DashElement, DashLatestOperations, ListDashDoctInbox } from "src/components/dashboard_comps";
import { useNavigate } from "react-router-dom";
import { IInboxDoct } from "src/models/smarttypes";
import ModalDocumentPreviwer from "src/components/ModalDocumentPreviwer";
import NetServices from "src/utils/netservices";

const languages = getLangs();
const appFxs = generalFxs();

const todayDate = new Date(); 
const monthsOptions = appFxs.wdMonthsLng.map((o, i) => {
  const f = {
    label: o,
    value: i + 1,
  };

  return f;
});

export default function DashboardPage() {
  document.title = `${languages.dashboard} | Archivix`;
  const [month, setMonth] = useState<number>(todayDate.getMonth() + 1);
  const [modalOpen, setModalOpen] = useState(false);
  const [padfArray, setPdfArray] = useState<ArrayBufferLike | null>(null);

  const navigate = useNavigate();

  const navigateToPage = (type: "document" | "signature" | "partner") => {
    const pathX =
      type === "document"
        ? "/app/documents"
        : type === "signature"
        ? "/app/signdocs"
        : "/app/partners";

    navigate(pathX);
  };

  const onPreviewFile = async (pdfPath: string) => {
    const fileBuffer = await appFxs.getFileBlobBuffer(pdfPath, true);

    if (!fileBuffer) {
      setPdfArray(null);
      return;
    }

    setPdfArray(fileBuffer);

    setModalOpen(true);
  };
  const onPreviewImage = (pdfPath: string) => {
    appFxs.openWindowUrl(pdfPath);
  };
  const onStartSignProcess = async (oSign: IInboxDoct) => {
    const oOper = await NetServices.requestGet(
      `signdocs/getdocumentforsignstartprocess/${oSign.mainOpsKey}`,
      true
    );
    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    window
      .open(
        `${appDecl.webAppPath}/sign/signoperation/${oOper.signerOperationKey}`,
        "_blank"
      )
      ?.focus();
  };

  const onOpenDoct = (fileKey: string) => {
    navigate('/app/documents/previewdoct/' + fileKey)
  }

  return (
    <>
      <ModalDocumentPreviwer
        isOpen={modalOpen}
        padfArray={padfArray!}
        onClose={() => {
          setModalOpen(false);
          setPdfArray(null);
        }}
      />
      <div className="flex flex-col space-y-2">
        <div className="flex justify-end">
          <WiseSelectInput
            onChange={(e) => setMonth(Number(e.target.value))}
            selValue={month}
            vOptions={monthsOptions}
          />
        </div>

        <div className="flex flex-wrap">
          <div className="basis-full md:basis-2/3">
            <div className="pr-5 flex flex-col space-y-3">
              <h3 className="text-lg font-bold">{languages.dashboard}</h3>
              <div></div>
              <div className="flex flex-wrap justify-between mb-5">
                <DashElement
                  type="document"
                  month={month}
                  onDetail={navigateToPage}
                />
                <DashElement
                  type="signature"
                  month={month}
                  onDetail={navigateToPage}
                />
                <DashElement
                  type="partner"
                  month={month}
                  onDetail={navigateToPage}
                />
              </div>
              <DashLatestOperations month={month} onOpenDoct={onOpenDoct} />
            </div>
          </div>
          <div className="basis-full md:basis-1/3">
          <div className="sticky top-20 ">
            <ListDashDoctInbox
              month={month}
              onPreviewFile={(e) => onPreviewFile(e)}
              onPreviewImage={onPreviewImage}
              onStartSignProcess={onStartSignProcess}
            />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
