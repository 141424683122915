/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useEffect, useState } from "react";
import ReactDom from "react-dom";
import { IArchivixUser, IDocumentFile } from "src/models/archivix_models";

import charConv from "src/assets/images/conversation.png";
import NetServices from "src/utils/netservices";

interface IWiseDoctChatNotif {
  children: ReactNode;
  isOpen: boolean;
}

export default function WiseDoctChatNotif({children, isOpen}: IWiseDoctChatNotif) {
    if (!isOpen) return null;
    const nodeElement = document.getElementById("portals")!;
  
    return ReactDom.createPortal(
      <>
        <div
          className="rounded-t-lg bg-transparent"
          style={{
            position: "fixed",
            right: "3%",
            bottom: "3%",
            padding: "0px",
            zIndex: 1000,
          }}
        >
          {children}
        </div>
      </>,
      nodeElement
    );
}


interface IChatNotificationBtn {
    isOpen: boolean,
    connectedUser: IArchivixUser,
    archiveFile: IDocumentFile,
    onClickIcon: () => void,
}
export const ChatNotificationBtn = ({connectedUser, archiveFile, isOpen, onClickIcon}: IChatNotificationBtn) => {
    const [unreadMessage, setUnreadMessage] = useState(2);


    const checkServerData = async () => {
        const oOper = await NetServices.requestGet(`chatsystem/getlistunreadmessagefordocument/${archiveFile.fileKey}`)
        if(!oOper.bReturn){
            setUnreadMessage(0);
            return;
        }
        setUnreadMessage(oOper.unreadMessageNbr ?? 0);
    }

    useEffect(() => {
      checkServerData();
        const timer = setInterval(() => {
          checkServerData();
        }, 5000);
        return () => clearInterval(timer);
      }, []);

    if(!isOpen) return null;

    return(
        <WiseDoctChatNotif isOpen={isOpen}>
            <div className="w-12 h-14 rounded-full relative cursor-pointer" onClick={onClickIcon}>
                <img src={charConv} alt="" className="w-full h-full rounded-full object-contain" />
                {unreadMessage > 0 ?? <div className="absolute -top-1 -right-1 w-5 h-5 z-1 border border-solid border-green-200 flex justify-center items-center rounded-full bg-amber-400">
                    {unreadMessage}
                </div>}
            </div>
        </WiseDoctChatNotif>
    );
}