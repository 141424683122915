/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import appDecl from "src/utils/declarations";
import getLang from "src/utils/language";
import generalFx from "src/utils/general_fx";
import NetServices from "src/utils/netservices";
import {
  IArchUserFixer,
  IPartnerSignDocument,
  ISignProcessSenderPartner,
  ISignerFldPart,
  IStepsError,
} from "src/models/smarttypes";

import {
  ListSignerDocumentForPartner,
  SignDoctFieldAreaPart,
  SignerDocumentListPartGuest,
  SignerDocumentTitle,
  SignerMessageSubjectArea,
} from "src/components/majorsmallcomps";
import LoadinInterfaceComp from "src/components/LoadinInterfaceComp";

import { ReactComponent as ArrowLeftSvg } from "src/assets/images/svg/arrow-left.svg";

import { ReactComponent as DraftFileSvg } from "src/assets/images/svg/draft-file.svg";
import { ReactComponent as CompletedFileSvg } from "src/assets/images/svg/completed-file.svg";
import { ReactComponent as PendingFileSvg } from "src/assets/images/svg/pending-file.svg";

import PDFViewer from "src/components/pdfviewer/PDFViewer";
import ModalListUsers from "src/components/ModalListUsers";
import { IArchivixUser } from "src/models/archivix_models";
import imgProfile from "src/assets/images/profile_placeholder.jpg";

const languages = getLang();
const appFxs = generalFx();

const defaultSignOps: ISignerFldPart = {
  documentTitle: "",
  applyStamp: false,
  lstSigners: [],
  messageTemplate: {
    subject: "",
    message: "",
  },
  messageSet: false,
};

export default function EditorPartnerDoctPage() {
  const [doctTitle, setDoctTitle] = useState(languages.loading + "...");

  const { fileKey } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [pdfFile, setPdfFile] = useState<ArrayBufferLike | null>(null);

  const [IconStatus, setIconStatus] = useState<any>(DraftFileSvg);

  const [singleDignDoct, setSingleDignDoct] =
    useState<IPartnerSignDocument | null>(null);

    const [lstArchUsers, setLstArchUsers] = useState<IArchivixUser[]>([]);
    const [formStep, setFormStep] = useState<number>(1);
    const [operationRecord, setOperationRecord] = useState<ISignerFldPart>({
    ...defaultSignOps,
  });
  const [formErrors, setFormErrors] = useState<IStepsError>({});
  const [staffViewerInvitees, setStaffViewerInvitees] = useState<IArchUserFixer[]>([]);
  const [userDlgVis, setUserDlgVis] = useState(false);

  document.title = `${doctTitle} | Archivix`;
  const [doctStatus, setDoctStatus] = useState<string>(languages.draft);
  const bLoaded = useRef(false);

  const populateFilePdf = async () => {
    if (!fileKey) {
      await appFxs.showAlert(
        languages.accessPathError,
        languages.accessPathErrorMsg
      );
      navigate("/app/partners");
      return;
    }

    setLoading(true);
    const oOper = await NetServices.requestGet(
      `partners/getonesinglesigndoct/${fileKey}`
    );

    if (!oOper.bReturn) {
      await appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      navigate("/app/partners");
      return;
    }

    const doctRec = oOper.singleDignDoctPartner!;

    setSingleDignDoct(doctRec);
    setDoctTitle(doctRec.fileTitle);
    setStatusOfDoct(doctRec);

    setLstArchUsers(oOper.lstArchUsers!);

    setOperationRecord({
      ...operationRecord,
      documentTitle: doctRec.fileTitle,
    });

    const filePath = `${appDecl.documentsPartners}/${doctRec.companyKey}/${fileKey}/${fileKey}.pdf`;
    const fileBuffer = await appFxs.getFileBlobBuffer(filePath);

    if (!fileBuffer) {
      navigate("/app/partners");
      return;
    }

    setPdfFile(fileBuffer);
    setLoading(false);
  };
  const setStatusOfDoct = (oDoct: IPartnerSignDocument) => {
    if (oDoct.operationStatus === 0) {
      setDoctStatus(languages.draft);
      setIconStatus(DraftFileSvg);
    }
    if (oDoct.operationStatus === 1) {
      setDoctStatus(languages.pending);
      setIconStatus(PendingFileSvg);
    }
    if (oDoct.operationStatus === 2) {
      setDoctStatus(languages.complete);
      setIconStatus(CompletedFileSvg);
    }
  };
  const moveToAction = (oAction: "back" | "forard") => {
    if (oAction === "back") {
      setFormStep(formStep - 1);
      return;
    }

    if (formStep === 1) checkDoctTitle();
    if (formStep === 2) checkGuestSelector();
    if (formStep === 3) checkDoctSigners();
    if (formStep === 4) checkDocsSignFields();
    if (formStep === 5) sendMessageToSigners();
  };
  const checkGuestSelector = async () => {
    if (staffViewerInvitees.length === 0) {
      const bAsk = await appFxs.showConfirm(languages.guests, languages.noGuestsSelectedMsg);
      if(!bAsk.isConfirmed){
        return;
      }
    }

    setFormStep(formStep + 1);
  }
  const checkDoctTitle = () => {
    const errors: IStepsError = {};
    if (
      !operationRecord.documentTitle ||
      operationRecord.documentTitle.length === 0
    ) {
      errors.fileTitle = languages.fldRequired;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setFormStep(formStep + 1);
  };
  const checkDoctSigners = () => {
    if (operationRecord.lstSigners.length === 0) {
      appFxs.showAlert(languages.signers, languages.signersAddErr);
      return;
    }

    const someError = operationRecord.lstSigners.some((o) => {
      const isEmailCorrect =
        o.userEmail &&
        o.userEmail.length > 0 &&
        appFxs.isEmailValid(o.userEmail);
      const isNameCorrect = o.userName.length > 0;

      return !isEmailCorrect || !isNameCorrect;
    });

    if (someError) {
      appFxs.showAlert(languages.signers, languages.signersAddErrV2);
      return;
    }

    setFormStep(formStep + 1);
    //setFieldUserSigner(operationRecord.lstSigners[0]);
  };
  const checkDocsSignFields = () => {
    setFormStep(formStep + 1);
  };
  const sendMessageToSigners = () => {
    const finalRecord: ISignerFldPart = {
      ...operationRecord,
      messageSet: operationRecord.messageTemplate.message.length > 0,
    };

    const partEmails = staffViewerInvitees.map(o => o.userEmail);

    const finalFormData: ISignProcessSenderPartner = {
      doctTitle: finalRecord.documentTitle,
      applyStamp: finalRecord.applyStamp,
      listSignerEmails: finalRecord.lstSigners,
      messageSubject: finalRecord.messageTemplate.subject,
      messageContent: finalRecord.messageTemplate.message,
      documentOperKey: singleDignDoct!.operationKey,
      companyKey: singleDignDoct!.companyKey,
      listOfStaffPartners: partEmails,
    };

    documentToInboxForSigners(finalFormData);
  };
  const documentToInboxForSigners = async (e: ISignProcessSenderPartner) => {
    const dataToSend = new FormData();
    const jsSonData = JSON.stringify(e);

    dataToSend.append("requestSignOps", jsSonData);
    //TO DO

    const oOper = await NetServices.requestPostWithFileAxios(
      "partners/senddocumenttoinbox",
      dataToSend,
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    navigate("/app/partners");
  };
  const onMessageChange = (e: { subject: string; message: string }) => {
    const ff = { ...e };
    setOperationRecord({ ...operationRecord, messageTemplate: ff });
  };

  const addSignerToList = (o: IArchivixUser) => {
    if(o.userEmail.toLowerCase() === singleDignDoct?.operatorUser.toLowerCase()){
      appFxs.showAlert(languages.guests, languages.noGuestOperator);
      return;
    }

    const oUser = staffViewerInvitees.find(
      (f) => f.userEmail === o.userEmail
    );
    if (oUser) return;

    const imgPath = !o.photoProfile
      ? imgProfile
      : `${appDecl.profilesPath}/${o.companyKey}/${o.photoProfile}`;

    const lst = [...staffViewerInvitees];
    lst.push({
      userEmail: o.userEmail,
      userName: `${o.firstName} ${o.lastName}`,
      userPic: imgPath,
    });

    setStaffViewerInvitees(lst);
  };
  const removeGuest = async (oSigner: IArchUserFixer) => {
    const bAsk = await appFxs.showConfirm(
      languages.removeInvitee,
      languages.removeInviteeMsg
    );
    if (!bAsk.isConfirmed) return;
    const filtered = staffViewerInvitees.filter(
      (o) => o.userEmail !== oSigner.userEmail
    );
    setStaffViewerInvitees(filtered);
  };

  useEffect(() => {
    if (bLoaded.current) return;
    populateFilePdf();
    bLoaded.current = true;
  }, []);

  return (
    <>
    <ModalListUsers
        dialogTitle={languages.selectGuests}
        isOpen={userDlgVis}
        lstUsers={lstArchUsers}
        onClose={(o) => {
          setUserDlgVis(false);
          if (!o) return;
          addSignerToList(o);
        }}
      />
    <div className="flex flex-col space-y-2">
      {/* Header */}
      <div className="flex flex-col">
        <div className="flex items-center flex-wrap">
          <div className="flex-1 flex items-center gap-2">
            <Link to={"/app/partners"}>
              <ArrowLeftSvg width={16} height={16} />
            </Link>
            <h3 className="text-lg flex-1">{doctTitle}</h3>
          </div>
          <div></div>
        </div>
        <div className="flex items-center gap-2">
          <IconStatus width={16} height={16} />

          <span className="text-sm">{doctStatus}</span>
        </div>
      </div>

      <div className="flex flex-wrap mt-8 h-screen overflow-y-auto hide-scroll">
        <div className="w-full md:w-1/2 lg:w-[60%]">
          <div className="p-5">
            <PDFViewer loading={loading} file={pdfFile} />
          </div>
        </div>

        <div className="w-full md:w-1/2 lg:w-[40%]">
          {loading ? (
            <LoadinInterfaceComp />
          ) : (
            <div className="p-1 sticky top-20 ">
              <div className="dark:bg-background border-border bg-widgetflex h-full max-h-[64rem] flex-col justify-between overflow-auto rounded-xl border px-4 py-6 lg:h-[calc(100vh-6rem)]">
                <div className="-mx-2 flex flex-col px-2 flex-grow">
                  <div className="flex-grow flex-1">
                    {formStep === 1 && (
                      <>
                        <SignerDocumentTitle
                          defValue={operationRecord.documentTitle}
                          formError={formErrors.fileTitle}
                          onSaveOps={(e) => {
                            setOperationRecord({
                              ...operationRecord,
                              documentTitle: e.documentTitle,
                              applyStamp: e.applyStamp,
                            });
                          }}
                        />
                      </>
                    )}

                    {formStep === 2 && (
                      <>
                        <SignerDocumentListPartGuest
                          lstSigners={staffViewerInvitees}
                          removeGuest={removeGuest}
                          setUserDlgVis={setUserDlgVis}
                        />
                      </>
                    )}

                    {formStep === 3 && (
                      <>
                        <ListSignerDocumentForPartner
                          lstSigners={operationRecord.lstSigners}
                          updateMainList={(lst) => {
                            const ff: ISignerFldPart = {
                              ...operationRecord,
                              lstSigners: [...lst],
                            };

                            setOperationRecord(ff);
                          }}
                        />
                      </>
                    )}

                    {formStep === 4 &&
                      operationRecord.lstSigners.length > 0 && (
                        <SignDoctFieldAreaPart
                          lstSigners={operationRecord.lstSigners}
                          addFieldToPage={(e) => {}}
                          onClickFieldAction={(e, s) => {}}
                        />
                      )}
                    {formStep === 5 && (
                      <SignerMessageSubjectArea 
                      onSaveOps={onMessageChange} />
                    )}
                  </div>
                </div>
                <div className="mt-4 flex-shrink-0">
                  <div className="mt-4">
                    <div>
                      <p className="text-black text-sm">
                        {languages.step}&nbsp;
                        <span>
                          {formStep}&nbsp;{languages.of.toLowerCase()}
                          &nbsp;5
                        </span>
                      </p>

                      <div className="bg-muted relative mt-4 h-[2px] rounded-md">
                        <div
                          className="bg-archivix absolute inset-y-0 left-0"
                          style={{
                            width: `${(formStep / 5) * 100}%`,
                            opacity: 1,
                          }}
                        ></div>
                      </div>
                      <div className="flex gap-x-4 mt-4">
                        <button
                          type="button"
                          disabled={formStep === 1}
                          onClick={() => moveToAction("back")}
                          className={`inline-flex items-center justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background text-primary-foreground h-11 px-8 rounded-md flex-1 bg-green-400 hover:bg-green-400/40`}
                        >
                          {languages.goback}
                        </button>
                        <button
                          type="button"
                          onClick={() => moveToAction("forard")}
                          className={`inline-flex items-center justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background text-primary-foreground h-11 px-8 rounded-md flex-1 bg-green-400 hover:bg-green-400/40 hover:text-white`}
                        >
                          {formStep === 5 ? languages.send : languages.continue}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
    </>
  );
}
