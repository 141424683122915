/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

import { ReactComponent as MenuIcon } from "src/assets/images/svg/icons/menu.svg";

import appLang from "src/utils/language";

const languages = appLang();

export default function AppSettsLayout() {
  const location = useLocation();
  const [menuActives, setMenuActives] = useState({
    users: false,
    settings: false,
    company: false,
  });

  const changeMenuStatus = () => {
    const href = document.location.href;

    setMenuActives({
      ...menuActives,
      company: href.includes("settings/company"),
      settings: href.includes("settings/settings"),
      users: href.includes("settings/users"),
    });
  };

  useEffect(() => {
    changeMenuStatus();
  }, [location]);

  return (
    <div className="w-full  bg-base-100 p-4 rounded-lg">
      <div className="flex flex-row w-full">
        <div className="hidden md:block basis-1/4">
          <ul className="sett-menus pt-2 bg-base-100 min-h-full text-base-content flex flex-col gap-2">
            <li>
              <Link
                data-active={menuActives.users}
                to={"/app/settings/users"}
                className="data-[active=true]:bg-green-300"
              >
                <span className="m-title">{languages.users}</span>
              </Link>
            </li>
            <li>
              <Link
                data-active={menuActives.settings}
                to={"/app/settings/settings"}
                className="data-[active=true]:bg-green-300"
              >
                <span className="m-title">{languages.settings}</span>
              </Link>
            </li>
            <li>
              <Link
                data-active={menuActives.company}
                to={"/app/settings/company"}
                className="data-[active=true]:bg-green-300"
              >
                <span className="m-title">{languages.company}</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="basis-full md:basis-3/4 pt-2">
          <div className="flex md:hidden justify-end">
            <div className="dropdown">
              <span
                className="dropdown-toggle not-dropdown cursor-pointer"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <MenuIcon width={24} height={24} className="rotate-hor" />
              </span>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <Link to={"/app/settings/users"} className="dropdown-item">
                    {languages.users}
                  </Link>
                </li>
                <li>
                  <Link to={"/app/settings/settings"} className="dropdown-item">
                    {languages.settings}
                  </Link>
                </li>
                <li>
                  <Link to={"/app/settings/company"} className="dropdown-item">
                    {languages.company}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="pt-2">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
