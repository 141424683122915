/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import { ReactComponent as FileSvgIcon } from "src/assets/images/svg/file-icon.svg";
import { ReactComponent as SignatureSvgIcon } from "src/assets/images/svg/signature-icon.svg";
import { ReactComponent as PartnerSvgIcon } from "src/assets/images/svg/partner-icon.svg";

import getLangs from "src/utils/language";
import generalFxs from "src/utils/general_fx";
import appDecl from "src/utils/declarations";

import { useEffect, useState } from "react";
import NetServices from "src/utils/netservices";
import { IInboxDoct, ILatestOper } from "src/models/smarttypes";
import { WiseSimpleButton } from "./majorsmallcomps";
import moment from "moment";

const languages = getLangs();
const appFxs = generalFxs();

interface IDashElement {
  type: "document" | "signature" | "partner";
  month: number;
  onDetail: (type: "document" | "signature" | "partner") => void;
}
export const DashElement = ({ type, month, onDetail }: IDashElement) => {
  const [loading, setLoading] = useState(true);
  const [nbrStats, setNbrStats] = useState(0);

  const IconSvg =
    type === "document"
      ? FileSvgIcon
      : type === "signature"
      ? SignatureSvgIcon
      : PartnerSvgIcon;

  const doctTitle =
    type === "document"
      ? languages.documents
      : type === "signature"
      ? languages.signatures
      : languages.partners;

  const doctSubTitle =
    type === "document"
      ? languages.relatedDocuments
      : type === "signature"
      ? languages.doctsToSign
      : languages.partnersSign;

  const popaulateData = async () => {
    setLoading(true);
    const oOper = await NetServices.requestGet(
      `dashboard/getdashstats/${month}/${type}`
    );

    if (!oOper.bReturn) {
      setNbrStats(0);
      setLoading(false);
      return;
    }

    setNbrStats(oOper.statDoctNbr!);
    setLoading(false);
  };

  useEffect(() => {
    popaulateData();
  }, [month]);

  return (
    <div
      data-doctype={type}
      className="basis-full md:basis-[32%] mb-2 rounded-lg data-[doctype='document']:bg-lime-200/40  data-[doctype='signature']:bg-orange-200/40   data-[doctype='partner']:bg-green-200/40"
    >
      <div className="p-3">
        <div className="flex gap-3">
          <div>
            <IconSvg width={24} height={24} />
          </div>
          <div className="flex-1 flex flex-col">
            <strong className="text-[13px]">{doctTitle}</strong>
            <span className="text-[12px]">{doctSubTitle}</span>
          </div>
        </div>
        <div className="h-[45px]"></div>
        <div className="flex items-center">
          <div className="flex-1 flex items-center gap-3">
            <strong className="text-sm">
              {loading ? (
                <i className="fa fa-spin fa-spinner"></i>
              ) : (
                <span>{nbrStats}</span>
              )}
            </strong>
            <span className="text-[11px]">
              {languages.documents.toLowerCase()}
            </span>
          </div>
          <button
            type="button"
            onClick={() => onDetail(type)}
            className="group hover:bg-slate-300/10 rounded-full border border-solid border-slate-300/40 w-7 h-7 flex justify-center items-center"
          >
            <i className="bi bi-caret-right"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

interface IListDashDoctInbox {
  month: number;
  onPreviewFile: (pdfPath: string) => void;
  onPreviewImage: (imgPath: string) => void;
  onStartSignProcess: (oSign: IInboxDoct) => void;
}
export const ListDashDoctInbox = ({
  month,
  onPreviewFile,
  onPreviewImage,
  onStartSignProcess,
}: IListDashDoctInbox) => {
  const [loading, setLoading] = useState(true);
  const [lstLast, setLstLast] = useState<IInboxDoct[]>([]);

  const populateData = async () => {
    setLoading(true);
    const oOper = await NetServices.requestGet(
      `dashboard/getinboxsigndocts/${month}`
    );

    if (!oOper.bReturn) {
      setLstLast([]);
      setLoading(false);
      return;
    }

    setLstLast(oOper.lstLastSignInbox!);
    setLoading(false);
  };

  useEffect(() => {
    populateData();
  }, [month]);

  return (
    <div className="flex flex-col">
      <h3 className="text-lg font-bold mb-4">{languages.inbox}</h3>
      <span className="text-sm text-neutral-400">
        {languages.doctsInboxMsg}
      </span>

      <div className="flex flex-col space-y-1 divide-y-[1px]">
        {loading && <LastInboxLoading />}
        {!loading && lstLast.length > 0 && (
          <React.Fragment>
            {lstLast.map((o, idx) => (
              <DashDoctInbox
                lastDoct={o}
                key={idx}
                onPreviewFile={onPreviewFile}
                onPreviewImage={onPreviewImage}
                onStartSignProcess={onStartSignProcess}
              />
            ))}
          </React.Fragment>
        )}
        {!loading && lstLast.length === 0 && (
          <div className="flex flex-col items-start justify-start pt-1 space-y-2">
            <p className="text-sm">{languages.noRecFnd}</p>
            <button className="btn" onClick={() => populateData()}>
              {languages.reload}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

interface IDashDoctInbox {
  lastDoct: IInboxDoct;
  onPreviewFile: (pdfPath: string) => void;
  onPreviewImage: (imgPath: string) => void;
  onStartSignProcess: (oSign: IInboxDoct) => void;
}

export const DashDoctInbox = ({
  lastDoct,
  onPreviewFile,
  onPreviewImage,
  onStartSignProcess,
}: IDashDoctInbox) => {
  const doctImagePath = `${appDecl.apiPath}/${lastDoct.imageThumbPath}`;
  const doctPdfPath = `${appDecl.apiPath}/${lastDoct.originalDoctPath}`;

  return (
    <div className="flex items-end py-3">
      <div className="flex-1 flex gap-3">
        <div className="w-[70px] h-[50px] bg-lime-300 border border-neutral-300/40 rounded-md overflow-hidden">
          <img
            src={doctImagePath}
            alt=""
            title={languages.prevThumb}
            className="w-full h-full object-cover cursor-pointer"
            onClick={() => onPreviewImage(doctImagePath)}
          />
        </div>
        <div className="flex-1 flex flex-col space-y-2">
          <a
            title={lastDoct.doctTitle}
            className="text-sky-400 hover:text-sky-800 cursor-pointer"
            onClick={() => onPreviewFile(doctPdfPath)}
          >
            {appFxs.shortenText(lastDoct.doctTitle, 30, true, true)}
          </a>
          <span className="flex items-center gap-2 text-neutral-400 text-sm">
            <i className="bi bi-person-circle"></i>
            <span>{lastDoct.doctOwnerName}</span>
          </span>
        </div>
      </div>
      <button
        title={languages.signDoctNow}
        className="group hover:bg-slate-300/10 border border-solid border-slate-300 flex items-center px-2 rounded-md gap-3"
        onClick={() => onStartSignProcess(lastDoct)}
      >
        <SignatureSvgIcon width={20} height={20} className="" />
        <i className="bi bi-caret-right text-neutral-400 group-hover:text-neutral-600 "></i>
      </button>
    </div>
  );
};

const LastInboxLoading = () => {
  return (
    <div className="w-full min-h-[350px]">
      {[1, 2, 3, 4, 5, 6].map((o) => (
        <LoadingLastElt key={o} />
      ))}
    </div>
  );
};

const LoadingLastElt = () => {
  return (
    <div className="flex items-end py-3 gap-3">
      <div className="flex-1 flex gap-3">
        <div className="w-[70px] h-[50px] bg-slate-400/40 border border-neutral-300/40 rounded-md overflow-hidden flex items-center justify-center">
          <i className="fa fa-spin fa-spinner fa-1x text-neutral-400"></i>
        </div>
        <div className="flex-1 flex flex-col space-y-2">
          <span className="bg-slate-400/40 w-[150px] h-6"></span>
          <span className="flex items-center gap-2 text-neutral-400 text-sm">
            <i className="bi bi-person-circle"></i>
            <span className="flex-1 h-6 bg-slate-400/40"></span>
          </span>
        </div>
      </div>
      <span className="bg-slate-300/10 border border-solid border-slate-300 flex items-center px-2 rounded-md gap-3">
        <SignatureSvgIcon width={20} height={20} className="" />
        <i className="bi bi-caret-right text-neutral-400 group-hover:text-neutral-600 "></i>
      </span>
    </div>
  );
};

interface IDashLatestOperations {
  month: number,
  onOpenDoct: (fileKey: string) => void,
}
export const DashLatestOperations = ({ month, onOpenDoct }: IDashLatestOperations) => {
  const [loading, setLoading] = useState(true);
  const [lstRecords, setLstRecords] = useState<ILatestOper[]>([]);

  const populateRecords = async () => {
    setLoading(true);
    setLstRecords([]);
    const oOper = await NetServices.requestGet(
      `dashboard/getlatestposteddocts/${month}`
    );

    if (!oOper.bReturn) {
      setLstRecords([]);
      setLoading(false);
      return;
    }

    setLstRecords(oOper.latestPostedDocts!);
    setLoading(false);
  };

  useEffect(() => {
    populateRecords();
  }, [month]);

  return (
    <div className="mb-5 flex flex-col">
      <div className="flex items-center">
        <h3 className="text-lg flex-1">
          {languages.latestOps}&nbsp;
        </h3>
        <div></div>
      </div>
      <table className="table">
        <tbody>
          {loading && (
            <tr>
              <td colSpan={5}>
                <div className="flex justify-center items-center py-3">
                  <i className="fa fa-spin fa-spinner fa-2x"></i>
                </div>
              </td>
            </tr>
          )}
          {(!loading && lstRecords.length === 0) && (
            <tr>
              <td colSpan={5}>
                <div className="flex flex-col justify-center items-center py-3 space-y-3">
                  <span className="font-bold">{languages.noRecFnd}</span>
                  <WiseSimpleButton label={languages.reload} btnType="primary" onClick={() => populateRecords()} />
                </div>
              </td>
            </tr>
          )}
          {lstRecords.map((o, idx) => (
            <LastDoctRow record={o} key={idx} onOpenDoct={onOpenDoct}  />
          ))}
        </tbody>
      </table>
    </div>
  );
};

interface ILastDoctRow {
  record: ILatestOper,
  onOpenDoct: (fileKey: string) => void,
}
const LastDoctRow = ({ record, onOpenDoct }: ILastDoctRow) => {
  return (
    <tr>
      <td>
        <div className="w-8 h-8 rounded-md overflow-hidden">
          <FileSvgIcon width={32} height={32} />
        </div>
      </td>
      <td>
        <div className="flex flex-col">
          <a onClick={() => onOpenDoct(record.doctKey)} className="text-blue-500 hover:text-blue-900 cursor-pointer">{appFxs.shortenText(record.doctTitle, 30, true, true)}</a>
          <span className="text-xs text-neutral-400">
            {record.tags.length > 0 ? record.tags: `(${languages.noTags})`}
          </span>
        </div>
      </td>
      <td>
        <div className="flex flex-col">
          <span>{record.comments}</span>
          <span className="text-xs text-neutral-400">{languages.comments}</span>
        </div>
      </td>
      <td>
        <div className="flex flex-col">
          <span>{record.access.length}</span>
          <span className="text-xs text-neutral-400">{languages.guests}</span>
        </div>
      </td>
      <td>
        <div className="flex flex-col justify-end">
          <span>{moment(new Date(record.createdDate)).format('DD MMM YY')}</span>
          <span className="text-xs text-neutral-400">{languages.dateUpload}</span>
        </div>
      </td>
    </tr>
  );
};
