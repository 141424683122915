/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { WiseChatterBox } from "./WiseChatterBox";
import { ChatDoctMessageComp, EmojiSelectorBtn, EmojiWrapper, WiseToolbarButton } from "../majorsmallcomps";

import imgProfile from "src/assets/images/profile_placeholder.jpg";

import appDecl from "src/utils/declarations";
import getLang from "src/utils/language";
import generalFxs from "src/utils/general_fx";

import {
  IArchivixUser,
  IDocumentChat,
  IDocumentFile,
} from "src/models/archivix_models";
import NetServices from "src/utils/netservices";
import { ChatMessageUser, EmojiFilter } from "src/models/smarttypes";
import { IEmoji, archiveEmojis } from "src/utils/app_arch_data";

const languages = getLang();
const appFxs = generalFxs();

interface IDocumentChatterInner {
  isOpen: boolean;
  onClose: () => void;
  chatter: IArchivixUser;
  archiveFile: IDocumentFile;
}

export default function DocumentChatterInner({
  isOpen,
  onClose,
  chatter,
  archiveFile,
}: IDocumentChatterInner) {
  const [messageToSend, setMessageToSend] = useState("");
  const [uiAllShow, setUiAllShow] = useState(true);
  const [serverInUse, setServerInUse] = useState(false);
  const [emojiVis, setEmojiVis] = useState(false);

  const [lstChatMessages, setLstChatMessages] = useState<ChatMessageUser[]>([]);
  const [emojiFilter, setEmojiFilter] = useState<EmojiFilter>("emotions");

  const imageSelectorRef = useRef<HTMLInputElement>(null);
  const divMessagesRef = useRef<HTMLDivElement | null>(null);

  const profilePhoto = chatter.photoProfile
    ? `${appDecl.profilesPath}/${chatter.companyKey}/${chatter.photoProfile}`
    : imgProfile;

  const tapMinimize = () => {
    setUiAllShow(!uiAllShow);
  };
  const tapClose = () => {
    onClose();
  };
  const tapImageShow = () => {
    imageSelectorRef.current?.click();
  };
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const selFile = e.target.files![0];
    postFileMessage(selFile);
  };
  const postFileMessage = async (oFile: File) => {
    if(!appFxs.isFileImage(oFile)){
      appFxs.showAlert(languages.imageFileFormat, languages.imageFileFormatMsg)
      return;
    }

    const k = appFxs.generateUUID();

    const oRec: IDocumentChat = {
      chatKey: k,
      chatType: 1,
      companyKey: chatter.companyKey,
      documentKey: archiveFile.fileKey,
      messageDate: new Date(),
      recordTime: new Date().getTime(),
      senderEmail: chatter.userEmail,
      chatDocument: '',
      chatEmoji: '',
      chatMessage: '',
    };

    setServerInUse(true);
    const lastInput =
      lstChatMessages.length === 0
        ? 0
        : lstChatMessages[lstChatMessages.length - 1].message.recordTime;

    const oFormD = new FormData();
    oFormD.append("chatMessageRecord", JSON.stringify(oRec));
    oFormD.append("lastRecordTime", JSON.stringify(lastInput));
    oFormD.append("chatDocumentFile", oFile);

    const oOper = await NetServices.requestPostWithFileAxios(
      "chatsystem/sendchatmessage",
      oFormD
    );

    if (!oOper.bReturn) {
      setLstChatMessages([]);
      setServerInUse(false);
      return;
    }

    const fList = oOper.lstLastDoctChatMessages ?? [];

    setLstChatMessages([...lstChatMessages, ...fList]);
    setMessageToSend("");
    setServerInUse(false);
  };

  const tapEmoji = () => {
    setEmojiVis(true);
  };
  const tapSendMessage = async () => {
    const k = appFxs.generateUUID();

    const oRec: IDocumentChat = {
      chatKey: k,
      chatType: 0,
      companyKey: chatter.companyKey,
      documentKey: archiveFile.fileKey,
      messageDate: new Date(),
      recordTime: new Date().getTime(),
      senderEmail: chatter.userEmail,
      chatDocument: undefined,
      chatEmoji: undefined,
      chatMessage: messageToSend,
    };

    setServerInUse(true);
    const lastInput =
      lstChatMessages.length === 0
        ? 0
        : lstChatMessages[lstChatMessages.length - 1].message.recordTime;

    const oFormD = new FormData();
    oFormD.append("chatMessageRecord", JSON.stringify(oRec));
    oFormD.append("lastRecordTime", JSON.stringify(lastInput));

    const oOper = await NetServices.requestPostWithFileAxios(
      "chatsystem/sendchatmessage",
      oFormD
    );

    if (!oOper.bReturn) {
      setLstChatMessages([]);
      setServerInUse(false);
      return;
    }

    const fList = oOper.lstLastDoctChatMessages ?? [];

    setLstChatMessages([...lstChatMessages, ...fList]);
    setMessageToSend("");
    setServerInUse(false);
  };

  const populateListData = async () => {
    if (serverInUse) return;

    setServerInUse(true);
    const lastInput =
      lstChatMessages.length === 0
        ? 0
        : lstChatMessages[lstChatMessages.length - 1].message.recordTime;
    const oOper = await NetServices.requestGet(
      `chatsystem/getlistofdoctchatmessages/${archiveFile.fileKey}/${lastInput}`
    );

    if (!oOper.bReturn) {
      setLstChatMessages([]);
      setServerInUse(false);
      return;
    }

    const fList = oOper.lstLastDoctChatMessages ?? [];

    setLstChatMessages([...lstChatMessages, ...fList]);
    setServerInUse(false);
  };

  const onInputKeyup = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      if (!messageToSend) return;
      tapSendMessage();
    }
  };
  const afterSelectEmoji = async (e: IEmoji) => {
    setEmojiVis(false);

    const k = appFxs.generateUUID();

    const oRec: IDocumentChat = {
      chatKey: k,
      chatType: 2,
      companyKey: chatter.companyKey,
      documentKey: archiveFile.fileKey,
      messageDate: new Date(),
      recordTime: new Date().getTime(),
      senderEmail: chatter.userEmail,
      chatDocument: '',
      chatEmoji: e.url,
      chatMessage: '',
    };

    setServerInUse(true);
    const lastInput =
      lstChatMessages.length === 0
        ? 0
        : lstChatMessages[lstChatMessages.length - 1].message.recordTime;

    const oFormD = new FormData();
    oFormD.append("chatMessageRecord", JSON.stringify(oRec));
    oFormD.append("lastRecordTime", JSON.stringify(lastInput));

    const oOper = await NetServices.requestPostWithFileAxios(
      "chatsystem/sendchatmessage",
      oFormD
    );

    if (!oOper.bReturn) {
      setLstChatMessages([]);
      setServerInUse(false);
      return;
    }

    const fList = oOper.lstLastDoctChatMessages ?? [];

    setLstChatMessages([...lstChatMessages, ...fList]);
    setMessageToSend("");
    setServerInUse(false);
  }

  const scrollToBottom = () => {
    divMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    populateListData();
    const timer = setInterval(() => {
      populateListData();
    }, 1000 * 45);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [lstChatMessages]);

  if (!isOpen) return null;
  return (
    <WiseChatterBox isOpen={isOpen} onClose={onClose}>
      <div className="w-[350px] flex flex-col border border-solid border-slate-400 rounded-t-lg overflow-hidden">
        {/* header */}
        <div className="bg-white flex items-center justify-between p-2 border-b border-solid">
          <div className="flex-1 flex items-center space-x-2">
            <div className="w-8 h-8 bg-white border border-solid border-slate-400 rounded-full overflow-hidden">
              <img
                className="w-full h-full object-cover object-top"
                src={profilePhoto}
                alt=""
              />
            </div>
            <h3 className="text-sm font-semibold">{`${chatter.firstName} ${chatter.lastName}`}</h3>
          </div>
          <div className="flex items-center space-x-2">
            <WiseToolbarButton
              type={uiAllShow ? "minimise" : "restore"}
              tooltip={uiAllShow ? languages.minimize : languages.restore}
              onTapClick={tapMinimize}
              iconColor="#000"
            />
            <WiseToolbarButton
              type="cancel"
              tooltip={languages.close}
              onTapClick={tapClose}
              iconColor="#000"
            />
          </div>
        </div>
        {/* body */}
        {uiAllShow && (
          <>
            <div className="flex flex-col h-[300px] overflow-y-scroll"  ref={divMessagesRef}>
              <div className="flex flex-col grow-0">
                <div className="flex flex-col items-center justify-center pt-5">
                  <img
                    className="w-[60px] h-[60px] object-cover object-top rounded-full border border-solid border-slate-300"
                    src={profilePhoto}
                    alt=""
                  />
                  <h3 className="text-center font-semibold py-3">{`${chatter.firstName} ${chatter.lastName}`}</h3>
                  <p className="py-2 text-center px-2 text-[12px]">
                    {languages.chatOverDoct}
                  </p>
                </div>
              </div>
              <div className="flex flex-col grow">
                <div className="flex flex-col p-2 space-y-2">
                  {lstChatMessages.map((o, idx) => {
                    const lng = lstChatMessages.length;
                    const lastPos = lng - 1;

                    const prevMsg: ChatMessageUser | undefined =
                      idx === 0
                        ? undefined
                        : idx === lastPos
                        ? lstChatMessages[lastPos - 1]
                        : lstChatMessages[idx - 1];

                    return (
                      <ChatDoctMessageComp
                        message={o}
                        key={idx}
                        prevMsg={prevMsg}
                        cChatter={chatter}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div />
          </>
        )}

        {/* footer */}
        {uiAllShow && (
          <div className="flex flex-row p-1 items-end space-x-1 relative">
            <input
              onChange={onFileChange}
              ref={imageSelectorRef}
              type="file"
              accept="image/jpeg,image/png,image/gif"
              className="hidden"
            />

            <WiseToolbarButton
              displayType="static"
              type="image"
              onTapClick={tapImageShow}
            />
            <div className="relative">
              {emojiVis && (
                <>
                  <div
                    className="absolute bottom-0 -left-10 w-[1500px] h-[1500px] border border-solid border-slate-400 z-[9] bg-black/5"
                    onClick={() => setEmojiVis(false)}
                  ></div>
                  <div className="flex flex-col overflow-hidden absolute bottom-full left-0 w-[200px] h-[250px] border border-solid border-slate-400 z-10 bg-white rounded-t-lg">
                    <div className="w-full flex items-center space-x-2 py-1 pl-1 border-b border-solid border-neutral-400/40 mb-1">
                      <EmojiSelectorBtn
                        etype="emotions"
                        onClick={() => setEmojiFilter("emotions")}
                      />
                      <EmojiSelectorBtn
                        etype="people"
                        onClick={() => setEmojiFilter("people")}
                      />
                    </div>
                    <div className="flex-1 flex flex-wrap items-center justify-between px-2 overflow-y-scroll">
                      {archiveEmojis.map((emoji, idx) => {
                        return emoji.group === emojiFilter ? (
                          <EmojiWrapper key={idx} emoji={emoji} onClick={(e) => afterSelectEmoji(e)} />
                        ) : null;
                      })}
                    </div>
                  </div>
                </>
              )}

              <WiseToolbarButton
                displayType="static"
                type="emoji"
                onTapClick={tapEmoji}
              />
            </div>
            <div className="flex-1 rounded-lg bg-[#f0f2f5]">
              <div className="p-1 w-full h-full">
                <textarea 
                placeholder={languages.typeYMsgHere}
                  className="resize-none w-full bg-transparent overflow-y-hidden p-1 outline-none text-[12px]"
                  autoCorrect=""
                  value={messageToSend}
                  onChange={(e) => setMessageToSend(e.target.value)}
                  onKeyUp={onInputKeyup}
                  disabled={serverInUse}
                ></textarea>
              </div>
            </div>

            <>
              {messageToSend && (
                <WiseToolbarButton
                  displayType="static"
                  type="send"
                  onTapClick={() => tapSendMessage()}
                />
              )}
            </>
          </div>
        )}
      </div>
    </WiseChatterBox>
  );
}

