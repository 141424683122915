/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FileRecordNotFileFormComp } from "src/components/FileRecordFormComp";
import LoadinInterfaceComp from "src/components/LoadinInterfaceComp";
import { IDocumentFile } from "src/models/archivix_models";

import generalFxs from "src/utils/general_fx";
import getLanguages from "src/utils/language";
import NetServices from "src/utils/netservices";

const languages = getLanguages();
const appFxs = generalFxs();

const defaultData: IDocumentFile = {
  companyKey: "",
  createDate: new Date(),
  createdBy: "",
  fileKey: "",
  fileName: "",
  fileSources: [],
  sourceFile: 0,
  folderKey: "",
  primaryLocation: "",
  secondaryLocation: "",
  tags: [],
  userAccess: [],
};

export default function DocumentEditorPage() {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [doctTitle, setDoctTitle] = useState(languages.loading);
  const [documentData, setDocumentData] = useState<IDocumentFile>(defaultData);

  const { fileKey, folderKey } = useParams();
  const navigate = useNavigate();
  const bLoaded = useRef(false);

  const onReceiveData = (data: IDocumentFile) => {
    saveRecord(data);
  };

  const populateFileDoct = async () => {
    if (!fileKey) {
      await appFxs.showAlert(
        languages.pageSettings,
        languages.pageSettingsErrMsg
      );
      navigate(
        folderKey ? `/app/documents/folders/${folderKey}` : `/app/documents`
      );
      return;
    }

    const oOper = await NetServices.requestGet(
      `documents/getsimpledocumentbykey/${fileKey}`
    );

    if (!oOper.bReturn) {
      await appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      navigate(
        folderKey ? `/app/documents/folders/${folderKey}` : `/app/documents`
      );
      return;
    }

    const oArchive = oOper.archiveFileRecord!;
    setDocumentData(oArchive);
    setDoctTitle(oArchive.fileName);
    setLoading(false);
  };

  const saveRecord = async (data: IDocumentFile) => {
    setSaving(true);

    const dataFile: IDocumentFile = { ...data};

    const oOper = await NetServices.requestPost(
      "documents/updatefiledocumentrecord",
      {
        documentFileRecord: dataFile
      }
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setSaving(false);
      return;
    }

    if(folderKey){
        navigate(`/app/documents/folders/${folderKey}`);
    }else{
        navigate('/app/documents');
    }
  };



  useEffect(() => {
    if(bLoaded.current) return;
    populateFileDoct();
    bLoaded.current = true;
  }, []);

  return (
    <div className="flex flex-col mx-auto h-screen ">
      <div className="max-w-[800px] w-full mx-auto py-5 flex flex-col space-y-3">
        {/* Header */}
        <div className="flex items-center justify-between">
          <div className="flex-1 flex items-center gap-2">
            {loading && <i className="fa fa-spin fa-spinner"></i>}
            {!loading && folderKey && (
              <Link to={`/app/documents/folders/${folderKey}`}>
                <i className="fa fa-long-arrow-left"></i>
              </Link>
            )}
            {!loading && !folderKey && (
              <Link to={"/app/documents"}>
                <i className="fa fa-long-arrow-left"></i>
              </Link>
            )}
            <h3 className="font-bold text-sm md:text-lg flex-1">
              {doctTitle + (loading ? "..." : "")}
            </h3>
          </div>
        </div>

        {/* Footer */}
        {loading && <LoadinInterfaceComp />}
        {!loading && (
          <FileRecordNotFileFormComp
            saving={saving}
            onReceiveData={onReceiveData}
            defaultData={documentData!}
          />
        )}
      </div>
    </div>
  );
}
