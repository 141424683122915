import { Link } from "react-router-dom";

import {IListOfDoscumentRecords,} from "src/models/smarttypes";

import getLanguages from "src/utils/language";
import { SpecialDocumentRecord } from "./dpecialized_document_records";

import {ReactComponent as FolderPlusSvg} from 'src/assets/images/svg/folder-plus.svg';
import {ReactComponent as FilePlusSvg} from 'src/assets/images/svg/file-plus.svg';
import {ReactComponent as ReloadSvg} from 'src/assets/images/svg/reload.svg';

import {ReactComponent as HomeSvg} from 'src/assets/images/svg/home-icon.svg';

import {ReactComponent as CancelSvg} from 'src/assets/images/svg/cancel.svg';
import {ReactComponent as FolderIconSvg} from 'src/assets/images/svg/folder-icon.svg';
import {ReactComponent as ArrowLeftSvg} from 'src/assets/images/svg/arrow-left.svg';

import { useState } from "react";

const languages = getLanguages();

export const ListOfDocscumentRecords = ({
  onFilter,
  documents,
  addFile,
  addFolder,
  onRefresh,
  operationFolder,
  operationDoct,
  folderDet,
}: IListOfDoscumentRecords) => {

  const [searchKey, setSearchKey] = useState('');

  const setSearchValue = (e:string) => {
    onFilter(e);
    setSearchKey(e);
  }

  const cancelSearch = () => {    
    onFilter('');
    setSearchKey('');
  }
  return (
    <>
    <div className="flex flex-col space-y-2">
      <div className="flex items-center flex-wrap">
        <div className="flex-1 flex flex-col basis-full md:basis-1/2">
          <h3 className="text-lg">{languages.documents}</h3>
          {folderDet && (
            <div className="flex items-center gap-2">
              {!folderDet.parentKey && (
                <Link to={`/app/documents`} title={languages.home}>
                  <HomeSvg width={16} height={16} fill="rgb(59 130 246)" />
                </Link>
              )}
              {folderDet.parentKey && (
                <Link to={`/app/documents/folders/${folderDet.parentKey}`}>
                  <ArrowLeftSvg width={16} height={16} />
                </Link>
              )}
              <div className="flex items-center gap-2">
                <FolderIconSvg width={20} height={20} />
                <span className="text-sm">{folderDet.title}</span>
              </div>
            </div>
          )}
        </div>

        <div className="flex items-center gap-1 basis-full md:basis-1/2">
          <div className="flex items-center flex-1 border border-neutral-300 rounded-lg px-2">
            <input
              type="text"
              className="focus:outline-none px-2 py-2 flex-1 focus:bg-slate-100"
              placeholder={languages.filter.toLowerCase()}
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchKey}
            />
            {searchKey &&
              <button onClick={cancelSearch}>
                <CancelSvg width={16} height={16} />
              </button>
            }
          </div>
          <button
            className="flex items-center justify-center border border-solid border-neutral-400/40 rounded-lg p-2 bg-slate-100 hover:bg-slate-300/40"
            onClick={addFolder}
          >
            <FolderPlusSvg width={18} height={18} />
          </button>
          <button
            className="flex items-center justify-center border border-solid border-neutral-400/40 rounded-lg p-2 bg-slate-100 hover:bg-slate-300/40"
            onClick={() => addFile()}
          >
            <FilePlusSvg width={18} height={18} />
          </button>
          <button
            className="flex items-center justify-center border border-solid border-neutral-400/40 rounded-lg p-2 bg-slate-100 hover:bg-slate-300/40"
            onClick={onRefresh}
          >
            <ReloadSvg width={18} height={18} />
          </button>
        </div>
      </div>

      <div className="flex flex-col  w-full">
        {documents.length === 0 && (
          <div className="w-full h-[350px] flex flex-col items-center justify-center">
            <h3 className="font-bold">{languages.noFilesFnd}</h3>
          </div>
        )}

        <div className="flex flex-wrap w-full">
          {documents.map((doct, idx) => (
            <SpecialDocumentRecord
              docFile={doct}
              key={idx}
              operationFolder={operationFolder}
              operationDoct={operationDoct}
            />
          ))}
        </div>
      </div>
    </div>
    </>
  );
};

