/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";

import appLang from "src/utils/language";
import generalFxs from "src/utils/general_fx";
import appDecl from "src/utils/declarations";

import archivixLogo from "src/assets/images/archivix_logo_black_white_v2.png";
import { RightSideAppMenu, rmenuType } from "src/components/RightSideAppMenu";

import { ReactComponent as FrenchFlag } from "src/assets/images/svg/icons/fr_flag.svg";
import { ReactComponent as BritainFlag } from "src/assets/images/svg/icons/uk_flag.svg";
import { IArchivixUser, ICompanyRecord } from "src/models/archivix_models";

const languages = appLang();
const appFxs = generalFxs();
const localLng = appFxs.getLocalLanguage();
const today = new Date();

export default function NewAppLayout() {
  const [isOpen, setIsOpen] = useState(false);

  const [connectedUser, setConnectedUser] = useState<IArchivixUser | null>(null);
  const [connectedComp, setConnectedComp] = useState<ICompanyRecord | null>(null);

  const navigate = useNavigate();

  const onCloseMenu = (e?: rmenuType) => {
    if (e) {
      switch (e) {
        case "home":
          navigate("/app");
          break;
        case "dashboard":
          navigate("/app/dashboard");
          break;
        case "documents":
          navigate("/app/documents");
          break;
        case "search":
          navigate("/app/search");
          break;
        case "signatures":
          navigate("/app/signdocs");
          break;
        case "partners":
          navigate("/app/partners");
          break;

        default:
          break;
      }
    }

    setIsOpen(false);
  };
  const onChangeLang = async (lng: string) => {
    if (localLng === lng) return;

    const bAsk = await appFxs.showConfirm(
      languages.changeAppLang,
      languages.changeAppLangMsg
    );
    if (!bAsk.isConfirmed) return;
    appFxs.setLocalStorageByKey(appDecl.currentLang, lng);
    document.location.reload();
  };
  const logoutUser = async () => {
    const bAsk = await appFxs.showConfirm(
      languages.signOut,
      languages.signOutMsg
    );
    if (!bAsk.isConfirmed) return;

    localStorage.clear();
    window.location.href = "/";
  };

  const prepareUserData = () => {
    const oUserData = appFxs.getLocalStorageByKey(appDecl.connectedUser,'');
    const oCompData = appFxs.getLocalStorageByKey(appDecl.connectedCompany,'');

    const fUser = oUserData.length === 0 ? null: (JSON.parse(oUserData) as IArchivixUser);
    const fCompany = oCompData.length === 0 ? null: (JSON.parse(oCompData) as ICompanyRecord);

    setConnectedUser(fUser);
    setConnectedComp(fCompany);

    if(!fUser){
      appFxs.setLocalStorageByKey(appDecl.connectedUser, '');
      appFxs.setLocalStorageByKey(appDecl.connectedCompany, '');
      appFxs.setLocalStorageByKey(appDecl.connectionStatus, '0');
      appFxs.setLocalStorageByKey(appDecl.currentBranch, 'HQ');
      appFxs.setLocalStorageByKey(appDecl.currentDevise, 'USD');
      appFxs.setLocalStorageByKey(appDecl.currentYearbook, today.getFullYear().toString());

      navigate('/app')
      return;
    }


  }

  useEffect(() => {
    prepareUserData();
  }, [])

  return (
    <>
      <RightSideAppMenu closeMenu={onCloseMenu} isOpen={isOpen} />
      <header className="supports-backdrop-blur:bg-background/60 bg-background/95 sticky top-0 z-[60] flex h-16 w-full items-center border-b border-b-transparent backdrop-blur duration-200">
        <div className="mx-auto flex w-full max-w-screen-xl items-center justify-between gap-x-4 px-4 md:justify-normal md:px-8">
          <Link
            to="/"
            className="focus-visible:ring-ring ring-offset-background hidden rounded-md focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 md:inline"
          >
            <img src={archivixLogo} alt="" className="w-[186px] h-[28px] object-cover" />
          </Link>
          <div className="ml-8 hidden flex-1 items-center gap-x-12 md:flex md:justify-between">
            <div className="flex items-baseline gap-x-6">
              <Link
                to="/app/dashboard"
                className="text-[14px] focus-visible:ring-ring ring-offset-background rounded-md font-medium leading-5 hover:opacity-80 focus-visible:outline-none focus-visible:ring-2 text-foreground dark:text-muted-foreground"
              >
                {languages.dashboard}
              </Link>
              <Link
                to="/app/documents"
                className="text-[14px] focus-visible:ring-ring ring-offset-background rounded-md font-medium leading-5 hover:opacity-80 focus-visible:outline-none focus-visible:ring-2 text-foreground dark:text-muted-foreground"
              >
                {languages.documents}
              </Link>
              <Link
                to="/app/search"
                className="text-[14px] focus-visible:ring-ring ring-offset-background rounded-md font-medium leading-5 hover:opacity-80 focus-visible:outline-none focus-visible:ring-2 text-foreground dark:text-muted-foreground"
              >
                {languages.search}
              </Link>
              <Link
                to="/app/signdocs"
                className="text-[14px] focus-visible:ring-ring ring-offset-background rounded-md font-medium leading-5 hover:opacity-80 focus-visible:outline-none focus-visible:ring-2 text-foreground dark:text-muted-foreground"
              >
                {languages.signatures}
              </Link>
              <Link
                to="/app/partners"
                className="text-[14px] focus-visible:ring-ring ring-offset-background rounded-md font-medium leading-5 hover:opacity-80 focus-visible:outline-none focus-visible:ring-2 text-foreground dark:text-muted-foreground"
              >
                {languages.partners}
              </Link>
            </div>
          </div>
          <div className="flex gap-x-4 md:ml-8">
            <div className="dropdown">
              <button
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                className="dropdown-toggle not-drop justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background relative flex h-12 flex-row items-center px-2 py-2 ring-0 focus:outline-none focus-visible:border-0 focus-visible:ring-0 focus-visible:ring-transparent"
              >
                <div className="flex w-full max-w-xs items-center gap-2">
                  <span className="relative flex shrink-0 overflow-hidden rounded-full dark:border-border h-10 w-10 border-2 border-solid border-white">
                    <span className="bg-muted flex h-full w-full items-center justify-center rounded-full text-xs text-black font-bold">
                      {connectedUser ? <span>{(connectedUser.firstName + ' ' + connectedUser.lastName).split(' ').map(e => e.split('')[0]).join('')}</span>:<i className="fa fa-spin fa-spinner"></i>}
                    </span>
                  </span>
                  <div className="flex flex-col text-left text-sm font-normal">
                    <span className="text-foreground truncate">
                      {connectedUser ? `${connectedUser?.firstName} ${connectedUser?.lastName}`:`${languages.loading}...`}
                    </span>
                    <span className="text-muted-foreground truncate text-xs">
                      <span className="truncate">{connectedComp ? connectedComp?.companyName:`${languages.loading}...`}</span>
                    </span>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="text-muted-foreground ml-auto h-4 w-4"
                  >
                    <path d="m7 15 5 5 5-5"></path>
                    <path d="m7 9 5-5 5 5"></path>
                  </svg>
                </div>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <Link
                    to={"/app/myprofile"}
                    className="dropdown-item cursor-pointer"
                  >
                    {languages.profileSettings}
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/app/settings/settings"}
                    className="dropdown-item cursor-pointer"
                  >
                    {languages.settings}
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a
                    className="dropdown-item cursor-pointer"
                    onClick={() => onChangeLang("fr")}
                  >
                    <span className="flex items-center">
                      <span
                        className={`flex-1 ${localLng === "fr" && "font-bold"}`}
                      >
                        {languages.french_m}
                      </span>
                      <FrenchFlag width={24} height={24} />
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item cursor-pointer"
                    onClick={() => onChangeLang("en")}
                  >
                    <span className="flex items-center">
                      <span
                        className={`flex-1 ${localLng === "en" && "font-bold"}`}
                      >
                        {languages.english_m}
                      </span>
                      <BritainFlag width={24} height={24} />
                    </span>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a
                    className="dropdown-item cursor-pointer"
                    onClick={() => logoutUser()}
                  >
                    {languages.signOut}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-row items-center space-x-4 md:hidden">
            <button onClick={() => setIsOpen(!isOpen)} type="button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="text-muted-foreground h-6 w-6"
              >
                <line x1="4" x2="20" y1="12" y2="12"></line>
                <line x1="4" x2="20" y1="6" y2="6"></line>
                <line x1="4" x2="20" y1="18" y2="18"></line>
              </svg>
            </button>
          </div>
        </div>
      </header>
      <main className="mt-8 pb-8 md:mt-12 md:pb-12">
        <div className="mx-auto w-full max-w-screen-xl px-4 md:px-8">
          <Outlet />
        </div>
      </main>
    </>
  );
}
