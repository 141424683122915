import React from 'react';

import generalFx from "src/utils/general_fx";
const appFxs = generalFx();
interface IWrapperDropModalElt{
  children: React.ReactNode,
  afterClick: (x:number, y:number, w:number, h:number) => void,
}
export default function WrapperDropModalElt({children, afterClick}:IWrapperDropModalElt) {
  const parentId = appFxs.generateUUID();
  const handleClickDiv = (e:React.MouseEvent<HTMLDivElement>) => {
    const x = e.clientX;
    const y = e.clientY;
    const oelt = document.getElementById(parentId);
    const w = oelt?.clientWidth;
    const h = oelt?.clientHeight;

    afterClick(x,y, w!, h!);
  }
  return (
    <div onClick={handleClickDiv} id={parentId}>
        {children}
    </div>
  )
}
