import React, {  useMemo, useRef, useState } from "react";
import { ReactComponent as SearchSvg } from "src/assets/images/svg/seach-doct.svg";

import { IDocumentRecordWrapperExtended } from "src/models/smarttypes";

import appDecl from "src/utils/declarations";
import getLanguages from "src/utils/language";
import generalFxs from "src/utils/general_fx";
import NetServices from "src/utils/netservices";
import { LstSearchDocumentRecord } from "./search_document_records";

import { ReactComponent as CancelSvg } from "src/assets/images/svg/cancel.svg";
import { WisePagination } from "./wpagination/WisePagination";

const languages = getLanguages();
const appFxs = generalFxs();

const PageSize = Number(appFxs.getLocalStorageByKey(appDecl.itemsPerPage, '9'));

export interface IListSearchDocsRecords {
  operationFolder: (operType: "prev" | "del" | "edit", fldKey: string) => void;
  operationDoct: (
    operType: "prev" | "del" | "edit" | "detail",
    doctKey: string,
    fileKey: string
  ) => void;
}
export const ListSearchDocsRecords = ({
  operationDoct,
  operationFolder,
}: IListSearchDocsRecords) => {
  const [searchKey, setSearchKey] = useState("");
  const [searching, setSearching] = useState(false);
  const [lstDocuments, setLstDocuments] = useState<
    IDocumentRecordWrapperExtended[]
  >([]);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  


  const onTextChanged = (s: string) => {
    setSearchKey(s);
  };
  const applySearch = async () => {
    setSearching(true);

    const encrypted = window.btoa(searchKey);
    const url = `documents/searchfordocuments/${encrypted}`;

    const oOper = await NetServices.requestGet(url);
    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setSearching(false);
      return;
    }

    const lst = oOper.listOfRelatedDoctsExt!;

    setLstDocuments(lst);
    setSearching(false);
  };
  const canclSearch = () => {
    setLstDocuments([]);
    setSearchKey("");
    inputRef.current?.focus();
  };
  const keypressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.charCode === 13) applySearch();
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return lstDocuments.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, lstDocuments]);

  

  return (
    <>
      
      <div className="flex flex-col space-y-2">
        <div className="flex items-center flex-wrap">
          <div className="flex-1 flex flex-col basis-full md:basis-1/2">
            <h3 className="text-lg">{languages.search}</h3>
          </div>
          <div className="flex items-center gap-2 basis-full md:basis-1/2">
            <div className="flex-1 border border-neutral-400 flex items-center px-2  rounded-lg">
              <input
                ref={inputRef}
                type="text"
                className="focus:outline-none px-2 py-2 flex-1"
                placeholder={languages.keyword.toLowerCase()}
                onChange={(e) => onTextChanged(e.target.value)}
                value={searchKey}
                disabled={searching}
                onKeyPress={keypressHandler}
              />
              {searchKey && (
                <button onClick={canclSearch} disabled={searching}>
                  <CancelSvg width={16} height={16} />
                </button>
              )}
            </div>
            <button
              title={languages.search}
              className="flex items-center justify-center border border-solid border-neutral-400/40 rounded-lg p-2 bg-slate-100 hover:bg-slate-300/40"
              onClick={() => applySearch()}
              disabled={searchKey.length === 0 || searching}
            >
              <SearchSvg width={22} height={22} />
            </button>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center">
          {!searching && currentTableData.length === 0 && (
            <div className="w-full h-[350px] flex flex-col items-center justify-center">
              <h3 className="font-bold">{languages.noFilesFnd}</h3>
            </div>
          )}
          {searching && (
            <div className="w-full h-[350px] flex flex-col items-center justify-center">
              <i className="fa fa-spin fa-spinner fa-3x"></i>
            </div>
          )}
          {!searching && currentTableData.length > 0 && (
            <>
              <div className="flex flex-wrap w-full">
                {currentTableData.map((doct, idx) => (
                  <LstSearchDocumentRecord
                    docFile={doct}
                    key={idx}
                    operationFolder={operationFolder}
                    operationDoct={operationDoct}
                  />
                ))}
              </div>

              <WisePagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={lstDocuments.length}
                pageSize={PageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
