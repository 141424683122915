/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import LoadinInterfaceComp from "src/components/LoadinInterfaceComp";

import { ReactComponent as UploadIconSvg } from "src/assets/images/svg/download-icon.svg";
import { ReactComponent as TrashIconSvg } from "src/assets/images/svg/delete-icon.svg";
import { ReactComponent as ArrowLeftIconSvg } from "src/assets/images/svg/arrow-left.svg";

import { ICompanyRecord } from "src/models/archivix_models";
import { IFormError } from "src/models/smarttypes";

import appDecl from "src/utils/declarations";
import generalFxs from "src/utils/general_fx";
import generalLangs from "src/utils/language";
import NetServices from "src/utils/netservices";
import WiseModalTextInput from "src/components/WiseModalTextInput";
import { WiseSimpleButton } from "src/components/majorsmallcomps";

const appFxs = generalFxs();
const languages = generalLangs();

const defaultRec: ICompanyRecord = {
  companyKey: "",
  companyName: "",
  deactivationDate: new Date(),
  isActive: true,
  registrationDate: new Date(),
  shortName: "",
  companyEmail: "",
  companyLogo: "",
  fullAddress: "",
  phoneNumber: "",
};
export default function CompanySettingsDetailPage() {
  document.title = `${languages.companyDetails} | Archivix`;

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [companyRecord, setCompanyRecord] = useState<ICompanyRecord>({
    ...defaultRec,
  });
  const [formErrors, setFormErrors] = useState<IFormError>({});
  const [imagePath, setImagePath] = useState<string | null>(null);

  const [companyLogoFile, setCompanyLogoFile] = useState<File | null>(null);

  const navigate = useNavigate();
  const logoPicker = useRef<HTMLInputElement | null>(null);

  const onTakeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files!.length === 0) return;
    const oFile = e.target.files![0];

    if (!appFxs.isFileImagePng(oFile)) {
      appFxs.showAlert(languages.pngFormatError, languages.pngFormatErrorMsg);
      return;
    }

    const reader = new FileReader();
    setCompanyLogoFile(oFile);

    reader.onload = () => {
      setImagePath(reader.result as string);
    };

    reader.readAsDataURL(oFile);
  };
  const removeSelectedImage = () => {
    if (companyLogoFile) {
      setCompanyLogoFile(null);

      if (companyRecord.companyLogo && companyRecord.companyLogo.length > 0) {
        const fP = `${appDecl.companyResourcesPath}/${companyRecord.companyKey}/logos/${companyRecord.companyLogo}`;
        setImagePath(fP);
      } else {
        setImagePath(null);
        setCompanyRecord({ ...companyRecord, companyLogo: "" });
      }
    } else {
      setImagePath(null);
      setCompanyRecord({ ...companyRecord, companyLogo: "" });
    }

    logoPicker.current!.value = "";
  };

  const changeFields = (f: string, v: string | number) => {
    setCompanyRecord({ ...companyRecord, [f]: v });
  };

  const saveOperationRecord = async () => {
    const errors: IFormError = {};

    if (companyRecord.phoneNumber && companyRecord.phoneNumber.length > 0) {
      if (companyRecord.phoneNumber.length !== 12) {
        errors.phoneNumber = languages.phonenbrLengthMsg;
      }
    }
    if (!companyRecord.companyName || companyRecord.companyName.length === 0) {
      errors.companyName = languages.fldRequired;
    }
    if (!companyRecord.shortName || companyRecord.shortName.length === 0) {
      errors.shortName = languages.fldRequired;
    }
    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setSaving(true);

    const formData = new FormData();
    if (companyLogoFile) {
      formData.append("companyLogo", companyLogoFile!);
    }
    formData.append("companyRecordData", JSON.stringify(companyRecord));

    const oOper = await NetServices.requestPostWithFileAxios(
      "settings/savecompanyrecorddata",
      formData,
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setSaving(false);
      return;
    }

    navigate("/app/settings/company");
  };

  const populateCompanyData = async () => {
    const oOper = await NetServices.requestGet("settings/getcompanydatarecord");

    if (!oOper.bReturn) {
      await appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      navigate("/app/settings/company");
      return;
    }

    const oRecord = oOper.companyDataRecord!;
    const oPath =
      oRecord.companyLogo && oRecord.companyLogo.length > 0
        ? `${appDecl.companyResourcesPath}/${oRecord.companyKey}/logos/${oRecord.companyLogo}`
        : null;

    setImagePath(oPath);
    setCompanyRecord(oRecord);

    setLoading(false);
  };

  useEffect(() => {
    populateCompanyData();
  }, []);

  if (loading) return <LoadinInterfaceComp />;
  return (
    <div className="flex flex-col">
      <div className="flex items-center mb-3 gap-3">
        <Link to={"/app/settings/company"}>
          <ArrowLeftIconSvg width={24} height={24} />
        </Link>
        <h3 className="flex-1 font-bold text-lg">{languages.companyDetails}</h3>
        <button
          className="btn"
          title={languages.save}
          disabled={saving}
          onClick={() => saveOperationRecord()}
        >
          <i className={`fa ${!saving ? "fa-save" : "fa-spin fa-spinner"}`}></i>
        </button>
      </div>
      <div className="flex flex-col w-full max-w-[450px]">
        <div className="relative  mb-3 w-[220px] h-[220px] border border-slate-300 rounded-lg overflow-hidden flex items-center justify-center">
          {(companyLogoFile || imagePath) && (
            <button
              title={languages.remove}
              className="absolute top-1 right-1 z-10"
              onClick={removeSelectedImage}
            >
              <TrashIconSvg width={16} height={16} />
            </button>
          )}
          {!(companyLogoFile || imagePath) && (
            <div
              className="w-[210px] h-[210px] cursor-pointer flex items-center justify-center"
              onClick={() => logoPicker!.current!.click()}
            >
              <UploadIconSvg
                className="cursor-pointer"
                width={50}
                height={50}
              />
            </div>
          )}

          {(companyLogoFile || imagePath) && (
            <img
              alt=""
              className="w-full h-full object-cover relative z-0 cursor-pointer"
              src={imagePath!}
              onClick={() => logoPicker!.current!.click()}
            />
          )}

          <input
            title="Stamp selector"
            aria-label="Stamp selector"
            accept="image/png"
            type="file"
            className="hidden"
            ref={logoPicker}
            onChange={onTakeFiles}
          />
        </div>

        <hr className="my-3" />
        <WiseModalTextInput
          fieldName="companyEmail"
          value={companyRecord.companyEmail!}
          label={languages.email}
          fldType="email"
          placeholder={languages.email.toLowerCase()}
          maxLength={25}
          graphicType="email"
          onChange={changeFields}
          disabled={true}
          showLabel={true}
        />
        <WiseModalTextInput
          fieldName="phoneNumber"
          value={companyRecord.phoneNumber ?? ""}
          label={languages.phoneNbr}
          fldType="text"
          placeholder={languages.phoneNbr.toLowerCase()}
          errorText={formErrors.phoneNumber}
          maxLength={12}
          graphicType="phone"
          onChange={changeFields}
          disabled={saving}
          showLabel={true}
          isNumeric={true}
        />
        <WiseModalTextInput
          fieldName="companyName"
          value={companyRecord.companyName}
          label={languages.companyName}
          fldType="text"
          placeholder={languages.companyName.toLowerCase()}
          errorText={formErrors.companyName}
          maxLength={25}
          graphicType="office"
          onChange={changeFields}
          disabled={saving}
          showLabel={true}
        />
        <WiseModalTextInput
          fieldName="shortName"
          value={companyRecord.shortName}
          label={languages.shortName}
          fldType="text"
          placeholder={languages.shortName.toLowerCase()}
          errorText={formErrors.shortName}
          maxLength={10}
          graphicType="office"
          onChange={changeFields}
          disabled={saving}
          showLabel={true}
        />
        <WiseModalTextInput
          fieldName="fullAddress"
          value={companyRecord.fullAddress ?? ""}
          label={languages.address}
          fldType="textarea"
          placeholder={languages.address.toLowerCase()}
          errorText={formErrors.fullAddress}
          maxLength={250}
          graphicType="map"
          onChange={changeFields}
          disabled={saving}
          showLabel={true}
        />
        <WiseSimpleButton
          label={languages.save}
          disabled={saving}
          btnType="default"
          onClick={() => saveOperationRecord()}
        />
      </div>
    </div>
  );
}
