import { useState, useEffect } from 'react';

const useMediaQuery = (query: string) => {
    const [matches, setMatches] = useState(false);
  
    useEffect(() => {
      const media = window.matchMedia(query);
  
      const listener = () => setMatches(media.matches);
      listener();
  
      window.addEventListener('resize', listener);
  
      return () => window.removeEventListener('resize', listener);
    }, [query]);
  
    return matches;
  };

  export default useMediaQuery;