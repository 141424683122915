/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { ReactComponent as UploadIconSvg } from "src/assets/images/svg/download-icon.svg";
import { ReactComponent as TrashIconSvg } from "src/assets/images/svg/delete-icon.svg";
import { ReactComponent as ArrowLeftIconSvg } from "src/assets/images/svg/arrow-left.svg";

import digitalSignIconSvg from "src/assets/images/svg/digital-signature.svg";

import LoadinInterfaceComp from "src/components/LoadinInterfaceComp";
import { IFormError } from "src/models/smarttypes";

import generalFxs from "src/utils/general_fx";
import generalLangs from "src/utils/language";
import NetServices from "src/utils/netservices";
import WiseModalTextInput from "src/components/WiseModalTextInput";
import { WiseSimpleButton } from "src/components/majorsmallcomps";

const appFxs = generalFxs();
const languages = generalLangs();

export default function CompanySettingsSignaturePage() {
  document.title = `${languages.digitalSignature} | Archivix`;

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasSign, setHasSign] = useState(false);
  /* const [digitalsign, setDigitalsign] = useState<ICompanySignatureFile | null>(
    null
  ); */
  const [signatureFile, setSignatureFile] = useState<File | null>(null);

  const [formSignData, setFormSignData] = useState({
    password: "",
    confPassword: "",
  });
  const [dataErrors, setDataErrors] = useState<IFormError>({});

  const navigate = useNavigate();
  const signaturePicker = useRef<HTMLInputElement | null>(null);

  const onTakeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files!.length === 0) return;
    const oFile = e.target.files![0];

    if (!appFxs.isFileDigitalSign(oFile)) {
      appFxs.showAlert(languages.signFormatError, languages.signFormatErrorMsg);
      return;
    }

    setSignatureFile(oFile);
  };
  const removeSelectedImage = () => {
    setSignatureFile(null);
    signaturePicker.current!.value = "";
  };



  const saveOperationRecord = async () => {
    const errors:IFormError = {};

    if(!signatureFile){
      errors.fileData = languages.fileNotSelected;
    }

    if(formSignData.password && formSignData.password.length > 0){
      if(!formSignData.confPassword || formSignData.confPassword.length === 0){
        errors.confPassword = languages.fldRequired;
      }else if(formSignData.confPassword !== formSignData.password){
        errors.confPassword = languages.passValidMsg;
      }
    }

    setDataErrors(errors);
    if(Object.keys(errors).length > 0) return;

    setSaving(true);
    const formData = new FormData();

    formData.append("companySignature", signatureFile!);
    
    if(formSignData.password && formSignData.password.length > 0){
      const pw = window.btoa(formSignData.password);
      formData.append("siganturePassword", JSON.stringify(pw));
    }else{
      formData.append("siganturePassword", '');
    }


    const oOper = await NetServices.requestPostWithFileAxios(
      "settings/savecompdigitalsignature",
      formData,
      true
    );

    if (!oOper.bReturn) {
      setSaving(false);
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    navigate("/app/settings/company");
  };

  const changeFields = (f: string, v: string | number) => {
    setFormSignData({ ...formSignData, [f]: v });
  };

  const populateSignature = async () => {
    const oOper = await NetServices.requestGet(
      "settings/getcompdigitalsignature"
    );

    if (!oOper.bReturn) {
      await appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      navigate("/app/settings/company");
      return;
    }

    setHasSign(oOper.hasCompanySignature!);
    if (oOper.hasCompanySignature!) {
      //const oCompRec = oOper.companySignatureData!;
      setHasSign(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    populateSignature();
  }, []);

  if (loading) return <LoadinInterfaceComp />;
  return (
    <div className="flex flex-col">
      <div className="flex items-center mb-3 gap-3">
        <Link to={"/app/settings/company"}>
          <ArrowLeftIconSvg width={24} height={24} />
        </Link>
        <h3 className="flex-1 font-bold text-lg">
          {languages.digitalSignature}
        </h3>
        <button
          className="btn"
          title={languages.save}
          disabled={saving || !signatureFile}
          onClick={() => saveOperationRecord()}
        >
          <i className={`fa ${!saving ? "fa-save" : "fa-spin fa-spinner"}`}></i>
        </button>
      </div>

      <div className="flex flex-col w-full max-w-[450px]">
        <div className="flex flex-col items-center justify-center">
          <div className="relative  mb-3 w-[220px] h-[220px] border border-slate-300 rounded-lg overflow-hidden flex items-center justify-center">
            {signatureFile && (
              <button
                title={languages.remove}
                className="absolute top-1 right-1 z-10"
                onClick={removeSelectedImage}
              >
                <TrashIconSvg width={16} height={16} />
              </button>
            )}
            {!signatureFile && (
              <UploadIconSvg
                className="cursor-pointer"
                width={50}
                height={50}
                onClick={() => signaturePicker!.current!.click()}
              />
            )}

            {signatureFile && (
              <img
                alt=""
                className="w-full h-full object-cover relative z-0"
                src={digitalSignIconSvg}
              />
            )}
          </div>
          <input
            title="Stamp selector"
            aria-label="Stamp selector"
            accept="application/x-pkcs12"
            type="file"
            className="hidden"
            ref={signaturePicker}
            onChange={onTakeFiles}
          />
        </div>
        {hasSign && 
          <span className="text-sm font-bold block py-3 text-green-400 text-center">
            {languages.signatureAlSet}
          </span>
        }
        <hr className="my-3" />

        <WiseModalTextInput
          fieldName="password"
          value={formSignData.password}
          label={languages.password}
          fldType="password"
          placeholder={languages.password.toLowerCase()}
          errorText={dataErrors.password}
          maxLength={15}
          graphicType="key"
          onChange={changeFields}
          disabled={saving}
          showLabel={true}
        />

        <WiseModalTextInput
          fieldName="confPassword"
          value={formSignData.confPassword}
          label={languages.confirmPassword}
          fldType="password"
          placeholder={languages.confPwHere.toLowerCase()}
          errorText={dataErrors.confPassword}
          maxLength={15}
          graphicType="key"
          onChange={changeFields}
          disabled={saving}
          showLabel={true}
        />

        <WiseSimpleButton
          label={languages.save}
          disabled={saving || !signatureFile}
          btnType="default"
          onClick={() => saveOperationRecord()}
        />
      </div>
    </div>
  );
}
