/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';

import generalFxs from 'src/utils/general_fx';
import appLanguages from 'src/utils/language';
import NetServices from 'src/utils/netservices';
import WiseTextInput from 'src/components/WiseTextInput';

const appFxs = generalFxs();
const languages = appLanguages();

interface IFormError {
  password?: string,
  confPassword?: string,
}
interface IFormRecord {
  password: string,
  confPassword: string,
}

export default function AcctResetPwPage() {
  document.title = `${languages.setNewPw} | Archivix`;
  const {resetKey} = useParams();
  const navigate = useNavigate();

  
  const [formData, setFormData] = useState<IFormRecord>({
    confPassword: '',
    password: ''
  });
  const [formErrors, setFormErrors] = useState<IFormError>({});
  const [serverInUse, setServerInUse] = useState(false);

  const onChange = (fldName: string, fldValue: string) => {
    setFormData({...formData, [fldName]: fldValue});
  }
  const onResetPassword = async () => {
    const errors:IFormError = {}
    if(!formData.password || formData.password.length === 0){
      errors.password = languages.fldRequired;
    }else if(!appFxs.isPasswordGood(formData.password)){
      errors.password = languages.passwordErrorSec;
    }

    if(!formData.confPassword || formData.confPassword.length === 0){
      errors.confPassword = languages.fldRequired;
    }else if(formData.confPassword !== formData.password){
      errors.confPassword = languages.confirmPaswordError;
    }

    setFormErrors(errors);
    if(Object.keys(errors).length > 0)return;

    setServerInUse(true);
    const oOper = await NetServices.requestPost('account/finalresetpassword', {
      resetPasswordKey: resetKey,
      password: formData.password,
      confirmPassword: formData.confPassword,      
    });

    if(!oOper.bReturn){
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setServerInUse(false);
      return;
    }

    
    setServerInUse(false);

    await appFxs.showAlert(languages.resetPassword, languages.resetPasswordSuccessMsg);

    navigate('/account/login');
  }

  return (
    <>
      <h3 className="font-bold tracking-wide text-3xl w-text-primary">
        {languages.setNewPassword}
      </h3>
      <p className="text-sm">{languages.resetNewPwMsg}</p>

      <WiseTextInput
        fldName="password"
        fldType="password"
        label={languages.password}
        placeholder={languages.newPassword.toLowerCase()}
        graphicType="lock"
        value={formData.password}
        onChange={onChange}
        errorText={formErrors.password}
        disabled={serverInUse}
        checkPassword={true}
      />

      <WiseTextInput
        fldName="confPassword"
        fldType="password"
        label={languages.confirmPassword}
        placeholder={languages.confirmPassword.toLowerCase()}
        graphicType="lock"
        value={formData.confPassword}
        onChange={onChange}
        errorText={formErrors.confPassword}
        disabled={serverInUse}
      />

      <div className="mt-6">
          <button
            className={`btn btn-sm text-white px-[1.2rem] rounded-lg w-bg-primary disabled:bg-gray-400 disabled:text-gray-200 disabled:cursor-not-allowed`}
            onClick={() => onResetPassword()}
            disabled={serverInUse}
          >
            <span className="flex flex-row items-center gap-2">
              {serverInUse && <i className="fa fa-spin fa-spinner"></i>}
              <span>
                {serverInUse && <span>{`${languages.loading}...`}</span>}
                {!serverInUse && <span>{languages.reset}</span>}
              </span>
            </span>
          </button>
        </div>

      <div className="flex justify-end items-center">
        <Link
          className="text-blue-600 hover:text-blue-800"
          to={"/account/login"}
        >
          {languages.signInAnyway}
        </Link>
      </div>
      </>
  );
}
