/* eslint-disable jsx-a11y/anchor-is-valid */
import SignatureCanvas from "react-signature-canvas";

/* eslint-disable react-hooks/exhaustive-deps */
import { ReactComponent as CanclBtn } from "src/assets/images/svg/icons/cancel.svg";
import { ReactComponent as DashBtn } from "src/assets/images/svg/icons/dash.svg";
import { ReactComponent as PlusBtn } from "src/assets/images/svg/icons/plus.svg";
import { ReactComponent as ImageBtn } from "src/assets/images/svg/icons/image.svg";
import { ReactComponent as ImageEmoji } from "src/assets/images/svg/icons/emoji.svg";
import { ReactComponent as ImageSend } from "src/assets/images/svg/icons/send.svg";
import { ReactComponent as ImageRestore } from "src/assets/images/svg/icons/restore_icon.svg";

import { ReactComponent as Calendar01 } from "src/assets/images/svg/calendar/cal-01.svg";
import { ReactComponent as Calendar02 } from "src/assets/images/svg/calendar/cal-02.svg";
import { ReactComponent as Calendar03 } from "src/assets/images/svg/calendar/cal-03.svg";
import { ReactComponent as Calendar04 } from "src/assets/images/svg/calendar/cal-04.svg";
import { ReactComponent as Calendar05 } from "src/assets/images/svg/calendar/cal-05.svg";
import { ReactComponent as Calendar06 } from "src/assets/images/svg/calendar/cal-06.svg";
import { ReactComponent as Calendar07 } from "src/assets/images/svg/calendar/cal-07.svg";
import { ReactComponent as Calendar08 } from "src/assets/images/svg/calendar/cal-08.svg";
import { ReactComponent as Calendar09 } from "src/assets/images/svg/calendar/cal-09.svg";
import { ReactComponent as Calendar10 } from "src/assets/images/svg/calendar/cal-10.svg";

import { ReactComponent as Calendar11 } from "src/assets/images/svg/calendar/cal-11.svg";
import { ReactComponent as Calendar12 } from "src/assets/images/svg/calendar/cal-12.svg";
import { ReactComponent as Calendar13 } from "src/assets/images/svg/calendar/cal-13.svg";
import { ReactComponent as Calendar14 } from "src/assets/images/svg/calendar/cal-14.svg";
import { ReactComponent as Calendar15 } from "src/assets/images/svg/calendar/cal-15.svg";
import { ReactComponent as Calendar16 } from "src/assets/images/svg/calendar/cal-16.svg";
import { ReactComponent as Calendar17 } from "src/assets/images/svg/calendar/cal-17.svg";
import { ReactComponent as Calendar18 } from "src/assets/images/svg/calendar/cal-18.svg";
import { ReactComponent as Calendar19 } from "src/assets/images/svg/calendar/cal-19.svg";
import { ReactComponent as Calendar20 } from "src/assets/images/svg/calendar/cal-20.svg";

import { ReactComponent as Calendar21 } from "src/assets/images/svg/calendar/cal-21.svg";
import { ReactComponent as Calendar22 } from "src/assets/images/svg/calendar/cal-22.svg";
import { ReactComponent as Calendar23 } from "src/assets/images/svg/calendar/cal-23.svg";
import { ReactComponent as Calendar24 } from "src/assets/images/svg/calendar/cal-24.svg";
import { ReactComponent as Calendar25 } from "src/assets/images/svg/calendar/cal-25.svg";
import { ReactComponent as Calendar26 } from "src/assets/images/svg/calendar/cal-26.svg";
import { ReactComponent as Calendar27 } from "src/assets/images/svg/calendar/cal-27.svg";
import { ReactComponent as Calendar28 } from "src/assets/images/svg/calendar/cal-28.svg";
import { ReactComponent as Calendar29 } from "src/assets/images/svg/calendar/cal-29.svg";
import { ReactComponent as Calendar30 } from "src/assets/images/svg/calendar/cal-30.svg";
import { ReactComponent as Calendar31 } from "src/assets/images/svg/calendar/cal-31.svg";

import { ReactComponent as TrashBtnSvg } from "src/assets/images/svg/delete-icon.svg";

import { ReactComponent as StampIconSvg } from "src/assets/images/svg/stamp-icon.svg";
import { ReactComponent as SignatureIconSvg } from "src/assets/images/svg/signature-icon.svg";
import { ReactComponent as CompanyIconSvg } from "src/assets/images/svg/company-icon.svg";

import { ReactComponent as UsersPlusSvg } from "src/assets/images/svg/users-plus.svg";
import { ReactComponent as SignerPenSvg } from "src/assets/images/svg/signer-pen.svg";
import { ReactComponent as CopyIconSvg } from "src/assets/images/svg/copy-icon.svg";
import { ReactComponent as DoctApproverSvg } from "src/assets/images/svg/doct-approver.svg";
import { ReactComponent as ViewerIconSvg } from "src/assets/images/svg/eye-icon.svg";
import { ReactComponent as CheckIconSvg } from "src/assets/images/svg/check-icon.svg";

import SignatureIconImg from "src/assets/images/svg/signature-icon.svg";

import imgDash from "src/assets/images/minimize.png";
import imgCancel from "src/assets/images/cancel_blue.png";
import imgPlus from "src/assets/images/plus_btn.png";
import imgGallery from "src/assets/images/gallery.png";
import imgEmoji from "src/assets/images/emoji.png";
import imgSend from "src/assets/images/send.png";
import imgRestore from "src/assets/images/restore_window.png";

import imgProfile from "src/assets/images/profile_placeholder.jpg";

import appDecl from "src/utils/declarations";
import getLang from "src/utils/language";
import generalFxs from "src/utils/general_fx";

import {
  ChatMessageUser,
  EmojiFilter,
  IArchUserFixer,
  ICompSetting,
  IEmailName,
  IFieldPagePos,
  IShortUserSelector,
  IShortUserSelectorKeys,
  ISignReturnType,
  SignFieldType,
} from "src/models/smarttypes";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { IEmoji } from "src/utils/app_arch_data";
import NetServices from "src/utils/netservices";
import { Link } from "react-router-dom";
import { IArchivixUser } from "src/models/archivix_models";

const languages = getLang();

const appFxs = generalFxs();

const signerActions: {
  type: "signature" | "email" | "name" | "date";
}[] = [
  { type: "signature" },
  { type: "email" },
  { type: "name" },
  { type: "date" },
];

interface LoadingTableRowProps {
  colSpan: number;
  loadText?: string;
}

interface ErrorTableRowProps {
  colSpan: number;
  messageDisplay?: string;
  actionToTake?: () => void;
  buttonIconCss?: string;
}
interface NoRecordTableRowProps {
  colSpan: number;
  messageDisplay: string;
  actionToTake?: () => void;
  buttonIconCss?: string;
}

export const LoadingTableRow = ({
  colSpan,
  loadText,
}: LoadingTableRowProps) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="h-auto py-2 flex flex-col justify-center items-center space-y-2">
          <span className="loading loading-spinner loading-lg"></span>
          <h3 className="font-semibold text-lg">
            {loadText ?? languages.loading}
          </h3>
        </div>
      </td>
    </tr>
  );
};

export const ErrorTableRow = ({
  colSpan,
  messageDisplay,
  actionToTake,
  buttonIconCss,
}: ErrorTableRowProps) => {
  const iconCss = buttonIconCss ?? "fa fa-history";
  const handleClick = () => {
    if (!actionToTake) return;
    actionToTake!();
  };
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="h-auto py-2 flex flex-col justify-center items-center space-y-2">
          <div className="flex flex-col items-center justify-center">
            <h3 className="text-lg my-3">{messageDisplay}</h3>

            <button
              type="button"
              className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
              onClick={handleClick}
            >
              <i className={iconCss}></i>
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
};

export const NoRecordTableRow = ({
  colSpan,
  messageDisplay,
  actionToTake,
  buttonIconCss,
}: NoRecordTableRowProps) => {
  const handleClick = () => {
    if (!actionToTake) return;
    actionToTake!();
  };
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="h-auto py-2 flex flex-col justify-center items-center space-y-2">
          <div className="flex flex-col items-center justify-center">
            <h3 className="text-lg my-3">{messageDisplay}</h3>
            <button
              type="button"
              className="flex items-center p-3 font-semibold bg-gray-200 hover:text-white hover:bg-gray-400 rounded-full"
              onClick={handleClick}
            >
              <i className={buttonIconCss}></i>
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
};

interface IWiseToolbarButton {
  type:
    | "cancel"
    | "minimise"
    | "phone"
    | "plus"
    | "image"
    | "emoji"
    | "send"
    | "restore";
  tooltip?: string;
  displayType?: "default" | "static";
  onTapClick?: () => void;
  btnSize?: number;
  backColor?: string;
  iconColor?: string;
}
export const WiseToolbarButton = ({
  type,
  tooltip,
  displayType,
  onTapClick,
  btnSize,
  backColor,
  iconColor,
}: IWiseToolbarButton) => {
  const bColor = backColor ?? "#f0f2f5";
  const iColor = iconColor ?? "#e5e5e5";
  const dType = displayType ?? "default";
  const bSize = btnSize ?? 16;
  const tapClick = () => {
    if (!onTapClick) return;
    onTapClick();
  };

  const imgSource =
    type === "cancel"
      ? imgCancel
      : type === "image"
      ? imgGallery
      : type === "minimise"
      ? imgDash
      : type === "phone"
      ? imgCancel
      : type === "plus"
      ? imgPlus
      : type === "emoji"
      ? imgEmoji
      : type === "send"
      ? imgSend
      : type === "restore"
      ? imgRestore
      : imgCancel;

  const SvgIcon =
    type === "cancel"
      ? CanclBtn
      : type === "minimise"
      ? DashBtn
      : type === "plus"
      ? PlusBtn
      : type === "image"
      ? ImageBtn
      : type === "emoji"
      ? ImageEmoji
      : type === "send"
      ? ImageSend
      : type === "restore"
      ? ImageRestore
      : CanclBtn;

  return (
    <button
      style={{
        backgroundColor: dType === "static" ? bColor : undefined,
      }}
      className="h-[28px] w-[28px] rounded-full bg-transparent flex justify-center items-center cursor-pointer relative hover:bg-slate-300"
      title={tooltip}
      onClick={tapClick}
    >
      {dType === "default" && (
        <SvgIcon width={bSize} height={bSize} stroke={iColor} />
      )}
      {dType === "static" && (
        <img style={{ width: bSize, height: bSize }} src={imgSource} alt="" />
      )}
    </button>
  );
};

export interface IChatDoctMessageComp {
  prevMsg?: ChatMessageUser,
  message: ChatMessageUser,
  cChatter: IArchivixUser,
}
export const ChatDoctMessageComp = ({
  message,
  prevMsg,
  cChatter
}: IChatDoctMessageComp) => {
  const { message: chatMsg, user } = message;

  const isMine = chatMsg.senderEmail === cChatter.userEmail;
  const photoProfile = !user.photoProfile
    ? imgProfile
    : `${appDecl.profilesPath}/${cChatter.companyKey}/${user.photoProfile}`;

  const hasPrev = prevMsg === undefined ? false : true;

  const showDate = !hasPrev
    ? true
    : !appFxs.hasSameDayDate(
        prevMsg!.message.messageDate,
        message.message.messageDate
      );

  const TextMessageChat = () => {
    return (
      <>
      <div
        className={`flex items-center ${
          isMine ? "justify-end" : "justify-start"
        }`}
      >
        <div
          className={`w-[80%] flex items-start space-x-1 ${
            isMine ? "justify-end" : "justify-start"
          }`}
        >
          {!isMine && (
            <img
              src={photoProfile}
              alt=""
              className="w-[28px] h-[28px] object-cover object-top rounded-full border border-solid border-slate-300"
            />
          )}
          <div className="flex flex-col space-y-1">
            <div className="bg-[#f2f2f2] py-1 px-2 text-[12px] rounded-xl">
              {chatMsg.chatMessage}
            </div>
            <div className="flex items-center justify-end">
              <TimeAgoArea dDate={new Date(chatMsg.messageDate)} />
            </div>
          </div>
        </div>
      </div>
      </>
    );
  };

  const ImageMessageChat = () => {
    const filePath = `${appDecl.chatDoctsPath}/${chatMsg.documentKey}/${chatMsg.chatDocument}`;
    return (
      <div
        className={`flex items-center ${
          isMine ? "justify-end" : "justify-start"
        }`}
      >
        <div
          className={`w-[80%] flex items-start space-x-1 ${
            isMine ? "justify-end" : "justify-start"
          }`}
        >
          {!isMine && (
            <img
              src={photoProfile}
              alt=""
              className="w-[28px] h-[28px] object-cover object-top rounded-full border border-solid border-slate-300"
            />
          )}
          <div className="flex flex-col space-y-1">
            <div className="py-1 px-2 text-[12px] rounded-xl">
              {chatMsg.chatType === 1 && (
                <img
                  src={filePath}
                  alt=""
                  className="block max-h-[200px] max-w-full rounded-[18px] object-contain"
                />
              )}
              {chatMsg.chatType === 2 && (
                <div
                  style={{
                    height: 64,
                    width: 64,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: 64,
                    backgroundImage: `url(${chatMsg.chatEmoji})`,
                  }}
                ></div>
              )}
            </div>
            <div className="flex items-center justify-end">
              <TimeAgoArea dDate={new Date(chatMsg.messageDate)} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {showDate && (
        <div className="divider w-[90%] mx-auto">
          <span className="text-[10px] text-gray-500 font-bold flex space-x-2">
            <i className="bi bi-calendar2-week"></i>
            <span>{moment(chatMsg.messageDate).format("DD/MM/YYYY")}</span>
          </span>
        </div>
      )}
      {chatMsg.chatType === 0 && <TextMessageChat />}
      {(chatMsg.chatType === 1 || chatMsg.chatType === 2) && (
        <ImageMessageChat />
      )}
    </>
  );
};

export const TimeAgoArea = ({ dDate }: { dDate: Date }) => {
  const [timeSet, setTimeSet] = useState(
    moment(dDate).format("DD/MM/YY HH:mm")
  );

  const updateTime = () => {
    const x = appFxs.timeLongAgo(dDate);
    setTimeSet(x);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      updateTime();
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return <span className="text-[10px]">{timeSet}</span>;
};

export const EmojiWrapper = ({
  emoji,
  onClick,
}: {
  emoji: IEmoji;
  onClick: (e: IEmoji) => void;
}) => {
  return (
    <span
      className="flex items-center justify-around cursor-pointer h-8 w-8 border border-solid border-slate-400/10"
      title={emoji.title}
    >
      <img
        loading="eager"
        src={emoji.url}
        alt=""
        className="w-6 h-6"
        onClick={() => onClick(emoji)}
      />
    </span>
  );
};

export const EmojiSelectorBtn = ({
  etype,
  onClick,
}: {
  etype: EmojiFilter;
  onClick: () => void;
}) => {
  const eltTitle = etype === "emotions" ? languages.emotions : languages.people;

  return (
    <button
      onClick={() => onClick()}
      title={eltTitle}
      className="w-7 h-7 flex justify-center items-center border border-solid border-slate-300/40 bg-slate-200/40 rounded-sm"
    >
      {etype === "emotions" && (
        <img
          src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f600/512.webp"
          alt=""
          className="w-4 h-4"
        />
      )}

      {etype === "people" && (
        <img
          src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f483/512.webp"
          alt=""
          className="w-4 h-4"
        />
      )}
    </button>
  );
};

interface IToolbarButton {
  type: "delete" | "email" | "key" | "share" | "download";
  onClick: () => void;
  title?: string;
  iconColor?: string;
  tooltipId?: string;
}
export const ToolbarButton = ({
  type,
  onClick,
  iconColor,
  title,
  tooltipId,
}: IToolbarButton) => {
  const btnCss =
    type === "delete"
      ? "bi bi-trash-fill"
      : type === "email"
      ? "bi bi-envelope-fill"
      : type === "key"
      ? "bi bi-key-fill"
      : type === "share"
      ? "bi bi-share-fill"
      : type === "download"
      ? "bi bi-cloud-arrow-down-fill"
      : "bi bi-trash-fill";
  return (
    <button
      onClick={() => onClick()}
      data-tooltip-id={tooltipId}
      data-tooltip-content={title}
      className="w-7 h-7 flex justify-center items-center border border-solid border-slate-300/40 bg-[#f2f2f2]/40 rounded-sm"
    >
      <i
        style={{
          color: iconColor ?? "#000",
        }}
        className={`${btnCss} `}
      ></i>
    </button>
  );
};

interface IWiseButtonAction {
  title: string;
  onAction: () => void;
  iconType?: "download" | "edit" | "sign" | "complete";
  paddingHoriz?: number;
  paddingVert?: number;
  height?: number;
  width?: number;
}
export const WiseButtonAction = ({
  title,
  onAction,
  iconType,
  paddingHoriz,
  paddingVert,
  height,
  width,
}: IWiseButtonAction) => {
  return (
    <button
      onClick={onAction}
      className=" h-10 py-2 px-4 w-32 inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-green-400 text-white hover:bg-green-400/90"
    >
      {iconType && (
        <span>
          {iconType === "download" && <i className="fa fa-download"></i>}
          {iconType === "edit" && <i className="fa fa-pencil-square"></i>}
          {iconType === "sign" && <i className="fa fa-pencil"></i>}
          {iconType === "complete" && <i className="fa fa-check-circle"></i>}
          &nbsp;&nbsp;
        </span>
      )}
      {title}
    </button>
  );
};
export const WiseLinkAction = ({ title, onAction }: IWiseButtonAction) => {
  return (
    <a
      className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-primary text-primary-foreground hover:bg-primary/90 h-10 py-2 px-4 w-32"
      href="/documents/5009/edit"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="-ml-1 mr-2 h-4 w-4"
      >
        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
        <path d="M18.5 2.5a2.12 2.12 0 0 1 3 3L12 15l-4 1 1-4Z"></path>
      </svg>
      {title}
    </a>
  );
};

export const PDFLoader = () => (
  <>
    <span className="loading loading-bars w-[60px]"></span>
    <p className="text-neutral-600 mt-4">{languages.loadingDocument}...</p>
  </>
);

interface ISignerVignette {
  signer: IArchUserFixer;
  selected: boolean;
  onSelect: (e: IArchUserFixer) => void;
}
const SignerVignette = ({ signer, selected, onSelect }: ISignerVignette) => {
  return (
    <div
      onClick={() => onSelect(signer)}
      title={signer.userName}
      className={`cursor-pointer overflow-hidden w-12 h-12 rounded-full border-4 border-solid ${
        selected ? "border-blue-500/40" : "border-neutral-600/40"
      } hover:border-blue-500/40`}
    >
      <img
        src={signer.userPic}
        alt={signer.userName}
        className="w-full h-full object-cover"
      />
    </div>
  );
};


interface ISignerVignettePart {
  signer: IEmailName;
  selected: boolean;
  onSelect: (e: IEmailName) => void;
}
const SignerVignettePart = ({ signer, selected, onSelect }: ISignerVignettePart) => {
  const fN = signer.userName.split(' ').map(o => o.split('')[0]).join('');
  return (
    <div
      onClick={() => onSelect(signer)}
      title={signer.userName}
      className={`cursor-pointer overflow-hidden w-12 h-12 rounded-full border-4 border-solid ${
        selected ? "border-blue-500/40" : "border-neutral-600/40"
      } hover:border-blue-500/40`}
    >
      <span className="w-full h-full flex justify-center items-center">{fN}</span>
    </div>
  );
};











interface ISignerFieldAction {
  signer: IArchUserFixer;
  type: SignFieldType;
  onClickAction: (e: SignFieldType) => void;
}
const SignerFieldAction = ({
  signer,
  type,
  onClickAction,
}: ISignerFieldAction) => {
  const headerX =
    type === "signature"
      ? signer.userName
      : type === "date"
      ? languages.date
      : type === "email"
      ? languages.email
      : type === "name"
      ? languages.name
      : "";
  return (
    <button
      type="button"
      className="group h-full w-full border border-solid border-slate-500 rounded-lg"
      onClick={() => onClickAction(type)}
    >
      <div className="p-3 flex flex-col items-center justify-center px-3 py-2">
        {type === "signature" ? (
          <React.Fragment>
            <p className="text-black group-data-[selected]:text-foreground w-full truncate text-2xl font-medium cavaet-font">
              {signer.userName}
            </p>
            <p className="text-black80 mt-2 text-center text-xs">
              {languages.signature}
            </p>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p className="text-black group-data-[selected]:text-foreground w-full truncate text-2xl font-medium">
              {headerX}
            </p>
            <p className="text-black80 mt-2 text-center text-xs">{headerX}</p>
          </React.Fragment>
        )}
      </div>
    </button>
  );
};

interface ISignDoctFieldArea {
  lstSigners: IArchUserFixer[];
  addFieldToPage: (oFldProps: IFieldPagePos) => void;
  onClickFieldAction: (f: SignFieldType, signer: IArchUserFixer) => void;
}
export const SignDoctFieldArea = ({
  lstSigners,
  addFieldToPage,
  onClickFieldAction,
}: ISignDoctFieldArea) => {
  const [selectedSigner, setSelectedSigner] = useState(lstSigners[0]);

  const onClickFldAction = (e: SignFieldType) => {};

  return (
    <>
      <div>
        <h3 className="text-foreground text-2xl font-semibold">
          {languages.addFields}
        </h3>
        <p className="text-black/80 mt-2 text-sm">{languages.addFieldsMsg}</p>
        <hr className="border-border mb-8 mt-4" />
      </div>
      <div className="custom-scrollbar -mx-2 flex flex-1 flex-col overflow-hidden px-2">
        <div className="flex flex-1 flex-col">
          <div className="flex flex-col">
            {/* Signer Selector */}
            <div className="flex flex-wrap gap-x-2">
              {lstSigners.map((oUser, index) => (
                <SignerVignette
                  onSelect={(e) => setSelectedSigner(e)}
                  signer={oUser}
                  key={index}
                  selected={oUser.userEmail === selectedSigner.userEmail}
                />
              ))}
            </div>
            {/* Fields */}
            <div className="-mx-2 flex-1 overflow-y-auto px-2 mt-5">
              <fieldset className="grid grid-cols-2 gap-x-4 gap-y-8">
                {signerActions.map((oAction, index) => (
                  <SignerFieldAction
                    key={index}
                    signer={selectedSigner}
                    type={oAction.type}
                    onClickAction={onClickFldAction}
                  />
                ))}
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


interface ISignDoctFieldAreaPart {
  lstSigners: IEmailName[];
  addFieldToPage: (oFldProps: IFieldPagePos) => void;
  onClickFieldAction: (f: SignFieldType, signer: IEmailName) => void;
}
export const SignDoctFieldAreaPart = ({
  lstSigners,
  addFieldToPage,
  onClickFieldAction,
}: ISignDoctFieldAreaPart) => {
  const [selectedSigner, setSelectedSigner] = useState(lstSigners[0]);

  const onClickFldAction = (e: SignFieldType) => {};

  return (
    <>
      <div>
        <h3 className="text-foreground text-2xl font-semibold">
          {languages.addFields}
        </h3>
        <p className="text-black/80 mt-2 text-sm">{languages.addFieldsMsg}</p>
        <hr className="border-border mb-8 mt-4" />
      </div>
      <div className="custom-scrollbar -mx-2 flex flex-1 flex-col overflow-hidden px-2">
        <div className="flex flex-1 flex-col">
          <div className="flex flex-col">
            {/* Signer Selector */}
            <div className="flex flex-wrap gap-x-2">
              {lstSigners.map((oUser, index) => (
                <SignerVignettePart
                  onSelect={(e) => setSelectedSigner(e)}
                  signer={oUser}
                  key={index}
                  selected={oUser.userEmail === selectedSigner.userEmail}
                />
              ))}
            </div>
            {/* Fields */}
            <div className="-mx-2 flex-1 overflow-y-auto px-2 mt-5">
              <fieldset className="grid grid-cols-2 gap-x-4 gap-y-8">
                {signerActions.map((oAction, index) => (
                  <SignerFieldAction
                    key={index}
                    signer={selectedSigner}
                    type={oAction.type}
                    onClickAction={onClickFldAction}
                  />
                ))}
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export const SignerFieldOnPage = () => {
  return (
    <div className="rect-fld-signer" draggable>
      <button className="text-muted-foreground/50 hover:text-muted-foreground/80 bg-white absolute -right-2 -top-2 z-20 flex h-8 w-8 items-center justify-center rounded-full border">
        <i className="bi bi-trash-fill"></i>
      </button>
      <div className="text-foreground group relative rounded-lg border-2 dark:shadow-[0] bg-field-card/80 h-full w-full backdrop-blur-[1px] border-field-card-border">
        <div className="text-field-card-foreground flex h-full w-full flex-col items-center justify-center p-2">
          Name
          <p className="w-full truncate text-center text-xs">
            rugenge.francais@gmail.com
          </p>
        </div>
      </div>
    </div>
  );
};

interface ISignerFieldMover {
  visible: boolean;
  top: number;
  left: number;
}
export const SignerFieldMover = ({ visible, left, top }: ISignerFieldMover) => {
  if (!visible) return null;
  return (
    <div className="rect-fld-signer" style={{ left, top }}>
      <div className="text-foreground group relative rounded-lg border-2 dark:shadow-[0] bg-field-card/80 h-full w-full backdrop-blur-[1px] border-field-card-border">
        <div className="text-field-card-foreground flex h-full w-full flex-col items-center justify-center p-2">
          Name
          <p className="w-full truncate text-center text-xs">
            rugenge.francais@gmail.com
          </p>
        </div>
      </div>
    </div>
  );
};

interface ISignerMessageSubjectArea {
  onSaveOps: (e: { subject: string; message: string }) => void;
}
export const SignerMessageSubjectArea = ({
  onSaveOps,
}: ISignerMessageSubjectArea) => {
  const [dataForm, setDataForm] = useState({ subject: "", message: "" });

  const changeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fldData = { ...dataForm, [e.target.name]: e.target.value };
    setDataForm(fldData);
    onSaveOps(fldData);
  };

  return (
    <>
      <div>
        <h3 className="text-foreground text-2xl font-semibold">
          {languages.addSubject}
        </h3>
        <p className="text-black/80 mt-2 text-sm">{languages.addSubjectMsg}</p>
        <hr className="border-border mb-8 mt-4" />
      </div>
      <div className="custom-scrollbar -mx-2 flex flex-1 flex-col overflow-hidden px-2">
        <div className="flex flex-1 flex-col">
          <div className="flex flex-col">
            <div className="flex flex-col gap-y-4">
              {/* Subject */}
              <div className="py-1">
                <label
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  htmlFor="subject"
                >
                  {languages.subject}&nbsp;
                  <span className="text-black/40">({languages.optional})</span>
                </label>
                <input
                  className="border-input ring-offset-background placeholder:text-black/40 focus-visible:ring-ring flex h-10 w-full rounded-md border px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 bg-background mt-2"
                  id="subject"
                  name="subject"
                  value={dataForm.subject}
                  onChange={changeField}
                />
              </div>
              {/* message */}
              <div className="py-1">
                <label
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  htmlFor="message"
                >
                  {languages.message}&nbsp;
                  <span className="text-black/40">({languages.optional})</span>
                </label>
                <textarea
                  className="border-input ring-offset-background placeholder:text-black/40 focus-visible:ring-ring flex w-full rounded-md border px-3 py-2 text-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 bg-background mt-2 h-32 resize-none"
                  id="message"
                  name="message"
                  value={dataForm.message}
                  onChange={(e) =>
                    setDataForm({
                      ...dataForm,
                      [e.target.name]: e.target.value,
                    })
                  }
                ></textarea>
              </div>
              {/* message fields */}
              <div className="py-1">
                <p className="text-black text-sm">{languages.messageUseInfo}</p>
                <ul className="mt-2 flex list-inside list-disc flex-col gap-y-2 text-sm mb-6">
                  <li className="text-black/90">
                    <code className="text-black/60 bg-muted-foreground/20 rounded p-1 text-sm">
                      {`{signer.name}`}
                    </code>
                    &nbsp;- {languages.thSignerName}
                  </li>
                  <li className="text-black/90">
                    <code className="text-black/60 bg-muted-foreground/20 rounded p-1 text-sm">
                      {`{signer.email}`}
                    </code>
                    &nbsp;- {languages.thSignerEmail}
                  </li>
                  <li className="text-black/90">
                    <code className="text-black/60 bg-muted-foreground/20 rounded p-1 text-sm">
                      {`{document.name}`}
                    </code>
                    &nbsp;- {languages.thDoctName}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

interface ISignerDocumentTitle {
  onSaveOps: (e: { documentTitle: string; applyStamp: boolean }) => void;
  formError?: string;
  defValue: string;
}
export const SignerDocumentTitle = ({
  onSaveOps,
  formError,
  defValue,
}: ISignerDocumentTitle) => {
  const [dataForm, setDataForm] = useState({
    documentTitle: defValue,
    applyStamp: false,
  });

  const changeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fldData = { ...dataForm, [e.target.name]: e.target.value };
    setDataForm(fldData);
    onSaveOps(fldData);
  };
  const changeApplyStamp = () => {
    const fldData = { ...dataForm, applyStamp: !dataForm.applyStamp };
    setDataForm(fldData);
    onSaveOps(fldData);
  };

  return (
    <div className="flex flex-col">
      <h3 className="text-foreground text-2xl font-semibold">
        {languages.addTitle}
      </h3>
      <p className="text-gray-700 mt-2 text-sm">{languages.addTitleMsg}</p>
      <hr className="border-border mb-8 mt-4" />
      <div className="custom-scrollbar -mx-2 flex flex-1 flex-col overflow-hidden px-2">
        <div className="flex flex-1 flex-col">
          <div className="flex flex-col">
            <div className="flex flex-col gap-y-4">
              <div>
                <label
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  form="title"
                  htmlFor="documentTitle"
                >
                  {languages.title}
                  <span className="text-destructive ml-1 inline-block font-medium">
                    *
                  </span>
                </label>
                <input
                  className="border-input ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring flex h-10 w-full rounded-md border px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 bg-background my-2"
                  id="documentTitle"
                  name="documentTitle"
                  value={dataForm.documentTitle}
                  onChange={changeField}
                />
                {formError && (
                  <p className="text-sm text-red-500">{formError}</p>
                )}
              </div>
              <div>
                <label
                  className="flex items-center gap-2 text-sm font-medium leading-none cursor-pointer"
                  form="applyStamp"
                  htmlFor="applyStamp"
                >
                  <input
                    type="checkbox"
                    className="checkbox checkbox-sm"
                    id="applyStamp"
                    name="applyStamp"
                    checked={dataForm.applyStamp}
                    onChange={(e) => changeApplyStamp()}
                  />
                  <span>{languages.applyStamp}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ISignerDocumentListSigners {
  setUserDlgVis: (value: React.SetStateAction<boolean>) => void;
  lstSigners: IArchUserFixer[];
  removeSigner: (oSigner: IArchUserFixer) => void;
}
export const SignerDocumentListSigners = ({
  setUserDlgVis,
  lstSigners,
  removeSigner,
}: ISignerDocumentListSigners) => {
  return (
    <div className="flex flex-col">
      <div className=" flex">
        <h3 className="text-foreground text-2xl font-semibold flex-1">
          {languages.addSigners}
        </h3>
        <button className="btn flex gap-2" onClick={() => setUserDlgVis(true)}>
          <UsersPlusSvg width={16} height={16} />
        </button>
      </div>
      <p className="text-gray-700 mt-2 text-sm">{languages.addSignersMsg}</p>
      <hr className="border-border mb-8 mt-4" />
      <div className="custom-scrollbar -mx-2 flex flex-1 flex-col overflow-hidden px-2">
        <div className="flex flex-1 flex-col">
          {lstSigners.length === 0 && (
            <div className="flex items-center justify-center border border-solid border-neutral-300/90 bg-slate-400/40 h-[80px] w-full">
              <h3 className="text-lg">{languages.addSigners}</h3>
            </div>
          )}
          {lstSigners.length > 0 && (
            <ul className="flex flex-col space-y-2 w-full divide-y-2">
              {lstSigners.map((oSigner, index) => {
                return (
                  <>
                    <SignerSelector
                      key={index}
                      oSigner={oSigner}
                      onRemoveSigner={removeSigner}
                    />
                  </>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};


interface ISignerDocumentListPartGuest {
  setUserDlgVis: (value: React.SetStateAction<boolean>) => void;
  lstSigners: IArchUserFixer[];
  removeGuest: (oSigner: IArchUserFixer) => void;
}
export const SignerDocumentListPartGuest = ({
  setUserDlgVis,
  lstSigners,
  removeGuest,
}: ISignerDocumentListPartGuest) => {
  return (
    <div className="flex flex-col">
      <div className=" flex">
        <h3 className="text-foreground text-2xl font-semibold flex-1">
          {languages.addGuests}
        </h3>
        <button className="btn flex gap-2" onClick={() => setUserDlgVis(true)}>
          <UsersPlusSvg width={16} height={16} />
        </button>
      </div>
      <p className="text-gray-700 mt-2 text-sm">{languages.addGuestsMsg}</p>
      <hr className="border-border mb-8 mt-4" />
      <div className="custom-scrollbar -mx-2 flex flex-1 flex-col overflow-hidden px-2">
        <div className="flex flex-1 flex-col">
          {lstSigners.length === 0 && (
            <div className="flex items-center justify-center border border-solid border-neutral-300/90 bg-slate-400/40 h-[80px] w-full">
              <h3 className="text-lg">{languages.addGuests}</h3>
            </div>
          )}
          {lstSigners.length > 0 && (
            <ul className="flex flex-col space-y-2 w-full divide-y-2">
              {lstSigners.map((oSigner, index) => {
                return (
                  <>
                    <SignerSelector
                      key={index}
                      oSigner={oSigner}
                      onRemoveSigner={removeGuest}
                    />
                  </>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};




interface IListSignerDocumentForPartner {
  lstSigners: IEmailName[];
  updateMainList: (lst: IEmailName[]) => void,
}

export const ListSignerDocumentForPartner = ({
  lstSigners,
  updateMainList
}: IListSignerDocumentForPartner) => {
  const [users, setUsers] = useState<IEmailName[]>(
    lstSigners.map((o) => {
      return {
        userEmail: o.userEmail,
        userName: o.userName,
        orderKey: o.orderKey,
        typeUser: o.typeUser,
      };
    })
  );

  const addEmpty = () => {
    const ff: IEmailName | undefined =
      users.length > 0 ? users[users.length - 1] : undefined;

    if (!ff) {
      const pp: IEmailName = {
        orderKey: appFxs.tempoKey(),
        userEmail: "",
        userName: "",
        typeUser: 0,
      };
      const ff = [...users, pp];

      setUsers(ff);
      updateMainList(ff);
    } 
    else if (
      ff &&
      ff.userEmail.length > 0 &&
      appFxs.isEmailValid(ff.userEmail) &&
      ff.userName.length > 0
    ) {
      const pp: IEmailName = {
        orderKey: appFxs.tempoKey(),
        userEmail: "",
        userName: "",
        typeUser: 0,
      };

      const ff = [...users, pp];

      setUsers(ff);
      updateMainList(ff);
    }
  };

  const handleItemChange = (element: IEmailName) => {
    const newList = users.map((item) => {
      if (item.orderKey === element.orderKey) {
        const updatedItem: IEmailName = {
          ...item,
          userName: element.userName,
          typeUser: element.typeUser,
          userEmail: element.userEmail,
        };

        return updatedItem;
      }

      return item;
    });

    setUsers(newList);

      updateMainList(newList);
  };

  const handleItemDelete = (element: IEmailName) => {
    const newList = users.filter(o => o.orderKey !== element.orderKey);

    setUsers(newList);
    updateMainList(newList);
  };


  return (
    <div className="flex flex-col">
      <div className=" flex">
        <h3 className="text-foreground text-2xl font-semibold flex-1">
          {languages.addSigners}
        </h3>
        <button className="btn flex gap-2" onClick={addEmpty}>
          <UsersPlusSvg width={16} height={16} />
        </button>
      </div>
      <p className="text-gray-700 mt-2 text-sm">{languages.addSignersMsg}</p>
      <hr className="border-border mb-8 mt-4" />
      <div className="custom-scrollbar -mx-2 flex flex-1 flex-col overflow-hidden px-2">
        <div className="flex flex-1 flex-col">
          <ul className="flex flex-col space-y-2 w-full divide-y-2 mb-16">
            {users.map((o, idx) => (
              <UserWithEmailInterf
                key={idx}
                user={o}
                handleItemChange={handleItemChange}
                handleItemDelete={handleItemDelete}
              />
            ))}
          </ul>

          <div className="mt-2 flex flex-row items-center space-x-4">
            <button
              onClick={addEmpty}
              className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-[#027e6f] text-white hover:bg-[#027e6f]/90 h-10 py-2 px-4"
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="-ml-1 mr-2 h-5 w-5"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              {languages.addSigner}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

interface IUserWithEmailInterf {
  user: IEmailName,
  handleItemChange: (element: IEmailName) => void,
  handleItemDelete: (element: IEmailName) => void,
}
const UserWithEmailInterf = ({
  user,
  handleItemChange,
  handleItemDelete,
}: IUserWithEmailInterf) => {
  const [fieldVal, setFieldVal] = useState<IEmailName>({ ...user });

  const onFieldChanged = (f: string, v: string | number) => {
    const fData = { ...fieldVal, [f]: v };
    setFieldVal(fData);
    handleItemChange(fData);
  };

  const onTypeChanged = (iType: number) => {
    if(iType!==0){
      appFxs.showAlert(languages.featureUnavail, languages.featureUnavailMsg);
      return;
    }


    const fData = { ...fieldVal, typeUser: iType };
    setFieldVal(fData);
    handleItemChange(fData);
  };

  return (
    <li className="flex w-full flex-col gap-y-2 py-3">
      <fieldset className="grid grid-cols-8 gap-4 pb-4">
        <div className="space-y-2 relative col-span-3">
          <label
            htmlFor=""
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            {languages.email}
            <span className="text-destructive ml-1 inline-block font-medium">
              *
            </span>
          </label>
          <input
            type="email"
            className="bg-background border-input ring-offset-background placeholder:text-muted-foreground/40 focus-visible:ring-ring flex h-10 w-full rounded-md border px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
            placeholder={languages.email}
            value={fieldVal.userEmail}
            onChange={(e) => onFieldChanged("userEmail", e.target.value)}
          />
        </div>
        <div className="space-y-2 col-span-3">
          <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
            {languages.name}
            <span className="text-destructive ml-1 inline-block font-medium">
              *
            </span>
          </label>
          <input
            className="bg-background border-input ring-offset-background placeholder:text-muted-foreground/40 focus-visible:ring-ring flex h-10 w-full rounded-md border px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
            placeholder={languages.name}
            aria-invalid="false"
            value={fieldVal.userName}
            onChange={(e) => onFieldChanged("userName", e.target.value)}
          />
        </div>
        <div className="space-y-2 col-span-1 mt-auto">
          <div className="dropdown">
            <button
              type="button"
              className="dropdown-toggle not-dropdown border-input ring-offset-background placeholder:text-muted-foreground focus:ring-ring flex h-10 items-center justify-between rounded-md border px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 bg-background w-[60px] gap-2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {fieldVal.typeUser === 0 && (
                <SignerPenSvg width={24} height={24} />
              )}
              {fieldVal.typeUser === 1 && (
                <CopyIconSvg width={24} height={24} />
              )}
              {fieldVal.typeUser === 2 && (
                <DoctApproverSvg width={24} height={24} />
              )}
              {fieldVal.typeUser === 3 && (
                <ViewerIconSvg width={24} height={24} />
              )}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-4 w-4 opacity-50"
                aria-hidden="true"
              >
                <path d="m6 9 6 6 6-6"></path>
              </svg>
            </button>
            <ul className="dropdown-menu">
              <li>
                <a
                  className="dropdown-item cursor-pointer"
                  onClick={() => onTypeChanged(0)}
                >
                  <span className="flex items-center gap-1">
                    <span className="inline-block w-6 h-6">
                      {fieldVal.typeUser === 0 && (
                        <CheckIconSvg width={18} height={18} />
                      )}
                    </span>
                    <SignerPenSvg width={18} height={18} />
                    <span>{languages.signer}</span>
                  </span>
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item cursor-pointer"
                  onClick={() => onTypeChanged(1)}
                >
                  <span className="flex items-center gap-1">
                    <span className="inline-block w-6 h-6">
                      {fieldVal.typeUser === 1 && (
                        <CheckIconSvg width={18} height={18} />
                      )}
                    </span>
                    <CopyIconSvg width={18} height={18} />
                    <span>{languages.receivesCopy}</span>
                  </span>
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item cursor-pointer"
                  onClick={() => onTypeChanged(2)}
                >
                  <span className="flex items-center gap-1">
                    <span className="inline-block w-6 h-6">
                      {fieldVal.typeUser === 2 && (
                        <CheckIconSvg width={18} height={18} />
                      )}
                    </span>
                    <DoctApproverSvg width={18} height={18} />
                    <span>{languages.approver}</span>
                  </span>
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item cursor-pointer"
                  onClick={() => onTypeChanged(3)}
                >
                  <span className="flex items-center gap-1">
                    <span className="inline-block w-6 h-6">
                      {fieldVal.typeUser === 3 && (
                        <CheckIconSvg width={18} height={18} />
                      )}
                    </span>
                    <ViewerIconSvg width={18} height={18} />
                    <span>{languages.viewer}</span>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <button
        onClick={() => handleItemDelete(fieldVal)}
          type="button"
          className="col-span-1 mt-auto inline-flex h-10 w-10 items-center justify-center text-slate-500 hover:opacity-80 disabled:cursor-not-allowed disabled:opacity-50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="h-5 w-5"
          >
            <path d="M3 6h18"></path>
            <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
            <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
          </svg>
        </button>
      </fieldset>
    </li>
  );
};

interface IWiseSimpleButton {
  label: string;
  working?: boolean;
  disabled?: boolean;
  btnType?: "default" | "primary";
  onClick?: () => void;
}
export const WiseSimpleButton = ({
  label,
  disabled,
  btnType,
  onClick,
  working,
}: IWiseSimpleButton) => {
  const defaultCss = `inline-flex items-center justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background text-secondary-foreground h-11 px-8 rounded-md dark:bg-muted dark:hover:bg-muted/80 w-full bg-black/5 hover:bg-black/10`;
  const primaryCss = `inline-flex items-center justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-[#027E6F] text-white hover:bg-[#027E6F] h-11 px-8 rounded-md w-full`;

  const isWorking = working ?? false;

  const btnCss =
    btnType === undefined
      ? defaultCss
      : btnType === "default"
      ? defaultCss
      : primaryCss;

  const handleClick = () => {
    if (!onClick) return;
    onClick!();
  };

  return (
    <button
      onClick={handleClick}
      disabled={disabled ?? false}
      type="button"
      className={`${btnCss} `}
    >
      {isWorking && (
        <>
          <i className="fa fa-spin fa-spinner"></i>&nbsp;
        </>
      )}
      <span>{label}</span>
    </button>
  );
};

export const SignaturePad = () => {
  const canvasRef = useRef<SignatureCanvas>(null);

  return (
    <div className="flex flex-col space-y-1 w-full p-1 border border-solid border-slate-500/40 rounded-lg mt-2">
      <div className="sign-cav w-full flex-1">
        <SignatureCanvas
          ref={canvasRef}
          canvasProps={{}}
          clearOnResize={false}
          onEnd={(e) => {}}
        />
      </div>
      <div className="flex items-center justify-end p-2 gap-2">
        <button
          onClick={() => canvasRef?.current?.clear()}
          type="button"
          className="text-[12px] p-2 hover:bg-gray-200/20 rounded-lg"
        >
          {languages.clearSignature}
        </button>
        <button
          onClick={() => canvasRef?.current?.clear()}
          type="button"
          className="text-[12px] p-2 hover:bg-gray-200/20 rounded-lg"
        >
          {languages.save}
        </button>
      </div>
    </div>
  );
};

interface ISignaturePadRef {
  onClear: () => void;
  onSaveSign: () => void;
  onLoadSign?: () => void;
}
export const SignaturePadRef = React.forwardRef<
  SignatureCanvas,
  ISignaturePadRef
>((props, ref) => {
  const onClear = () => {
    props.onClear();
  };
  const onSaveSign = () => {
    props.onSaveSign();
  };
  const onLoad = () => {
    if (!props.onLoadSign) return;
    props.onLoadSign!();
  };

  return (
    <div className="flex flex-col space-y-1 w-full p-1 border border-solid border-slate-500/40 rounded-lg mt-2">
      <div className="sign-cav w-full flex-1">
        <SignatureCanvas
          ref={ref}
          canvasProps={{}}
          clearOnResize={false}
          penColor="blue"
        />
      </div>
      <div className="flex items-center justify-end p-2 gap-2">
        {props.onLoadSign && (
          <button
            onClick={onLoad}
            type="button"
            className="text-[12px] p-2 hover:bg-gray-200/20 rounded-lg"
          >
            {languages.load}
          </button>
        )}
        <button
          onClick={onClear}
          type="button"
          className="text-[12px] p-2 hover:bg-gray-200/20 rounded-lg"
        >
          {languages.clearSignature}
        </button>
        <button
          onClick={onSaveSign}
          type="button"
          className="text-[12px] p-2 hover:bg-gray-200/20 rounded-lg"
        >
          {languages.save}
        </button>
      </div>
    </div>
  );
});

interface IWiseSignaturePadRef {
  domainKey: string;
  signerEmail: string;
}
export const WiseSignaturePadRef = ({
  domainKey,
  signerEmail,
}: IWiseSignaturePadRef) => {
  const [loading, setLoading] = useState(true);
  const [typeSign, setTypeSign] = useState(0);

  const [imagePath, setImagePath] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);

  //const [signatureRecord, setSignatureRecord] = useState<IArchivixSignatureFile | null>(null);

  const signRef = useRef<SignatureCanvas | null>(null);
  const refFile = useRef<HTMLInputElement | null>(null);

  const enryptedEmail = window.btoa(signerEmail);
  const enryptedDomKey = window.btoa(domainKey);

  const onLoadSign = () => {
    refFile!.current?.click();
  };
  const onClearSign = () => {
    if (imageFile) {
      setImageFile(null);
      setTypeSign(0);
    } else {
      signRef?.current?.clear();
      setTypeSign(0);
    }

    refFile!.current!.value = "";
  };
  const onSaveSign = async () => {
    const isCanvEmpty = signRef?.current?.isEmpty();
    if (isCanvEmpty && !imageFile) {
      appFxs.showAlert(languages.canvasEmpty, languages.canvasEmptyMsg);
      return;
    }

    const formData = new FormData();
    formData.append("userEmail", signerEmail);
    formData.append("domainKey", domainKey);

    if (imageFile) {
      formData.append("signatureType", "1");
      formData.append("archiUserSignFile", imageFile);
    } else {
      const dataURI = signRef?.current
        ?.getTrimmedCanvas()
        .toDataURL("image/png", 1.0);

      const oBlob = appFxs.getImageBlobFromURI(dataURI!);
      const file = appFxs.convertBlobToFile(oBlob);

      formData.append("signatureType", "0");
      formData.append("archiUserSignFile", file);
    }

    const bAsk = await appFxs.showConfirm(
      languages.signature,
      languages.signatureSaveQst
    );
    if (!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPostWithFileAxios(
      "signdocs/saveusersignatureprocess/notconn",
      formData,
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }
  };

  const onTakeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files!.length === 0) return;
    const oFile = e.target.files![0];

    if (!appFxs.isFileImagePng(oFile)) {
      appFxs.showAlert(languages.pngFormatError, languages.pngFormatErrorMsg);
      return;
    }

    const reader = new FileReader();
    setImageFile(oFile);
    setTypeSign(1);

    reader.onload = () => {
      setImagePath(reader.result as string);
    };

    reader.readAsDataURL(oFile);
  };

  const populateSignature = async () => {
    const oOper = await NetServices.requestGet(
      `signdocs/getusersignaturefile/notconn/${enryptedEmail}/${enryptedDomKey}`
    );

    if (!oOper.bReturn) {
      setTypeSign(0);
      setLoading(false);
      return;
    }

    const realtedUser = oOper.relatedUser!;

    if (oOper.userHasSignature!) {
      const vOper = oOper.signatureRecord!;

      setTypeSign(vOper.signatureType);

      const signPath = `${appDecl.userSignatures}/${domainKey}/${realtedUser.userKey}/${vOper.signatureName}`;

      if (vOper.signatureType === 0) {
        loadSignatureInPad(signPath);
      } else {
        setImagePath(signPath);
      }

      setLoading(false);
    } else {
      setTypeSign(0);
      setLoading(false);
    }
  };
  const loadSignatureInPad = async (imgPth: string) => {
    const filePath = imgPth;

    appFxs.toDataUrl(filePath, (dataUrl) => {
      setTimeout(() => {
        signRef!.current!.fromDataURL(dataUrl);
      }, 2000);
    });
  };

  useEffect(() => {
    populateSignature();
  }, []);

  return (
    <div className="flex flex-col space-y-1 w-full p-1 border border-solid border-slate-500/40 rounded-lg mt-2">
      <div className="sign-cav w-full flex-1">
        {typeSign === 0 && (
          <SignatureCanvas
            ref={signRef}
            canvasProps={{}}
            clearOnResize={false}
            penColor="blue"
          />
        )}
        {typeSign === 1 && (
          <img
            alt=""
            src={imagePath || SignatureIconImg}
            className="w-full h-[150px] object-contain"
          />
        )}
        {loading && (
          <div className="w-full h-[150px] object-contain flex items-center justify-center">
            <i className="fa fa-spin fa-spinner"></i>
          </div>
        )}
        <input
          ref={refFile}
          type="file"
          accept="image/png"
          className="hidden"
          onChange={onTakeFiles}
        />
      </div>
      <div className="flex items-center justify-end p-2 gap-2">
        <button
          onClick={onLoadSign}
          type="button"
          className="text-[12px] p-2 hover:bg-gray-200/20 rounded-lg"
        >
          {languages.load}
        </button>
        <button
          onClick={onClearSign}
          type="button"
          className="text-[12px] p-2 hover:bg-gray-200/20 rounded-lg"
        >
          {languages.clearSignature}
        </button>
        <button
          onClick={() => onSaveSign()}
          type="button"
          className="text-[12px] p-2 hover:bg-gray-200/20 rounded-lg"
        >
          {languages.save}
        </button>
      </div>
    </div>
  );
};


export const WiseSignaturePadRefPart = ({
  domainKey,
  signerEmail,
}: IWiseSignaturePadRef) => {
  const [loading, setLoading] = useState(true);
  const [typeSign, setTypeSign] = useState(0);

  const [imagePath, setImagePath] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);

  //const [signatureRecord, setSignatureRecord] = useState<IArchivixSignatureFile | null>(null);

  const signRef = useRef<SignatureCanvas | null>(null);
  const refFile = useRef<HTMLInputElement | null>(null);

  const enryptedEmail = window.btoa(signerEmail);
  const enryptedDomKey = window.btoa(domainKey);

  const onLoadSign = () => {
    refFile!.current?.click();
  };
  const onClearSign = () => {
    if (imageFile) {
      setImageFile(null);
      setTypeSign(0);
    } else {
      signRef?.current?.clear();
      setTypeSign(0);
    }

    refFile!.current!.value = "";
  };
  const onSaveSign = async () => {
    const isCanvEmpty = signRef?.current?.isEmpty();
    if (isCanvEmpty && !imageFile) {
      appFxs.showAlert(languages.canvasEmpty, languages.canvasEmptyMsg);
      return;
    }

    const formData = new FormData();
    formData.append("userEmail", signerEmail);
    formData.append("domainKey", domainKey);

    if (imageFile) {
      formData.append("signatureType", "1");
      formData.append("archiUserSignFile", imageFile);
    } else {
      const dataURI = signRef?.current
        ?.getTrimmedCanvas()
        .toDataURL("image/png", 1.0);

      const oBlob = appFxs.getImageBlobFromURI(dataURI!);
      const file = appFxs.convertBlobToFile(oBlob);

      formData.append("signatureType", "0");
      formData.append("archiUserSignFile", file);
    }

    const bAsk = await appFxs.showConfirm(
      languages.signature,
      languages.signatureSaveQst
    );
    if (!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPostWithFileAxios(
      "partners/saveusersignatureprocess/notconn",
      formData,
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }
  };

  const onTakeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files!.length === 0) return;
    const oFile = e.target.files![0];

    if (!appFxs.isFileImagePng(oFile)) {
      appFxs.showAlert(languages.pngFormatError, languages.pngFormatErrorMsg);
      return;
    }

    const reader = new FileReader();
    setImageFile(oFile);
    setTypeSign(1);

    reader.onload = () => {
      setImagePath(reader.result as string);
    };

    reader.readAsDataURL(oFile);
  };

  const populateSignature = async () => {
    const oOper = await NetServices.requestGet(
      `partners/getusersignaturefile/notconn/${enryptedEmail}/${enryptedDomKey}`
    );

    if (!oOper.bReturn) {
      setTypeSign(0);
      setLoading(false);
      return;
    }

    const realtedUser = oOper.relatedUser!;

    if (oOper.userHasSignature!) {
      const vOper = oOper.signatureRecord!;

      setTypeSign(vOper.signatureType);

      const signPath = `${appDecl.partnerSignatures}/${domainKey}/${realtedUser.userKey}/${vOper.signatureName}`;

      if (vOper.signatureType === 0) {
        loadSignatureInPad(signPath);
      } else {
        setImagePath(signPath);
      }

      setLoading(false);
    } else {
      setTypeSign(0);
      setLoading(false);
    }
  };
  const loadSignatureInPad = async (imgPth: string) => {
    const filePath = imgPth;

    appFxs.toDataUrl(filePath, (dataUrl) => {
      setTimeout(() => {
        signRef!.current!.fromDataURL(dataUrl);
      }, 2000);
    });
  };

  useEffect(() => {
    populateSignature();
  }, []);

  return (
    <div className="flex flex-col space-y-1 w-full p-1 border border-solid border-slate-500/40 rounded-lg mt-2">
      <div className="sign-cav w-full flex-1">
        {typeSign === 0 && (
          <SignatureCanvas
            ref={signRef}
            canvasProps={{}}
            clearOnResize={false}
            penColor="blue"
          />
        )}
        {typeSign === 1 && (
          <img
            alt=""
            src={imagePath || SignatureIconImg}
            className="w-full h-[150px] object-contain"
          />
        )}
        {loading && (
          <div className="w-full h-[150px] object-contain flex items-center justify-center">
            <i className="fa fa-spin fa-spinner"></i>
          </div>
        )}
        <input
          ref={refFile}
          type="file"
          accept="image/png"
          className="hidden"
          onChange={onTakeFiles}
        />
      </div>
      <div className="flex items-center justify-end p-2 gap-2">
        <button
          onClick={onLoadSign}
          type="button"
          className="text-[12px] p-2 hover:bg-gray-200/20 rounded-lg"
        >
          {languages.load}
        </button>
        <button
          onClick={onClearSign}
          type="button"
          className="text-[12px] p-2 hover:bg-gray-200/20 rounded-lg"
        >
          {languages.clearSignature}
        </button>
        <button
          onClick={() => onSaveSign()}
          type="button"
          className="text-[12px] p-2 hover:bg-gray-200/20 rounded-lg"
        >
          {languages.save}
        </button>
      </div>
    </div>
  );
};

export const ShortUserSelector = ({
  oSh,
  activFile,
  updateUser,
  isSaving,
}: IShortUserSelector) => {
  const bTempoChecked =
    oSh.userEmail === activFile.createdBy ||
    activFile.userAccess!.includes(oSh.userEmail);
  const finalImage = !oSh.photoProfile
    ? imgProfile
    : `${appDecl.profilesPath}/${activFile.companyKey}/${oSh.photoProfile}`;
  const [isChecked, setIsChecked] = useState(bTempoChecked);

  const changeValue = () => {
    const newVal = !isChecked;
    setIsChecked(newVal);
    updateUser(oSh.userEmail, newVal);
  };

  return (
    <li className="flex flex-row mt-2">
      <div className="flex-1 flex items-center space-x-2">
        <div className="w-7 h-7 border border-solid border-slate-400/40 rounded-full overflow-hidden">
          <img
            src={finalImage}
            alt=""
            className="w-full h-full object-cover object-top"
          />
        </div>
        <h3 className="font-semibold text-sm">{oSh.userName}</h3>
      </div>
      <input
        type="checkbox"
        className="checkbox checkbox-primary"
        checked={isChecked}
        onChange={() => changeValue()}
        disabled={oSh.userEmail === activFile.createdBy || isSaving}
      />
    </li>
  );
};

export const ShortUserSelectorKeys = ({
  oSh,
  activFile,
  updateUser,
  isSaving,
  keys,
  isVisible = true,
}: IShortUserSelectorKeys) => {
  /* const bTempoChecked =
    oSh.userEmail === activFile.createdBy ||
    activFile.userAccess!.includes(oSh.userEmail); */

  const bTempoChecked =
    oSh.userEmail === activFile.createdBy.toLowerCase() ||
    keys.includes(oSh.userEmail.toLowerCase());

  const finalImage = !oSh.photoProfile
    ? imgProfile
    : `${appDecl.profilesPath}/${activFile.companyKey}/${oSh.photoProfile}`;

  const [isChecked, setIsChecked] = useState(bTempoChecked);

  const changeValue = (bChecked: boolean) => {
    setIsChecked(bChecked);
    updateUser(oSh.userEmail, bChecked);
  };

  useEffect(() => {
    const bTempoCheckedV =
      oSh.userEmail === activFile.createdBy.toLowerCase() ||
      keys.includes(oSh.userEmail.toLowerCase());
    setIsChecked(bTempoCheckedV);
  }, [keys]);

  return (
    <li className={`${isVisible ? "flex" : "hidden"} flex-row mt-2`}>
      <div className="flex-1 flex flex-col">
        <div className="flex items-center gap-2">
          <div className="w-8 h-8 border border-solid border-slate-400/40 rounded-full overflow-hidden">
            <img
              src={finalImage}
              alt=""
              className="w-full h-full object-cover object-top"
            />
          </div>
          <div className="flex-1 flex flex-col">
            <h3 className="font-semibold text-sm">{oSh.userName}</h3>
            <span className="text-[10px]">{oSh.userEmail}</span>
          </div>
        </div>
      </div>
      <input
        type="checkbox"
        className="checkbox checkbox-primary"
        checked={isChecked}
        onChange={(e) => changeValue(e.target.checked)}
        disabled={oSh.userEmail === activFile.createdBy || isSaving}
      />
    </li>
  );
};

interface IWiseSelectInput {
  selValue: string | number;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  vOptions: { label: string; value: string | number }[];
}
export const WiseSelectInput = ({
  selValue,
  onChange,
  vOptions,
}: IWiseSelectInput) => {
  return (
    <div className="flex items-center space-x-2 select select-bordered  select-sm px-1 py-3 wise-select">
      <CalendarIcon fillColor="black" width={22} height={22} />
      <select
        className="max-w-xs focus:outline-none"
        value={selValue}
        onChange={onChange}
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        {vOptions.map((l, idx) => (
          <option key={idx} value={l.value}>
            {l.label}
          </option>
        ))}
      </select>
    </div>
  );
};

interface ICalendarIcon {
  fillColor?: string;
  width?: number;
  height?: number;
}
export const CalendarIcon = ({ fillColor, height, width }: ICalendarIcon) => {
  const w = width ?? 16;
  const h = height ?? 16;
  const f = fillColor ?? "black";

  const today = new Date().getDate();
  const SVGIcon =
    today === 1
      ? Calendar01
      : today === 2
      ? Calendar02
      : today === 3
      ? Calendar03
      : today === 4
      ? Calendar04
      : today === 5
      ? Calendar05
      : today === 6
      ? Calendar06
      : today === 7
      ? Calendar07
      : today === 8
      ? Calendar08
      : today === 9
      ? Calendar09
      : today === 10
      ? Calendar10
      : today === 11
      ? Calendar11
      : today === 12
      ? Calendar12
      : today === 13
      ? Calendar13
      : today === 14
      ? Calendar14
      : today === 15
      ? Calendar15
      : today === 16
      ? Calendar16
      : today === 17
      ? Calendar17
      : today === 18
      ? Calendar18
      : today === 19
      ? Calendar19
      : today === 20
      ? Calendar20
      : today === 21
      ? Calendar21
      : today === 22
      ? Calendar22
      : today === 23
      ? Calendar23
      : today === 24
      ? Calendar24
      : today === 25
      ? Calendar25
      : today === 26
      ? Calendar26
      : today === 27
      ? Calendar27
      : today === 28
      ? Calendar28
      : today === 29
      ? Calendar29
      : today === 30
      ? Calendar30
      : today === 31
      ? Calendar31
      : Calendar01;

  return <SVGIcon width={w} height={h} fill={f} />;
};

interface ISignerSelector {
  oSigner: IArchUserFixer;
  onRemoveSigner: (o: IArchUserFixer) => void;
}
export const SignerSelector = ({
  oSigner,
  onRemoveSigner,
}: ISignerSelector) => {
  return (
    <li className="flex items-center gap-2 pt-2">
      <div className="flex items-start gap-2 flex-1">
        <div className="w-7 h-7 rounded-full border border-solid border-slate-400 overflow-hidden ">
          <img
            src={oSigner.userPic}
            alt={oSigner.userName}
            className="w-full h-full"
          />
        </div>
        <div className="flex flex-col">
          <span>{oSigner.userName}</span>
          <span className="text-xs">{oSigner.userEmail}</span>
        </div>
      </div>
      <button className="btn" onClick={() => onRemoveSigner(oSigner)}>
        <TrashBtnSvg width={16} height={16} />
      </button>
    </li>
  );
};

export const CompSettingsCompon = ({
  settType,
  navigateToUrl,
}: ICompSetting) => {
  const IconSvg =
    settType === "stamp"
      ? StampIconSvg
      : settType === "signature"
      ? SignatureIconSvg
      : settType === "detail"
      ? CompanyIconSvg
      : StampIconSvg;

  const settTitle =
    settType === "stamp"
      ? languages.stamp
      : settType === "signature"
      ? languages.digitalSignature
      : settType === "detail"
      ? languages.companyDetails
      : languages.stamp;

  const settDetail =
    settType === "stamp"
      ? languages.applyStampBusDocs
      : settType === "signature"
      ? languages.applySignatureToDocs
      : settType === "detail"
      ? languages.companyDetailsMsg
      : languages.applyStampBusDocs;

  const settUrl =
    settType === "stamp"
      ? "stamp"
      : settType === "signature"
      ? "signature"
      : settType === "detail"
      ? "detail"
      : "stamp";

  return (
    <div
      onClick={() => navigateToUrl(settUrl)}
      className="mb-2 flex gap-4 w-full border border-slate-400/40 p-2 rounded-lg overflow-hidden hover:bg-slate-300/30 cursor-pointer max-w-full sm:flex-col sm:gap-1 sm:w-[48%] md:w-[31%] lg:w-[24%]"
    >
      <div className="w-[51px] h-[51px] flex items-center justify-center">
        <IconSvg width={50} height={50} />
      </div>
      <div className="flex flex-col space-y-2 pb-2">
        <h4 className="font-semibold">{settTitle}</h4>
        <p className="text-sm truncate">{settDetail}</p>
      </div>
    </div>
  );
};

export interface ICompleteSignatureZone {
  signatureSit: ISignReturnType,
  onShareDoct: () => void,
  onViewDoct: (signSit: ISignReturnType) => void,
  onDowanloadDoct: (fileUrlPath: string, fileTitle: string) => void,
  typeSign?: 'staff'|'partner'
}

export const CompleteSignatureZone = ({
  signatureSit,
  onDowanloadDoct,
  onShareDoct,
  onViewDoct,
  typeSign
}: ICompleteSignatureZone) => {
  const oType = typeSign ?? 'staff';

  const signatureName = signatureSit.userSignatureFilePath.split("/").pop();
  const signaturePath = `${oType ==='staff'? appDecl.userSignatures: appDecl.partnerSignatures}/${signatureSit.companyKey}/${signatureSit.userKey}/${signatureName}`;
  const signedDoctPath = `${oType ==='staff'? appDecl.documentsSign: appDecl.documentsPartners}/${signatureSit.companyKey}/${signatureSit.mainOperKey}/signed-${signatureSit.mainOperKey}.pdf`;


  return (
    <div className="-mx-4 flex max-w-[100vw] flex-col items-center overflow-x-hidden px-4 pt-24 md:-mx-8 md:px-8 lg:pt-36 xl:pt-44">
      {/* Signature */}
      <div
        className="relative w-full max-w-xs md:max-w-sm"
        style={{ perspective: "800px" }}
      >
        <div
          className="bg-white w-full rounded-lg"
          style={{
            transformStyle: "preserve-3d",
            backgroundImage:
              "linear-gradient(30deg, transparent, rgba(188,188,188 / 0) 49.98927325204858%, transparent)",
            transform: "none",
          }}
        >
          <div className="text-foreground group relative rounded-lg border-2 backdrop-blur-[2px] gradient-border-mask before:pointer-events-none before:absolute before:-inset-[2px] before:rounded-lg before:p-[2px] group z-10 mx-auto flex aspect-[21/9] w-full items-center justify-center bg-transparent">
            <div className="font-signature p-6 text-center">
              <img alt="" className="h-full max-w-[100%]" src={signaturePath} />
            </div>
          </div>
        </div>
      </div>
      {/* Details */}
      <div className="relative mt-6 flex w-full flex-col items-center">
        <div className="text-archivix-700 flex items-center text-center">
          {signatureSit.doctSignStatus === 1 && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="mr-2 h-5 w-5"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <polyline points="12 6 12 12 8 14"></polyline>
            </svg>
          )}
          {signatureSit.doctSignStatus === 2 && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="mr-2 h-5 w-5"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"></path>
              <path d="m9 12 2 2 4-4"></path>
            </svg>
          )}
          <span className="text-sm font-bold">
            {signatureSit.doctSignStatus === 1
              ? languages.waitOtherSign
              : languages.everyOneSigned}
          </span>
        </div>
        <h2 className="mt-6 maxw35ch text-center text-2xl font-semibold leading-normal md:text-3xl lg:text-4xl">
          {languages.uHvSigned}
          <span className="mt-1.5 block">
            "
            <span className="overflow-hidden truncate">{`${appFxs.shortenText(
              signatureSit.originalDocumentName
            )}`}</span>
            "
          </span>
        </h2>
        <p className="text-muted-foreground/60 mt-2.5 max-w-[60ch] text-center text-sm font-medium md:text-base">
          {signatureSit.doctSignStatus === 2
            ? languages.sentCopySigned
            : languages.receiveCopySigned}
        </p>
        <div className="mt-8 flex w-full max-w-sm items-center justify-center gap-4">
          <button
            className="inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background border border-input hover:bg-[#92E554] hover:text-accent-foreground h-10 py-2 px-2 flex-1 text-[11px]"
            onClick={() => {
              if (signatureSit.doctSignStatus === 2) {
                onViewDoct(signatureSit);
              }
              if (signatureSit.doctSignStatus === 1) {
                onShareDoct();
              }
            }}
          >
            {signatureSit.doctSignStatus === 1 && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="mr-2 h-5 w-5"
              >
                <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z"></path>
                <path d="M5 3v4"></path>
                <path d="M19 17v4"></path>
                <path d="M3 5h4"></path>
                <path d="M17 19h4"></path>
              </svg>
            )}

            {signatureSit.doctSignStatus === 2 && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.7"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="mr-2 h-5 w-5"
              >
                <path d="M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v3"></path>
                <polyline points="14 2 14 8 20 8"></polyline>
                <path d="M5 17a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
                <path d="m9 18-1.5-1.5"></path>
              </svg>
            )}

            <span className="text-black font-bold text-[11px]">
              {signatureSit.doctSignStatus === 2
                ? languages.viewOrigDoct
                : languages.shareSignCard}
            </span>
          </button>

          <button
            className="inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background border border-input hover:bg-[#92E554] hover:text-accent-foreground h-10 py-2 px-2 flex-1 text-[11px]"
            onClick={() => {
              if (signatureSit.doctSignStatus === 1) {
                onViewDoct(signatureSit);
              }
              if (signatureSit.doctSignStatus === 2) {
                onDowanloadDoct(
                  signedDoctPath,
                  signatureSit.originalDocumentName
                );
              }
            }}
          >
            {signatureSit.doctSignStatus === 1 && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.7"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="mr-2 h-5 w-5"
              >
                <path d="M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v3"></path>
                <polyline points="14 2 14 8 20 8"></polyline>
                <path d="M5 17a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
                <path d="m9 18-1.5-1.5"></path>
              </svg>
            )}

            {signatureSit.doctSignStatus === 2 && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="mr-2 h-5 w-5"
              >
                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                <polyline points="7 10 12 15 17 10"></polyline>
                <line x1="12" x2="12" y1="15" y2="3"></line>
              </svg>
            )}
            <span className="text-black font-bold text-[11px]">
              {signatureSit.doctSignStatus === 2
                ? languages.download
                : languages.viewOrigDoct}
            </span>
          </button>
        </div>
        <Link
          to="/app"
          className="text-green-500 hover:text-green-500/80 mt-36 font-bold"
        >
          {languages.goBackHome}
        </Link>
      </div>
    </div>
  );
};

