import imagePlaceHolder from "src/assets/images/image-placeholder.png";
import wordPlaceHolder from "src/assets/images/word-placeholder.png";
import excelPlaceHolder from "src/assets/images/excel-placeholder.png";
import pdfPlaceHolder from "src/assets/images/pdf-placeholder.png";

import getLangs from "src/utils/language";
import { SelectorBtnType } from "src/models/smarttypes";
import { IDropModernOpt } from "src/components/wsedropdown/WiseDropdownModern";

const languages = getLangs();

export interface IEmoji {
  title: string;
  url: string;
  group: "emotions" | "people";
}

export const archiveEmojis: IEmoji[] = [
  {
    title: "smile",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f600/512.webp",
    group: "emotions",
  },
  {
    title: "Smile with big eyes",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f603/512.webp",
    group: "emotions",
  },
  {
    title: "Grin",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f604/512.webp",
    group: "emotions",
  },
  {
    title: "Grinning",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f601/512.webp",
    group: "emotions",
  },
  {
    title: "Laughing",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f606/512.webp",
    group: "emotions",
  },
  {
    title: "Grin sweat",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f605/512.webp",
    group: "emotions",
  },
  {
    title: "Joy",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f602/512.webp",
    group: "emotions",
  },
  {
    title: "Rofl",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f923/512.webp",
    group: "emotions",
  },
  {
    title: "Loudly-crying",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f62d/512.webp",
    group: "emotions",
  },
  {
    title: "Wink",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f609/512.webp",
    group: "emotions",
  },
  {
    title: "Kissing smiling eyes",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f619/512.webp",
    group: "emotions",
  },
  {
    title: "Kissing heart",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f618/512.webp",
    group: "emotions",
  },
  {
    title: "Heart face",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f970/512.webp",
    group: "emotions",
  },
  {
    title: "Heart eyes",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f60d/512.webp",
    group: "emotions",
  },
  {
    title: "Heart struck",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f929/512.webp",
    group: "emotions",
  },
  {
    title: "Partying face",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f973/512.webp",
    group: "emotions",
  },
  {
    title: "Melting",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1fae0/512.webp",
    group: "emotions",
  },
  {
    title: "Upside down face",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f643/512.webp",
    group: "emotions",
  },
  {
    title: "Slightly happy",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f642/512.webp",
    group: "emotions",
  },
  {
    title: "Happy cry",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f972/512.webp",
    group: "emotions",
  },
  {
    title: "Holding back tears",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f979/512.webp",
    group: "emotions",
  },
  {
    title: "Blush",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f60a/512.webp",
    group: "emotions",
  },
  {
    title: "Warm smile",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/263a_fe0f/512.webp",
    group: "emotions",
  },
  {
    title: "Relieved",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f60c/512.webp",
    group: "emotions",
  },
  {
    title: "Smirk",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f60f/512.webp",
    group: "emotions",
  },
  {
    title: "Drool",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f924/512.webp",
    group: "emotions",
  },
  {
    title: "Yum",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f60b/512.webp",
    group: "emotions",
  },
  {
    title: "Stuck out tongue",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f61b/512.webp",
    group: "emotions",
  },
  {
    title: "Squinting tongue",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f61d/512.webp",
    group: "emotions",
  },
  {
    title: "Winky tongue",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f61c/512.webp",
    group: "emotions",
  },
  {
    title: "Zany face",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f92a/512.webp",
    group: "emotions",
  },
  {
    title: "Woozy",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f974/512.webp",
    group: "emotions",
  },
  {
    title: "Pensive",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f614/512.webp",
    group: "emotions",
  },
  {
    title: "Pleading",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f97a/512.webp",
    group: "emotions",
  },
  {
    title: "Grimacing",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f62c/512.webp",
    group: "emotions",
  },
  {
    title: "Expressionless",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f611/512.webp",
    group: "emotions",
  },
  {
    title: "Neutral face",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f610/512.webp",
    group: "emotions",
  },
  {
    title: "Mouth none",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f636/512.webp",
    group: "emotions",
  },
  {
    title: "Face in clouds",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f636_200d_1f32b_fe0f/512.webp",
    group: "emotions",
  },
  {
    title: "Dotted line face",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1fae5/512.webp",
    group: "emotions",
  },
  {
    title: "Zipper face",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f910/512.webp",
    group: "emotions",
  },
  {
    title: "Salute",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1fae1/512.webp",
    group: "emotions",
  },
  {
    title: "Thinking face",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f914/512.webp",
    group: "emotions",
  },
  {
    title: "Shushing face",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f92b/512.webp",
    group: "emotions",
  },
  {
    title: "Hand over mouth",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1fae2/512.webp",
    group: "emotions",
  },
  {
    title: "Smiling eyes with and over mouth",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f92d/512.webp",
    group: "emotions",
  },
  {
    title: "Yawn",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f971/512.webp",
    group: "emotions",
  },
  {
    title: "Hug face",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f917/512.webp",
    group: "emotions",
  },
  {
    title: "Peeking",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1fae3/512.webp",
    group: "emotions",
  },
  {
    title: "Screaming",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f631/512.webp",
    group: "emotions",
  },
  {
    title: "Raised eyebrow",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f928/512.webp",
    group: "emotions",
  },
  {
    title: "Monocle",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f9d0/512.webp",
    group: "emotions",
  },
  {
    title: "Unamused",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f612/512.webp",
    group: "emotions",
  },
  {
    title: "Rolling eyes",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f644/512.webp",
    group: "emotions",
  },

  {
    title: "Dancer wooman",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f483/512.webp",
    group: "people",
  },
  {
    title: "Dancer wooman",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f483_1f3fb/512.webp",
    group: "people",
  },
  {
    title: "Dancer wooman",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f483_1f3fc/512.webp",
    group: "people",
  },
  {
    title: "Dancer wooman",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f483_1f3fd/512.webp",
    group: "people",
  },
  {
    title: "Dancer wooman",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f483_1f3fe/512.webp",
    group: "people",
  },
  {
    title: "Dancer wooman",
    url: "https://fonts.gstatic.com/s/e/notoemoji/latest/1f483_1f3ff/512.webp",
    group: "people",
  },
];

export const sourceImgPlaceholders = [
  imagePlaceHolder,
  pdfPlaceHolder,
  wordPlaceHolder,
  excelPlaceHolder,
];
export const mimeTypes = [
  "image/jpeg,image/png",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
];

export const SignerListTypes: SelectorBtnType[] = [
  {
    icon: "bi bi-wallet2",
    qty: 0,
    type: "all",
    label: languages.all,
  },
  {
    icon: "bi bi-file-earmark-fill",
    qty: 0,
    type: "draft",
    label: languages.draft,
  },
  {
    icon: "bi bi-file-check-fill",
    qty: 0,
    type: "completed",
    label: languages.completed,
  },
  {
    icon: "bi bi-back",
    qty: 0,
    type: "pending",
    label: languages.pending,
  },
  {
    icon: "bi bi-inbox-fill",
    qty: 0,
    type: "inbox",
    label: languages.inbox,
  },
];

export const menuDoctsOpts: IDropModernOpt[] = [
  {
    label: languages.actions,
    clickable: false,
  },
  {
    label: languages.edit,
    clickable: true,
    iconCls: "fa fa-pencil-square",
    enabled: false,
    retType: 0,
  },
  {
    label: languages.sign,
    clickable: true,
    iconCls: "fa fa-pencil",
    enabled: false,
    retType: 1,
  },
  {
    label: languages.download,
    clickable: true,
    iconCls: "fa fa-download",
    enabled: false,
    retType: 2,
  },
  {
    label: languages.duplicate,
    clickable: true,
    iconCls: "fa fa-copy",
    enabled: false,
    retType: 3,
  },
  {
    label: languages.delete,
    clickable: true,
    iconCls: "fa fa-trash",
    enabled: false,
    retType: 4,
  },
  {
    label: languages.share,
    clickable: false,
  },
  {
    label: languages.resend,
    clickable: true,
    iconCls: "fa fa-history",
    enabled: false,
    retType: 5,
  },
  {
    label: languages.shareFile,
    clickable: true,
    iconCls: "fa fa-share",
    enabled: false,
    retType: 6,
  },
  {
    label: languages.complete,
    clickable: true,
    iconCls: "fa fa-check",
    enabled: false,
    retType: 7,
  },
];

export const colorsBn = [
  "#cd9575",
  "#915c83",
  "#008000",
  "#4b5320",
  "#007fff",
  "#8a2be2",
  "#b5a642",
];
