import React, { useState } from "react";
import { IFormError } from "src/models/smarttypes";

import getLangs from "src/utils/language";
import generalFxs from "src/utils/general_fx";
import NetServices from "src/utils/netservices";

const languages = getLangs();
const appFxs = generalFxs();

export default function DeleteCompAcct() {
  const [formError, setFormError] = useState<IFormError>({});
  const [formData, setformData] = useState({
    adminEmail: "",
  });
  const [formStatus, setformStatus] = useState(0);
  const [saving, setSaving] = useState(false);

  const saveOperations = async () => {
    if(formStatus === 1){
        document.location = 'https://archivix.net';
        return;
    }
    const errors:IFormError = {};
    if(!formData.adminEmail || formData.adminEmail.length === 0){
        errors.adminEmail = languages.fldRequired;
    }else if(!appFxs.isEmailValid(formData.adminEmail)){
        errors.adminEmail = languages.emailFormat;
    }
    setFormError(errors)
    if(Object.keys(errors).length > 0) return;

    setSaving(true);

    const oOper = await NetServices.requestPost('general/requestacctdeletion', {
        adminEmail: formData.adminEmail
    });
    if(!oOper.bReturn){
        appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
        setSaving(false);
        return;
    }
    setSaving(false);
    setformStatus(1);
  }

  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <div className="w-full max-w-[500px] border border-neutral-200 rounded-lg flex flex-col justify-center items-center p-3">
        <h3 className="text-lg font-bold">{languages.provideUrEmail}</h3>
        <p className="text-sm text-neutral-400 text-center py-3 px-2">
          {languages.deleteCompaCttDet}
        </p>
        {formStatus === 0 && <input
          value={formData.adminEmail}
          onChange={(e) =>
            setformData({ ...formData, adminEmail: e.target.value })
          }
          disabled={saving}
          type="email"
          className="mt-3 border border-neutral-300 py-3 px-3 w-full rounded-md"
          placeholder={languages.adminEmail.toLowerCase()}
        />}
        {formError.adminEmail && <p className="w-full text-left py-1 text-sm text-red-500">{formError.adminEmail}</p>}
        {formStatus === 1 && <p className="text-center py-3 px-4 bg-neutral-100 text-sm mt-4 rounded-lg">
          {languages.requestDelAcctFinalMsg}
        </p>}

        <div className="mt-3 py-3 w-full">
          <button
            onClick={() => saveOperations()}
            disabled={saving}
            className="hover:bg-green-600 bg-green-400 w-full py-2 rounded-md text-white hover:font-bold font-semibold"
          >
            {!saving && <span>{formStatus === 0 ? languages.requestDelAcct:languages.home}</span>}
            {saving && <span className="fa fa-spin fa-spinner fa-2x"></span>}
          </button>
        </div>
      </div>
    </div>
  );
}
