import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { SmallWiseBtn } from "src/components/ButtonLinkWithIcon";

import generalFxs from "src/utils/general_fx";
import appLanguages from "src/utils/language";
import NetServices from "src/utils/netservices";
import WiseTextInput from "src/components/WiseTextInput";
import appDeclarations from "src/utils/declarations";
import { IArchivixUser, ICompanyRecord } from "src/models/archivix_models";
import CompaniesLstVign from "src/components/CompaniesLstVign";

const appFxs = generalFxs();
const languages = appLanguages();

interface IFormErrors {
  formEmail?: string;
  formPassword?: string;
}

export default function AcctLoginPage() {
  document.title = `${languages.login} | Archivix`;

  const navigate = useNavigate();

  const [loginStep, setLoginStep] = useState(0);
  const [formEmail, setFormEmail] = useState("");
  const [formPassword, setFormPassword] = useState("");
  const [formErrors, setFormErrors] = useState<IFormErrors>({});

  const [serverInUse, setServerInUse] = useState(false);
  const [lstDomains, setLstDomains] = useState<ICompanyRecord[]>([]);

  const onReturnBack = () => {
    setFormPassword("");
    setLoginStep(0);
  };

  const onSendLogin = () => {
    const errors: IFormErrors = {};
    if (!formEmail || formEmail.length === 0) {
      errors.formEmail = languages.fldRequired;
    } else if (!appFxs.isEmailValid(formEmail)) {
      errors.formEmail = languages.emailFormat;
    }

    if (loginStep === 0) {
      setFormErrors(errors);
      if (Object.keys(errors).length > 0) return;
      checkEmailExist();
      return;
    }
    if (loginStep === 1) sendLoginPassword();
  };

  const checkEmailExist = async () => {
    setServerInUse(true);
    
    const oOper = await NetServices.requestPost("account/checkemailexist", {
      userEmail: formEmail,
    });

    const bExist = oOper.isUserExistByEmail!;
    if (!bExist) {
      setFormErrors({ ...formErrors, formEmail: languages.noUserEmail });
      setServerInUse(false);
      return;
    }

    setLoginStep(1);
    setServerInUse(false);
  };

  const sendLoginPassword = async () => {
    const errors: IFormErrors = {};
    if (!formEmail || formEmail.length === 0) {
      errors.formEmail = languages.fldRequired;
    } else if (!appFxs.isEmailValid(formEmail)) {
      errors.formEmail = languages.emailFormat;
    }

    if (!formPassword || formPassword.length === 0) {
      errors.formPassword = languages.fldRequired;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setServerInUse(true);
    const oOper = await NetServices.requestPost("account/loginwithemailpw", {
      userEmail: formEmail,
      userPassword: formPassword,
    });

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setServerInUse(false);
      return;
    }

    if (oOper.typeReturn === 1) {
      onLoginToApp(
        oOper.accessKey!,
        oOper.connectedUser!,
        oOper.connectedCompany!
      );
    } else {
      setLstDomains(oOper.userCompanies!);
      setLoginStep(2);
    }
    setServerInUse(false);
  };

  const onLoginToApp = (
    accessKey: string,
    connectedUser: IArchivixUser,
    connectedCompany: ICompanyRecord
  ) => {
    appFxs.setLocalStorageByKey(appDeclarations.userAccessKey, accessKey);
    appFxs.setLocalStorageByKey(appDeclarations.connectionStatus, "1");

    appFxs.setLocalStorageByKey(
      appDeclarations.connectedUser,
      JSON.stringify(connectedUser)
    );

    appFxs.setLocalStorageByKey(
      appDeclarations.connectedCompany,
      JSON.stringify(connectedCompany)
    );

    navigate("/app");
  };

  const gotoForgot = () => {
    const link = `/account/forgotpw/${window.btoa(formEmail)}`;
    navigate(link);
  };

  const onSelectedDomain = async (e: ICompanyRecord) => {
    setServerInUse(true);
    const oOper = await NetServices.requestPost(
      "account/loginwithemailpwdomain",
      {
        userEmail: formEmail,
        userPassword: formPassword,
        clientDomainKey: e.companyKey,
      }
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setServerInUse(false);
      return;
    }

    onLoginToApp(
      oOper.accessKey!,
      oOper.connectedUser!,
      oOper.connectedCompany!
    );
    setServerInUse(false);
  };

  return (
    <>
      <h3 className="font-bold tracking-wide text-3xl mt-4 w-text-primary">
        {languages.logToYourAcct}
      </h3>
      <strong className="block mt-4 mb-10">
        {languages.dtHaveAcct}&nbsp;
        <Link
          className="font-bold text-blue-600 hover:text-blue-800"
          to={"/account/register"}
        >
          {languages.sign_up}
        </Link>
      </strong>
      <div className="mb-12 gap-6 flex flex-col">
        {loginStep !== 2 && (
          <div className="relative">
            <WiseTextInput
              fldName="email"
              fldType="email"
              label={languages.email}
              placeholder={languages.yourEmailHere}
              graphicType="email"
              disabled={loginStep !== 0}
              textOnDisabled={languages.change}
              actionOnDisabled={onReturnBack}
              value={formEmail}
              onChange={(f, v) => setFormEmail(v)}
              errorText={formErrors.formEmail}
            />
          </div>
        )}

        {loginStep === 1 && (
          <div>
            <WiseTextInput
              fldName="password"
              fldType="password"
              label={languages.password}
              placeholder={languages.yourPassword}
              graphicType="lock"
              value={formPassword}
              onChange={(f, v) => setFormPassword(v)}
              errorText={formErrors.formPassword}
            />
          </div>
        )}

        {loginStep !== 2 && (
          <div>
            <SmallWiseBtn
              label={
                serverInUse
                  ? `${languages.loading}...`
                  : loginStep === 0
                  ? languages.next
                  : languages.login
              }
              onClick={onSendLogin}
            />
          </div>
        )}

        {loginStep === 2 && (
          <div className="relative">
            {serverInUse && (
              <div className="absolute top-0 left-0 w-full h-full bg-black/30 z-10"></div>
            )}
            {serverInUse && (
              <div className="absolute top-0 left-0 w-full h-full bg-black/30 z-10 flex justify-center items-center">
                <i className="fa fa-spinner fa-spin fa-3x text-white"></i>
              </div>
            )}
            <CompaniesLstVign
              companies={lstDomains}
              onSelected={(e) => onSelectedDomain(e)}
            />
          </div>
        )}

        <div className={`${loginStep === 0 ? "hidden" : "flex"} justify-end`}>
          <button
            onClick={gotoForgot}
            className="font-bold text-sm text-blue-600 hover:text-blue-800"
          >
            {languages.forgotPassWord}
          </button>
        </div>
      </div>
    </>
  );
}
