/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";


import generalFxs from 'src/utils/general_fx';
import appLanguages from 'src/utils/language';
import appDeclarations from 'src/utils/declarations';
import NetServices from 'src/utils/netservices';

const appFxs = generalFxs();
const languages = appLanguages();

function HomePage() {
  document.title = `${languages.loading} | ${languages.appName}`;
  const navigate = useNavigate();

  const checkIfConn = async () => {
    const isConnected = appFxs.getLocalStorageByKey(appDeclarations.connectionStatus, '0') === '1';
    if(!isConnected) {
      navigate('/account/login');
      return;
    }
   
    const oRes = await NetServices.requestGet('account/checkifconnected'); 

    if(oRes.bReturn){
      navigate('/app');
    }else{
      navigate('/account/login');
    }
  }

  useEffect(() => {
    checkIfConn();
  }, [])


  return (
    <div className="h-screen flex justify-center items-center">
      <i className="fa fa-spin fa-spinner fa-3x"></i>
    </div>
  )
}

export default HomePage