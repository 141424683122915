import {
  IDocumentFileWrapper,
  IDocumentFolderWrapper,
  ISpecialDocumentRecord,
} from "src/models/smarttypes";

import getLanguages from "src/utils/language";
import appDecl from "src/utils/declarations";

const languages = getLanguages();

export const SpecialDocumentRecord = ({
  docFile,
  operationFolder,
  operationDoct,
}: ISpecialDocumentRecord) => {
  return (
    <>
      {docFile.doctType === "folder" && (
        <DocumentFolderWrapper
          docFile={docFile}
          operationFolder={operationFolder}
        />
      )}
      {docFile.doctType === "file" && (
        <DocumentFileWrapper docFile={docFile} operationDoct={operationDoct} />
      )}
    </>
  );
};

export const DocumentFileWrapper = ({
  docFile,
  operationDoct,
}: IDocumentFileWrapper) => {
  const bgGround = `url(${appDecl.apiPath}/${docFile.documentData?.vigettePath})`;

  return (
    <div className="mr-2 basis-full md:basis-[49%] lg:basis-[32%] h-[150px] p-1  mb-3 border border-slate-200/40 rounded-lg relative overflow-hidden">
      <div
        className="bg-slate-100 w-full h-full rounded-lg overflow-hidden relative z-0"
        style={{
          backgroundImage: bgGround,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      ></div>

      <div className="w-full h-full bg-neutral/30 z-[1] absolute top-0 left-0"></div>

      <div className="absolute top-2 w-full flex justify-between items-center px-2 z-10">
        <div
          className="bg-black/40 p-1 rounded-md flex items-center gap-2 truncate mr-3"
          title={docFile.doctName}
        >
          <i className="bi bi-file-earmark-medical text-white"></i>
          <span className="text-[14px] text-white truncate">
            {docFile.doctName}
          </span>
        </div>

        <div className="flex gap-2 pr-2">
          <div className="flex gap-1" title={languages.messages}>
            <i className="bi bi-chat-text text-[12px] text-white"></i>
            <span className="text-[12px] text-white">
              {docFile.documentData?.chats}
            </span>
          </div>
        </div>
      </div>

      <div className="absolute z-10 w-full bottom-2 flex justify-between px-2">
        <div className="flex items-center gap-2 p-1 bg-white rounded-md px-2">
          <button
            title={languages.previewDoct}
            onClick={() => operationDoct("prev", docFile.companyKey, docFile.doctKey)}
          >
            <i className="bi bi-eye-fill text-sm"></i>
          </button>

          <button
            title={languages.editDocument}
            onClick={() => operationDoct("edit", docFile.companyKey, docFile.doctKey)}
          >
            <i className="bi bi-pencil-fill text-blue-500 text-sm"></i>
          </button>

          <button
            title={languages.deleteDoct}
            onClick={() => operationDoct("del", docFile.companyKey, docFile.doctKey)}
          >
            <i className="bi bi-trash text-red-500 text-sm"></i>
          </button>
        </div>

        <div className="flex items-center gap-2 px-2">
          <span className="text-[12px] text-white font-bold">
            {languages.showDetails}
          </span>
          <button onClick={() => operationDoct("detail", docFile.companyKey, docFile.doctKey)}>
            <i className="bi bi-play-circle-fill text-white"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export const DocumentFolderWrapper = ({
  docFile,
  operationFolder,
}: IDocumentFolderWrapper) => {
  return (
    <div className="mr-2 basis-full md:basis-[49%] lg:basis-[32%] h-[150px] bg-yellow-100 mb-3 relative border border-slate-200/40 rounded-lg">
      <div className="absolute top-2 w-full flex justify-between items-center pl-2">
        <div className="bg-black/40 p-1 rounded-md flex items-center gap-2 truncate mr-2">
          <i className="bi bi-folder-fill text-yellow-300 text-[14px]"></i>
          <span className="text-[14px] text-white truncate">
            {docFile.doctName}
          </span>
        </div>
        <div className="flex gap-2 pr-2">
          <div className="flex gap-1" title={languages.files}>
            <i className="bi bi-file-earmark-medical text-[12px]"></i>
            <span className=" text-[12px]">{docFile.folderData?.files}</span>
          </div>
          <div className="flex gap-1" title={languages.folders}>
            <i className="bi bi-folder text-[12px]"></i>
            <span className="text-[12px]">{docFile.folderData?.folders}</span>
          </div>
        </div>
      </div>

      <div className="absolute w-full bottom-2 flex justify-between px-2">
        <div className="flex items-center gap-2">
          <button
            title={languages.deleteFolder}
            onClick={() => operationFolder("del", docFile.doctKey)}
          >
            <i className="bi bi-trash text-red-500 text-sm"></i>
          </button>
          <button
            title={languages.editFolder}
            onClick={() => operationFolder("edit", docFile.doctKey)}
          >
            <i className="bi bi-pencil-fill text-blue-500 text-sm"></i>
          </button>
        </div>
        <div className="flex items-center gap-2">
          <span className="text-[12px]">{languages.showDetails}</span>
          <button onClick={() => operationFolder("prev", docFile.doctKey)}>
            <i className="bi bi-play-circle-fill"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

