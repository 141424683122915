/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";

import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";
import appDecl from "src/utils/declarations";
import NetServices from "src/utils/netservices";

import { DocumentSignElt } from "src/models/smarttypes";
import { useNavigate } from "react-router-dom";
import { GroupButtonSignerSelector } from "src/components/group-button";
import {
  WiseSelectInput,
} from "src/components/majorsmallcomps";
import { FilesReceiverSignDrop } from "src/components/files_receiver_drop";
import { menuDoctsOpts } from "src/utils/app_arch_data";


import { WiseModalDropdown } from "src/components/modalportal/WiseModal";
import { IDropModernOpt } from "src/components/wsedropdown/WiseDropdownModern";
import { WisePagination } from "src/components/wpagination/WisePagination";
import { DocumentRowElt } from "src/components/sign_comps";

const languages = getLanguages();
const appFxs = getFxs();
const todayDate = new Date();
const monthsOptions = appFxs.wdMonthsLng.map((o, i) => {
  const f = {
    label: o,
    value: i + 1,
  };

  return f;
});
const PageSize = Number(appFxs.getLocalStorageByKey(appDecl.itemsPerPage, '9'));

export default function SignaturesDoctsPage() {
  document.title = `${languages.signDocts} - Archivix`;

  const navigate = useNavigate();

  const [filterType, setFilterType] = useState(0);
  const [isFileLoading, setFileLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [month, setMonth] = useState((todayDate.getMonth() + 1).toString());

  const [filteredDocts, setFilteredDocts] = useState<DocumentSignElt[]>([]);
  const [lstAllDocts, setLstAllDocts] = useState<DocumentSignElt[]>([]);
  const [selectedRecord, setSelectedRecord] = useState<DocumentSignElt | null>(
    null
  );

  const [dropDownSize, setDropDownSize] = useState({
    clientWidth: 0,
    clientHeight: 0,
    clientX: 0,
    clientY: 0,
    isOpen: false,
  });

  const [menuOpts, setMenuOpts] = useState<IDropModernOpt[]>([]);

  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return filteredDocts.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, filteredDocts]);

  const filterDocuments = (lstDocs: DocumentSignElt[]) => {
    if (filterType === 0) {
      setFilteredDocts([...lstDocs]);
      return;
    }
    if (filterType === 4) {
      const f = lstDocs.filter((o) => o.inbox === true);

      setFilteredDocts([...f]);
      return;
    }

    const oStatus: "draft" | "pending" | "completed" =
      filterType === 1
        ? "draft"
        : filterType === 2
        ? "completed"
        : filterType === 3
        ? "pending"
        : "completed";

    const filtered = [...lstDocs].filter((o) => o.status === oStatus);
    setFilteredDocts([...filtered]);
  };

  const populateListOfData = async () => {
    setLoading(true);

    const oOper = await NetServices.requestGet(
      `signdocs/getlistofsigndocts/${month}`
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setLoading(false);
      return;
    }

    const lstDocs = oOper.lstSignDocuments!;

    setFilteredDocts(lstDocs);
    setLstAllDocts(lstDocs);

    filterDocuments(lstDocs);
    setLoading(false);
  };

  const afterDropFile = (file: File) => {
    processFileTosign(file);
  };
  const processFileTosign = async (file: File) => {
    const dataToFile = new FormData();
    dataToFile.append("archiveFoctTosign", file);
    dataToFile.append("fileTitle", file.name);
    dataToFile.append("fileSize", file.size.toString());

    setFileLoading(true);

    const oOper = await NetServices.requestPostWithFileAxios(
      "signdocs/postdocumentforsign",
      dataToFile
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setFileLoading(false);
      return;
    }

    const fileOperKey = oOper.generatedSignDocumentKey!;

    navigate(`/app/signdocs/editor/${fileOperKey}`);
  };
  const openMenu = (
    d: DocumentSignElt,
    x: number,
    y: number,
    w: number,
    h: number
  ) => {
    const lst: IDropModernOpt[] = [];
    const allLst = [...menuDoctsOpts];
    setSelectedRecord(d);

    //actions
    lst.push(allLst[0]);

    //edit
    lst.push({
      ...allLst[1],
      enabled: d.action === "edit",
    });

    //sign
    lst.push({ ...allLst[2], enabled: d.action === "sign" });

    //download
    lst.push({ ...allLst[3], enabled: d.action === "download" });

    //complete
    lst.push({ ...allLst[9], enabled: d.action === "complete" });

    //duplicate
    lst.push({ ...allLst[4], enabled: true });

    //delete
    lst.push({ ...allLst[5], enabled: d.meOwner && d.status === "draft" });

    //share - title
    lst.push({ ...allLst[6], enabled: true });

    //resend
    lst.push({ ...allLst[7], enabled: d.meOwner && d.status !== "completed" });

    //share_files
    lst.push({ ...allLst[8], enabled: d.meOwner && d.status === "completed" });

    setMenuOpts((prev) => {
      return lst;
    });

    setDropDownSize({
      ...dropDownSize,
      clientHeight: h,
      clientWidth: w,
      clientX: x,
      clientY: y,
      isOpen: true,
    });
  };
  const onActionClick = (
    action: "download" | "edit" | "sign" | "complete" | undefined,
    data: DocumentSignElt
  ) => {
    if (action === "edit") {
      editFileByKey(data.fileKey);
    } else if (action === "sign") {
      signDocumentProcessByKey(data.fileKey);
    } else if (action === "download") {
      downloadDocumentProcessByKey(data);
    }else{
      editFileByKey(data.fileKey);
    }
  };
  const onClickFile = (
    action: "download" | "edit" | "sign" | "complete" | undefined,
    data: DocumentSignElt
  ) => {
    if (action === "edit") {
      editFileByKey(data.fileKey);
    } else if (action === "sign") {
      signDocumentProcessByKey(data.fileKey);
    } else if (action === "download") {
      displayFinalDocument(data);
    }
  };
  const displayFinalDocument = async (data: DocumentSignElt) => {
    const path = `/app/signdocs/preview/${data.fileKey}`;
    navigate(path);
  }

  const editFileByKey = (fileKey: string) => {
    navigate("/app/signdocs/editor/" + fileKey);
  };
  const signDocumentProcessByKey = async (fileKey: string) => {
    const oOper = await NetServices.requestGet(
      `signdocs/getdocumentforsignstartprocess/${fileKey}`,
      true
    );
    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    window
      .open(
        `${appDecl.webAppPath}/sign/signoperation/${oOper.signerOperationKey}`,
        "_blank"
      )
      ?.focus();
  };
  const downloadDocumentProcessByKey = async (data: DocumentSignElt) => {
    const filePath = `${appDecl.documentsSign}/${data.companyKey}/${data.fileKey}/signed-${data.fileKey}.pdf`;

    await appFxs.downloadFromFileUrl(filePath, `${selectedRecord?.title}.pdf`);
  };

  const afterCloseDialog = (data?: DocumentSignElt, e?: number) => {
    setDropDownSize({ ...dropDownSize, isOpen: false });

    if (e === undefined) return;
    if (data === undefined) return;

    if (e === 0) editFileByKey(selectedRecord!.fileKey);
    if (e === 1) signDocumentProcessByKey(selectedRecord!.fileKey);

    if (e === 2) downloadDocumentProcessByKey(data);
    if (e === 3) duplicateFileByKey(selectedRecord!.fileKey);
    if (e === 4) deleteFileByKey(selectedRecord!.fileKey);
    if (e === 5) resendSignProcessFileByKey(selectedRecord!.fileKey);
    if (e === 6) shareDoctByKey(data);
    if (e === 7) completeDoctByKey(selectedRecord!.fileKey);
  };
  const duplicateFileByKey = async (fileKey: string) => {
    const bAsk = await appFxs.showConfirm(
      languages.duplicateFile,
      languages.duplicateFileMsg
    );
    if (!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPost(
      "signdocs/duplicatedocumentforsign",
      {
        fileOperKey: fileKey,
      },
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    const fileOperKey = oOper.generatedSignDocumentKey!;

    navigate(`/app/signdocs/editor/${fileOperKey}`);
  };
  const resendSignProcessFileByKey = async (fileKey: string) => {
    const bAsk = await appFxs.showConfirm(
      languages.resendSignReq,
      languages.resendSignReqMsg
    );
    if (!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPost(
      "signdocs/resenddocumentforsign",
      {
        periodMonth: Number(month),
        fileOperKey: fileKey,
      },
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }
  };
  const deleteFileByKey = async (fileKey: string) => {
    const bAsk = await appFxs.showConfirm(
      languages.deleteFile,
      languages.deleteFileMsg
    );
    if (!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPost(
      "signdocs/deletedocumentforsign",
      {
        periodMonth: Number(month),
        fileOperKey: fileKey,
      },
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    const lstDocs = oOper.lstSignDocuments!;

    setFilteredDocts(lstDocs);
    setLstAllDocts(lstDocs);

    filterDocuments(lstDocs);
  };
  const shareDoctByKey = async (data: DocumentSignElt) => {
    const filePath = `${appDecl.documentsSign}/${data.companyKey}/${data.fileKey}/signed-${data.fileKey}.pdf`;

    appFxs.shareFilesFromUrl(filePath)

    
  };
  const completeDoctByKey = async (fileKey: string) => {
    const bAsk = await appFxs.showConfirm(
      languages.deleteFile,
      languages.deleteFileMsg
    );
    if (!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPost(
      "signdocs/deletedocumentforsign",
      {
        periodMonth: Number(month),
        fileOperKey: fileKey,
      },
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    const lstDocs = oOper.lstSignDocuments!;

    setFilteredDocts(lstDocs);
    setLstAllDocts(lstDocs);

    filterDocuments(lstDocs);
  };

  useEffect(() => {
    populateListOfData();
  }, [month]);

  useEffect(() => {
    filterDocuments(lstAllDocts);
  }, [filterType]);



  return (
    <>
      <WiseModalDropdown
        clientHeight={dropDownSize.clientHeight}
        clientWidth={dropDownSize.clientWidth}
        clientX={dropDownSize.clientX}
        clientY={dropDownSize.clientY}
        isOpen={dropDownSize.isOpen}
        position="right"
        options={menuOpts}
        onClose={(d,e) => afterCloseDialog(d,e)}
        dataRecord={selectedRecord!}
      />

      <div className="flex flex-col space-y-2">
        <div className="flex items-center flex-wrap">
          <h3 className="text-lg flex-1">{languages.doctToSign}</h3>
          <div className="flex items-center flex-wrap gap-2">
            <GroupButtonSignerSelector updateStatus={(e) => setFilterType(e)} />
            <WiseSelectInput
              onChange={(e) => setMonth(e.target.value)}
              selValue={month}
              vOptions={monthsOptions}
            />
          </div>
        </div>
        <div className="mt-2 md:mt-5">
          <FilesReceiverSignDrop
            disabled={isLoading}
            isLoading={isFileLoading}
            afterDropFile={afterDropFile}
          />
        </div>

        <div className="mt-8">
          <div className="relative">
            <div className="rounded-md border">
              <div className="w-full overflow-auto">
                {/* Table */}
                <table className="table w-full caption-bottom text-sm">
                  <thead className="[&_tr]:border-b">
                    <tr className="hover:bg-slate-200/10 data-[state=selected]:bg-muted border-b transition-colors">
                      <th className="hidden lg:table-cell text-black h-12 px-4 text-left align-middle font-medium [&:has([role=checkbox])]:pr-0">
                        {languages.created}
                      </th>
                      <th className="text-black h-12 px-4 text-left align-middle font-medium [&:has([role=checkbox])]:pr-0">
                        {languages.title}
                      </th>
                      <th className="hidden lg:table-cell text-black h-12 px-4 text-left align-middle font-medium [&:has([role=checkbox])]:pr-0">
                        {languages.recipient}
                      </th>
                      <th className="text-black h-12 px-4 text-left align-middle font-medium [&:has([role=checkbox])]:pr-0">
                        {languages.status}
                      </th>
                      <th className="text-black h-12 px-4 text-left align-middle font-medium [&:has([role=checkbox])]:pr-0">
                        {languages.actions}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="last:border-0">
                    {isLoading && (
                      <tr className="hover:bg-muted/50 data-[state=selected]:bg-muted border-b transition-colors">
                        <td
                          colSpan={5}
                          className="hidden lg:table-cell truncate p-4 align-middle [&:has([role=checkbox])]:pr-0"
                        >
                          <div className="w-full flex flex-col space-y-2 justify-center items-center">
                            <i className="fa fa-spin fa-spinner fa-3x"></i>
                            <h3>{languages.loadingFiles}...</h3>
                          </div>
                        </td>
                      </tr>
                    )}

                    {!isLoading && filteredDocts.length === 0 && (
                      <tr className="hover:bg-muted/50 data-[state=selected]:bg-muted border-b transition-colors">
                        <td
                          colSpan={5}
                          className="hidden lg:table-cell truncate p-4 align-middle [&:has([role=checkbox])]:pr-0"
                        >
                          <div className="w-full flex flex-col space-y-2 justify-center items-center">
                            <h3>{languages.noRecFnd}</h3>
                          </div>
                        </td>
                      </tr>
                    )}

                    {!isLoading &&
                      filteredDocts.length > 0 &&
                      currentTableData.map((oRow, index) => (
                        <DocumentRowElt
                          openMenu={() => openMenu(oRow, 0, 0, 0, 0)}
                          onActionClick={onActionClick}
                          key={index}
                          rowData={oRow}
                          onClickFile={onClickFile}
                        />
                      ))}
                  </tbody>
                </table>
                {/* Table */}
                <WisePagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={filteredDocts.length}
                  pageSize={PageSize}
                  onPageChange={page => setCurrentPage(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}




