/* eslint-disable jsx-a11y/anchor-is-valid */
import ReactDom from "react-dom";
import archivixLogo from "src/assets/images/archivix_logo_black_white_v2.png";
import appLang from "src/utils/language";

const languages = appLang();
const todayDate = new Date();

export type rmenuType = "home" | "dashboard" | "documents" | "search" | "signatures" | "partners";
interface IRightSideAppMenu {
  isOpen: boolean;
  closeMenu: (menu?: rmenuType) => void;
}

export const RightSideAppMenu = ({ isOpen, closeMenu }: IRightSideAppMenu) => {
  const nodeElement = document.getElementById("portals")!;

  if (!isOpen) return null;
  return ReactDom.createPortal(
    <>
      <div className="fixed inset-0 z-[61] flex justify-end">
        <div
          onClick={() => closeMenu()}
          className="bg-background/80 data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:fade-in fixed inset-0 z-[61] backdrop-blur-sm transition-all duration-100"
          data-aria-hidden="true"
          style={{ pointerEvents: "auto" }}
        ></div>
        <div
          className="fixed z-[61] scale-100 gap-4 bg-background p-6 opacity-100 shadow-lg border animate-in slide-in-from-right h-full duration-300 flex w-full max-w-[400px] flex-col"
          style={{ pointerEvents: "auto" }}
          tabIndex={-1}
        >
          <a onClick={() => closeMenu("home")} className="">
            <img
              width={170}
              height={25}
              src={archivixLogo}
              alt=""
            />
          </a>
          <div className="mt-8 flex w-full flex-col items-start gap-y-4">
            <a
              onClick={() => closeMenu("dashboard")}
              className="text-foreground hover:text-foreground/80 text-xl font-semibold"
            >
              {languages.dashboard}
            </a>
            <a
              onClick={() => closeMenu("documents")}
              className="text-foreground hover:text-foreground/80 text-xl font-semibold"
            >
              {languages.documents}
            </a>
            <a
              onClick={() => closeMenu("search")}
              className="text-foreground hover:text-foreground/80 text-xl font-semibold"
            >
              {languages.search}
            </a>
            <a
              onClick={() => closeMenu("signatures")}
              className="text-foreground hover:text-foreground/80 text-xl font-semibold"
            >
              {languages.signatures}
            </a>
            <a
              onClick={() => closeMenu("partners")}
              className="text-foreground hover:text-foreground/80 text-xl font-semibold"
            >
              {languages.partners}
            </a>
          </div>

          <div className="mt-auto flex w-full flex-col space-y-4 self-end">
            <p className="text-muted-foreground text-sm">
              &copy; {todayDate.getFullYear()} <strong>WiseObjects Ltd.</strong> {languages.all_rights_reserved}
            </p>
          </div>
          <button
            onClick={() => closeMenu()}
            type="button"
            className="ring-offset-background focus:ring-ring data-[state=open]:bg-secondary absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="h-4 w-4"
            >
              <path d="M18 6 6 18"></path>
              <path d="m6 6 12 12"></path>
            </svg>
            <span className="sr-only">{languages.close}</span>
          </button>
        </div>
      </div>
    </>,
    nodeElement
  );
};
