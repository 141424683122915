import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import { themeChange } from 'theme-change';


import AccountLayout from './pages/layout/_account';
import HomePage from './pages/HomePage';
import AcctLoginPage from './pages/account/AcctLoginPage';


//import AppLayout from './pages/layout/_applayout';
import DashboardPage from './pages/app/DashboardPage';
import AcctRegisterPage from './pages/account/AcctRegisterPage';
import AcctForgotPw from './pages/account/AcctForgotPw';
import AcctResetPwPage from './pages/account/AcctResetPwPage';
import initializeApp from './utils/app/initApp';
//import checkAuth from './utils/app/authApp';
import MyProfilePage from './pages/app/settings/MyProfilePage';
import AppSettsLayout from './pages/layout/_settingslayout';
import ListUsersPage from './pages/app/settings/ListUsersPage';
import PreviewUser from './pages/app/settings/PreviewUser';
import NewUserPage from './pages/app/settings/NewUserPage';
import AcctJoinTeamPage from './pages/account/AcctJoinTeamPage';
import PreviewFolderPage from './pages/app/PreviewFolderPage';
import PreviewDocumentFilePage from './pages/app/PreviewDocumentFilePage';
import SearchDoctsPage from './pages/app/SearchDoctsPage';
import SignaturesDoctsPage from './pages/app/SignaturesDoctsPage';
import NewDocumentPage from './pages/app/NewDocumentPage';
import EditorSignDoctPage from './pages/app/EditorSignDoctPage';
import SignLayout from './pages/layout/_signlayout';
import RequestSignOperPage from './pages/sign/RequestSignOperPage';
import NewAppLayout from './pages/layout/_newapplayout';
import PartnersOpsPage from './pages/app/PartnersOpsPage';
import DocumentEditorPage from './pages/app/DocumentEditorPage';
import ListDocumentsPage from './pages/app/ListDocumentsPage';
import CompanySettingsPage from './pages/app/settings/CompanySettingsPage';
import CompanySettingsStampPage from './pages/app/settings/CompanySettingsStampPage';
import CompanySettingsSignaturePage from './pages/app/settings/CompanySettingsSignaturePage';
import CompanySettingsDetailPage from './pages/app/settings/CompanySettingsDetailPage';
import AppSettingsPage from './pages/app/settings/AppSettingsPage';
import PreviewCompleteSignDoctPage from './pages/sign/PreviewCompleteSignDoctPage';
import PreviewSignDoctPage from './pages/app/PreviewSignDoctPage';
import EditorPartnerDoctPage from './pages/app/EditorPartnerDoctPage';
import RequestSignOperPartnerPage from './pages/partners/RequestSignOperPartnerPage';
import PreviewPartnerDoctPage from './pages/app/PreviewPartnerDoctPage';
import PreviewCompletePartnerDoctPage from './pages/partners/PreviewCompletePartnerDoctPage';
import DeleteCompAcct from './pages/app/DeleteCompAcct';


// Initializing different libraries
initializeApp();

// Check for login and initialize axios
//const token = checkAuth();

function App() {
  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false)
  }, [])

  
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/deleteaccount" element={<DeleteCompAcct />} />
      <Route path="/account" element={<AccountLayout />}>
        <Route index element={<AcctLoginPage  />} />
        <Route path="login" element={<AcctLoginPage />} />
        <Route path="register" element={<AcctRegisterPage />} />
        <Route path="forgotpw/:forgotPwEmail" element={<AcctForgotPw />} />
        <Route path="resetpassword/:resetKey" element={<AcctResetPwPage />} />
        <Route path="jointeam/:joinKey" element={<AcctJoinTeamPage />} />
      </Route>
      <Route path="/app" element={<NewAppLayout />}>
        <Route index element={<DashboardPage  />} />
        <Route path="dashboard" element={<DashboardPage />} />
        
        <Route path="documents" element={<ListDocumentsPage />} />
        <Route path="documents/new" element={<NewDocumentPage />} />
        <Route path="documents/new/:folderKey" element={<NewDocumentPage />} />
        
        <Route path="documents/edit/:fileKey" element={<DocumentEditorPage />} />
        <Route path="documents/edit/:folderKey/:fileKey" element={<DocumentEditorPage />} />

        <Route path="documents/folders/:folderKey" element={<PreviewFolderPage />} />

        <Route path="documents/previewdoct/:fileKey" element={<PreviewDocumentFilePage />} />
        <Route path="documents/previewdoct/:folderKey/:fileKey" element={<PreviewDocumentFilePage />} />


        <Route path="myprofile" element={<MyProfilePage />} />
        <Route path="search" element={<SearchDoctsPage />} />

        <Route path="signdocs" element={<SignaturesDoctsPage />} />
        <Route path="signdocs/editor/:fileKey" element={<EditorSignDoctPage />} />
        <Route path="signdocs/preview/:fileKey" element={<PreviewSignDoctPage />} />

        <Route path="partners" element={<PartnersOpsPage />} />
        <Route path="partners/editor/:fileKey" element={<EditorPartnerDoctPage />} />
        <Route path="partners/preview/:fileKey" element={<PreviewPartnerDoctPage />} />


        <Route path="/app/settings" element={<AppSettsLayout />}>
          <Route index element={<ListUsersPage  />} />
          <Route path="users" element={<ListUsersPage  />} />
          <Route path="users/preview/:userKey" element={<PreviewUser  />} />
          <Route path="users/newuser" element={<NewUserPage  />} />


          <Route path="settings" element={<AppSettingsPage  />} />
          <Route path="company" element={<CompanySettingsPage  />} />
          <Route path="company/stamp" element={<CompanySettingsStampPage  />} />
          <Route path="company/signature" element={<CompanySettingsSignaturePage  />} />
          <Route path="company/detail" element={<CompanySettingsDetailPage  />} />
        </Route>


      </Route>

      <Route path="/sign" element={<SignLayout />}>
        <Route path="signoperation/:signoperationkey" element={<RequestSignOperPage />} />
        <Route path="previewcomplete/:companykey/:signoperationkey" element={<PreviewCompleteSignDoctPage />} />
      </Route>

      <Route path="/partners" element={<SignLayout />}>
        <Route path="signoperation/:signoperationkey" element={<RequestSignOperPartnerPage />} />
        <Route path="previewcomplete/:companykey/:signoperationkey" element={<PreviewCompletePartnerDoctPage />} />
      </Route>

    </Routes>
  );
}

export default App;
