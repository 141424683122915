import appDecl from "./declarations";

const getAppLang = (): string => {
  const elt = localStorage.getItem(appDecl.currentLang);
  return elt ?? "en";
};

const retLang = (
  enCont: string,
  frCont: string,
  rwCont: string = ""
): string => {
  const lang = getAppLang();

  return lang === "en"
    ? enCont
    : lang === "fr"
    ? frCont
    : lang === "rw"
    ? rwCont
    : enCont;
};

function GetLanguage() {
  return {
    requestDelAcctFinalMsg: retLang(
      "You have requested the deletion of your Archivix account. We will review your situation within two business days. We will send you a link in your email. By clicking on this link, you will have confirmed the total deletion of your data.",
      "Vous avez demandé la suppression de votre compte Archivix. Nous examinerons votre situation dans un délai de deux jours ouvrables. Nous vous enverrons un lien dans votre e-mail. En cliquant sur ce lien, vous aurez confirmé la suppression totale de vos données.",
      ""
    ),
    requestDelAcct: retLang(
      "Request deletion",
      "Demander suppression",
      ""
    ),
    adminEmail: retLang(
      "Administraor email",
      "Email administrateur",
      ""
    ),
    deleteCompaCttDet: retLang(
      "You're about to delete your company account. Make sure you have sufficient rights to run this command.",
      "Vous êtes sur le point de supprimer votre compte d'entreprise. Assurez-vous que vous disposez des droits suffisants pour exécuter cette commande.",
      ""
    ),
    provideUrEmail: retLang(
      "Provide your administrator email",
      "Fournissez votre email d'administrateur",
      ""
    ),
    capitalLetters: retLang(
      "Capital letters",
      "Lettres capitales",
      ""
    ),
    smallLetters: retLang(
      "Small letters",
      "Petites lettes",
      ""
    ),
    cantShareDev: retLang(
      "This device cannot share files.",
      "Cet appareil ne partage pas les fichiers.",
      ""
    ),
    error: retLang("Error", "Erreur", ""),
    loading: retLang("Loading", "Chargement", ""),
    login: retLang("Login", "Connection", ""),
    loggin: retLang("Logging In", "Connection", ""),

    password: retLang("Password", "Mot de passe", ""),
    signInTo: retLang("Sign in to", "Connectez-vous à", ""),
    signIn: retLang("Sign in", "Connection", ""),
    signOut: retLang("Sign out", "Se deconnecter", ""),
    signOutMsg: retLang(
      "Do you want to sign out?",
      "Voulez-vous vous déconnecter?",
      ""
    ),
    rememberMe: retLang("Remember me", "Souviens-toi de moi", ""),
    forgotPassWord: retLang("Forgot password?", "Mot de passe oublié?", ""),
    appName: retLang("Archivix", "Archivix", ""),
    dashboard: retLang("Dashboard", "Tableau de bord", ""),
    blog: retLang("Blog", "Journal", ""),
    socialevents: retLang("Social events", "Evénements sociaux", ""),
    messages: retLang("Messages", "Messages", ""),
    events: retLang("Events", "Evénements", ""),
    calendar: retLang("Calendar", "Calendrier", ""),
    menu: retLang("Menu", "Menu", ""),

    emailEx: retLang("you@example.com", "vous@exemple.com", ""),
    reset: retLang("Reset", "Réinitialiser", ""),
    logInInstead: retLang(
      "I have account, log in instead",
      "J'ai déja un compte. Veuillez me connecter",
      ""
    ),
    resetPassword: retLang(
      "Reset your password",
      "Modifier votre mot de passe",
      ""
    ),
    setPasswordWithNew: retLang(
      "Set your password with a new one",
      "Configurez votre mot de passe avec un nouveau",
      ""
    ),
    confirmPassword: retLang(
      "Confirm your password",
      "Confirmer votre mot de passe",
      ""
    ),
    confirmPaswordError: retLang(
      "Your password does not match. Kindly confirm your password.",
      "Votre mot de passe ne correspond pas. Veuillez confirmer votre mot de passe.",
      ""
    ),
    pdf: retLang("Pdf", "Pdf", ""),
    word: retLang("Word", "Word", ""),
    weak: retLang("Weak", "Faible", ""),
    good: retLang("Good", "Bon", ""),
    strong: retLang("Strong", "Fort", ""),
    very_strong: retLang("Very strong", "Très fort", ""),
    passwordReqEmailSent: retLang(
      "Waiting for password reset",
      "En attente de la réinitialisation du mot de passe",
      ""
    ),
    checkUrEmail: retLang(
      "Check your email",
      "Vérifiez votre courrier électronique.",
      ""
    ),
    checkUrEmailPasswordReset: retLang(
      "Check your email for password reset process.",
      "Vérifiez votre courrier électronique pour le processus de réinitialisation du mot de passe.",
      ""
    ),

    deleteImgQst: retLang(
      "Do you want to remove this image?",
      "Voulez-vous supprimer cette image?",
      ""
    ),
    deletePdfQst: retLang(
      "Do you want to remove this PDF file?",
      "Voulez-vous supprimer ce fichier PDF?",
      ""
    ),
    deleteWordQst: retLang(
      "Do you want to remove this word file?",
      "Voulez-vous supprimer ce fichier Word?",
      ""
    ),

    specialChars: retLang("Special characters", "Caractères spéciaux", ""),
    users: retLang("Users", "Utilisateurs", ""),
    networkError: retLang("Network error", "Erreur de réseau", ""),
    networkErrorMsg: retLang(
      "There is a network error on this device. Check this issue and try again.",
      "Il y a une erreur réseau sur cet appareil. Vérifiez ce problème et réessayez.",
      ""
    ),
    fldRequired: retLang(
      "This field is required",
      "Ce champ est obligatoire",
      ""
    ),
    codeBeenSent: retLang(
      "A code has been sent to your email",
      "Un code a été envoyé à votre email",
      ""
    ),
    product: retLang("Product", "Produit", ""),
    productMsg: retLang(
      "You can bring some changes to this product and change its images.",
      "Vous pouvez apporter quelques modifications à ce produit et modifier ses images.",
      ""
    ),
    emailFormat: retLang(
      "The email format seems wrong.",
      "Le format de l'e-mail semble incorrect.",
      ""
    ),
    passLengthMsg: retLang(
      "Password must be at least 8 characters.",
      "Le mot de passe doit comporter au moins 8 caractères.",
      ""
    ),
    digitchars: retLang("Digit characters","Caractères numériques",""),
    passLength: retLang(
      "Password length",
      "Longeur du mot de passe",
      ""
    ),
    passValidMsg: retLang(
      "The password strength is too weak to be valid.",
      "Le niveau de sécurité du mot de passe est trop faible pour être valide.",
      ""
    ),
    sameAsPasswordMsg: retLang(
      "You are unable to confirm your password correctly.",
      "Vous n'arrivez pas à confirmer votre mot de passe correctement.",
      ""
    ),

    phonenbrLengthMsg: retLang(
      "The phone number must be 12 caracters long.",
      "Le numéro de téléphone doit être de 12 caractères.",
      ""
    ),

    jan_lng: retLang("January", "Janvier", "Mutarama"),
    feb_lng: retLang("February", "Février", "Gashyantare"),
    mar_lng: retLang("March", "Mars", "Werurwe"),
    apr_lng: retLang("April", "Avril", "Mata"),
    may_lng: retLang("May", "Mai", "Gicurasi"),
    jun_lng: retLang("Jun", "Juin", "Kamena"),
    jul_lng: retLang("July", "Juillet", "Nyakanga"),
    aug_lng: retLang("August", "Août", "Kanama"),
    sept_lng: retLang("September", "Septembre", "Nzeri"),
    oct_lng: retLang("October", "Octobre", "Ukwakira"),
    nov_lng: retLang("November", "Novembre", "Ugushyingo"),
    dec_lng: retLang("December", "Decembre", "Ukuboza"),

    jan_shrt: retLang("Jan", "Janr", "Muta"),
    feb_shrt: retLang("Feb", "Fév", "Gashy"),
    mar_shrt: retLang("Mar", "Mars", "Weru"),
    apr_shrt: retLang("Apr", "Avr", "Mata"),
    may_shrt: retLang("May", "Mai", "Gicu"),
    jun_shrt: retLang("Jun", "Juin", "Kame"),
    jul_shrt: retLang("July", "Juil", "Nyaka"),
    aug_shrt: retLang("Aug", "Août", "Kana"),
    sept_shrt: retLang("Sept", "Sept", "Nzeri"),
    oct_shrt: retLang("Oct", "Oct", "Ukwa"),
    nov_shrt: retLang("Nov", "Nov", "Ugus"),
    dec_shrt: retLang("Dec", "Dec", "Uku"),

    monday_lng: retLang("Monday", "Lundi", ""),
    tuesday_lng: retLang("Tuesday", "Mardi", ""),
    wednday_lng: retLang("Wednesday", "Mercredi", ""),
    thursday_lng: retLang("Thursday", "Jeudi", ""),
    friday_lng: retLang("Friday", "Vendredi", ""),
    saturday_lng: retLang("Saturday", "Samedi", ""),
    sunday_lng: retLang("Sunday", "Dimance", ""),

    monday_shrt: retLang("Mon", "Lun", ""),
    tuesday_shrt: retLang("Tue", "Mar", ""),
    wednday_shrt: retLang("Wed", "Mer", ""),
    thursday_shrt: retLang("Thu", "Jeu", ""),
    friday_shrt: retLang("Fri", "Ven", ""),
    saturday_shrt: retLang("Sat", "Sam", ""),
    sunday_shrt: retLang("Sun", "Dim", ""),

    other: retLang("Other", "Autre", ""),
    activate: retLang("Activate", "Activer", ""),
    deactivate: retLang("Disable", "Désactiver", ""),
    activate_qst: retLang(
      "Would you like to activate this user?",
      "Voudriez-vous activer cet utilisateur?",
      ""
    ),
    deactivate_qst: retLang(
      "Would you like to Disable this user?",
      "Voudriez-vous désactiver cet utilisateur?",
      ""
    ),
    activated: retLang("Activated", "Activé", ""),
    deactivated: retLang("Disabled", "Désactivé", ""),
    active: retLang("Active", "Actif", ""),
    inactive: retLang("Inactive", "Inactif", ""),
    status: retLang("Status", "Etat", ""),

    sign_up: retLang("Sign Up", "S'inscrire", ""),
    sign_out: retLang("Sign out", "Se déconnecter", ""),

    print_prev: retLang("Print preview", "Aperçu avant impression", ""),
    just_print: retLang("Just print", "Imprimer", ""),
    export_to_msword: retLang("Export to Ms Word", "Exporter vers Ms Word", ""),
    export_to_msexcel: retLang(
      "Export to Ms Excel",
      "Exporter vers Ms Excel",
      ""
    ),
    export_to_pdf: retLang("Export to PDF", "Exporter vers PDF", ""),
    export_to_image: retLang("Export to Image", "Exporter vers l'image", ""),
    compose_email_content: retLang(
      "Compose email content",
      "Ecrire le contenu du message",
      ""
    ),
    email_recipient: retLang("Email recipient", "Destinataire du courriel", ""),
    email_subject: retLang("Email subject", "Objet du message", ""),
    email_content: retLang("Email content", "Contenu du message", ""),
    compose_email: retLang("Compose email", "Composer un email", ""),
    add_recipient: retLang("Add recipient", "Ajouter destinataire", ""),
    from: retLang("From", "De", ""),
    subject: retLang("Subject", "Objet", ""),

    add_new_rec_email: retLang(
      "Add new recipient email",
      "Ajouter un nouvel e-mail de destinataire",
      ""
    ),
    add_new_rec_email_cc: retLang(
      "Add new recipient email (CC)",
      "Ajouter un nouvel e-mail de destinataire (CC)",
      ""
    ),
    add_new_rec_email_bcc: retLang(
      "Add new recipient email (BCC)",
      "Ajouter un nouvel e-mail de destinataire (BCC)",
      ""
    ),

    show_recipients_cc: retLang(
      "Show recipients input area (CC)",
      "Afficher la zone de saisie des destinataires (CC)",
      ""
    ),
    show_recipients_bcc: retLang(
      "Show recipients input area (BCC)",
      "Afficher la zone de saisie des destinataires (BCC)",
      ""
    ),
    hide_recipients_cc: retLang(
      "Hide recipients input area (CC)",
      "Masquer la zone de saisie des destinataires (CC)",
      ""
    ),
    hide_recipients_bcc: retLang(
      "Hide recipients input area (BCC)",
      "Masquer la zone de saisie des destinataires (BCC)",
      ""
    ),

    email_failure_sent: retLang(
      "Your email has not been sent successfully. Kindly check the related Email account and try again.",
      "Votre Email n'a pas été envoyé avec succès. Veuillez vérifier le compte Email associé et réessayer.",
      ""
    ),

    countr_miss_msg: retLang(
      "The related country seems missing from the database. Try again.",
      "Le pays concerné semble absent de la base de données. Essayer à nouveau.",
      ""
    ),
    success: retLang("Success", "Réussite", ""),
    sms_failure_sent: retLang(
      "Your SMS has not been sent successfully. Kindly check the related SMS account and try again.",
      "Votre SMS n'a pas été envoyé avec succès. Veuillez vérifier le compte SMS associé et réessayer.",
      ""
    ),
    sms_success_sent: retLang(
      "SMS sent with success",
      "SMS envoyé avec succès",
      ""
    ),
    email_sent_success: retLang(
      "Email sent with success",
      "Email envoyé avec succès",
      ""
    ),

    terms_m: retLang("Terms", "Termes", ""),
    cookies_m: retLang("Cookies", "Cookies", ""),
    privacy_m: retLang("Privacy", "Vie privée", ""),
    contactus_m: retLang("Contact Us", "Nous contacter", ""),
    terms_serv: retLang("Terms of Service", "Conditions d'utilisation", ""),
    priv_policy: retLang("Privacy Policy", "Politique de confidentialité", ""),
    all_rights_reserved: retLang(
      "All Rights Reserved",
      "Tous droits reservés",
      ""
    ),
    cookie_policy: retLang("Cookie Policy", "Politique de cookie", ""),
    waitServerResp: retLang(
      "Waiting for server response...",
      "Veuillez patienter la reponse du serveur...",
      ""
    ),

    english_m: retLang("English", "Anglais", ""),
    french_m: retLang("French", "Français", ""),
    rwanda_m: retLang("Kinyarwanda", "Kinyarwanda", ""),
    swahili_m: retLang("Swahili", "Swahili", ""),
    lingala_m: retLang("Lingala", "Lingala", ""),
    other_m: retLang("Other language", "Autre langue", ""),
    reload: retLang("Reload", "Recharger", ""),
    cancel: retLang("Cancel", "Annuler", ""),
    ok: retLang("OK", "OK", ""),
    yes: retLang("Yes", "Oui", ""),
    messageDate: retLang("Message date", "Date message", ""),
    networkName: retLang("Network name", "Nom du réseau", ""),
    home: retLang("Home", "Acceuil", ""),
    search: retLang("Search", "Chercher", ""),
    saving: retLang("Saving", "Enregistrement", ""),
    ourPartners: retLang("Our partners", "Nos Partenaires", ""),
    clcickHereToSelect: retLang(
      "Click here to select",
      "Cliquez ici pour sélectionner",
      ""
    ),
    subscribe: retLang("Subscribe", "S'abonner", ""),
    subscribers: retLang("Subscribers", "Abonnés", ""),
    yourEmailHere: retLang("Your email here", "Mettez votre email içi", ""),
    schoolNameOnly: retLang(
      "Archivix",
      "Archivix",
      ""
    ),
    close: retLang("Close", "Fermer", ""),
    clickHere: retLang("Click here", "Cliquez içi", ""),
    noOptionsFound: retLang("No options found", "Aucune options trouvées", ""),
    chooseOption: retLang("Choose option", "Choisir une option", ""),
    signInToApp: retLang(
      "Sign in to Archivix",
      "Connectez-vous - Archivix",
      ""
    ),
    signInToManagtApp: retLang(
      "Sign in to Management",
      "Connectez-vous - Management",
      ""
    ),
    enterUrEmailPw: retLang(
      "Enter your email and password",
      "Saisissez votre email et votre mot de passe",
      ""
    ),
    enterUrEmail: retLang("Enter your email", "Saisissez votre email", ""),
    yourEmail: retLang("Your email", "Votre email", ""),
    yourPassword: retLang("Your password", "Votre mot de passe", ""),
    logInstead: retLang("Log in instead", "Se connecter plutôt", ""),
    recaptchaError: retLang(
      "There's a security error in your form.",
      "Il y a une erreur de sécurité dans votre formulaire.",
      ""
    ),
    secCheckError: retLang(
      "Captcha error. Try again",
      "Erreur CAPTCHA. Essayer à nouveau",
      ""
    ),
    setNewPassword: retLang(
      "Set new password",
      "Definir un nouveau mot de passe",
      ""
    ),
    newPassword: retLang("New password", "Nouveau mot de passe", ""),
    setPassword: retLang("Set password", "Definir mot de passe", ""),
    provideNewPassword: retLang(
      "Provide new password",
      "Fournir un nouveau mot de passe",
      ""
    ),
    is: retLang("is", "est", ""),
    waitPwSet: retLang(
      "Waiting for password to be set.",
      "En attente de définition du mot de passe",
      ""
    ),
    loadingData: retLang("Loading data", "Chargement des données", ""),
    dashboardUserMsg: retLang(
      "Track KPIs, metrics, and other data points in one visual, central place.",
      "Suivez les Indicateurs clés, les mesures et autres points de données dans un endroit visuel et central.",
      ""
    ),
    details: retLang("Details", "Détails", ""),
    listBlogs: retLang("List of posts", "Liste des publications", ""),
    listBlogsMsg: retLang(
      "List of publications you have published; follow in detail the reactions of people who liked your stories and be open to their criticism.",
      "Liste des publications que vous avez publiées; suivez en détail les réactions des personnes qui ont aimé vos histoires et soyez ouvert à leurs critiques.",
      ""
    ),
    title: retLang("Title", "Titre", ""),
    date: retLang("Date", "Date", ""),
    tags: retLang("Tags", "Mots clés", ""),
    newTag: retLang("New tag", "Nouveau mot clé", ""),
    comments: retLang("Comments", "Commentaires", ""),
    published: retLang("Published", "Publié", ""),
    unpublished: retLang("Unpublished", "non publié", ""),
    publish: retLang("Publish", "Publier", ""),
    newBlog: retLang("New blog", "Nouveau blog", ""),
    blogSubjectEx: retLang(
      "ex: Shoes shopping",
      "ex: Achats de chaussures",
      ""
    ),
    blogTitleEx: retLang(
      "ex: Shoes price are skyrocketing",
      "ex: Le prix des chaussures monte en flèche",
      ""
    ),
    newBlogMsg: retLang(
      "Compose a blog telling the world about your daily journey and let them respond to your blog post. These blogs can be found by search engines. These articles will be published on the Archivix website. You will be notified if people react to your messages.",
      "Créez un blog racontant au monde votre voyage quotidien et laissez-le répondre à votre article de blog. Ces blogs peuvent être trouvés par les moteurs de recherche. Ces articles seront publiés sur le site Internet de Archivix. Vous serez averti si les gens réagissent à vos messages.",
      ""
    ),
    noTagsYet: retLang("No tags yet", "Pas d'étiquettes"),
    remove: retLang("Remove", "Retirer"),
    featuredImage: retLang("Featured image", "Image en vedette"),
    dialogBoxTitle: retLang(
      "Dialog box title",
      "Titre de la boîte de dialogue"
    ),
    selectFileFromComp: retLang(
      "Select a file from your computer",
      "Sélectionnez un fichier sur votre ordinateur"
    ),
    selectFileFromPexels: retLang(
      "Pick an image from pexels website",
      "Choisissez une image sur le site Web de Pexels"
    ),
    searchFromPexels: retLang(
      "Search from Pexels website",
      "Rechercher sur le site Web Pexels"
    ),
    waitingforPexelsServer: retLang(
      "Waiting for Pexels server",
      "En attente du serveur Pexels"
    ),
    waitingforServerResp: retLang(
      "Waiting for server response",
      "En attente de la reponse du serveur"
    ),
    photographer: retLang("Photographer", "Photographe"),
    typeHere: retLang("Type here", "Tapez içi"),
    postContent: retLang("Blog post content", "Contenu de l'article de blog"),
    previewContent: retLang("Preview content", "Visualisez le contenu"),
    hideContent: retLang("Hide content", "Cacher le contenu"),
    copyOnlineImagesLink: retLang(
      "Search images and copy links",
      "Rechercher des images et copier des liens"
    ),
    photoImage: retLang("Photo or Image link", "Photo ou video"),
    searchByImgs: retLang("Search images", "Cherchez les images"),
    searchByVidz: retLang("Search vidéos", "Cherchez les vidéos"),
    photoImageLinkCp: retLang(
      "Your media link has been copied to the clipboard. Jost paste by using CTRL+V (windows)/Cmd+V(Mac Os).",
      "Votre lien média a été copié dans le presse-papiers. Collez simplement en utilisant CTRL+V (windows)/Cmd+V(Mac Os)."
    ),

    listOfblogs: retLang("List of blogs", "Liste des blogs"),
    blogs: retLang("Blogs", "Blogs"),
    noblogFnd: retLang("No blog found", "Aucun blog trouvé"),
    loadingBlogs: retLang("Loading blogs", "Chargement des blogs"),
    save: retLang("Save", "Enregistrer"),
    warning: retLang("Warning", "Avertissement"),
    next: retLang("Next", "Suivant", ""),
    previous: retLang("Previous", "Précédent", ""),
    deleteComment: retLang("Delete comment", "Supprimer commentaire", ""),
    warningPostTags: retLang(
      "Blogs need tags and it appears that you have not set any. Add at least one and try again.",
      "Les blogs ont besoin de balises et il semble que vous n'en ayez défini aucune. Ajoutez-en au moins un et réessayez."
    ),
    warningPostFimg: retLang(
      "You need to set a featured image before saving this blog post.",
      "Vous devez définir une image sélectionnée avant d'enregistrer cet article de blog."
    ),
    warningPostTitle: retLang(
      "The post title field seems empty. Provide a meaningful title to your blog post and try again.",
      "Le champ du titre du message semble vide. Donnez un titre significatif à votre article de blog et réessayez."
    ),
    warningPostSubject: retLang(
      "The post subject field seems empty. Provide a meaningful subject to your blog post and try again.",
      "Le champ Objet du message semble vide. Donnez un Sujet significatif à votre article de blog et réessayez."
    ),
    warningPostContent: retLang(
      "You haven't yet typed your story. Type a story before posting it. Otherwise Administrators shall remove it so that it does not appear online.",
      "Vous n'avez pas encore écrit votre histoire. Tapez une histoire avant de la publier. Dans le cas contraire, les administrateurs devront le supprimer afin qu'il n'apparaisse pas en ligne."
    ),
    publishPostMsg: retLang(
      "Do you want to publish this blog post?",
      "Voulez-vous publier cet article de blog ?"
    ),
    delete: retLang("Delete", "Supprimer", ""),
    event: retLang("Event", "Événement", ""),
    nbr: retLang("Number", "Nombre", ""),
    edit: retLang("Edit", "Modifier", ""),
    typeDescr: retLang(
      "Type description here",
      "Saisissez la description içi",
      ""
    ),
    description: retLang("Description", "Description", ""),
    nbrPics: retLang("Number", "Nombre", ""),
    newSocialEvent: retLang("New social event", "Nouvel événement social", ""),
    fieldError: retLang(
      "Input field error",
      "Erreur dans le champ de saisie",
      ""
    ),
    fieldErrorDesc: retLang(
      "Some record have empty description field.",
      "Certains enregistrements ont un champ de description vide.",
      ""
    ),
    fieldErrorTitle: retLang(
      "Some record have empty title field.",
      "Certains enregistrements ont un champ de titre vide.",
      ""
    ),
    loadSocEvts: retLang(
      "Loading social events",
      "Chargement des événements sociaux",
      ""
    ),
    deleteCommentMsg: retLang(
      "Do you want to delete this blog comment?",
      "Voudriez-vous supprimer ce commentaire du blog?",
      ""
    ),
    previewComments: retLang("Preview comments", "Aperçu des commentaires", ""),
    socialEventsMsg: retLang(
      "Post photos of your vacations, parties and important events. Appreciate the reactions of your former colleagues.",
      "Postez des photos de vos vacances, de vos fêtes et des évenements importants. Appreciez les réactions de vos anciens collègues.",
      ""
    ),
    newSocialEventMsg: retLang(
      "Upload photos from your social event, comment on them and let people enjoy your photos and your social routine.",
      "Téléchargez des photos de votre événement social, commentez-les et laissez les gens profiter de vos photos et de votre routine sociale.",
      ""
    ),
    deleteSocEventDet: retLang(
      "Do you want to delete this social event detail?",
      "Voudriez-vous supprimer ce détail en rapport avec cet événement social?",
      ""
    ),
    eventTitle: retLang("Event title", "Titre de l'événement", ""),
    typeEventTitle: retLang(
      "Type event title here",
      "Saisissez le titre de l'événement",
      ""
    ),
    location: retLang("Location", "Emplacement", ""),
    typeEventLoc: retLang(
      "Type event location here",
      "Saisissez l'emplacement de l'événement",
      ""
    ),
    tagHolderText: retLang(
      "Type and press enter to inser a new tag",
      "Tapez et appuyez sur Entrée pour insérer une nouvelle balise",
      ""
    ),
    locTitleReqMsg: retLang(
      "Location and Title fields are required. Fill them and try again.",
      "Les champs Emplacement et Titre sont obligatoires. Remplissez-les et réessayez.",
      ""
    ),
    messagesMsg: retLang(
      "Send to your former classmates private and encrypted messages, they can answer and share their impressions.",
      "Envoyez à vos anciens camarades de classe des messages privés et cryptés, ils pourront y répondre et partager leurs impressions.",
      ""
    ),
    recipient: retLang("Recipient", "Destinataire", ""),
    recipients: retLang("Recipients", "Destinataires", ""),
    loadingMessages: retLang(
      "Loading messages",
      "Chanrgement des messages",
      ""
    ),
    noMsgFnd: retLang("No messages found", "Aucun message trouvé", ""),
    newMessage: retLang("New message", "Nuveau message", ""),
    newMessageMsg: retLang(
      "Compose a message to send to several recipients among your former classmates.",
      "Composez un message à envoyer à plusieurs destinataires parmi vos anciens camarades de classe.",
      ""
    ),
    noAttachts: retLang("No attachments", "Pas des fichiers joints", ""),
    typeUrSubject: retLang(
      "Type your subject here",
      "Tapez ici votre sujet",
      ""
    ),
    showMediaFiles: retLang(
      "Show media files",
      "Afficher les fichiers multimédias",
      ""
    ),
    addMoreFiles: retLang("Add more files", "Ajouter plus de fichiers", ""),
    addMorePictures: retLang("Add more pictures", "Ajouter plus de photos", ""),
    addOnePictureTime: retLang(
      "Add one picture at a time",
      "Add one image at a time",
      ""
    ),
    imageProdAddQst: retLang(
      "Do you want to add this picture file to this product?",
      "Voulez-vous ajouter ce fichier image à ce produit ?",
      ""
    ),
    mediaFiles: retLang("Media files", "Fichiers multimedia", ""),
    noMediaFiles: retLang(
      "No media files were found. Download a few before using this feature.",
      "Aucun fichier multimédia n'a été trouvé. Téléchargez-en quelques-uns avant d'utiliser cette fonctionnalité.",
      ""
    ),
    uploadFiles: retLang("Upload files", "Télécharger des fichiers", ""),
    files: retLang("Files", "Fichiers", ""),
    noFilesSelected: retLang(
      "No files have been selected. Try again.",
      "Aucun fichier n'a été sélectionné. Essayer à nouveau.",
      ""
    ),
    loadingFiles: retLang(
      "Loading media files",
      "Chargement des fichiers multimedia",
      ""
    ),
    sku: retLang("SKU", "UGS", ""),
    skuV2: retLang("Stock keeping unit", "Unité de gestion de stock", ""),
    productName: retLang("Product name", "Nom produit", ""),

    to: retLang("To", "A", ""),
    end: retLang("End", "Fin", ""),
    inbox: retLang("Inbox", "Bte réception", ""),
    sent: retLang("Sent", "Envoyés", ""),
    deleted: retLang("Deleted", "Supprimés", ""),
    preview: retLang("Preview", "Visualisez", ""),
    markAsRead: retLang("Mark as read", "Marquer comme lu", ""),
    markAsUnread: retLang("Mark as unread", "Marquer comme non lu", ""),
    deleteMessage: retLang(
      "Do you want to delete this message?",
      "Voulez-vous supprimer ce message?",
      ""
    ),
    undeleteMessage: retLang(
      "Do you want to un-delete this message?",
      "Voulez-vous annuler la suppression de ce message?",
      ""
    ),
    undelete: retLang("Undelete", "Annuler la suppression", ""),
    eventColor: retLang("Event color", "Couleur de l'événement", ""),
    default: retLang("Default", "Par défaut", ""),
    hasEndDate: retLang("Has end date", "A une date de fin", ""),
    dateEndError: retLang(
      "Kindly check date of end vs date of start.",
      "Veuillez vérifier les paramétrages des dates.",
      ""
    ),

    messageContentReq: retLang(
      "Hey there! It seems you forgot to type your message body. Try again.",
      "Salut! Il semble que vous ayez oublié de saisir le corps de votre message. Essayer à nouveau.",
      ""
    ),
    messageSubjectReq: retLang(
      "Hey there! It seems you forgot to type your message subject. Try again.",
      "Salut! Il semble que vous ayez oublié de saisir l'objet de votre message. Essayer à nouveau.",
      ""
    ),
    messageReceipientsReq: retLang(
      "Hey there! It seems you forgot the recipients. Try again.",
      "Salut! Il semble que vous ayez oublié les destinataires. Essayer à nouveau.",
      ""
    ),
    attachments: retLang("Attachements", "Pièces jointes", ""),
    in: retLang("In", "Dans", ""),
    start: retLang("Start", "Debut", ""),
    calendarEvent: retLang("Calendar event", "Événement calendrier", ""),
    calendarEvents: retLang("Calendar events", "Événements calendrier", ""),
    calendarEventsMsg: retLang(
      "Your calendar event will trigger a notification before the event date so that you stay up to date with other Archivix student.",
      "Votre événement de calendrier déclenchera une notification avant la date de l'événement afin que vous restiez au courant des autres anciens étudiants.",
      ""
    ),
    deleteCalEvtMsg: retLang(
      "Do you want to delete this calendar event?",
      "Voudriez-vous supprimer cet événement calendrier.",
      ""
    ),
    firstName: retLang("First name", "Prénom", ""),
    lastName: retLang("Last name", "Nom", ""),
    birthDate: retLang("Birth date", "Date de naissance", ""),
    phoneNbr: retLang("Phone number", "N° de téléphone", ""),
    myProfile: retLang("My profile", "Mon profile", ""),
    sex: retLang("Sex", "Sexe", ""),
    female: retLang("Female", "Féminin", ""),
    address: retLang("Address", "Adresse", ""),
    yearCompleted: retLang("Year completed", "Année completée", ""),
    completedDegree: retLang("Completed degree", "Diplôme obtenu", ""),
    wrongFieldValue: retLang(
      "Wrong field value",
      "Valeur de champ incorrecte",
      ""
    ),
    male: retLang("Male", "Masculin", ""),
    myProfileMsg: retLang(
      "Please identify yourself so that other collaborators can identify you.",
      "Veuillez vous identifier pour que les autres les autres collaborateurs puissent vous identifier.",
      ""
    ),
    social: retLang("Social", "Social", ""),
    view: retLang("View", "Afficher", ""),
    serverError: retLang("Server error", "Erreur serveur", ""),
    language: retLang("Language", "Langue", ""),
    year: retLang("Year", "Année", ""),
    unfollow: retLang("Unfollow", "Pas suivre", ""),
    follow: retLang("Follow", "Suivre", ""),
    alumniMembers: retLang("Archivix members", "Membres - Anciens élèves", ""),
    alumniMember: retLang("Archivix member", "Membre - Ancien élève", ""),
    job: retLang("Job", "Travail", ""),
    unEmployed: retLang("Unemployed", "Chaumeur", ""),
    members: retLang("Members", "Membres", ""),
    sender: retLang("Sender", "Expéditeur", ""),
    mailingList: retLang("Mailing list", "Liste de diffusion", ""),
    mailingListMsg: retLang(
      "You can review the list of subscribers and send them mails accordingly.",
      "Vous pouvez consulter la liste des abonnés et leur envoyer des e-mails en conséquence.",
      ""
    ),
    mailingListShort: retLang("Mailing", "Diffusion", ""),
    loadAlumniMembers: retLang(
      "Loading Archivix members",
      "Chargement des membres Archivix",
      ""
    ),
    previewAlumniMember: retLang(
      "Preview the details of an Archivix member before activating his/her account.",
      "Veuillez vérifier les détails de cet ancien élève avant d'activer son compte.",
      ""
    ),
    alumniMembersMsg: retLang(
      "List of registered former students. You can activate or deactivate them, or even permanently suspend them.",
      "Liste des anciens élèves qui se sont inscrits. Vous pouvez les activer ou les désactiver, voir les suspendre définitivement.",
      ""
    ),
    refresh: retLang("Refresh", "Recharger", ""),
    itemsPerList: retLang("Items per list", "Éléments par liste", ""),
    savingSettings: retLang(
      "Saving settings",
      "Enregistrement des paramètres",
      ""
    ),
    settings: retLang("Settings", "Paramètres", ""),
    name: retLang("Name", "Nom", ""),
    email: retLang("Email", "Email", ""),
    dob: retLang("DOB", "D. Naiss.", ""),
    settingsMsg: retLang(
      "Configure your software so that it can work according to your preferences.",
      "Paramétrer votre logiciel pour qu'il puisse fonctionner selon vos préférences.",
      ""
    ),
    loadingRecipients: retLang(
      "Loading recipients",
      "Chargement des destinataires",
      ""
    ),
    management: retLang("Management", "Gestion", ""),
    deleteRecordQst: retLang(
      "Do you want to delete this record?",
      "Voulez-ous supprimer cet enregistrement?",
      ""
    ),
    noRecFnd: retLang("No record found", "Aucun enregistrement trouvé", ""),
    usersMsg: retLang(
      "You can invite other employees to join you as users with limited rights.",
      "Vous pouvez inviter d'autres employés à vous joindre comme utilisateurs avec des droits limités.",
      ""
    ),
    enableUser: retLang("Enable user", "Activer utilisateur", ""),
    disableUser: retLang("Disable user", "Désactiver utilisateur", ""),
    enableUserMsg: retLang(
      "Do you want to activate this user?",
      "Voulez-vous activer cet utilisateur?",
      ""
    ),
    disableUserMsg: retLang(
      "Do you want to de-activaye this user?",
      "Vulez-vous désactiver cet utilisateur",
      ""
    ),
    addNewUser: retLang("Add new user", "Ajouter nouvel utilisateur", ""),
    editUser: retLang("Edit user", "Modifier utilisateur", ""),
    administrator: retLang("Administrator", "Administrateur", ""),
    joinTeam: retLang("Join team", "Joindre l'équipe", ""),
    confirmAndSetPassword: retLang(
      "Confirm and set password",
      "Confirmer et définir le mot de passe",
      ""
    ),
    blogsMsg: retLang(
      'When you create a blog with <strong>La Colombire <a href="https://blog.lacolombiere.ac.rw" target="_blank">Blog Engine</a></strong>, you must inform the application of management so that it displays on the main website. Otherwise, people visiting your website will not be informed of any changes.',
      "Lorsque vous créez un blog avec <strong>La Colombire <a href=\"https://blog.lacolombiere.ac.rw\" target=\"_blank\">Blog Engine</a></strong>, vous devez en informer l'application de gestion afin qu'il l'affiche sur le site Web principal. Sinon, les personnes visitant votre site Web ne seront informées d'aucun changement.",
      ""
    ),
    author: retLang("Author", "Auteur", ""),
    addNewBlog: retLang("Add new blog", "Ajouter nouveau blog", ""),
    editBlog: retLang("Edit blog", "Modifier blog", ""),
    notSpecified: retLang("Not specified", "Non spécifié", ""),
    imageUrl: retLang("Image URL", "Adresse image", ""),
    blogPathUrl: retLang("Blog web address", "Adresse web du blog", ""),
    selectAuthor: retLang("Select author", "Sélectionne l'auteur", ""),
    blogColor: retLang("Blog color", "Couleur du blog", ""),
    unpublish: retLang("Unpublish", "Annuler la publication", ""),
    publishBlog: retLang("Publish blog", "Publier l'article", ""),
    publishBlogMsg: retLang(
      "Do you want to publish this blog online?",
      "Voulez-vous publier cet article en ligne?",
      ""
    ),
    unPublishBlog: retLang(
      "Unpublish blog",
      "Supprimer la publication du blog",
      ""
    ),
    unPublishBlogMsg: retLang(
      "Do you want to unpublish this blog?",
      "Voulez-vous supprimer cette publication en ligne?",
      ""
    ),
    color_1: retLang("Color 1", "Couleur 1", ""),
    color_2: retLang("Color 2", "Couleur 2", ""),
    color_3: retLang("Color 3", "Couleur 3", ""),
    color_4: retLang("Color 4", "Couleur 4", ""),
    color_5: retLang("Color 5", "Couleur 5", ""),

    activateAlumniMember: retLang(
      "Would you like to activate this Archivix member?",
      "Voudriez-vous activer ce membre des anciens élèves?",
      ""
    ),
    deActivateAlumniMember: retLang(
      "Would you like to de-activate this Archivix member?",
      "Voudriez-vous désactiver ce membre des anciens élèves?",
      ""
    ),
    noMailListFnd: retLang(
      "No mail has been sent to mailing subscribers. Compose now.",
      "Aucun courrier n'a été envoyé aux abonnés mailing. Composez maintenant.",
      ""
    ),
    noMailListMembersFnd: retLang(
      "No subscribers to Archivix mailing list has been found.",
      "Aucun abonné à la liste de diffusion de Archivix n'a été trouvé.",
      ""
    ),
    composeNewMail: retLang(
      "Compose new mail",
      "Composer un nouveau courrier",
      ""
    ),
    composeNewMailMsg: retLang(
      "This email you compose will be sent to all mailing subscribers.",
      "Cet email sera envoyé à tous les abonnés au de la messagerie.",
      ""
    ),
    mailAddress: retLang("Mail address", "Edresse email", ""),
    send: retLang("Send", "Envoyer", ""),
    sendMailListQst: retLang(
      "Do you want to send this email to subscribers?",
      "Voulez-vous envoyer cet email à tous les abonnés.",
      ""
    ),
    subscrDate: retLang("Subscription date", "Date abonnement", ""),
    previewMessage: retLang("Preview message", "Affichage du message", ""),

    price: retLang("Price", "Prix", ""),
    discounted: retLang("Discounted", "Prix reduit", ""),
    category: retLang("Catgeory", "Catégory", ""),
    noProdFound: retLang(
      "No product has been found in our database. Create a new one.",
      "Aucun produit n'a été trouvé dans notre base de données. Créez-en un nouveau.",
      ""
    ),

    previewMessageMsg: retLang(
      "Preview the selected message.",
      "Affichage du message sélectionné",
      ""
    ),
    noContactMsgFnd: retLang(
      "No contact message found",
      "Aucun message de contact n'a été trouve.",
      ""
    ),
    download: retLang("Download", "Télécharger", ""),
    shop: retLang("Shop", "Boutique", ""),
    sales_ops: retLang("Sales ops", "Opér. ventes", ""),
    lookingFor: retLang("Looking for", "Recherche", ""),
    saveProdQst: retLang(
      "Do you want to save this product?",
      "Voulez-vous enregistrer ce produit?",
      ""
    ),
    updateProdQst: retLang(
      "Do you want to update this product?",
      "Voulez-vous mettre à jour ce produit?",
      ""
    ),
    prodSkuError: retLang(
      "The product SKU must have 15 characters.",
      "Le SKU du produit doit comporter 15 caractères.",
      ""
    ),
    productImageError: retLang(
      "A product must have at least one picture. Select a picture before proceeding.",
      "Un produit doit avoir au moins une photo. Sélectionnez une image avant de continuer.",
      ""
    ),
    low: retLang("Low", "Bas", ""),
    medium: retLang("Medium", "Moyen", ""),
    high: retLang("High", "Haut", ""),
    staff: retLang("Staff", "Employé", ""),
    student: retLang("Student", "Elève", ""),
    askAbtKids: retLang(
      "Ask about students",
      "Renseignez-vous sur les étudiants",
      ""
    ),
    classRoom: retLang("Class room", "Salle", ""),
    priority: retLang("Priority", "Priorité", ""),
    goback: retLang("Go back", "Retour", ""),
    products: retLang("Products", "Produits", ""),
    salesOpers: retLang("Sales Operations", "Opérations de vente", ""),
    client: retLang("Client", "Client", ""),
    total: retLang("Total", "Total", ""),
    items: retLang("Items", "Article", ""),
    salesDetails: retLang("Sales details", "Détails des ventes", ""),
    salesContact: retLang("Sales contact", "Contact des ventes", ""),
    city: retLang("City", "Ville", ""),
    askKidsMessageMsg: retLang(
      "This message has been sent from Asking about Kids form.",
      "Ce message a été envoyé à partir du formulaire Demander des informations sur les enfants.",
      ""
    ),
    viewDetails: retLang("View details", "Afficher détails", ""),
    viewCustomer: retLang("View customer", "Afficher client", ""),
    sendEmail: retLang("Send email", "Envoyer message", ""),
    placeCall: retLang("Place call", "appel téléphonique", ""),
    salesOpersMsg: retLang(
      "Check purchases made online and above all make sure that payments have been made.",
      "Vérifiez les achats qui ont été effectués en ligne et surtout assurez-vous que les paiements ont été effectués.",
      ""
    ),
    requiredFields: retLang("Required fields", "Champs requis", ""),
    requiredFieldsMsg: retLang(
      "Some required fields have not yet filled. Try again.",
      "Certains champs obligatoires ne sont pas encore remplis. Essayer à nouveau.",
      ""
    ),
    contactMessages: retLang("Contact messages", "Messages de contact", ""),
    contactMessagesMsg: retLang(
      "List all messages that were sent through the main website's contact page. You can respond directly to your contact using this page.",
      "Répertoriez tous les messages envoyés via la page de contact du site Web principal. Vous pouvez répondre directement à votre contact en utilisant cette page.",
      ""
    ),

    previewContactMessage: retLang(
      "You can preview messages sent from the contact form through the main web site.",
      "Vous pouvez prévisualiser les messages envoyés depuis le formulaire de contact via le site Web principal.",
      ""
    ),

    foodBev: retLang("Food & Beverages", "Nourriture et boissons", ""),
    schoolSupplStat: retLang(
      "School Supplies and Stationery",
      "Fournitures scolaires et papeterie",
      ""
    ),
    clothAccess: retLang(
      "Clothes & Styling Accessories",
      "Vêtements et accessoires de style",
      ""
    ),
    services: retLang("Services", "Services", ""),
    otherAweSome: retLang(
      "Other Awesome Things",
      "D'autres choses géniales",
      ""
    ),
    discountedPriceErr: retLang(
      "The discounted price must be less than the product price.",
      "Le prix réduit doit être inférieur au prix du produit.",
      ""
    ),
    available: retLang("Available", "Disponible", ""),
    notAvailabe: retLang("Not available", "Non disponible", ""),
    newProduct: retLang("New product", "Nouveau produit", ""),
    listProds: retLang("List products", "Liste des produits", ""),
    images: retLang("Images", "Images", ""),
    listProdsMsg: retLang(
      "You can setup new products and they will appear under the Shop menu of your main website.",
      "Vous pouvez configurer de nouveaux produits et ils apparaîtront dans le menu Boutique de votre site Web principal.",
      ""
    ),
    newProductMsg: retLang(
      "This page helps you how to create a new product. If you want to setup variants, kindly save this product and preview it before proceeding.",
      "Cette page vous aide à créer un nouveau produit. Si vous souhaitez configurer des variantes, veuillez enregistrer ce produit et prévisualiser-le avant de continuer.",
      ""
    ),

    orWithEmPw: retLang(
      "Or with email and password",
      "Ou avec email et mot de passe",
      ""
    ),
    logToYourAcct: retLang(
      "Log in to your account",
      "Connectez-vous à votre compte",
      ""
    ),
    dtHaveAcct: retLang(
      "Don't have an account?",
      "Vous n'avez pas de compte ?",
      ""
    ),

    showPassword: retLang("Show password", "Afficher mot de passe", ""),
    hidePassword: retLang("Hide password", "Cacher mot de passe", ""),
    change: retLang("Change", "Changer", ""),
    noUserEmail: retLang(
      "No users using this email address were found.",
      "Aucun utilisateur utilisant cette adresse e-mail n'a été trouvé.",
      ""
    ),
    accountMgt: retLang("Account management", "Gestion du compte", ""),
    account: retLang("Account", "Compte", ""),
    registerAcct: retLang("Register account", "Créer un compte", ""),
    registerCompAcct: retLang(
      "Register company account",
      "Créer un compte entreprise",
      ""
    ),
    registerComp: retLang("Register company", "Enregistrer une entreprise", ""),
    registerCompMsg: retLang(
      `
                You agree to comply with the Archivix 
                <a class="general_link" target="_blank" href="https://archivix.net/legal/privacy">Privacy Policy</a> 
                and by registering you agree that you have sufficient rights to represent your business.<br/> <br/> 
                Let's start with your email.
            `,
      `
                Vous acceptez de vous conformer à la 
                <a class="general_link" target="_blank" href="https://archivix.net/legal/privacy">Politique de confidentialité</a> 
                d'Archivix et en vous inscrivant, vous acceptez que vous disposez de droits suffisants pour 
                représenter votre entreprise.<br/> <br/> 
                Commençons par votre email.
            `,
      ""
    ),
    getStarted: retLang("Get started", "Commencer", ""),
    haveAcct: retLang("Have an account?", "Avez-vous un compte ?", ""),
    logInNow: retLang("Log in now", "Connectez-vous maintenant", ""),
    serverWorking: retLang("Server working", "Serveur en fonctionnement", ""),
    code: retLang("Code", "Code", ""),
    confirm: retLang("Confirm", "Confirmez", ""),
    confirmRegistercodes: retLang(
      "Confirm registration code",
      "Confirmez le code d'enregistrement",
      ""
    ),
    companyDetails: retLang("Company details", "Détails de l'entreprise", ""),
    goToAdminDet: retLang("Company details", "Détails de l'entreprise", ""),
    companyEmailHere: retLang(
      "Company email here",
      "Email de l'entreprise",
      ""
    ),
    companyName: retLang("Company name", "Nom de l'entreprise", ""),
    companyNameHere: retLang(
      "Type your company name",
      "Saisir le nom de l'entreprise",
      ""
    ),
    shortName: retLang("Short name", "Nom court", ""),
    shortNameEntr: retLang("Short name here", "Abrégé içi", ""),
    adminUserDetails: retLang(
      "Administrator details",
      "Détails de l'administrateur",
      ""
    ),
    typeFirstName: retLang(
      "Type your first name here",
      "Saisis votre prénom içi",
      ""
    ),
    typeLastName: retLang(
      "Type your last name here",
      "Saisis votre nom içi",
      ""
    ),

    typeFirstNameH: retLang("Type first name here", "Saisis le prénom içi", ""),
    typeLastNameH: retLang("Type last name here", "Saisis le nom içi", ""),

    typeDisplNameFac: retLang(
      "Type your display name here (optional)",
      "Tapez votre nom d'affichage ici (facultatif)",
      ""
    ),
    displayName: retLang("Display name", "Nom d'affichage", ""),
    typePwHere: retLang(
      "Type your password here",
      "Saisissez votre mot de passe içi",
      ""
    ),
    confPwHere: retLang(
      "Confirm your password here",
      "Confirmez votre mot de passe içi",
      ""
    ),
    register: retLang("Register", "S'inscrire", ""),
    emailAddress: retLang("Email address", "Adresse email", ""),
    registerAcctMsg: retLang(
      "Do you want to register your company and the Administrator user account?",
      "Souhaitez-vous enregistrer votre entreprise et le compte utilisateur Administrateur ?",
      ""
    ),
    resetPwMessage: retLang(
      "To reset your password, enter your email below and submit. An email will be sent to you with instructions about how to complete the process.",
      "Pour réinitialiser votre mot de passe, entrez votre e-mail ci-dessous et soumettez. Un e-mail vous sera envoyé avec des instructions sur la façon de terminer le processus.",
      ""
    ),

    resetReqFinalMsg: retLang(
      "We've sent an email to your email inbox containing the link that can help you reset your password. Check your email (or sometimes your junk box) for further steps.",
      "Nous avons envoyé un e-mail dans votre boîte de réception contenant le lien qui peut vous aider à réinitialiser votre mot de passe. Vérifiez votre courrier électronique (ou parfois votre courrier indésirable) pour connaître les étapes ultérieures.",
      ""
    ),

    resetNewPwMsg: retLang(
      "Provide a new password that meets security requirements. Your password must be at least 8 characters long, contain special characters as well as lower and upper case letters. There will be a security bar that will show that your password meets the security rules.",
      "Fournissez un nouveau mot de passe qui répond aux exigences de sécurité. Votre mot de passe doit comporter au moins 8 caractères, contenir des caractères spéciaux ainsi que des lettres minuscules et majuscules. Il y aura une barre de sécurité qui montrera que votre mot de passe répond aux règles de sécurité.",
      ""
    ),

    signInAnyway: retLang("Sign in anyway", "Connectez-vous quand même", ""),
    setNewPw: retLang(
      "Set new password",
      "Definir un nouveau mot de passe",
      ""
    ),
    passwordErrorSec: retLang(
      "This password does not meet the security rules.",
      "Ce mot de passe ne répond pas aux règles de sécurité.",
      ""
    ),
    resetPasswordSuccessMsg: retLang(
      "Your password has been reset with success. Proceed to login screen.",
      "Votre mot de passe a été réinitialisé avec succès. Passez à l'écran de connexion.",
      ""
    ),
    relatedCompanies: retLang("Related companies", "Entreprises liées", ""),
    profileSettings: retLang(
      "Profile settings",
      "Paramètre de profil liées",
      ""
    ),
    changeAppLang: retLang(
      "Change app language",
      "Changer la langue de l'application",
      ""
    ),
    sendInvite: retLang("Send invitation", "Envoyer invitation", ""),
    changeAppLangMsg: retLang(
      "Would you like to change the application main language?",
      "Souhaitez-vous changer la langue principale de l'application ?",
      ""
    ),

    newUser: retLang("New user", "Nouvel utilisateur", ""),
    newUserMsg: retLang(
      "Invite a new user to join your company's team. An email containing a key to join the team will be sent to the new user's email address. This will allow the new user to provide more details about their account.",
      "Invitez un nouvel utilisateur à rejoindre l'équipe de votre entreprise. Un e-mail contenant une clé pour rejoindre l'équipe sera envoyé à l'adresse e-mail du nouvel utilisateur. Cela permettra au nouvel utilisateur de fournir plus de détails sur son compte.",
      ""
    ),

    invite: retLang("Invitation", "Invitation", ""),
    inviteUserQst: retLang(
      "Do you want to resend the invitation email to this user?",
      "Voulez-vous renvoyer l'e-mail d'invitation à cet utilisateur ?",
      ""
    ),
    join: retLang("Join", "Joindre", ""),
    joinTeamMsg: retLang(
      "This operation will help you join your new team on Archivix. Proceed?",
      "Cette opération vous permettra de rejoindre votre nouvelle équipe sur Archivix. Continuer ?",
      ""
    ),

    userDetails: retLang("User details", "Détails utilisateur", ""),
    previewUserMsg: retLang(
      "You can preview the selected user details without modifying. In case you want some details changed, kindly contact the account's owner.",
      "Vous pouvez prévisualiser les détails de l'utilisateur sélectionné sans les modifier. Si vous souhaitez modifier certains détails, veuillez contacter le propriétaire du compte.",
      ""
    ),
    listUsers: retLang("List of users", "Liste des utilisateurs", ""),
    document: retLang("Document", "Document", ""),
    documents: retLang("Documents", "Documents", ""),
    documentsMsg: retLang(
      "List of documents to which you have access. You can add documents, change access rights to existing documents, or delete documents that have no sharing or signing functionality. Certain documents can be sent to other collaborators for signature.",
      "Liste des documents auxquels vous avez accès. Vous pouvez ajouter des documents, modifier les droits d'accès aux documents existants ou supprimer des documents qui n'ont aucune fonctionnalité de partage ou de signature. Certains documents peuvent être envoyés aux autres collaborateurs pour signature.",
      ""
    ),
    newFolder: retLang("New folder", "Nouveau dossier", ""),
    editFolder: retLang("Edit folder", "Modifier dossier", ""),
    newDocument: retLang("New document", "Nouveau document", ""),
    editDocument: retLang("Edit document", "Edit document", ""),
    fillThisField: retLang("Fill this field", "Remplis ce champ", ""),
    folderName: retLang("Folder name", "Nom dossier", ""),
    primaryLoc: retLang("Primary location", "Emplacement principal", ""),
    secondaryLoc: retLang("Secondary location", "Emplacement secondaire", ""),
    fileName: retLang("File name", "Nom du fichier", ""),
    documentName: retLang("Document name", "Nom du document", ""),
    fileSource: retLang("File source", "Source des fichiers", ""),
    movePrevious: retLang("Move previous", "Déplacer précédent", ""),
    moveNext: retLang("Move next", "Déplacer suivant", ""),
    moveLeft: retLang("Move left", "Déplacer gauche", ""),
    moveRight: retLang("Move right", "Déplacer droite", ""),

    movePageUp: retLang("Move page up", "Déplacer la page vers le haut", ""),
    movePageDown: retLang("Move page down", "Déplacer la page vers le bas", ""),
    listMode: retLang("List mode", "Mode liste", ""),
    thumbnailMode: retLang("Thumbnail mode", "Mode miniature", ""),
    createdBy: retLang("Created by", "Créé par", ""),
    deleteFolder: retLang("Delete folder", "Suppression dossier", ""),
    deleteFolderMsg: retLang(
      "Do you want to delete the selected folder?",
      "Voulez-vous supprimer le dossier selectionné?",
      ""
    ),

    deleteFile: retLang("Delete file", "Suppression fichier", ""),
    deleteFileMsg: retLang(
      "Do you want to delete the selected file?",
      "Voulez-vous supprimer le fichier selectionné?",
      ""
    ),

    folderMiss: retLang("Folder missing", "Farde d'archives manquante", ""),
    folderMissMsg: retLang(
      "It appears that your selection does not match any archive folders among those you retrieved from the archive server. Try again or please contact your administrator.",
      "Il semble que votre sélection ne correspond à aucun dossier d'archive parmi ceux que vous avez récupérés sur le serveur d'archives. Réessayez ou veuillez contacter votre administrateur.",
      ""
    ),

    archFileMiss: retLang(
      "Archive file missing",
      "Fichier d'archives manquante",
      ""
    ),
    archFileMissMsg: retLang(
      "It appears that your selection does not match any archive file among those you retrieved from the archive server. Try again or please contact your administrator.",
      "Il semble que votre sélection ne correspond à aucun fichier d'archive parmi ceux que vous avez récupérés sur le serveur d'archives. Réessayez ou veuillez contacter votre administrateur.",
      ""
    ),
    archFolder: retLang("Archive folder", "Dossier d'archives", ""),
    archFolderMsg: retLang(
      "Find all archive folders, archive documents that are directly connected to this folder. you can add files or other documents.",
      "Retrouvez tous les dossiers d'archives, les documents d'archives qui sont directement connectés à ce dossier. vous pouvez ajouer les dossiers ou d'autres documents.",
      ""
    ),
    documenstHome: retLang("Documents (Home)", "Documents (Acceuil)", ""),
    documenstArch: retLang("Archive document", "Document archive", ""),
    documenstArchMsg: retLang(
      "You can grant access rights to other colleagues, post comments or make changes to documents.",
      "Vous pouvez octroyer les droits d'accès aux autres collègues, placez des commentaires ou apporter des changements au documents.",
      ""
    ),
    minimize: retLang("Minimize", "Minimiser", ""),
    restore: retLang("Restore", "Restorer", ""),
    chatOverDoct: retLang(
      "You can chat with other collaborators who have access to this archive document.",
      "Vous pouvez discuter avec d'autres collaborateurs ayant accès à ce document d'archive.",
      ""
    ),

    justNow: retLang("Just now", "En ce moment", ""),
    emotions: retLang("Emotions", "Emotions", ""),
    people: retLang("People", "Peuple", ""),
    grantRights: retLang("Grant rights", "Accorder des droits", ""),
    typeYMsgHere: retLang(
      "Type your message here",
      "Tapez votre message içi",
      ""
    ),
    imageFileFormat: retLang(
      "Image file format",
      "Format de fichier image",
      ""
    ),
    imageFileFormatMsg: retLang(
      "The selected file appears to have the wrong format. Select an image file and then try again.",
      "Le fichier sélectionné semble avoir un format erroné. Sélectionnez un fichier image et puis recommancez.",
      ""
    ),
    shareFile: retLang("Share file", "Partage fichier", ""),
    shareFileMsg: retLang(
      "Hello thre! I share this file via Archivix.",
      "Bonjour! Je partage ce fichier via Archivix.",
      ""
    ),
    webShare: retLang("Web share", "Partage web", ""),
    webShareMsg: retLang(
      "Web share is currently not supported on this browser. Please provide a callback.",
      "Le partage Web n'est actuellement pas pris en charge sur ce navigateur. Veuillez fournir un rappel.",
      ""
    ),
    grantAccess: retLang("Grant access", "Accorder l'accès", ""),
    searching: retLang("Searching", "Recherche", ""),
    fileAccessOperMsg: retLang(
      "You're about to update this document access rights? Proceed?",
      "Vous êtes sur le point de mettre à jour les droits d'accès à ce document ? Procéder?",
      ""
    ),
    sign: retLang("Sign", "Signer", ""),
    signDocts: retLang("Sign documents", "Signer documents", ""),
    signDoctsMsg: retLang(
      "You can send a document for signature or sign one that you have been invited to do.",
      "Vous pouvez envoyer un document pour signature ou en signer un auquel vous avez été invité.",
      ""
    ),
    searchDocts: retLang("Search documents", "Chercher documents", ""),
    searchDoctsMsg: retLang(
      "Enter the keywords corresponding to the documents you would like to find.",
      "Saisissez les mots clés correspondants aux document que vous aimeriez retrouver.",
      ""
    ),
    addDocument: retLang("Add document", "Ajouter document", ""),
    dragDropDoctHere: retLang(
      "Drag & drop your document here.",
      "Faites glisser et déposez votre document ici.",
      ""
    ),
    featureUnavail: retLang(
      "Feature unavailable",
      "Fonctionnalité indisponible",
      ""
    ),
    featureUnavailMsg: retLang(
      "This feature does not exist. Please check soon if we have activated it.",
      "Cette fonctionnalité n'existe pas. Veuillez vérifier dans un délai court si nous l'avons activé.",
      ""
    ),
    viewer: retLang("Viewer", "Téléspecteur", ""),
    approver: retLang("Approver", "Approbateur", ""),
    receivesCopy: retLang("Receives copy", "Recoit une copie", ""),
    doctToSignPart: retLang(
      "Documents to sign (Patners)",
      "Documents à signer (Partenaires)",
      ""
    ),
    doctToSign: retLang("Documents to sign", "Documents à signer", ""),
    all: retLang("All", "Tout", ""),
    draft: retLang("Draft", "Brouillon", ""),
    completed: retLang("Completed", "Terminé", ""),
    pending: retLang("Pending", "En cours", ""),
    created: retLang("Created", "Créé", ""),
    actions: retLang("Actions", "Actions", ""),
    duplicate: retLang("Duplicate", "Dupliquer", ""),
    share: retLang("Share", "Partager", ""),
    resend: retLang("Resend", "Renvoyer", ""),
    signer: retLang("Signer", "Signataire", ""),
    signers: retLang("Signers", "Signataires", ""),
    noFilesFnd: retLang("No files found", "Aucuns fichiers trouvés", ""),
    loadingDocument: retLang(
      "Loading documents",
      "Chargement des documents",
      ""
    ),
    duplicateFile: retLang("Duplicate file", "Dupliquer fichier", ""),
    duplicateFileMsg: retLang(
      "Cette opération var dupliquer ce fichier pour raison des signatures. Continuer?",
      "This operation will duplicate this file due to the signatures. Continue?",
      ""
    ),
    accessPathError: retLang(
      "Access path error",
      "Erreur de chemin d'accès",
      ""
    ),
    accessPathErrorMsg: retLang(
      "There was an error accessing this resource. Please start again.",
      "Il y a eu erreur d'accès à cette ressource. Veuillez recommencer.",
      ""
    ),
    addTitle: retLang("Add title", "Ajouter titre", ""),
    addTitleMsg: retLang(
      "Add the title to the document.",
      "Ajoutez le titre au document.",
      ""
    ),
    step: retLang("Step", "Etape", ""),
    of: retLang("Of", "De", ""),
    continue: retLang("Continue", "Continuer", ""),
    addSigner: retLang("Add signer", "Ajouter signataire", ""),
    addSigners: retLang("Add signers", "Ajouter signataires", ""),
    addSignersMsg: retLang(
      "Add people who will sign the document",
      "Ajouter les personnes qui signeront le document",
      ""
    ),
    selectSigner: retLang("Select signer", "Choisir un signataire", ""),
    deleteSigner: retLang("Delete signer", "Supprimer signataire", ""),
    deleteSignerMsg: retLang(
      "Do you want to remove this signer?",
      "CVoulez-vous rétirer ce signataire?",
      ""
    ),
    signersAddErr: retLang(
      "No signers have been added. Add at least one.",
      "Aucun signataire n'a été ajouté. Ajoutez-en au moins un.",
      ""
    ),
    signersAddErrV2: retLang(
      "Some signers have been set with wrong data format such as emails or names. Check signer details and try again.",
      "Certains signataires ont été définis avec un mauvais format de données, comme des e-mails ou des noms. Vérifiez les détails du signataire et réessayez.",
      ""
    ),
    signature: retLang("Signature", "Signature", ""),
    addFields: retLang("Add Fields", "Ajouter les champs", ""),
    addFieldsMsg: retLang(
      "Add all relevant fields for each recipient.",
      "Ajoutez tous les champs pertinents pour chaque destinataire.",
      ""
    ),
    addSubject: retLang("Add Subject", "Ajouter l'objet", ""),
    addSubjectMsg: retLang(
      "Add the subject and message you wish to send to signers.",
      "Ajoutez le objet et le message que vous souhaitez envoyer aux signataires.",
      ""
    ),
    optional: retLang("Optional", "Facultatif", ""),
    message: retLang("Message", "Message", ""),
    messageUseInfo: retLang(
      "You can use the following variables in your message:",
      "Vous pouvez utiliser les variables suivantes dans votre message:",
      ""
    ),
    thDoctName: retLang("The document's name", "Le nom du document", ""),
    thSignerEmail: retLang("The signer's email", "L'email du signataire", ""),
    thSignerName: retLang("The signer's name", "Le nom du signataire", ""),
    signDocument: retLang("Sign document", "Signer le document", ""),
    signDocumentMsg: retLang(
      "Please review the document before signing.",
      "Veuillez examiner le document avant de le signer.",
      ""
    ),
    resendSignReq: retLang(
      "Resend the file for signature request",
      "Renvoyer le fichier pour demande de signature",
      ""
    ),
    resendSignReqMsg: retLang(
      "Would you like to resend this file so that the signers can add their signatures",
      "Souhaitez-vous renvoyer ce fichier afin que les signataires puissent ajouter leur signature",
      ""
    ),
    load: retLang("Load", "Charger", ""),
    fullName: retLang("Full name", "Nom complet", ""),
    complete: retLang("Complete", "Terminer", ""),
    clearSignature: retLang("Clear signature", "Effacer signature", ""),
    canvasEmpty: retLang("Empty canvas", "Tableau vide", ""),
    canvasEmptyMsg: retLang(
      "The canvas seems empty and therefore its content cannot be saved. Draw your signature and try again.",
      "Le tableau semble vide et son contenu ne peut donc pas être sauvegardé. Dessinez votre signature et réessayez.",
      ""
    ),
    signatureSaveQst: retLang(
      "We're going to save this signature and link it to your account for further signature operations. Proceed?",
      "Nous allons enregistrer cette signature et la lier à votre compte pour d'autres opérations de signature. Procéder?",
      ""
    ),
    noSignatureRec: retLang(
      "No signature record",
      "Aucun enregistrement de signature",
      ""
    ),
    noSignatureRecMsg: retLang(
      "We did not detect your recorded signature. Save one template and try again.",
      "Nous n'avons pas détecté votre signature enregistrée. Enregistrez un modèle et réessayez.",
      ""
    ),
    signDoct: retLang("Sign document", "Signer le document", ""),
    signDoctMsg: retLang(
      "You're about to finish signing this document. Are you sure?",
      "Vous êtes sur le point de signer ce document. Voulez-vous continuer?",
      ""
    ),
    signatures: retLang("Signatures", "Signatures", ""),
    partners: retLang("Partners", "Partenaires", ""),
    relatedDocuments: retLang("Related documents", "Documents associés", ""),
    doctsToSign: retLang("Documents to sign", "Documents à signer", ""),
    partnersSign: retLang("Your partners", "Vos partenaires", ""),
    latestOps: retLang("Latest operations", "Dernières opérations", ""),
    doctsInbox: retLang(
      "Documents Inbox",
      "Boîte de réception des documents",
      ""
    ),
    doctsInboxMsg: retLang(
      "Last documents awaiting your signature",
      "Derniers documents en attente de votre signature",
      ""
    ),
    filter: retLang("Filter", "Filtre", ""),
    dropFileErrorMsg: retLang(
      "Some file formats are not allowed. Kindly use PDF or Word documents.",
      "Certains formats de fichiers ne sont pas autorisés. Veuillez utiliser des documents PDF ou Word.",
      ""
    ),
    filesDownRequired: retLang(
      "Files to upload are required and it seems none has been selected.",
      "Les fichiers à télécharger sont obligatoires et il semble qu'aucun n'ait été sélectionné.",
      ""
    ),
    previewDoct: retLang("Preview document", "Afficher document", ""),
    deleteDoct: retLang("Delete document", "Suppression document", ""),
    editFile: retLang("Edit document", "Modifier document", ""),
    showDetails: retLang("Show details", "Afficher détails", ""),
    folders: retLang("Folders", "Repertoires", ""),
    pageSettings: retLang("Page settings", "Paramètres de la page", ""),
    pageSettingsErrMsg: retLang(
      "Some critical keys seems missing. This page can't load. Try again.",
      "Certaines clés critiques semblent manquantes. Cette page ne peut pas se charger. Essayer à nouveau.",
      ""
    ),
    noDoctSelected: retLang(
      "No document selected",
      "Aucun document n'est sélectionné.",
      ""
    ),
    documentAccess: retLang("Document access", "Accès au document.", ""),
    accessRights: retLang("Access rights", "Droit d'accès", ""),
    accessRightsMsg: retLang(
      "You have no access rights to run this command. Contact your Administrator or the owner of the document.",
      "Vous n'avez aucun droit d'accès pour exécuter cette commande. Contactez votre administrateur ou le propriétaire du document.",
      ""
    ),
    sendSignature: retLang(
      "Send document to signature ops",
      "Envoyer le document aux opérations de signature",
      ""
    ),
    sendSignatureStaffMsg: retLang(
      "Do you want to send this document to staff signature ops?",
      "Voulez-vous envoyer le document aux opérations de signature (employés)?",
      ""
    ),
    sendSignaturePartnerMsg: retLang(
      "Do you want to send this document to partners signature ops?",
      "Voulez-vous envoyer le document aux opérations de signature (partenaires)?",
      ""
    ),
    stamp: retLang("Stamp", "Sceau", ""),
    stampMSg: retLang(
      "In case you want to apply your business stamp to documents, you need to set one up.",
      "Si vous souhaitez apposer votre cachet professionnel sur des documents, vous devez en créer un.",
      ""
    ),
    keyword: retLang("Key word", "Mot clé", ""),

    activateUser: retLang("Activate user", "Activer utilisateur", ""),
    activateUserMsg: retLang(
      "Do you want to activate the selected user?",
      "Voulez-vous activer l'utilisateur sélectionné?",
      ""
    ),

    deactivateUser: retLang("De-Activate user", "Désactiver utilisateur", ""),
    deactivateUserMsg: retLang(
      "Do you want to de-activate the selected user?",
      "Voulez-vous désactiver l'utilisateur sélectionné?",
      ""
    ),

    clear: retLang("Clear", "Effacer", ""),
    applyStamp: retLang("Apply stamp", "Appliquer un sceau", ""),
    company: retLang("Company", "Entrprise", ""),
    companySetts: retLang("Company settings", "Paramètres entrprise", ""),
    settingsGlance: retLang(
      "All settings at a glance",
      "Tous les paramètres en un coup d'œil",
      ""
    ),
    pngFormatError: retLang(
      "Image format error",
      "Erreur de format d'image",
      ""
    ),
    pngFormatErrorMsg: retLang(
      "This operation requires an image file with a transparent background. Kindly choose a PNG file format.",
      "Cette opération nécessite un fichier image avec un fond transparent. Veuillez choisir un format de fichier PNG.",
      ""
    ),

    signFormatError: retLang(
      "Digital signature format error",
      "Erreur de format de la signature digitale",
      ""
    ),
    signFormatErrorMsg: retLang(
      "This operation requires a digital signature file with a transparent background. Kindly choose a P12 file format.",
      "Cette opération nécessite un fichier de signature numérique avec un fond transparent. Veuillez choisir un format de fichier P12.",
      ""
    ),

    settingsGlanceMsg: retLang(
      "Change the settings of your company and let Archivix work accordingly.",
      "Modifiez les paramètres de votre entreprise et laissez Archivix fonctionner en conséquence.",
      ""
    ),

    applyStampBusDocs: retLang(
      "Set up stamps for business docs",
      "Configurer un tampon pour les documents professionnels",
      ""
    ),
    applySignatureToDocs: retLang(
      "Setup your digital signature",
      "Configurer votre signature digitale",
      ""
    ),
    uHvSigned: retLang("You have signed", "Vous avez signé", ""),
    digitalSignature: retLang("Digital signature", "Signature digital", ""),
    fileNotSelected: retLang(
      "File not selected. Select before submitting.",
      "Fichier non sélectionné. Sélectionnez avant de soumettre.",
      ""
    ),
    signatureAlSet: retLang(
      "Digital Signature is already set",
      "La signature électronique est déjà définie",
      ""
    ),
    companyDetailsMsg: retLang(
      "Update your company details",
      "Modifier les données de votre entreprise",
      ""
    ),
    goBackHome: retLang("Go Back Home", "Retour Acceuil", ""),
    shareSignCard: retLang(
      "Share Signature Card",
      "Partager la Carte de Signature",
      ""
    ),
    viewOrigDoct: retLang(
      "View Originial Document",
      "Afficher Document Original",
      ""
    ),
    receiveCopySigned: retLang(
      "You will receive an Email copy of the signed document once everyone has signed.",
      "Vous recevrez une copie par e-mail du document signé une fois que tout le monde aura signé.",
      ""
    ),
    sentCopySigned: retLang(
      "Everyone has signed! We've sent you an Email copy of the signed document.",
      "Tout le monde a signé ! Nous vous avons envoyé une copie par e-mail du document signé.",
      ""
    ),
    everyOneSigned: retLang("Everyone has signed", "Tout le monde a signé", ""),
    waitOtherSign: retLang(
      "Waiting for others to sign",
      "En attendant que d'autres signent",
      ""
    ),
    page: retLang("Page", "Page", ""),
    completeSigning: retLang("Complete Signing", "Procéder à la Signature", ""),
    doctAllSigned: retLang(
      "This document has been signed by all recipients",
      "Ce document a été signé par tous les destinataires",
      ""
    ),
    doctNoneSigned: retLang(
      "None of the signers have yet signed this document",
      "Aucun des signataires n'a encore signé ce document",
      " "
    ),
    doctPendSigned: retLang(
      "Some signatories have not yet signed this document",
      "Certains signataires n'ont pas encore signé ce document",
      ""
    ),
    information: retLang("Information", "Information", ""),
    uploadedBy: retLang("Uploaded by", "Téléchargé par", ""),
    lastModified: retLang("Last modified", "Dernière modification", ""),
    signed: retLang("Signed", "Signé", ""),
    recentActivity: retLang("Recent activity", "Activité récente", ""),
    isCompleted: retLang("is Completed", "est Terminé", ""),
    isDraft: retLang("is a Draft", "est un Brouillon", ""),
    isPending: retLang("is a Pending", "est un en Entente", ""),
    you: retLang("You", "Vous", ""),
    createdDoct: retLang("created the document", "avez créé le document", ""),
    openedDoct: retLang("opened the document", "avez ouvert le document", ""),
    sentDoct: retLang("sent the document", "avez envoyé le document", ""),
    signedDoct: retLang("signed the document", "avez signé le document", ""),
    documentCompleted: retLang("Document completed", "Document terminé", ""),
    removeInvitee: retLang("Remove guest", "Supprimer l'invité", ""),
    removeInviteeMsg: retLang(
      "Do you want to remove this guest?",
      "Voulez-vous supprimer cet invité?",
      ""
    ),
    selectGuests: retLang("Select guests", "Sélectionnez les invités", ""),
    addGuests: retLang("Add guests", "Ajouter invités", ""),
    addGuestsMsg: retLang(
      "Add people who will be guest to this document and can access it.",
      "Ajoutez des personnes qui seront invités à ce document et pourront y accéder.",
      ""
    ),
    dateUpload: retLang("Date upload", "Date téléch.", ""),
    noTags: retLang("No tags", "Pas de mots clés", ""),
    signDoctNow: retLang("Sign this document now", "Signez ce document maintenant", ""),
    prevThumb: retLang("Preview the thumbnail of the first page.", "Aperçu de la miniature de la première page.", ""),
    fileSize: retLang("File size (MB)", "Taille de fichiers (MB)", ""),
    fileSizeMsg: retLang("File size allowed to be uploaded", "Taille de fichier autorisée à être téléchargée", ""),
    fileSizeErrorMsg: retLang("The selected file has a size that exceeds the allowed size.", "Le fichier sélectionné a une taille qui dépasse la taille autorisée.", ""),
    owner: retLang("Owner", "Propriétaire", ""),
    guest: retLang("Guest", "Invité", ""),
    guests: retLang("Guests", "Invités", ""),
    noGuestsSelectedMsg: retLang(
      "No guest has been selected yet. Continue without guests.",
      "Aucun invité n'a encore été sélectionné. Continuez sans invités.",
      ""
    ),
    noGuestOperator: retLang(
      "You cannot select a guest who also owns the document.",
      "Vous ne pouvez pas sélectionner un invité qui est également propriétaire du document.",
      ""
    ),
    loginMsg: retLang(
      "Unlock the power of Document Management",
      "Libérez la puissance de la gestion documentaire.",
      ""
    ),
    loginMsgV: retLang(
      "Streamline your workflow with our document management app. From organizing files to collaboration, it's the ultimate tool for boosting productivity. Say hello to seamless document handling and goodbye to chaos.",
      "Rationalisez votre flux de travail avec notre application de gestion de documents. De l'organisation des fichiers à la collaboration, c'est l'outil ultime pour augmenter la productivité. Dites bonjour à la gestion transparente des documents et adieu au chaos.",
      ""
    ),
    enjoyRed: retLang(
      "Enjoy 50% off on Life Time license",
      "Profitez de 50 % de réduction sur la licence à vie",
      ""
    ),
  };
}

export default GetLanguage;
