import React from "react";


import getLanguages from "src/utils/language";
import { CompSettingsCompon } from "src/components/majorsmallcomps";
import { useNavigate } from "react-router-dom";

const languages = getLanguages();




export default function CompanySettingsPage() {
  document.title = `${languages.companySetts} | Archivix`;
  const navigate = useNavigate();

  const navigateToUrl = (u:string) => {
    navigate(`/app/settings/company/${u}`)
  }
  

  return (
    <div className="flex flex-col space-y-2">
      <div className="mb-2">
        <h3 className="font-bold">{languages.settingsGlance}</h3>
        <p className="text-sm">{languages.settingsGlanceMsg}</p>
      </div>

      {/* Settings */}
      <div className="flex flex-wrap gap-x-2 items-start">
        <CompSettingsCompon settType="stamp" navigateToUrl={navigateToUrl} />
        <CompSettingsCompon settType="signature" navigateToUrl={navigateToUrl} />
        <CompSettingsCompon settType="detail" navigateToUrl={navigateToUrl} />
      </div>
    </div>
  );
}
