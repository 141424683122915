/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from "react";
import { emulateTab } from "emulate-tab";

import appLanguages from "src/utils/language";
import generalFxs from "src/utils/general_fx";

import { ReactComponent as SexSvg } from "src/assets/images/svg/input/sex.svg";
import { ReactComponent as EnvelopeSvg } from "src/assets/images/svg/input/envelope.svg";
import { ReactComponent as OfficeSvg } from "src/assets/images/svg/input/office.svg";
import { ReactComponent as KeySvg } from "src/assets/images/svg/input/key.svg";
import { ReactComponent as LockSvg } from "src/assets/images/svg/input/lock.svg";
import { ReactComponent as PhoneSvg } from "src/assets/images/svg/input/phone.svg";
import { ReactComponent as CalendarSvg } from "src/assets/images/svg/input/calendar.svg";
import { ReactComponent as PersonSvg } from "src/assets/images/svg/input/person.svg";
import { ReactComponent as EyeOnSvg } from "src/assets/images/svg/input/eye.svg";
import { ReactComponent as EyeOffSvg } from "src/assets/images/svg/input/eyeoff.svg";
import { IPassworderChecker } from "src/models/smarttypes";

const languages = appLanguages();
const appFxs = generalFxs();

interface WiseTextInputProps {
  fldName: string;
  label: string;
  fldType: React.HTMLInputTypeAttribute | "select";
  disabled?: boolean;
  placeholder?: string;
  graphicType?:
    | "email"
    | "person"
    | "calendar"
    | "phone"
    | "person"
    | "lock"
    | "key"
    | "office"
    | "sex"
    | undefined;
  onChange?: (fldName: string, fldValue: string) => void;
  textOnDisabled?: string;
  actionOnDisabled?: () => void;
  value?: string;
  errorText?: string;
  maxLength?: number;
  isNumeric?: boolean;
  moveToNext?: boolean;
  useTabEnter?: boolean;
  tabIndex?: number;
  selectallFocus?: boolean;
  checkPassword?: boolean;
  selectOptions?: { value: string | number; label: string }[];
}
export default function WiseTextInput({
  label,
  fldName,
  fldType,
  disabled,
  placeholder,
  graphicType,
  onChange,
  textOnDisabled,
  actionOnDisabled,
  value,
  errorText,
  maxLength,
  isNumeric,
  moveToNext,
  useTabEnter,
  tabIndex,
  selectallFocus,
  checkPassword,
  selectOptions,
}: WiseTextInputProps) {
  const isDis = disabled ?? false;

  const SVGIcon = !graphicType
    ? null
    : graphicType === "email"
    ? EnvelopeSvg
    : graphicType === "person"
    ? PersonSvg
    : graphicType === "calendar"
    ? CalendarSvg
    : graphicType === "phone"
    ? PhoneSvg
    : graphicType === "lock"
    ? LockSvg
    : graphicType === "key"
    ? KeySvg
    : graphicType === "office"
    ? OfficeSvg
    : graphicType === "sex"
    ? SexSvg
    : EnvelopeSvg;

  const mLength = maxLength ?? 35;
  const bNumeric = isNumeric ?? false;
  const bMoveToNext = moveToNext ?? false;
  const bUseTabEnter = useTabEnter ?? true;
  const bSelectAllFoc = selectallFocus ?? true;
  const bCheckPw = checkPassword ?? false;

  const sOptions = selectOptions ?? [
    { value: "", label: languages.notSpecified },
  ];

  const [passVis, setPassVis] = useState(false);

  const [inputType, setInputType] =
    useState<React.HTMLInputTypeAttribute>(fldType);

  /* const [pwStrength, setPwStrength] = useState(0);
  const [pwStatusColor, setPwStatusColor] = useState("#ff0000"); */

  const passWordstatus: IPassworderChecker = useMemo(
    () => appFxs.getThePassStatus(value ?? ''),
    [value]
  );


  const onPwVisibChange = () => {
    const visState = !passVis;
    setPassVis(visState);
    setInputType(visState ? "text" : "password");
  };

  const onChangeVal = (fldVal: string) => {
    if (!onChange) return;
    onChange!(fldName, fldVal);
  };

  const onDisbaledEvtClicked = () => {
    if (!actionOnDisabled) return;
    actionOnDisabled!();
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (bNumeric) appFxs.strictlyNbr(e);
  };

  const onInputKeyup = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (bUseTabEnter) emulateTab();
    } else {
      const len = e.currentTarget.value.length;
      if (len === mLength && bMoveToNext) emulateTab();
    }
  };

  const onFocusInput = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!bSelectAllFoc) return;
    e.target.select();
  };

  return (
    <div className="flex flex-col">
      <label
        htmlFor={fldName}
        className={`text-sm ${
          errorText ? "text-red-600" : "text-black"
        } font-semibold block mb-2`}
      >
        {label}
      </label>
      <div
        className={`flex-1 relative border border-solid overflow-hidden ${
          errorText ? "border-red-400" : "border-gray-400"
        } rounded-md ${
          errorText
            ? "focus-within:border-red-600"
            : "focus-within:border-gray-600"
        }`}
      >
        <div
          className={`cover absolute top-0 left-0 w-full h-full bg-gray-100/40 flex justify-end items-center ${
            isDis ? "block" : "hidden"
          }`}
        >
          {textOnDisabled && (
            <a
              onClick={onDisbaledEvtClicked}
              className="text-blue-400 hover:text-blue-600 text-sm font-bold cursor-pointer pr-4"
            >
              {textOnDisabled}
            </a>
          )}
        </div>
        <div className="flex flex-row items-center gap-2">
          {graphicType && (
            <div className="px-2 flex items-center justify-center">
              {SVGIcon && <SVGIcon height={16} width={16} />}
            </div>
          )}
          {inputType !== "select" && (
            <input
              id={fldName}
              name={fldName}
              type={inputType}
              className={`flex-1 ${
                errorText ? "text-error" : "text-black"
              } font-light focus:border-none focus-visible:border-none focus-visible:outline-none py-3 disabled:bg-transparent ${
                errorText
                  ? "placeholder:text-red-400/60"
                  : "placeholder:text-gray-400/80"
              }`}
              placeholder={placeholder ?? ""}
              onChange={(e) => onChangeVal(e.target.value)}
              value={value}
              maxLength={mLength}
              onKeyPress={onKeyPress}
              onKeyUp={onInputKeyup}
              tabIndex={tabIndex}
              onFocus={onFocusInput}
            />
          )}
          {inputType === "select" && (
            <select
              id={fldName}
              name={fldName}
              onChange={(e) => onChangeVal(e.target.value)}
              value={value}
              tabIndex={tabIndex}
              className={`flex-1 ${
                errorText ? "text-error" : "text-black"
              } font-light focus:border-none focus-visible:border-none focus-visible:outline-none py-3 disabled:bg-transparent ${
                errorText
                  ? "placeholder:text-red-400/60"
                  : "placeholder:text-gray-400/80"
              }`}
            >
              {sOptions.map((o, idx) => (
                <option key={idx} value={o.value}>
                  {o.label}
                </option>
              ))}
            </select>
          )}
          {!passVis && fldType === "password" && (
            <>
              <div
                className="w-8 h-8 flex items-center justify-center cursor-pointer pr-3"
                onClick={onPwVisibChange}
              >
                <EyeOnSvg width={16} height={16} className="cursor-pointer" />
              </div>
            </>
          )}
          {passVis && fldType === "password" && (
            <>
              <div
                className="w-8 h-8 flex items-center justify-center cursor-pointer pr-3"
                onClick={onPwVisibChange}
              >
                <EyeOffSvg width={16} height={16} className="cursor-pointer" />
              </div>
            </>
          )}
        </div>

        {bCheckPw && fldType === "password" && (
          <div className="flex flex-col">
            <div className="w-full h-[3px] bg-gray-300 relative mb-1">
              <div
                className="h-full bg-red-500 absolute left-0"
                style={{
                  width: `${passWordstatus.percentVal}%`,
                  backgroundColor: passWordstatus.percentVal < 50 ? "#ff0000" : passWordstatus.percentVal < 70 ? "#ff6700" : "#0f0",
                }}
              ></div>
            </div>
            <PasswordValidVerifComp password={value ?? ""} />
          </div>
        )}
      </div>
      {errorText && <div className="text-error text-sm">{errorText}</div>}
    </div>
  );
}

interface IPasswordValidVerifComp {
  password: string;
}

const PasswordValidVerifComp = ({ password }: IPasswordValidVerifComp) => {
  const passWordstatus: IPassworderChecker = useMemo(
    () => appFxs.getThePassStatus(password),
    [password]
  );

  return (
    <div className="py-2 flex flex-col space-y-1">
      <PasswordFeatureComp
        eltStatus={passWordstatus.capLetters.status}
        label={languages.capitalLetters}
      />
      <PasswordFeatureComp
        eltStatus={passWordstatus.smLetters.status}
        label={languages.smallLetters}
      />
      <PasswordFeatureComp
        eltStatus={passWordstatus.numChars.status}
        label={languages.digitchars}
      />
      <PasswordFeatureComp
        eltStatus={passWordstatus.specChars.status}
        label={languages.specialChars}
      />
      <PasswordFeatureComp
        eltStatus={passWordstatus.lenChars.status}
        label={languages.passLength}
      />
    </div>
  );
};

interface IPasswordFeatureComp {
  label: string;
  eltStatus: "poor" | "medium" | "high";
}
const PasswordFeatureComp = ({ label, eltStatus }: IPasswordFeatureComp) => {
  return (
    <div className="flex items-center gap-2 px-2">
      <i
        data-status={eltStatus}
        className={`fa ${eltStatus==='high' ? 'fa-check-circle-o': 'fa-circle-o'} text-xs text-gray-500 data-[status='medium']:text-orange-400 data-[status='high']:text-green-500`}
      ></i>
      <span
        data-status={eltStatus}
        className="text-xs flex-1 text-gray-500  data-[status='medium']:text-orange-400 data-[status='high']:text-green-500 data-[status='medium']:font-medium data-[status='high']:font-bold"
      >
        {label}
      </span>
    </div>
  );
};
