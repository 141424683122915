import appDecl from "src/utils/declarations";

import WiseModal from "./modalportal/WiseModal";
import { IArchivixUser } from "src/models/archivix_models";

import imgProfile from "src/assets/images/profile_placeholder.jpg";

import getLang from "src/utils/language";
import { useMemo, useState } from "react";

const languages = getLang();

interface IModalListUsers {
  isOpen: boolean;
  onClose?: (oUser?: IArchivixUser) => void;
  dialogWidth?: number;
  dialogTitle: string;
  lstUsers: IArchivixUser[];
}

export default function ModalListUsers({
  isOpen,
  dialogTitle,
  lstUsers,
  dialogWidth,
  onClose,
}: IModalListUsers) {
  const [filterTxt, setFilterTxt] = useState("");

  const filteredUsers = useMemo(() => {
    return lstUsers;
  }, [lstUsers]);

  const closeDlg = () => {
    if (!onClose) return;
    onClose!();
  };

  if (!isOpen) return null;

  return (
    <WiseModal
      isOpen={isOpen}
      onClose={closeDlg}
      titleBox={dialogTitle}
      width={dialogWidth ?? 450}
      hasFooter={true}
    >
      <div className="flex flex-col">
        <input
          type="text"
          placeholder={languages.search.toLowerCase()}
          value={filterTxt}
          onChange={(e) => setFilterTxt(e.target.value)}
          className="w-full ring-0 focus:outline-none py-2 px-3 border border-neutral-300 rounded-md my-2 placeholder:text-gray-400"
        />
        <ul className="flex flex-col space-y-1 mb-6">
          {filteredUsers.map((oUser, index) => {
            const fName = `${oUser.firstName} ${oUser.lastName}`;
            return (
              <ListUserItem
                key={index}
                oUser={oUser}
                onClick={(e) => onClose!(e)}
                isVisible={
                  filterTxt.length === 0 ||
                  fName.toLowerCase().includes(filterTxt.toLowerCase())
                }
              />
            );
          })}
        </ul>
      </div>
    </WiseModal>
  );
}
interface IListUserItem {
  oUser: IArchivixUser;
  onClick: (e: IArchivixUser) => void;
  isVisible: boolean;
}
const ListUserItem = ({ oUser, onClick, isVisible }: IListUserItem) => {
  const imgPath = !oUser.photoProfile
    ? imgProfile
    : `${appDecl.profilesPath}/${oUser.companyKey}/${oUser.photoProfile}`;

  return (
    <li
      data-visible={isVisible}
      onClick={() => onClick(oUser)}
      className="hidden data-[visible=true]:list-item cursor-pointer bg-neutral-200/40 hover:bg-slate-300/40 border border-solid border-neutral-400 rounded-lg px-2 mb-2"
    >
      <div className="flex items-center gap-2 py-2">
        <div className="w-7 h-7 rounded-full border border-solid border-slate-400 overflow-hidden">
            <img
              src={imgPath}
              alt={`${oUser.firstName} ${oUser.lastName}`}
              className="w-full h-full"
            />
          </div>

          <div className="flex flex-col">
            <div className="flex-1 text-sm">{`${oUser.firstName} ${oUser.lastName}`}</div>
            <span className="text-xs">{oUser.userEmail}</span>
          </div>
      </div>
    </li>
  );
};
