import React, { useMemo, useState } from "react";

import getLanguages from "src/utils/language";
import generalFxs from "src/utils/general_fx";

import NetServices from "src/utils/netservices";
import { IArchUserShort } from "src/models/smarttypes";
import {
  IArchivixUser,
  IDocumentFile,
  IDocumentForlder,
} from "src/models/archivix_models";
import { ShortUserSelectorKeys } from "./majorsmallcomps";

import { WiseSpecialBtn } from "./wise_button_files";

const languages = getLanguages();
const appFxs = generalFxs();

interface IDocumentAccessGranter {
  lstUsers: IArchUserShort[];
  activFile: IDocumentFile;
  afterSaveOps: (
    sFile: IDocumentFile,
    lstUsers: IArchUserShort[],
    pFolder?: IDocumentForlder
  ) => void;
  meUser: IArchivixUser;
}
export default function DocumentAccessGranter({
  lstUsers,
  activFile,
  afterSaveOps,
  meUser,
}: IDocumentAccessGranter) {
  const [saving, setSaving] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState<string[]>(
    activFile.userAccess!
  );

  const [filterTxt, setFilterTxt] = useState("");

  const filteredUsers = useMemo(() => {
    return lstUsers;
  }, [lstUsers]);

  const saveRecord = async () => {
    const meOwner = meUser.userEmail === activFile.createdBy;
    if (!meOwner && !meUser.mainAdmin) {
      appFxs.showAlert(languages.accessRights, languages.accessRightsMsg);
      return;
    }
    const bAsk = await appFxs.showConfirm(
      languages.save,
      languages.fileAccessOperMsg
    );
    if (!bAsk.isConfirmed) return;
    setSaving(true);

    const fData = new FormData();
    fData.append("fileKey", activFile.fileKey);
    fData.append("grantedAccess", JSON.stringify(selectedUsers));

    const oOper = await NetServices.requestPostWithFileAxios(
      "documents/grantaccesstousers",
      fData
    );
    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setSaving(false);
      return;
    }

    afterSaveOps!(
      oOper.archiveFileRecord!,
      oOper.lstOfShortUsers!,
      oOper.parentFolderRecord!
    );
    setSaving(false);
  };

  const updateUser = (email: string, checked: boolean) => {
    let lst = [...selectedUsers];
    if (checked && !lst.includes(email)) {
      lst.push(email);
    }
    if (!checked && lst.includes(email)) {
      lst = lst.filter((o) => o !== email);
    }

    setSelectedUsers(lst);
  };

  return (
    <div className="flex flex-col px-3 py-2 bg-neutral-300/10 rounded-lg border border-neutral-500">
      <div className="flex items-center justify-between">
        <h3 className="flex-1 text-lg font-bold py-2">
          {languages.documentAccess}
        </h3>
        <WiseSpecialBtn iconType="save" 
            label={saving ? languages.saving : languages.save} 
            onClick={() => saveRecord()}
            disabled={saving}
            loading={saving} />
      </div>
      <div className="flex flex-col max-h-">
        <input
          type="text"
          placeholder={languages.search.toLowerCase()}
          value={filterTxt}
          onChange={(e) => setFilterTxt(e.target.value)}
          className="w-full ring-0 focus:outline-none py-2 px-3 border border-neutral-300 rounded-md my-2 placeholder:text-gray-400"
        />
        <ul className="flex flex-col space-y-2">
          {filteredUsers.map((oSh, idx) => (
            <ShortUserSelectorKeys
              key={idx}
              oSh={oSh}
              activFile={activFile}
              updateUser={updateUser}
              keys={selectedUsers}
              isVisible={
                filterTxt.length === 0 ||
                oSh.userName.toLowerCase().includes(filterTxt.toLowerCase())
              }
            />
          ))}
        </ul>
      </div>
    </div>
  );
}
