/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import generalFxs from "src/utils/general_fx";
import appLanguages from "src/utils/language";
import NetServices from "src/utils/netservices";
import WiseTextInput from "src/components/WiseTextInput";
import { IAcctFormRegister } from "src/models/smarttypes";

const appFxs = generalFxs();
const languages = appLanguages();

const isEn = appFxs.getLocalLanguage() === "en";

interface IFormError {
  adminEmail?: string;

  companyEmail?: string;
  companyName?: string;
  shortName?: string;

  registerCode1?: string;
  registerCode2?: string;

  firstName?: string;
  lastName?: string;
  displayName?: string;

  password?: string;
  confirmPassword?: string;
}

const defData: IAcctFormRegister = {
  adminEmail: "",

  registerCode1: "",
  registerCode2: "",

  companyEmail: "",
  companyName: "",
  shortName: "",

  firstName: "",
  lastName: "",
  displayName: "",

  password: "",
  confirmPassword: "",
};

export default function AcctRegisterPage() {
  document.title = `${languages.registerAcct} | Archivix`;
  const [registerStep, setRegisterStep] = useState(0);
  const [formErrors, setFormErrors] = useState<IFormError>({});
  const [formRecord, setFormRecord] = useState<IAcctFormRegister>({
    ...defData,
  });
  const [serverInUse, setServerInUse] = useState(false);

  const navigate = useNavigate();

  const updateFormData = (fld: string, val: string) => {
    setFormRecord({ ...formRecord, [fld]: val });
  };

  const onSendRegister = () => {
    if (registerStep === 0) registerWithEmail();
    if (registerStep === 1) confirmRegisterCode();
    if (registerStep === 2) acceptCompanyData();
    if (registerStep === 3) acceptAdminDetails();
    if (registerStep === 4) acceptAdminPassword();
    if (registerStep === 5) registerCompAdminRec();
  };
  const registerWithEmail = async () => {
    const errors: IFormError = {};

    if (!formRecord.adminEmail) {
      errors.adminEmail = languages.fldRequired;
    } else if (!appFxs.isEmailValid(formRecord.adminEmail)) {
      errors.adminEmail = languages.emailFormat;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setServerInUse(true);

    const oOper = await NetServices.requestPost("account/getstartedwithemail", {
      adminEmail: formRecord.adminEmail,
    });

    if (!oOper.bReturn) {
      const bDismiss = (await appFxs.showAlert(oOper.msgTitle, oOper.msgBody,languages.ok,true)).isDismissed;

      if(bDismiss && oOper.typeReturn === 1200){
        setServerInUse(false);
        setRegisterStep(1);
        return;
      }

      setServerInUse(false);
      return;
    }

    setServerInUse(false);
    setRegisterStep(1);
  };
  const confirmRegisterCode = async () => {
    const errors: IFormError = {};

    if (!formRecord.adminEmail) {
      errors.adminEmail = languages.fldRequired;
    } else if (!appFxs.isEmailValid(formRecord.adminEmail)) {
      errors.adminEmail = languages.emailFormat;
    }

    if (!formRecord.registerCode1 || formRecord.registerCode1.length === 0) {
      errors.registerCode1 = languages.fldRequired;
    }

    if (!formRecord.registerCode2 || formRecord.registerCode2.length === 0) {
      errors.registerCode2 = languages.fldRequired;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setServerInUse(true);

    const oOper = await NetServices.requestPost(
      "account/getstartedcheckregisteremailcode",
      {
        adminEmail: formRecord.adminEmail,
        registerCodes: `${formRecord.registerCode1}${formRecord.registerCode2}`,
      }
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setServerInUse(false);
      return;
    }

    setServerInUse(false);
    setRegisterStep(2);
  };
  const acceptCompanyData = () => {
    const errors: IFormError = {};

    if (!formRecord.companyEmail) {
      errors.companyEmail = languages.fldRequired;
    } else if (!appFxs.isEmailValid(formRecord.companyEmail)) {
      errors.companyEmail = languages.emailFormat;
    }

    if (!formRecord.companyName || formRecord.companyName.length === 0) {
      errors.companyName = languages.fldRequired;
    }

    if (!formRecord.shortName || formRecord.shortName.length === 0) {
      errors.shortName = languages.fldRequired;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setServerInUse(true);

    setTimeout(() => {
      setRegisterStep(3);
      setServerInUse(false);
    }, 1000);
  };
  const acceptAdminDetails = () => {
    const errors: IFormError = {};

    if (!formRecord.firstName || formRecord.firstName.length === 0) {
      errors.firstName = languages.fldRequired;
    }
    if (!formRecord.lastName || formRecord.lastName.length === 0) {
      errors.lastName = languages.fldRequired;
    }

    if (!formRecord.shortName || formRecord.shortName.length === 0) {
      errors.shortName = languages.fldRequired;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setServerInUse(true);

    setTimeout(() => {
      setRegisterStep(4);
      setServerInUse(false);
    }, 1000);
  };

  const acceptAdminPassword = () => {
    const errors: IFormError = {};

    const fChecker = appFxs.getThePassStatus(formRecord.password)

    if (!formRecord.password || formRecord.password.length === 0) {
      errors.password = languages.fldRequired;
    } else if (fChecker.lenChars.status !== 'high' || fChecker.percentVal < 80) {
      errors.password = languages.passValidMsg;
    }

    if (
      !formRecord.confirmPassword ||
      formRecord.confirmPassword.length === 0
    ) {
      errors.confirmPassword = languages.fldRequired;
    } else if (formRecord.confirmPassword !== formRecord.password) {
      errors.confirmPassword = languages.confirmPaswordError;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setServerInUse(true);

    setTimeout(() => {
      setRegisterStep(5);
      setServerInUse(false);
    }, 1000);
  };
  const registerCompAdminRec = async () => {
    const bAsk = await appFxs.showConfirm(
      languages.register,
      languages.registerAcctMsg
    );
    if (!bAsk.isConfirmed) return;

    const data: IAcctFormRegister = {
      ...formRecord,
      confirmPassword: formRecord.password,
    };

    setServerInUse(true);
    const oOper = await NetServices.requestPost("account/registeraccount", {
      registerAccount: data,
    });

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setServerInUse(false);
      return;
    }

    navigate("/account/login");
  };

  return (
    <>
      <h3 className="font-bold tracking-wide text-3xl mt-4 w-text-primary">
        {languages.registerComp}
      </h3>

      <strong className="block mt-4 mb-10">
        {languages.haveAcct}&nbsp;
        <Link
          className="font-bold text-blue-600 hover:text-blue-800"
          to={"/account/login"}
        >
          {languages.logInNow}
        </Link>
      </strong>

      {registerStep === 0 && (
        <div
          className="mt-4"
          dangerouslySetInnerHTML={{ __html: languages.registerCompMsg }}
        ></div>
      )}
      <div className="relative mt-6">
        {registerStep === 0 && (
          <WiseTextInput
            fldName="adminEmail"
            fldType="adminEmail"
            label={languages.email}
            placeholder={languages.yourEmailHere.toLowerCase()}
            graphicType="email"
            value={formRecord.adminEmail}
            onChange={updateFormData}
            errorText={formErrors.adminEmail}
            tabIndex={0}
            disabled={serverInUse}
          />
        )}

        {registerStep === 1 && (
          <div className="flex flex-col gap-3">
            <h3 className="text-lg font-semibold">
              {languages.confirmRegistercodes}
            </h3>
            <div>
              {languages.codeBeenSent} ({formRecord.adminEmail})
            </div>

            <div className="flex flex-col space-y-2">
              <div className="w-full">
                <WiseTextInput
                  fldName="registerCode1"
                  fldType="text"
                  label={`${languages.code} 1`}
                  placeholder="000"
                  graphicType="key"
                  value={formRecord.registerCode1}
                  onChange={updateFormData}
                  errorText={formErrors.registerCode1}
                  maxLength={3}
                  isNumeric={true}
                  moveToNext={true}
                  tabIndex={1}
                  disabled={serverInUse}
                />
              </div>
              <div className="w-full">
                <WiseTextInput
                  fldName="registerCode2"
                  fldType="text"
                  label={`${languages.code} 2`}
                  placeholder="000"
                  graphicType="key"
                  value={formRecord.registerCode2}
                  onChange={updateFormData}
                  errorText={formErrors.registerCode2}
                  maxLength={3}
                  isNumeric={true}
                  moveToNext={true}
                  tabIndex={2}
                  disabled={serverInUse}
                />
              </div>
            </div>
          </div>
        )}

        {registerStep === 2 && (
          <div className="flex flex-col gap-6">
            <h3 className="text-lg font-semibold">
              {languages.companyDetails}
            </h3>
            <WiseTextInput
              fldName="companyEmail"
              fldType="email"
              label={languages.email}
              placeholder={languages.companyEmailHere.toLowerCase()}
              graphicType="email"
              value={formRecord.companyEmail}
              onChange={updateFormData}
              errorText={formErrors.companyEmail}
              tabIndex={3}
              disabled={serverInUse}
            />

            <WiseTextInput
              fldName="companyName"
              fldType="text"
              label={languages.companyName}
              placeholder={languages.companyNameHere.toLowerCase()}
              graphicType="office"
              value={formRecord.companyName}
              onChange={updateFormData}
              errorText={formErrors.companyName}
              tabIndex={4}
              disabled={serverInUse}
            />

            <WiseTextInput
              fldName="shortName"
              fldType="text"
              label={languages.shortName}
              placeholder={languages.shortNameEntr.toLowerCase()}
              graphicType="office"
              value={formRecord.shortName}
              onChange={updateFormData}
              errorText={formErrors.shortName}
              tabIndex={5}
              disabled={serverInUse}
            />
          </div>
        )}

        {registerStep === 3 && (
          <div className="flex flex-col gap-6">
            <h3 className="text-lg font-semibold">
              {languages.adminUserDetails}
            </h3>
            <WiseTextInput
              fldName="firstName"
              fldType="text"
              label={languages.firstName}
              placeholder={languages.typeFirstName.toLowerCase()}
              graphicType="person"
              value={formRecord.firstName}
              onChange={updateFormData}
              errorText={formErrors.firstName}
              tabIndex={6}
              disabled={serverInUse}
            />

            <WiseTextInput
              fldName="lastName"
              fldType="text"
              label={languages.lastName}
              placeholder={languages.typeLastName.toLowerCase()}
              graphicType="person"
              value={formRecord.lastName}
              onChange={updateFormData}
              errorText={formErrors.lastName}
              tabIndex={7}
            />

            <WiseTextInput
              fldName="displayName"
              fldType="text"
              label={languages.displayName}
              placeholder={languages.typeDisplNameFac.toLowerCase()}
              graphicType="person"
              value={formRecord.displayName}
              onChange={updateFormData}
              errorText={formErrors.displayName}
              tabIndex={8}
              disabled={serverInUse}
            />
          </div>
        )}

        {registerStep === 4 && (
          <div className="flex flex-col gap-6">
            <h3 className="text-lg font-semibold">{languages.password}</h3>
            <WiseTextInput
              fldName="password"
              fldType="password"
              label={languages.password}
              placeholder={languages.typePwHere.toLowerCase()}
              graphicType="lock"
              value={formRecord.password}
              onChange={updateFormData}
              errorText={formErrors.password}
              tabIndex={9}
              disabled={serverInUse}
              checkPassword={true}
            />

            <WiseTextInput
              fldName="confirmPassword"
              fldType="password"
              label={languages.confirmPassword}
              placeholder={languages.confPwHere.toLowerCase()}
              graphicType="lock"
              value={formRecord.confirmPassword}
              errorText={formErrors.confirmPassword}
              onChange={updateFormData}
              tabIndex={10}
              disabled={serverInUse}
            />
          </div>
        )}

        {registerStep === 5 && (
          <div className="flex flex-col gap-6">
            <div className="py-4 px-2 border border-solid border-emerald-300 rounded-lg ">
              {isEn && (
                <div className="font-semibold">
                  By clicking <strong>Register</strong>, you accept and comply
                  with&nbsp;
                  <a
                    className="text-blue-600 hover:text-blue-900 font-bold"
                    target="_blank"
                    href="https://archivix.net/legal/terms"
                    rel="noopener"
                  >
                    Terms
                  </a>
                  &nbsp; of &nbsp;
                  <a
                    className="text-blue-600 hover:text-blue-900 font-bold"
                    target="_blank"
                    href="https://archivix.net"
                    rel="noopener"
                  >
                    Archivix
                  </a>
                  .
                </div>
              )}
              {!isEn && (
                <div>
                  Ecliquant sur <strong>S'inscrire</strong>, vous acceptez de
                  vous conformer aux
                  <a
                    className="text-blue-600 hover:text-blue-900 font-bold"
                    target="_blank"
                    href="https://archivix.net/legal/terms"
                    rel="noopener"
                  >
                    Conditions d'utilisation
                  </a>&nbsp;
                  <a
                    className="text-blue-600 hover:text-blue-900 font-bold"
                    target="_blank"
                    href="https://archivix.net"
                    rel="noopener"
                  >
                    Archivix
                  </a>
                  .
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="mt-6">
        <button
          className={`btn btn-sm text-white px-[1.2rem] rounded-lg bg-green-400 hover:bg-green-700 disabled:bg-gray-400 disabled:text-gray-200 disabled:cursor-not-allowed`}
          onClick={() => onSendRegister()}
          disabled={serverInUse}
        >
          <span className="flex flex-row items-center gap-2">
            {serverInUse && <i className="fa fa-spin fa-spinner"></i>}
            <span>
              {serverInUse && <span>{`${languages.loading}...`}</span>}
              {!serverInUse && (
                <span>
                  {registerStep === 0
                    ? languages.getStarted
                    : registerStep === 1
                    ? languages.confirm
                    : registerStep === 2
                    ? languages.next
                    : registerStep === 5
                    ? languages.register
                    : languages.next}
                </span>
              )}
            </span>
          </span>
        </button>
      </div>
    </>
  );
}
