import React from "react";

import { Outlet } from "react-router-dom";

import bg1 from "src/assets/images/bg-abstract.jpg";

import archivix_logo from "src/assets/images/general/archivix_logo_small_black.png";

import appLanguages from "src/utils/language";

const languages = appLanguages();

export default function AccountLayout() {
  document.title = `${languages.accountMgt} | Archivix`;
  return (
    <div className="h-screen w-full flex flex-row">
      <div className="w-full lg:w-[40%]">
        <div className="flex flex-col space-y-5 px-6 pt-8 pb-10 h-screen overflow-y-auto">
          <div className="-ml-3 relative z-[1]">
            <img src={archivix_logo} alt="logo" className="max-w-full" />
          </div>
          <Outlet />
        </div>
      </div>
      <div
        className="hidden lg:block lg:w-[60%] bg-no-repeat bg-center bg-cover relative"
        style={{ backgroundImage: `url(${bg1})` }}
      >
        <div className="absolute bg-black/30 z-0 w-full h-full"></div>
        <div className="cover absolute z-[1] blurry-bg top-0 left-0 w-full h-full pt-12 pl-12">
          <div className="max-w-[450px] flex flex-col space-y-3">
            <h2 className="text-white font-bold text-2xl">
              {languages.loginMsg}
            </h2>
            <p className="text-white mt-6 text-sm font-semibold leading-5">
              {languages.loginMsgV}
            </p>
            <p className="text-white mt-10 text-lg font-semibold leading-5">
              {languages.enjoyRed}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
