import React, { useEffect, useMemo, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import appDecl from "src/utils/declarations";
import getLanguages from "src/utils/language";
import getFxs from "src/utils/general_fx";
import { IDocumentFile, IDocumentForlder } from "src/models/archivix_models";

import NetServices from "src/utils/netservices";
import LoadinInterfaceComp from "src/components/LoadinInterfaceComp";

import { FolderFormModal } from "src/components/FolderFormModal";
import { IDocumentRecordWrapperExtended } from "src/models/smarttypes";
import { ListOfDocscumentRecords } from "src/components/ListOfDocscumentRecords";
import { PreviewerMiniatComp } from "src/components/PreviewerMiniatComp";
import ModalDocumentPreviwer from "src/components/ModalDocumentPreviwer";
import useMediaQuery from "src/utils/primitives/usemediaqueries";
import { WisePagination } from "src/components/wpagination/WisePagination";

const languages = getLanguages();
const appFxs = getFxs();

const defaultFolder: IDocumentForlder = {
  createDate: new Date(),
  createdBy: "",
  companyKey: "",
  folderKey: "",
  folderName: "",
  primaryFolder: true,
  primaryLocation: "",
  secondaryLocation: "",
  tags: [],
  parentFolderKey: "",
};

const PageSize = Number(appFxs.getLocalStorageByKey(appDecl.itemsPerPage, '9'));

export default function ListDocumentsPage() {
  document.title = `${languages.documents} - Archivix`;

  const [folderFormRec, setFolderFormRec] = useState<IDocumentForlder>({
    ...defaultFolder,
  });
  const [folderRecStatus, setFolderRecStatus] = useState<number>(0);
  const [folderDlgTitle, setFolderDlgTitle] = useState<string>(
    languages.newFolder
  );
  const [folderOpen, setFolderOpen] = useState<boolean>(false);

  const [pdfArrayLoading, setPdfArrayLoading] = useState<boolean>(false);

  //const [isFilterMode, setFilterMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const [lstRelatedDocs, setLstRelatedDocs] = useState<
    IDocumentRecordWrapperExtended[]
  >([]);
  const [filteredDocs, setFilteredDocs] = useState<
    IDocumentRecordWrapperExtended[]
  >([]);

  const [padfArray, setPdfArray] = useState<ArrayBufferLike | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [isModelVOpen, setModelVOpen] = useState(false);
  const [isDlgReady, setDlgReady] = useState(false);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return filteredDocs.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, filteredDocs]);

  const isPlatformMobile = useMediaQuery('(max-width: 1023px)')


  const bLoaded = useRef(false);
  const navigate = useNavigate();

  const openFolderDlg = (folderData?: IDocumentForlder) => {
    setFolderFormRec(folderData ?? { ...defaultFolder });
    setFolderRecStatus(folderData ? 1 : 0);
    setFolderDlgTitle(languages.newFolder);
    setFolderOpen(true);
  };

  const openFileDlg = (fileData?: IDocumentFile) => {
    navigate("/app/documents/new");
  };
  const previewFolder = (fldrKey: string) => {
    navigate(`/app/documents/folders/${fldrKey}`);
  };

  const loadListDocuments = async () => {
    setLoading(true);

    const oOper = await NetServices.requestGet(
      "documents/populateallrelateddata"
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setLoading(false);
      return;
    }

    setLstRelatedDocs(oOper.listOfRelatedDoctsExt!);
    setFilteredDocs(oOper.listOfRelatedDoctsExt!);
    setLoading(false);
  };

  const closeDialog = (
    closeType: "folder" | "file",
    lst?: IDocumentRecordWrapperExtended[]
  ) => {
    if (closeType === "folder") setFolderOpen(false);

    if (lst) {
      setLstRelatedDocs(lst);
      setFilteredDocs(lst);
    }
  };

  const deleteDocument = async (folderKey: string) => {
    const bASk = await appFxs.showConfirm(
      languages.deleteFolder,
      languages.deleteFolderMsg
    );
    if (!bASk.isConfirmed) return;

    const oOper = await NetServices.requestPost(
      "documents/deletedocumentfolderrecordextended",
      { folderKey },
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    setLstRelatedDocs(oOper.listOfRelatedDoctsExt!);
    setFilteredDocs(oOper.listOfRelatedDoctsExt!);
  };
  const editFolderDlg = (folderKey: string) => {
    const folderRecord = lstRelatedDocs.find((o) => o.doctKey === folderKey);
    if (!folderRecord) {
      appFxs.showAlert(languages.folderMiss, languages.folderMissMsg);
      return;
    }
    setFolderFormRec(folderRecord.folderData!.folder);
    setFolderRecStatus(1);
    setFolderDlgTitle(folderRecord.doctName);
    setFolderOpen(true);
  };

  const onChangeFilterFld = (eTxt: string) => {
    if (!eTxt) {
      setFilteredDocs(lstRelatedDocs);
      //setFilterMode(false);
      return;
    }

    //setFilterMode(true);

    const filtered = lstRelatedDocs.filter((o) => {
      const ltTxt = eTxt.toLowerCase();
      const bName = o.doctName.toLowerCase().includes(ltTxt);
      const fndRelTags = o.tags.filter((oTag) =>
        oTag.toLowerCase().includes(ltTxt)
      );
      const bTags = fndRelTags.length > 0;

      return bName || bTags;
    });

    setFilteredDocs(filtered);
  };

  const previewDetailFile = async (doctKey: string, fileKey: string) => {
    setPdfArrayLoading(true);

    const filePath = `${appDecl.documentsPath}/${doctKey}/${fileKey}/${fileKey}.pdf`;
    const fileBuffer = await appFxs.getFileBlobBuffer(filePath, true);

    if (!fileBuffer) {
      setPdfArray(null);
      setPdfArrayLoading(false);
      return;
    }

    setPdfArray(fileBuffer);
    setPdfArrayLoading(false);

    if(isPlatformMobile){
      setModelVOpen(true);
    }
  };
  const deleteDoctFile = async (fileKey: string) => {
    const bAsk = await appFxs.showConfirm(
      languages.deleteFile,
      languages.deleteFileMsg
    );
    if (!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPost(
      "documents/deletefiledocumentrecord",
      {
        fileKey: fileKey,
        reloadData: true,
      },
      true
    );
    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    setLstRelatedDocs(oOper.listOfRelatedDoctsExt!);
    setFilteredDocs(oOper.listOfRelatedDoctsExt!);
  };
  const editDoctFile = (fileKey: string) => {
    navigate(`/app/documents/edit/${fileKey}`);
  };
  const detailDoctFile = (fileKey: string) => {
    const path = `/app/documents/previewdoct/${fileKey}`;
    navigate(path);
  };

  const operationFolder = (
    operType: "prev" | "del" | "edit",
    fldKey: string
  ) => {
    if (operType === "prev") previewFolder(fldKey);
    if (operType === "del") deleteDocument(fldKey);
    if (operType === "edit") editFolderDlg(fldKey);
  };
  const operationDoct = (
    operType: "detail" | "prev" | "del" | "edit",
    doctKey: string,
    fileKey: string
  ) => {
    if (operType === "prev") previewDetailFile(doctKey, fileKey);
    if (operType === "del") deleteDoctFile(fileKey);
    if (operType === "edit") editDoctFile(fileKey);
    if (operType === "detail") detailDoctFile(fileKey);
  };

  useEffect(() => {
    if (bLoaded.current) return;
    loadListDocuments();
    bLoaded.current = true;
  }, []);

  useEffect(() => {
    setDlgReady(isPlatformMobile);
    if(!isPlatformMobile){
      setPdfArray(null);
    }
  }, [isPlatformMobile])

  if(loading) return <LoadinInterfaceComp />

  return (
    <>
      <FolderFormModal
        dialogTitle={folderDlgTitle}
        isOpen={folderOpen}
        recordStatus={folderRecStatus}
        currentRecord={folderFormRec}
        onClose={(lst) => closeDialog("folder", lst)}
      />

      {padfArray && (
        <ModalDocumentPreviwer
          isOpen={isModelVOpen && isDlgReady}
          padfArray={padfArray!}
          onClose={() => {
            setModelVOpen(false);
            setPdfArray(null);
          }}
        />
      )}

      <div className="flex flex-wrap justify-between">
        <div className="basis-full lg:basis-[65%]">
            <>
              <ListOfDocscumentRecords
                onFilter={(e) => onChangeFilterFld(e ?? "")}
                addFile={openFileDlg}
                addFolder={() => openFolderDlg()}
                onRefresh={() => loadListDocuments()}
                documents={currentTableData}
                operationFolder={operationFolder}
                operationDoct={operationDoct}
              />
              {/* Table */}
              <WisePagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={filteredDocs.length}
                  pageSize={PageSize}
                  onPageChange={page => setCurrentPage(page)}
                />
            </>
        </div>
        <div className="hidden lg:block lg:basis-[32%]">
          <div className="sticky top-20 ">
            <PreviewerMiniatComp
              loading={pdfArrayLoading}
              pdfFile={padfArray!}
              clearPdf={() => setPdfArray(null)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
