import React, { useEffect, useState } from "react";

import getLanguages from "src/utils/language";
import generalFx from "src/utils/general_fx";
import appDecl from "src/utils/declarations";
import LoadinInterfaceComp from "src/components/LoadinInterfaceComp";
import { WiseSimpleButton } from "src/components/majorsmallcomps";

const languages = getLanguages();
const appFxs = generalFx();

const oLang = appFxs.getLocalStorageByKey(appDecl.currentLang, "en");
const oYear = appFxs.getLocalStorageByKey(appDecl.currentYearbook, "2024");
const oItList = appFxs.getLocalStorageByKey(appDecl.itemsPerPage, "9");
const oFileSize = appFxs.getLocalStorageByKey(appDecl.fileSizeallowed, "2");

const langugesAll:{id: string, label:string}[] = [
  {id: 'en', label: languages.english_m},
  {id: 'fr', label: languages.french_m},
];
const fileSizes:number[] = [2,5,7,10];
const itemsPerList:number[] = [5,9,10,15,20,25,30,35,40,45,50];

const cYear = new Date().getFullYear();
  const appYears = Array.from({ length: 10 }).map(
    (_, i) => cYear - (10 - (i + 1))
  );

export default function AppSettingsPage() {
  document.title = `${languages.settings} | Archivix`;
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);

  const [appLang, setLang] = useState(oLang);
  const [appYear, setYear] = useState<number>(Number(oYear));
  const [itemsPerLst, setPerList] = useState<number>(Number(oItList));
  const [fileSize, setFileSize] = useState<number>(Number(oFileSize));

  

  const refreshSettings = async () => {
    const vLang = appFxs.getLocalStorageByKey(appDecl.currentLang, "en");
    const vYear = appFxs.getLocalStorageByKey(appDecl.currentYearbook, "2024");
    const vFileSize = appFxs.getLocalStorageByKey(appDecl.fileSizeallowed, "2");
    const vItemList = appFxs.getLocalStorageByKey(appDecl.itemsPerPage, "9");

    setLang(vLang);
    setYear(Number(vYear));
    setFileSize(Number(vFileSize));
    setPerList(Number(vItemList));
  };

  const saveSettings = async () => {
    setSaving(true);

    appFxs.setLocalStorageByKey(appDecl.currentLang, appLang);
    appFxs.setLocalStorageByKey(appDecl.currentYearbook, appYear.toString());
    appFxs.setLocalStorageByKey(appDecl.itemsPerPage, itemsPerLst.toString());
    appFxs.setLocalStorageByKey(appDecl.fileSizeallowed, fileSize.toString());

    setTimeout(() => {
      document.location.reload();
    }, 2000);
  };

  useEffect(() => {
    refreshSettings();
    setLoading(false);
  }, []);

  if (loading) return <LoadinInterfaceComp />;
  return (
    <div className="flex flex-col space-y-2">
      <div className="flex items-center mb-3 gap-3">
        <h3 className="flex-1 font-bold text-lg">{languages.settings}</h3>
        <button
          className="btn"
          title={languages.save}
          disabled={saving}
          onClick={() => saveSettings()}
        >
          <i className={`fa ${!saving ? "fa-save" : "fa-spin fa-spinner"}`}></i>
        </button>
      </div>

      <div className="flex flex-col w-full">
        <div className="py-2 flex flex-col">
          <label className="flex flex-wrap gap-3 items-center basis-full md:basis-[49%] mb-3">
            <span className="w-full mb-1 md:w-[150px]">
              {languages.language}
            </span>
            <select
              className="select select-primary border border-solid w-full md:flex-1"
              value={appLang}
              onChange={(e) => setLang(e.target.value)}
              disabled={saving}
            >
              {langugesAll.map((o, idx) => 
                <option key={idx} value={o.id}>{o.label}</option>)}
            </select>
          </label>

          <label className="flex flex-wrap gap-3 items-center basis-full md:basis-[49%] mb-3">
            <span className="w-full mb-1 md:w-[150px]">{languages.year}</span>
            <select
              className="select select-primary border border-solid w-full md:flex-1"
              value={appYear}
              onChange={(e) => setYear(Number(e.target.value))}
              disabled={saving}
            >
              {appYears.map((x, idx) => (
                <option key={idx} value={x}>
                  {x.toString()}
                </option>
              ))}
            </select>
          </label>

          <label className="flex flex-wrap gap-3 items-center basis-full md:basis-[49%] mb-3">
            <span className="w-full mb-1 md:w-[150px]">
              {languages.itemsPerList}
            </span>
            <select
              className="select select-primary border border-solid w-full md:flex-1"
              value={itemsPerLst}
              onChange={(e) => setPerList(Number(e.target.value))}
              disabled={saving}
            >
              {itemsPerList.map((o, idx) => <option key={idx} value={o}>{o}</option>)}
              
            </select>
          </label>

          <label className="flex flex-wrap gap-3 items-center basis-full md:basis-[49%] mb-3">
            <span className="w-full mb-1 md:w-[150px]">
              {languages.fileSize}
            </span>
            <select
              className="select select-primary border border-solid w-full md:flex-1"
              value={fileSize}
              onChange={(e) => setFileSize(Number(e.target.value))}
              disabled={saving}
            >
              {fileSizes.map((o, idx) => <option key={idx} value={o}>{o}</option>)}
              
            </select>
          </label>





          <WiseSimpleButton
            label={languages.save}
            disabled={saving}
            working={saving}
            btnType="default"
            onClick={() => saveSettings()}
          />
        </div>
      </div>
    </div>
  );
}
