/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import FileRecordFormComp from "src/components/FileRecordFormComp";

import { GroupButtonImgSelector } from "src/components/group-button";
import { IDocumentFile } from "src/models/archivix_models";
import { IFileElt } from "src/models/smarttypes";
import { mimeTypes } from "src/utils/app_arch_data";

import generalFxs from "src/utils/general_fx";
import getLanguages from "src/utils/language";
import NetServices from "src/utils/netservices";

const languages = getLanguages();
const appFxs = generalFxs();


export default function NewDocumentPage() {
  document.title = `${languages.newDocument} | Archivix`;
  const {folderKey} = useParams();
  const [saving, setSaving] = useState(false);
  const [mimeType, setMimeType] = useState(mimeTypes[1]);
  const navigate = useNavigate();


  const saveRecord = (lst: IFileElt[], data: IDocumentFile) => {
    setSaving(true);

    if (data.sourceFile === 0) saveImageOper(lst, data);
    if (data.sourceFile !== 0) saveOtherFilesOper(lst, data);
  };

  const saveImageOper = async (lst: IFileElt[], dataForm: IDocumentFile) => {
    const sources = lst.map((o) => o.fileKeyExt);
    const dataFile: IDocumentFile = { ...dataForm, fileSources: sources };

    const dataToSave = new FormData();
    dataToSave.append("documentFileRecord", JSON.stringify(dataFile));

    lst.forEach((o) => {
      dataToSave.append(o.fileKeyExt, o.fileBlob);
    });

    const oOper = await NetServices.requestPostWithFileAxios(
      "documents/saveorupdateimagedocumentextended",
      dataToSave
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setSaving(false);
      return;
    }
    if(folderKey){
      navigate(`/app/documents/folders/${folderKey}`);
    }else{
      navigate('/app/documents');
    }
  };
  const saveOtherFilesOper = async (
    fileSources: IFileElt[],
    dataForm: IDocumentFile
  ) => {
    const sources = fileSources.map((o) => o.fileKeyExt);
    const dataFile: IDocumentFile = { ...dataForm, fileSources: sources };

    const dataToSave = new FormData();
    dataToSave.append("documentFileRecord", JSON.stringify(dataFile));
    dataToSave.append("mainOps", JSON.stringify(false));

    fileSources.forEach((o) => {
      dataToSave.append(o.fileKeyExt, o.fileBlob);
    });

    const pathServer =
      dataForm.sourceFile === 1
        ? "documents/saveorupdatepdfdocumentextended"
        : dataForm.sourceFile === 2
        ? "documents/saveorupdateworddocumentextended"
        : "documents/saveorupdatepdfdocumentextended";

    const oOper = await NetServices.requestPostWithFileAxios(
      pathServer,
      dataToSave
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setSaving(false);
      return;
    }

    if(folderKey){
      navigate(`/app/documents/folders/${folderKey}`);
    }else{
      navigate('/app/documents');
    }
  };

  return (
    <div className="flex flex-col mx-auto h-screen ">
      <div className="max-w-[800px] w-full mx-auto py-5 flex flex-col space-y-3">
        <div className="flex items-center justify-between">
          <div className="flex-1 flex items-center gap-2">
            {!folderKey ? 
              <Link to={'/app/documents'}>
                <i className="fa fa-long-arrow-left"></i>
            </Link>:
            <Link to={`/app/documents/folders/${folderKey}`}>
                <i className="fa fa-long-arrow-left"></i>
            </Link>}
            <h3 className="font-bold text-sm md:text-lg flex-1">
                {languages.newDocument}
            </h3>
          </div>
          <GroupButtonImgSelector
            changeSrcType={(e) => {
                setMimeType(mimeTypes[e]);
            }}
          />
        </div>

        <FileRecordFormComp
          onReceiveData={saveRecord}
          saving={saving}
          mimeType={mimeType}
          folderKey={folderKey}
        />
      </div>
    </div>
  );
}
