/* eslint-disable jsx-a11y/anchor-is-valid */

import moment from "moment";


import { DocumentSignElt, IDoctSignRecip } from "src/models/smarttypes";
import { WiseButtonAction } from "./majorsmallcomps";

import { ReactComponent as DraftFileSvg } from "src/assets/images/svg/draft-file.svg";
import { ReactComponent as CompletedFileSvg } from "src/assets/images/svg/completed-file.svg";
import { ReactComponent as PendingFileSvg } from "src/assets/images/svg/pending-file.svg";
import { ReactComponent as DoctOwnerSvg } from "src/assets/images/svg/doct-owner.svg";
import { ReactComponent as StampIconSvg } from "src/assets/images/svg/stamp-icon.svg";

import { ReactComponent as ThreeDotsSvg } from "src/assets/images/svg/three-dots.svg";

import WiseDropDown from "src/components/wsedropdown/WiseDropDown";
import { colorsBn } from "src/utils/app_arch_data";


import getLanguages from "src/utils/language";
import appDecl from "src/utils/declarations";
import WrapperDropModalElt from "./WrapperDropModalElt";

const languages = getLanguages();

interface IDocumentRowElt {
    rowData: DocumentSignElt,
    openMenu: (data: DocumentSignElt) => void,
    onActionClick: (
      action: "download" | "edit" | "sign" | "complete" | undefined,
      data: DocumentSignElt
    ) => void,
    onClickFile: (
      action: "download" | "edit" | "sign" | "complete" | undefined,
      data: DocumentSignElt
    ) => void,
  }
  export const DocumentRowElt = ({
    rowData,
    onActionClick,
    openMenu,
    onClickFile,
  }: IDocumentRowElt) => {
    const actionType: "download" | "edit" | "sign" | "complete" | undefined =
      rowData.action === "sign"
        ? "sign"
        : rowData.action === "complete"
        ? "complete"
        : rowData.action === "download"
        ? "download"
        : rowData.action === "edit"
        ? "edit"
        : undefined;
  
    const fileStatusTxt =
      rowData.status === "completed"
        ? languages.completed
        : rowData.status === "draft"
        ? languages.draft
        : rowData.status === "pending"
        ? languages.pending
        : "";
  
    const FileStatusIcon =
      rowData.status === "completed"
        ? CompletedFileSvg
        : rowData.status === "draft"
        ? DraftFileSvg
        : rowData.status === "pending"
        ? PendingFileSvg
        : null;
  
    return (
      <tr className="hover:bg-slate-300/50 border-b transition-colors">
        {/* date */}
        <td className="hidden lg:table-cell truncate p-4 align-middle [&:has([role=checkbox])]:pr-0">
          <span>{moment(rowData.created).format("DD/MM/YYYY")}</span>
        </td>
        {/* title */}
        <td className="truncate p-4 align-middle [&amp;:has([role=checkbox])]:pr-0">
          <div className="flex flex-col space-y-1">
            <div className="flex flex-col">
              <a
                onClick={() => onClickFile(actionType, rowData)}
                title={rowData.title}
                className="cursor-pointer block max-w-[10rem] truncate font-medium hover:underline md:max-w-[20rem]"
              >
                {rowData.title}
              </a>
              <span className="flex items-center gap-2 text-[10px]">
                {rowData.hasStamp && <StampIconSvg width={10} height={10} />}
                <span className="flex items-center gap-1">
                  <DoctOwnerSvg width={10} height={10} />
                  {rowData.ownerName}
                </span>
              </span>
            </div>
            <span className="lg:hidden flex items-center gap-2">
              <i className="bi bi-calendar2-week"></i>
              {moment(rowData.created).format("DD/MM/YYYY")}
            </span>
            <span className="lg:hidden flex items-center gap-2">
              <i className="bi bi-people-fill"></i>
              <WiseDropDown
                hasDropdown={false}
                position="left"
                options={rowData.recipients.map((oSign, index) => {
                  return {
                    id: index.toString(),
                    label: oSign.fullName,
                  };
                })}
              >
                <span>{languages.signers}</span>
              </WiseDropDown>
            </span>
          </div>
        </td>
  
        {/* Recipients */}
        <td className="hidden lg:table-cell truncate p-4 align-middle [&amp;:has([role=checkbox])]:pr-0">
          <button
            type="button"
            aria-haspopup="dialog"
            aria-expanded="false"
            aria-controls="radix-:r1um:"
            data-state="closed"
            className="flex cursor-pointer"
          >
            {rowData.recipients.map((oUser, idx) => (
              <RecipientVignette keyPos={idx} vign={oUser} key={idx} />
            ))}
          </button>
        </td>
  
        {/* Status */}
        <td className="truncate p-4 align-middle [&amp;:has([role=checkbox])]:pr-0">
          <span className={`flex items-center ${rowData.status === 'completed' && 'w-text-primary'}`}>
            {FileStatusIcon && <FileStatusIcon width={16} height={16} />}&nbsp;
            {fileStatusTxt}
          </span>
        </td>
        {/* actions */}
        <td className="truncate p-4 align-middle [&amp;:has([role=checkbox])]:pr-0">
          <div className="flex items-center gap-x-4 relative">
            <RowActionButton
              row={rowData}
              onClick={() => onActionClick(actionType, rowData)}
            />
            <WrapperDropModalElt
              afterClick={(x, y, w, h) => {
                openMenu(rowData);
              }}
            >
              <button
                type="button"
                aria-haspopup="menu"
                aria-expanded="false"
                data-state="closed"
              >
                <ThreeDotsSvg width={16} height={16} />
              </button>
            </WrapperDropModalElt>
          </div>
        </td>
      </tr>
    );
  };

  interface IRowActionButton {
    row: DocumentSignElt;
    onClick: () => void;
  }
  const RowActionButton = ({ row, onClick }: IRowActionButton) => {
    const bShow = row.action !== "none";
  
    const iconType: "download" | "edit" | "sign" | "complete" | undefined =
      row.action === "sign"
        ? "sign"
        : row.action === "download"
        ? "download"
        : row.action === "edit"
        ? "edit"
        : row.action === "complete"
        ? "complete"
        : undefined;
  
    const iconText: string =
      row.action === "sign"
        ? languages.sign
        : row.action === "download"
        ? languages.download
        : row.action === "edit"
        ? languages.edit
        : row.action === "complete"
        ? languages.complete
        : "";
    if (!bShow) return null;
    return (
      <WiseButtonAction iconType={iconType} onAction={onClick} title={iconText} />
    );
  };
  
  const RecipientVignette = ({
    vign,
    keyPos,
  }: {
    keyPos: number;
    vign: IDoctSignRecip;
  }) => {
    const oSymb = vign.nameInitials;
    const bVignImg = vign.profilePath && vign.profilePath.length > 0;
    const profilePath = !bVignImg
      ? ""
      : `${appDecl.profilesPath}/${vign.companyKey}/${vign.profilePath}`;
  
    return (
      <span
        title={vign.fullName}
        data-signed={vign.hasSigned}
        data-hasimg={bVignImg}
        data-email={vign.userEmail}
        className="relative flex shrink-0 overflow-hidden rounded-full z-40 -ml-3 h-10 w-10 border-2 border-solid border-white data-[signed=true]:border-green-500"
      >
        {!bVignImg ? (
          <span
            className="flex h-full w-full items-center justify-center rounded-full bg-archivix-200 text-archivix-800"
            style={{ backgroundColor: colorsBn[keyPos], color: "#fff" }}
          >
            {oSymb}
          </span>
        ) : (
          <span
            className="overflow-hidden flex h-full w-full items-center justify-center rounded-full bg-white text-archivix-800"
          >
            <img
              src={profilePath}
              alt=""
              className="w-full h-full object-cover"
            />
          </span>
        )}
      </span>
    );
  };