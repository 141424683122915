/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadinInterfaceComp from "src/components/LoadinInterfaceComp";
import { DocumentSignElt } from "src/models/smarttypes";
import NetServices from "src/utils/netservices";
import appDecl from "src/utils/declarations";
import PDFViewer from "src/components/pdfviewer/PDFViewer";

import genFxs from "src/utils/general_fx";
import getLang from "src/utils/language";

import { ReactComponent as CompletedFileSvg } from "src/assets/images/svg/completed-file.svg";
import { ReactComponent as DraftFileSvg } from "src/assets/images/svg/draft-file.svg";
import { ReactComponent as PendingFileSvg } from "src/assets/images/svg/pending-file.svg";

import { ReactComponent as ArrowLeftSvg } from "src/assets/images/svg/arrow-left.svg";

import { ReactComponent as DownloadIconSvg } from "src/assets/images/svg/download-simple.svg";
import { ReactComponent as EditIconSvg } from "src/assets/images/svg/edit-icon.svg";
import moment from "moment";
import { IArchivixUser } from "src/models/archivix_models";
import {
  ListDocumentGuests,
  ListDocumentHistory,
  ListOfDoctSigners,
} from "src/components/partner_comps";

const appFxs = genFxs();
const languages = getLang();

const currentLang = appFxs.getLocalLanguage();

export default function PreviewPartnerDoctPage() {
  document.title = `${languages.documents} | Archivix`;

  const [doctTitle, setDoctTitle] = useState(languages.loading + "...");
  const { fileKey } = useParams();

  const [isLoading, setLoading] = useState(true);
  const [pdfFile, setPdfFile] = useState<ArrayBufferLike | null>(null);

  const [completeDoctHist, setCompleteDoctHist] =
    useState<DocumentSignElt | null>(null);

  const [currentUser, setCurrentUser] = useState<IArchivixUser | null>(null);

  const [isMyDoct, setIsMyDoct] = useState(false);

  const navigate = useNavigate();

  const downloadOrdEdit = () => {
    if (completeDoctHist?.status === "draft") {
      editFile();
    }
    if (completeDoctHist?.status === "completed") {
      downloadFile();
    }
  };
  const editFile = () => {
    navigate("/app/partners/editor/" + completeDoctHist?.fileKey);
  };
  const downloadFile = () => {
    const filePath =
      completeDoctHist?.status === "completed"
        ? `${appDecl.documentsPartners}/${completeDoctHist?.companyKey}/${completeDoctHist?.fileKey}/signed-${completeDoctHist?.fileKey}.pdf`
        : `${appDecl.documentsPartners}/${completeDoctHist?.companyKey}/${completeDoctHist?.fileKey}/${completeDoctHist?.fileKey}.pdf`;

    appFxs.downloadFromFileUrl(filePath, `${completeDoctHist?.title}.pdf`);
  };

  const populateDataFromServer = async () => {
    const oOper = await NetServices.requestGet(
      `partners/gethistoryforsigndoct/${fileKey}`
    );

    if (!oOper.bReturn) {
      await appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      navigate(`/app/partners`);
      return;
    }
    const oDoctHist = oOper.oneDocumentSignEltHist!;
    const oDoctSigner = oOper.connectedUser!;

    setIsMyDoct(oDoctHist.doctOwner.userEmail === oDoctSigner.userEmail);

    setCurrentUser(oDoctSigner);
    setCompleteDoctHist(oDoctHist);
    setDoctTitle(oDoctHist.title);

    const filePath =
      oDoctHist.status === "completed"
        ? `${appDecl.documentsPartners}/${oDoctHist.companyKey}/${oDoctHist.fileKey}/signed-${oDoctHist.fileKey}.pdf`
        : `${appDecl.documentsPartners}/${oDoctHist.companyKey}/${oDoctHist.fileKey}/${oDoctHist.fileKey}.pdf`;

    const fileBuffer = await appFxs.getFileBlobBuffer(filePath);
    if (!fileBuffer) {
      navigate(`/app/partners`);
      return;
    }

    setTimeout(() => {
      setPdfFile(fileBuffer);
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    populateDataFromServer();
  }, []);

  if (isLoading) return <LoadinInterfaceComp />;

  return (
    <div className="flex flex-col space-y-2">
      {/* Header */}
      <div className="flex flex-col">
        <div className="flex items-center flex-wrap">
          <div className="flex-1 flex items-center gap-2">
            <Link to={"/app/partners"}>
              <ArrowLeftSvg width={16} height={16} />
            </Link>
            <h3 className="text-lg flex-1">{doctTitle}</h3>
          </div>
          <div></div>
        </div>
        <div className="flex items-center gap-2">
          {completeDoctHist!.status === "pending" && (
            <PendingFileSvg width={16} height={16} />
          )}
          {completeDoctHist!.status === "draft" && (
            <DraftFileSvg width={16} height={16} />
          )}
          {completeDoctHist!.status === "completed" && (
            <CompletedFileSvg width={16} height={16} />
          )}

          {completeDoctHist!.status === "pending" && (
            <span className="text-sm">{languages.pending}</span>
          )}
          {completeDoctHist!.status === "draft" && (
            <span className="text-sm">{languages.draft}</span>
          )}
          {completeDoctHist!.status === "completed" && (
            <span className="text-sm">{languages.completed}</span>
          )}
        </div>
      </div>

      {/* Body */}
      <div className="flex flex-wrap mt-8 h-screen overflow-y-auto hide-scroll">
        <div className="w-full md:w-1/2 lg:w-[60%]">
          <div className="p-1 md:p-5">
            <PDFViewer loading={isLoading} file={pdfFile} />
          </div>
        </div>

        <div className="w-full md:w-1/2 lg:w-[40%]">
          <div className="p-1 sticky top-10 ">
            <div className="space-y-6 pt-12">
              {/* doct header */}
              <section className="border-border bg-widget flex flex-col rounded-xl border pb-4 pt-6">
                <div className="flex flex-row items-center justify-between px-4">
                  <h3 className="text-black text-2xl font-semibold">
                    {languages.document}&nbsp;
                    {completeDoctHist?.status === "draft" && (
                      <span>{languages.isDraft}</span>
                    )}
                    {completeDoctHist?.status === "pending" && (
                      <span>{languages.isPending}</span>
                    )}
                    {completeDoctHist?.status === "completed" && (
                      <span>{languages.isCompleted}</span>
                    )}
                  </h3>
                  <div className="dropdown hidden">
                    <button
                      className="dropdown-toggle not-dropdown"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="text-muted-foreground h-5 w-5"
                      >
                        <circle cx="12" cy="12" r="1"></circle>
                        <circle cx="19" cy="12" r="1"></circle>
                        <circle cx="5" cy="12" r="1"></circle>
                      </svg>
                    </button>
                    <ul className="dropdown-menu">
                      <li className="mb-2">
                        <a className="dropdown-item text-sm font-bold pointer-events-none">
                          {languages.actions}
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item cursor-pointer">
                          <span className="flex items-center gap-2 text-sm">
                            <i className="fa fa-download"></i>
                            {languages.download}
                          </span>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item cursor-pointer">
                          <span className="flex items-center gap-2 text-sm">
                            <i className="fa fa-copy"></i>
                            {languages.duplicate}
                          </span>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item cursor-pointer">
                          <span className="flex items-center gap-2 text-sm">
                            <i className="fa fa-trash"></i>
                            {languages.delete}
                          </span>
                        </a>
                      </li>
                      <li className="my-2">
                        <a className="dropdown-item text-sm font-bold pointer-events-none">
                          {languages.share}
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item cursor-pointer">
                          <span className="flex items-center gap-2 text-sm">
                            <i className="fa fa-history"></i>
                            {languages.resend}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <p className="text-black mt-2 px-4 text-sm ">
                  {completeDoctHist?.status === "draft" && (
                    <span>{languages.doctNoneSigned}</span>
                  )}
                  {completeDoctHist?.status === "pending" && (
                    <span>{languages.doctPendSigned}</span>
                  )}
                  {completeDoctHist?.status === "completed" && (
                    <span>{languages.doctAllSigned}</span>
                  )}
                </p>
                {(completeDoctHist?.status === "completed" ||
                  (completeDoctHist?.status === "draft" && isMyDoct)) && (
                  <div className="mt-4 border-t px-4 pt-4">
                    <button
                      className="inline-flex items-center justify-center rounded-md text-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-green-400 text-black font-bold hover:bg-green-400/90 h-10 py-2 px-4 w-full"
                      onClick={downloadOrdEdit}
                    >
                      {completeDoctHist?.status === "draft" && (
                        <EditIconSvg width={16} height={16} />
                      )}
                      {completeDoctHist?.status === "completed" && (
                        <DownloadIconSvg width={16} height={16} />
                      )}
                      &nbsp;
                      {completeDoctHist?.status === "draft" && (
                        <span>{languages.edit}</span>
                      )}
                      {completeDoctHist?.status === "completed" && (
                        <span>{languages.download}</span>
                      )}
                    </button>
                  </div>
                )}
              </section>

              {/* doct info */}
              <section className="dark:bg-background text-foreground border-border bg-widget flex flex-col rounded-xl border">
                <h1 className="px-4 py-3 font-medium">
                  {languages.information}
                </h1>
                <ul className="divide-y border-t">
                  <li className="flex items-center justify-between px-4 py-2.5 text-sm last:border-b">
                    <span className="text-muted-foreground">
                      {languages.uploadedBy}
                    </span>
                    <span>
                      {isMyDoct ? languages.you : completeDoctHist?.ownerName}
                    </span>
                  </li>
                  <li className="flex items-center justify-between px-4 py-2.5 text-sm last:border-b">
                    <span className="text-muted-foreground">
                      {languages.created}
                    </span>
                    <span>
                      {moment(completeDoctHist?.created).format("MMMM D, YYYY")}
                    </span>
                  </li>
                </ul>
              </section>

              {/* signers */}
              <ListOfDoctSigners finalRecipes={completeDoctHist!.recipients} />

              {/* guests */}
              <ListDocumentGuests
                companyKey={currentUser?.companyKey!}
                lang={currentLang}
                operationKey={completeDoctHist?.fileKey!}
              />

              {/* Recent activity */}
              <ListDocumentHistory
                companyKey={currentUser?.companyKey!}
                operationKey={completeDoctHist?.fileKey!}
                currentSigner={currentUser?.userEmail!}
                lang={currentLang}
                doctType="partner"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
