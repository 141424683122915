import { ReactNode } from "react";
import ReactDom from "react-dom";

//import getLanguages from 'src/utils/language';

//const languages = getLanguages();

interface IWiseChatterBoxModel {
  children: ReactNode;
  isOpen: boolean;
  onClose: (retData: any) => void;
}

export const WiseChatterBox = ({
  children,
  isOpen,
  onClose,
}: IWiseChatterBoxModel) => {
  if (!isOpen) return null;
  const nodeElement = document.getElementById("portals")!;

  return ReactDom.createPortal(
    <>
      <div
        className="rounded-t-lg overflow-hidden"
        style={{
          position: "fixed",
          right: "2%",
          bottom: "0px",
          backgroundColor: "#fff",
          padding: "0px",
          zIndex: 1000,
        }}
      >
        {children}
      </div>
    </>,
    nodeElement
  );
};
